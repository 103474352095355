import { Box, Drawer } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { LAYOUT } from '../../utils/constants/layout.constants';
import Logo from './Logo';
import Navigation from './Navigation';

interface DrawerDesktopProps {
  onMenuItemClick: React.MouseEventHandler;
  logout: Function;
}

const useStyles = makeStyles((theme) => ({
  drawer: {
    zIndex: 1100,
    [theme.breakpoints.up('sm')]: {
      width: LAYOUT.DrawerWidth,
    },
  },
  drawerPaper: {
    width: LAYOUT.DrawerWidth,
    backgroundColor: '#fff',
    boxShadow: '0px 4px 16px 1px rgba(76, 83, 94, 0.2)',
  },
}));

const DrawerDesktop: React.FC<DrawerDesktopProps> = ({
  onMenuItemClick,
  logout,
}: DrawerDesktopProps): JSX.Element => {
  const classes = useStyles();

  return (
    <nav className={classes.drawer} aria-label="mailbox folders">
      <Drawer
        classes={{
          paper: classes.drawerPaper,
        }}
        variant="permanent"
        open
      >
        <Box m={8}>
          <Logo
            variant="default"
            width={LAYOUT.DrawerLogoSize.width}
            height={LAYOUT.DrawerLogoSize.height}
          />
        </Box>
        <Navigation onMenuItemClick={onMenuItemClick} logout={logout} />
      </Drawer>
    </nav>
  );
};

export default DrawerDesktop;
