import { Box, Button, Grid, SvgIcon, Typography } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import React from 'react';
import { ReactComponent as AddIcon } from '../../assets/icons/add-icon.svg';
import { LAYOUT } from '../../utils/constants/layout.constants';
import TopPannel from '../common/TopPannel';

interface SectionContentProps {
  handleAddClick: () => void;
  children: React.ReactNode;
  addButtonText: string;
  pageName: string;
}

const useStyles = makeStyles((theme) => ({
  content: {
    marginTop: 64,
    [theme.breakpoints.up('md')]: {
      marginTop: LAYOUT.TopPanelHeight + LAYOUT.TopPanelBottomMargin,
      width: `calc(100% - ${LAYOUT.DrawerWidth}px)`,
      marginLeft: LAYOUT.DrawerWidth,
    },
  },
  button: {
    borderRadius: 10,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    marginRight: theme.spacing(1),
  },
  fullsize: {
    height: `calc(100vh - ${LAYOUT.TopPanelHeight}px - ${LAYOUT.TopPanelBottomMargin}px)`,
  },
  itemOrder: {
    order: 1,
    height: '100%',
    [theme.breakpoints.up('md')]: {
      order: 'initial',
      height: 'auto',
    },
  },
}));

const SectionContent: React.FC<SectionContentProps> = ({
  handleAddClick,
  children,
  addButtonText,
  pageName,
}: SectionContentProps): JSX.Element => {
  const classes = useStyles();
  const theme = useTheme();

  const matchesMediaQuery = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <Box className={classes.content}>
      {matchesMediaQuery ? (
        <TopPannel>
          <Typography variant="h4" color="textPrimary">
            {pageName}
          </Typography>
        </TopPannel>
      ) : (
        <Box ml={4} mb={4}>
          <Typography variant="h4" color="textPrimary" align="left">
            {pageName}
          </Typography>
        </Box>
      )}
      <Box
        mr={{ xs: 0, sm: 0, md: 0, lg: 5 }}
        ml={{ xs: 0, sm: 0, md: 0, lg: 5 }}
      >
        <Grid container direction="row" className={classes.fullsize}>
          <Grid item xs={12} sm={12} md={6} className={classes.itemOrder}>
            {children}
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Box
              display="flex"
              alignContent="left"
              ml={{ xs: 4, sm: 4, md: 4, lg: 25 }}
            >
              <Button
                variant="contained"
                color="secondary"
                disableElevation
                classes={{ root: classes.button }}
                component="label"
                onClick={handleAddClick}
              >
                <SvgIcon component={AddIcon}></SvgIcon>
                {addButtonText}
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default SectionContent;
