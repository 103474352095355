import { format } from 'date-fns';
import {
  createContext,
  useCallback,
  useEffect,
  useReducer,
  useState,
} from 'react';
import { useOrganisation } from '../hooks/services';
import {
  INVITES_ACTIONS,
  InvitesReducer,
  InvitesState,
  initialInvitesState,
} from '../reducers/invites';
import {
  addUserInviteFirebase,
  deleteInviteFirebase,
  getAllInvitesFirebase,
  getAllInvitesForAllOrganisations,
  getInvitesOnScrollFirebase,
  updateInviteFirebase,
} from '../services/invites';
import {
  getUserDetailsFirebaseByEmail,
  triggerSendEmail,
} from '../services/users';
import { User } from '../utils/types/user';

interface InvitesFilter {
  keywords: string;
}

interface InvitesContext {
  state: InvitesState;
  invitesFilter: InvitesFilter;
  filteredInvites?: User[];
  getAllInvites: () => void;
  getInvitesOnScroll: () => void;
  getInvitesDetails: (id: string) => void;
  addUserInvite: (user: any, organisationId: number) => void;
  updateInvite: (user: User) => void;
  remove: (id: string) => void;
  onInvitesKeywordChange: (value: string) => void;
  invitesSearch: () => void;
  chaseInvitedUser: (user: any) => void;
}

export const InvitesStore = createContext<InvitesContext>({
  state: initialInvitesState,
  invitesFilter: { keywords: '' },
  filteredInvites: undefined,
  getAllInvites: () => {},
  getInvitesOnScroll: () => {},
  getInvitesDetails: async (_id: string) => {},
  addUserInvite: async (_user: any) => {},
  updateInvite: async (_user: User) => {},
  remove: (_id: string) => {},
  onInvitesKeywordChange: () => {},
  invitesSearch: () => {},
  chaseInvitedUser: async (_user: any) => {},
});

const { Provider } = InvitesStore;

export const InvitesProvider = ({ children }: any) => {
  const [state, dispatch] = useReducer(InvitesReducer, initialInvitesState);
  const { organisation } = useOrganisation();
  const [invitesFilter, setInvitesFilter] = useState<InvitesFilter>({
    keywords: '',
  });
  const [filteredInvites, setFilterInvites] = useState<User[] | undefined>(
    undefined
  );
  const [fullDataFetched, setFullDataFetched] = useState(false);

  const getAllInvites = useCallback(async () => {
    if (!organisation || !organisation.id) {
      return;
    }

    dispatch({ type: INVITES_ACTIONS.FETCH });

    try {
      const invites = await getAllInvitesFirebase(organisation.id, 20);
      if (invites.length < 20) {
        setFullDataFetched(true);
      }

      dispatch({
        type: INVITES_ACTIONS.FETCH_INVITES_SUCCESS,
        loadingInv: false,
        invites: invites,
      });
    } catch (error) {
      console.error('Failed to fetch invites:', error);
    }
  }, [organisation?.id]);

  useEffect(() => {
    getAllInvites();
  }, [getAllInvites]);

  const getInvitesOnScroll = useCallback(async () => {
    if (fullDataFetched) return;
    dispatch({
      type: INVITES_ACTIONS.LOAD_MORE_INVITES_START,
    });

    try {
      const lastUser: any = state.invites?.[state.invites.length - 1];
      const result = await getInvitesOnScrollFirebase(
        lastUser,
        organisation?.id
      );

      if (result.length < 20) {
        setFullDataFetched(true);
      }

      if (result.length === 1 && result[0].id === lastUser?.id) {
        dispatch({
          type: INVITES_ACTIONS.LOAD_MORE_INVITES_SUCCESS,
          loading: false,
          invites: [],
        });
        return;
      }

      dispatch({
        type: INVITES_ACTIONS.LOAD_MORE_INVITES_SUCCESS,
        loadingInv: false,
        invites: result,
      });
    } catch (error: any) {
      dispatch({
        type: INVITES_ACTIONS.LOAD_MORE_INVITES_FAIL,
        loadingInv: false,
        error: error.message,
      });
    }
  }, [state.invites]);

  const getInvitesDetails = useCallback(
    async (id: string) => {
      dispatch({ type: INVITES_ACTIONS.FETCH_DETAILS });

      const _invite = state?.invites?.find((user: User) => user.id === id);
      if (_invite?.email) {
        dispatch({
          type: INVITES_ACTIONS.FETCH_DETAILS_SUCCESS,
          loadingInv: false,
          invite: _invite,
        });
      }
    },
    [state.invites]
  );

  const onInvitesKeywordChange = useCallback(
    (value: string) => {
      setInvitesFilter({ keywords: value });
      if (!value) {
        setFilterInvites(undefined);
      }
    },
    [setFilterInvites]
  );

  const invitesSearch = useCallback(async () => {
    let _invites: User[] = state.invites || [];
    const { keywords } = invitesFilter;
    const kws = keywords.split(' ').map((kw) => kw.trim());

    if (!fullDataFetched) {
      if (!organisation?.id) return;
      dispatch({ type: INVITES_ACTIONS.FETCH });

      const allInvites = await getAllInvitesFirebase(organisation.id);
      _invites = [...allInvites];
      setFullDataFetched(true);

      dispatch({
        type: INVITES_ACTIONS.FETCH_INVITES_SUCCESS,
        loading: false,
        data: allInvites,
      });
    }

    const _filteredInvites = _invites?.filter((inv) => {
      let res = false;
      for (let i = 0; i < kws.length; i++) {
        if (
          inv?.firstName?.toLowerCase().indexOf(kws[i].toLowerCase()) > -1 ||
          inv?.lastName?.toLowerCase().indexOf(kws[i].toLowerCase()) > -1 ||
          inv?.email?.toLowerCase().indexOf(kws[i].toLowerCase()) > -1
        ) {
          res = true;
        }
      }
      return res;
    });

    setFilterInvites(_filteredInvites);
  }, [invitesFilter, state]);

  const addUserInvite = useCallback(
    async (user: any, organisationId: number) => {
      const { invites } = state;

      const allInvites = await getAllInvitesForAllOrganisations();
      const sameEmailInvite = allInvites.find(
        (invite) => invite.email === user.email
      );
      const sameEmailUser = await getUserDetailsFirebaseByEmail(user.email);
      dispatch({ type: INVITES_ACTIONS.UPDATE_START });
      if (sameEmailInvite || sameEmailUser) {
        dispatch({
          type: INVITES_ACTIONS.UPDATE_FAIL,
          loadingInv: false,
          errorInv: 'This email is already in use!',
        });
      } else {
        const newInvite = await addUserInviteFirebase(
          {
            ...user,
            isRegistered: false,
            organization_id: organisationId,
            email: user.email.toLowerCase(),
          },
          organisationId
        );
        // const template = getTemplate(newInvite.license, newInvite.firstName);
        // await triggerSendEmail(
        //   'Fundpath Invite',
        //   newInvite.email,
        //   template.subject,
        //   template.id,
        //   [
        //     {
        //       var: 'first_name',
        //       value: newInvite.firstName,
        //     },
        //     { var: 'last_name', value: newInvite.lastName },
        //     { var: 'company_name', value: newInvite.company },
        //     {
        //       var: 'expiration_date',
        //       value: format(newInvite.expiry, 'MMMM d, yyyy.'),
        //     },
        //   ]
        // );
        invites?.push(newInvite);
        dispatch({ type: INVITES_ACTIONS.UPDATE_SUCCESS, loadingInv: false });
        return newInvite;
      }
    },
    [state.invites]
  );

  const updateInvite = useCallback(
    async (invite: User) => {
      if (!organisation || !organisation.id) {
        return;
      }
      dispatch({ type: INVITES_ACTIONS.UPDATE_START });

      try {
        await updateInviteFirebase(invite, organisation?.id);
        // const template = getTemplate(invite.license || '', invite.firstName);
        // await triggerSendEmail(
        //   'Fundpath Invite',
        //   invite.email,
        //   'Your invite has been updated.',
        //   template.id,
        //   [
        //     {
        //       var: 'first_name',
        //       value: invite.firstName,
        //     },
        //     { var: 'last_name', value: invite.lastName },
        //     { var: 'company_name', value: invite.company },
        //     {
        //       var: 'expiration_date',
        //       value: format(invite.expiry || new Date(), 'MMMM d, yyyy.'),
        //     },
        //     {
        //       var: 'calendly_url',
        //       value: 'https://calendly.com/benjamin-martin-33',
        //     },
        //   ]
        // );
        const users = await getAllInvitesFirebase(organisation?.id);
        dispatch({ type: INVITES_ACTIONS.UPDATE_SUCCESS, loadingInv: false });
        dispatch({
          type: INVITES_ACTIONS.FETCH_INVITES_SUCCESS,
          invites: users,
        });
      } catch (error) {
        dispatch({
          type: INVITES_ACTIONS.UPDATE_FAIL,
          loadingInv: false,
          errorInv: 'Error updating invite',
        });
      }
    },
    [state.selectedInvite]
  );

  const remove = useCallback(
    async (id: string) => {
      const { invites } = state;
      const _invite = invites?.find((invite: User) => invite.id === id);

      if (_invite?.type === 'invite') {
        dispatch({ type: INVITES_ACTIONS.REMOVE_START });
        await deleteInviteFirebase(_invite.id, organisation?.id);
        invites?.splice(
          invites?.findIndex((invite: User) => invite.id === id),
          1
        );

        dispatch({
          type: INVITES_ACTIONS.REMOVE_SUCCESS,
          invites: invites,
          loadingInv: false,
        });
      }
    },
    [state?.invites]
  );

  const chaseInvitedUser = async (invite: any) => {
    await triggerSendEmail(
      'Fundpath Invitation Reminder',
      invite.email,
      'Your invitation is still pending',
      '3z0vklo7pm7g7qrx',
      [
        {
          var: 'first_name',
          value: invite.firstName,
        },
        { var: 'last_name', value: invite.lastName },
        { var: 'company_name', value: invite.company },
        {
          var: 'expiration_date',
          value: format(invite.expiry, 'MMMM d, yyyy.'),
        },
      ]
    );
  };

  return (
    <Provider
      value={{
        state,
        getAllInvites,
        getInvitesOnScroll,
        getInvitesDetails,
        addUserInvite,
        updateInvite,
        remove,
        onInvitesKeywordChange,
        invitesFilter,
        invitesSearch,
        filteredInvites,
        chaseInvitedUser,
      }}
    >
      {children}
    </Provider>
  );
};
