import React from 'react';
import BranchProfileViewContent from '../../components/directory-profile/BranchProfileViewContent';

interface BranchProfilePageProps {}

const BranchProfilePage: React.FC<BranchProfilePageProps> = (): JSX.Element => {
  return <BranchProfileViewContent />;
};

export default BranchProfilePage;
