import { ButtonProps as MuiButtonProps } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

interface Props {
  disabled: true | false;
}

const useStyles = makeStyles({
  arrowButton: {
    backgroundColor: (props: Props) => (props.disabled ? `#ECEFF1` : `#FF3C00`),
    border: 0,
    borderRadius: 5,
    cursor: (props: Props) => (props.disabled ? `default` : `pointer`),
    color: 'white',
    padding: '0 15px',
  },
});

const ArrowButton = (props: Props & Omit<MuiButtonProps, keyof Props>) => {
  const { ...other } = props;
  const classes = useStyles(props);
  return <button className={classes.arrowButton} {...other} />;
};

export default ArrowButton;
