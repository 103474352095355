import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Grid,
  LinearProgress,
  TextField,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useContext } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';
import StyledButton from '../../components/common/StyledButton';
import WelcomeMessage from '../../components/common/WelcomeMessage';
import { AuthStore } from '../../providers/auth';
import { LOGIN_ROUTE } from '../../routes/Types';
import { resolveMfaSignin } from '../../services/auth';
import { LAYOUT } from '../../utils/constants/layout.constants';

type Position = `position${1 | 2 | 3 | 4 | 5 | 6}`;
type VerificationFormInputs = Record<Position, string>;

const useStyles = makeStyles((theme) => ({
  formWrapper: {
    width: LAYOUT.LoginFormWidth,
  },
  errorMessage: {
    height: theme.spacing(6),
  },
  loader: {
    width: '100%',
    position: 'absolute',
    top: 0,
  },
  digitInputContainer: {
    width: theme.spacing(10),
  },
  digitInput: {
    fontSize: 32,
  },
  title: {
    maxWidth: LAYOUT.LoginFormWidth,
  },
}));

function Verification() {
  const [{ error, loading, mfaError }, _login, _logout, initialLogin] =
    useContext(AuthStore) || [];
  const classes = useStyles();
  let history = useHistory();

  const positions = [1, 2, 3, 4, 5, 6];

  let validationSchema = yup.object().shape({
    position1: yup
      .string()
      .required()
      .matches(/^[0-9]$/),
    position2: yup
      .string()
      .required()
      .matches(/^[0-9]$/),
    position3: yup
      .string()
      .required()
      .matches(/^[0-9]$/),
    position4: yup
      .string()
      .required()
      .matches(/^[0-9]$/),
    position5: yup
      .string()
      .required()
      .matches(/^[0-9]$/),
    position6: yup
      .string()
      .required()
      .matches(/^[0-9]$/),
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = async (data: VerificationFormInputs) => {
    const otp = Object.values(data).join('');
    try {
      await resolveMfaSignin(otp, mfaError);
      initialLogin();
      history.push('/');
    } catch (_e) {
      console.log({ _e });
      history.push(LOGIN_ROUTE);
    }
  };

  const handleOnChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    position: number
  ) => {
    if (event.target.value.length > 0) {
      event.preventDefault();
      if (position === 6) {
        handleSubmit(onSubmit)();
      }
    }
  };

  const handleOnInput = (
    event: React.FormEvent<HTMLDivElement>,
    position: number
  ) => {
    const input = event.target as HTMLInputElement;
    if (input.value.length === 1 && position < 6) {
      const nextPosition = `position${position + 1}` as Position;
      const nextField = document.querySelector<HTMLInputElement>(
        `input[name="${nextPosition}"]`
      );
      nextField?.focus();
    } else if (input.value.length > 1) {
      input.value = input.value.slice(0, 1);
    }
  };

  const handleOnKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (!/[0-9]|Backspace|Delete|Tab|Arrow/.test(event.key)) {
      event.preventDefault();
    }
  };

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      style={{ minHeight: '100vh' }}
    >
      {loading && <LinearProgress className={classes.loader} />}
      <Grid item>
        <WelcomeMessage />
        <Grid container justifyContent="center">
          <form noValidate autoComplete="off">
            <Typography
              variant="subtitle1"
              align="center"
              color="textPrimary"
              className={classes.title}
            >
              Enter the verification code generated by your authenticator app
            </Typography>
            <Grid item className={classes.formWrapper}>
              <Box mb={2} mt={8}>
                <Grid container justifyContent="space-between">
                  {positions.map((position) => (
                    <Box key={position} className={classes.digitInputContainer}>
                      <Controller
                        name={`position${position}`}
                        control={control}
                        defaultValue=""
                        render={({ field: { onChange, ...field } }) => (
                          <TextField
                            InputProps={{
                              classes: {
                                root: classes.digitInput,
                              },
                            }}
                            type={'tel'}
                            onChange={(event) => {
                              onChange(event);
                              handleOnChange(event, position);
                            }}
                            onInput={(event) => handleOnInput(event, position)}
                            onKeyDown={handleOnKeyDown}
                            autoFocus={Boolean(position === 1)}
                            {...field}
                          />
                        )}
                      />
                    </Box>
                  ))}
                </Grid>
                <Typography
                  variant="body1"
                  color="error"
                  className={classes.errorMessage}
                >
                  {errors.email?.message}
                </Typography>
              </Box>
              <Box mb={2} mt={8}>
                <StyledButton
                  type="submit"
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit(onSubmit)}
                >
                  Verify account
                </StyledButton>
              </Box>
              {error && (
                <Typography
                  variant="body1"
                  color="error"
                  className={classes.errorMessage}
                >
                  {error.message}
                </Typography>
              )}
            </Grid>
          </form>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default Verification;
