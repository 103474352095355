import { Box, CircularProgress, Typography } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { DirectoryStore } from '../../providers/directory';
import { StorageProvider } from '../../providers/storage';
import { LAYOUT } from '../../utils/constants/layout.constants';
import {
  companyFields,
  individualFields,
} from '../../utils/constants/profileFields.constants';
import Title from '../common/Title';
import TopPannel from '../common/TopPannel';
import ProfileRecord from '../updates/ProfileRecord';
import BranchesTable from './BranchesTable';
import CompanyLogo from './CompanyLogo';
import PersonnelTable from './PersonnelTable';

interface DirectoryProfileContentProps {}

const useStyles = makeStyles((theme) => ({
  content: {
    position: 'absolute',
    width: '100%',
    top: 0,
    bottom: 0,
    background: theme.palette.background.paper,
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${LAYOUT.DrawerWidth}px)`,
      marginLeft: LAYOUT.DrawerWidth,
    },
  },
  profileDetails: {
    margin: '136px 40px 16px 40px',
    [theme.breakpoints.down('sm')]: {
      margin: '16px 10px 16px 10px',
    },
  },
  anchor: {
    position: 'absolute',
    marginTop: -112,
  },
  title: {
    marginTop: theme.spacing(7),
  },
  radioGroup: {
    flexDirection: 'row',
  },
  backArrowIcon: {
    width: 32,
    height: 32,
    marginRight: theme.spacing(3),
  },
  dividerThick: {
    height: 2,
    color: 'rgba(33, 43, 53, 0.4)',
    marginTop: theme.spacing(2),
  },
  button: {
    width: 268,
    height: 40,
    borderRadius: 10,
    justifyContent: 'flex-start',
    paddingTop: theme.spacing(3),
    paddingLeft: theme.spacing(4),
    paddingBottom: theme.spacing(3),
    paddingRight: theme.spacing(4),
    marginBottom: theme.spacing(3),
  },
  icon: {
    paddingRight: theme.spacing(2),
  },
}));

const DirectoryProfileViewContent: React.FC<
  DirectoryProfileContentProps
> = (): JSX.Element => {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const matchesMediaQuery = useMediaQuery(theme.breakpoints.up('md'));
  const [shouldDisplayProfile, setShouldDisplayProfile] =
    useState<boolean>(false);
  const {
    directoryState: { keyPeople, branches },
    getRecordById,
    getCompanyDetails,
  } = useContext(DirectoryStore);
  const [profile, setProfile] = useState<any>(undefined);

  let { id } = useParams<any>();

  const handleRedirectLinkClick = () => {
    history.goBack();
  };

  useEffect(() => {
    if (id) {
      const getProfile = async () => {
        const profile = await getRecordById(id);
        setProfile(profile);
      };

      getProfile();

      if (profile?.type === 'company') {
        getCompanyDetails(id);
      }
    }
  }, [id, profile?.type, getRecordById, getCompanyDetails]);

  const onLogoUpdate = useCallback((_logoPath: string) => {
    //
  }, []);

  useEffect(() => {
    if (id) {
      setTimeout(() => {
        setShouldDisplayProfile(true);
      }, 50);
    }
  }, [id]);

  return (
    <Box className={classes.content}>
      {matchesMediaQuery ? (
        <TopPannel>
          <Title
            onRedirectLinkClick={handleRedirectLinkClick}
            pageName={'Back to all profiles'}
          />
        </TopPannel>
      ) : (
        <Box ml={2} mt={25} textAlign={'left'}>
          <Title
            onRedirectLinkClick={handleRedirectLinkClick}
            pageName={'Back to all profiles'}
          />
        </Box>
      )}
      {!shouldDisplayProfile && <CircularProgress />}
      {shouldDisplayProfile && (
        <Box className={classes.profileDetails}>
          {profile?.type === 'individual' && (
            <>
              <Box textAlign={'left'} mb={6}>
                <Typography
                  variant={'h5'}
                >{`${profile?.data?.['IND_First_Name']} ${profile?.data?.['IND_Last_Name']} / ${profile?.data?.['COM_Company_Name']}`}</Typography>
              </Box>
              <ProfileRecord
                title=""
                data={profile?.data}
                mapping={individualFields}
              />
            </>
          )}
          {profile?.type === 'branch' && (
            <ProfileRecord
              title=""
              data={profile?.data}
              mapping={individualFields}
            />
          )}
        </Box>
      )}
      {profile?.type === 'company' && (
        <Box className={classes.profileDetails}>
          <Box textAlign={'left'} mb={6}>
            <Typography
              variant={'h5'}
            >{`${profile?.data?.['COM_Company_Name']}`}</Typography>
          </Box>
          <StorageProvider>
            <CompanyLogo
              profile={profile}
              name={profile?.data?.['COM_Company_Name']}
              onLogoUpdate={onLogoUpdate}
            />
          </StorageProvider>
          {keyPeople && <PersonnelTable people={keyPeople} />}
          {branches && <BranchesTable branches={branches} />}
          {shouldDisplayProfile && (
            <ProfileRecord
              title={''}
              data={profile?.data}
              mapping={companyFields}
            />
          )}
        </Box>
      )}
    </Box>
  );
};

export default DirectoryProfileViewContent;
