import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ToggleButton from '@material-ui/lab/ToggleButton';
import React from 'react';
import { useFocused, useSlate } from 'slate-react';

interface TextEditorMarkButtonProps {
  format: string;
  isMarkActive: Function;
  toggleMark: Function;
  children: any;
  onMouseDown?: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

const useStyles = makeStyles((theme) => ({
  button: {
    width: 24,
    height: 24,
    border: 'none',
    backgroundColor: theme.palette.secondary.main,
    borderRadius: 2,
  },
}));

const TextEditorMarkButton: React.FC<TextEditorMarkButtonProps> = ({
  format,
  isMarkActive,
  toggleMark,
  children,
  onMouseDown,
}: TextEditorMarkButtonProps): JSX.Element => {
  const editor = useSlate();
  const classes = useStyles();
  const isFocused = useFocused();

  const handleOnMouseDown = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (!isFocused && format === 'link') return;
    event.preventDefault();
    toggleMark(editor, format);
    onMouseDown && onMouseDown(event);
  };

  return (
    <Box ml={1} mt={1}>
      <ToggleButton
        value={format}
        selected={isMarkActive(editor, format)}
        onMouseDown={handleOnMouseDown}
        classes={{ root: classes.button }}
        color="secondary"
        disableRipple={true}
      >
        {children}
      </ToggleButton>
    </Box>
  );
};

export default TextEditorMarkButton;
