import { DirectoryListItem } from '../services/directory';
export interface DirectoryState {
  directoryList: DirectoryListItem[] | null;
  keyPeople?: Array<any>;
  branches?: Array<any>;
  loading: boolean;
  total: number | undefined;
  error: any;
}

export const initialDirectoryState: DirectoryState = {
  directoryList: null,
  total: undefined,
  loading: false,
  error: null,
};

export const DIRECTORY_ACTIONS = {
  FETCH: 'DIRECTORY/FETCH',
  FETCH_DATA_SUCCESS: 'DIRECTORY/SUCCESS',
  FETCH_DATA_FAIL: 'DIRECTORY/FAIL',
  KEY_PEOPLE_SUCCESS: 'DIRECTORY/KEY_PEOPLE_SUCCESS',
  BRANCHES_SUCCESS: 'DIRECTORY/BRANCHES_SUCCESS',
  RESET: 'DIRECTORY/RESET',
};

export const DirectoryReducer = (
  state: DirectoryState,
  action: any
): DirectoryState => {
  switch (action.type) {
    case DIRECTORY_ACTIONS.FETCH:
      return {
        directoryList: state.directoryList,
        total: state.total,
        loading: true,
        error: null,
        keyPeople: undefined,
        branches: undefined,
      };
    case DIRECTORY_ACTIONS.FETCH_DATA_SUCCESS:
      return {
        directoryList: action.data,
        total: action.total,
        loading: false,
        error: null,
      };
    case DIRECTORY_ACTIONS.FETCH_DATA_FAIL:
      return {
        directoryList: null,
        total: undefined,
        loading: false,
        error: action.error,
      };
    case DIRECTORY_ACTIONS.KEY_PEOPLE_SUCCESS:
      return { ...state, keyPeople: action.data };
    case DIRECTORY_ACTIONS.BRANCHES_SUCCESS:
      return { ...state, branches: action.data };
    case DIRECTORY_ACTIONS.RESET:
      return { ...initialDirectoryState };
    default:
      throw new Error();
  }
};
