import { PendingUpdatesListItem } from '../services/updates';
export interface UpdatesState {
  updatesList: PendingUpdatesListItem[] | null;
  oldProfileRecord: any;
  updatedProfileRecord: any;
  updatedRecords?: any;
  loading: boolean;
  updating: boolean;
  error: any;
  totalChanges?: number;
}

export const initialUpdatesState = {
  updatesList: null,
  oldProfileRecord: null,
  updatedProfileRecord: null,
  loading: false,
  updating: false,
  error: null,
};

export const UPDATES_ACTION = {
  FETCH: 'UPDATES/FETCH',
  FETCH_DATA_SUCCESS: 'UPDATES/SUCCESS',
  FETCH_DATA_FAIL: 'UPDATES/FAIL',
  FETCH_REVIEW_OLD_RECORD: 'UPDATES/FETCH_REVIEW_OLD_RECORD',
  FETCH_REVIEW_OLD_RECORD_SUCCESS: 'UPDATES/FETCH_REVIEW_OLD_RECORD_SUCCESS',
  FETCH_REVIEW_OLD_RECORD_ERROR: 'UPDATES/FETCH_REVIEW_OLD_RECORD_ERROR',
  FETCH_REVIEW_UPDATED_RECORD: 'UPDATES/FETCH_REVIEW_UPDATED_RECORD',
  FETCH_REVIEW_UPDATED_RECORD_SUCCESS:
    'UPDATES/FETCH_REVIEW_UPDATED_RECORD_SUCCESS',
  FETCH_REVIEW_UPDATED_RECORD_ERROR:
    'UPDATES/FETCH_REVIEW_UPDATED_RECORD_ERROR',
  UPDATE: 'UPDATES/UPDATE',
  UPDATE_SUCCESS: 'UPDATES/UPDATE_SUCCESS',
  UPDATE_ERROR: 'UPDATES/UPDATE_ERROR',
};

export const UpdatesReducer = (
  state: UpdatesState,
  action: any
): UpdatesState => {
  switch (action.type) {
    case UPDATES_ACTION.FETCH:
      return {
        ...state,
        updatesList: null,
        oldProfileRecord: null,
        updatedProfileRecord: null,
        loading: true,
        error: null,
      };
    case UPDATES_ACTION.FETCH_DATA_SUCCESS:
      return {
        ...state,
        updatesList: action.data,
        oldProfileRecord: null,
        updatedProfileRecord: null,
        loading: false,
        error: null,
      };
    case UPDATES_ACTION.FETCH_DATA_FAIL:
      return {
        ...state,
        updatesList: null,
        oldProfileRecord: null,
        updatedProfileRecord: null,
        loading: false,
        error: null,
      };
    case UPDATES_ACTION.FETCH_REVIEW_OLD_RECORD:
      return { ...state, oldProfileRecord: null, loading: true, error: null };
    case UPDATES_ACTION.FETCH_REVIEW_OLD_RECORD_SUCCESS:
      return {
        ...state,
        oldProfileRecord: action.data,
        loading: false,
        error: null,
      };
    case UPDATES_ACTION.FETCH_REVIEW_OLD_RECORD_ERROR:
      return {
        ...state,
        oldProfileRecord: null,
        loading: false,
        error: null,
      };
    case UPDATES_ACTION.FETCH_REVIEW_UPDATED_RECORD:
      return {
        ...state,
        updatedProfileRecord: null,
        loading: true,
        error: null,
      };
    case UPDATES_ACTION.FETCH_REVIEW_UPDATED_RECORD_SUCCESS:
      return {
        ...state,
        updatedRecords: action.data,
        totalChanges: action.totalChanges,
        loading: false,
        error: null,
      };
    case UPDATES_ACTION.FETCH_REVIEW_UPDATED_RECORD_ERROR:
      return {
        ...state,
        updatedProfileRecord: null,
        loading: false,
        error: null,
      };
    case UPDATES_ACTION.UPDATE:
      return {
        ...state,
        updating: true,
      };
    case UPDATES_ACTION.UPDATE_SUCCESS:
      return {
        ...state,
        updating: false,
      };
    case UPDATES_ACTION.UPDATE_ERROR:
      return {
        ...state,
        updating: false,
        error: action.error,
      };
    default:
      throw new Error();
  }
};
