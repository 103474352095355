export enum DeleteProfileTab {
  individuals = 'individuals',
  companies = 'companies',
}

export enum DeletedProfileType {
  individuals = 'removedIndividuals',
  companies = 'removedCompanies',
}

export interface DeletedIndividualSource {
  First_Name: string;
  Surname: string;
  Job_Title: string;
  Company_Name: string;
  Region: string;
}

export interface DeletedCompanySource {
  Company_Name: string;
  Company_Type: string;
  Region: string;
}

export interface DeletedProfileListItem {
  name: string;
  role: string;
  data: DeletedIndividualSource | DeletedCompanySource;
}
