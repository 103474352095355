import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useCallback, useContext } from 'react';
import { UsersStore } from '../../providers/users';
import WrappedDialog from '../common/WrappedDialog';

interface ResetPasswordDialogProps {
  isDialogOpened: boolean;
  onDialogClose: () => void;
  onDialogOpen: () => void;
  link: string;
}

const useStyles = makeStyles((theme) => ({
  button: {
    borderRadius: 10,
    marginLeft: theme.spacing(2),
  },
  content: {
    padding: 0,
  },
  link: {
    wordWrap: 'break-word',
  },
}));

const ResetPasswordDialog: React.FC<ResetPasswordDialogProps> = ({
  isDialogOpened,
  onDialogClose,
  link,
}: ResetPasswordDialogProps): JSX.Element => {
  const classes = useStyles();
  const { updateResetLinkCopied } = useContext(UsersStore);

  const copyLinkToClipboard = useCallback(() => {
    if (link) {
      navigator.clipboard.writeText(link);
      updateResetLinkCopied(true);
    }
    onDialogClose();
  }, [link]);

  return (
    <WrappedDialog
      open={isDialogOpened}
      handleDialogClose={onDialogClose}
      dialogTitle={'Password recovery link'}
    >
      <DialogContent classes={{ root: classes.content }}>
        <Box m={10}>
          <Typography classes={{ root: classes.link }}>{link}</Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Box mt={3} mb={3} mr={8}>
          <Button
            onClick={onDialogClose}
            color="secondary"
            variant="contained"
            disableElevation
            classes={{ root: classes.button }}
          >
            Close
          </Button>
          <Button
            onClick={copyLinkToClipboard}
            color="secondary"
            variant="contained"
            disableElevation
            classes={{ root: classes.button }}
          >
            Copy to clipboard
          </Button>
        </Box>
      </DialogActions>
    </WrappedDialog>
  );
};

export default ResetPasswordDialog;
