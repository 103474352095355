import { Box } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useCallback, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { useUserRoleRestrictions } from '../../hooks/services/useUserRoleRestrictions';
import { AuthStore } from '../../providers/auth';
import * as ROUTES from '../../routes/Types';
import { UserRole } from '../../utils/types/user';
import DrawerDesktop from './DrawerDesktop';
import DrawerMobile from './DrawerMobile';

declare global {
  interface Window {}
}

function DashboardWrap({ children }: any) {
  const [{ user }, , logout] = useContext(AuthStore);
  const { isUserRole: isUserAdmin } = useUserRoleRestrictions(
    UserRole.SuperAdmin
  );
  let history = useHistory();

  const onMenuItemClick = useCallback(
    (item) => {
      const { type } = item;
      if (type === 'Directory') {
        history.push(ROUTES.DIRECTORY_ROUTE);
        return;
      }

      if (type === 'Upload Data') {
        history.push(ROUTES.UPLOAD_DATA_ROUTE);
        return;
      }

      if (type === 'Delete Profiles') {
        history.push(ROUTES.DELETE_PROFILES_ROUTE);
        return;
      }

      if (type === 'Articles') {
        history.push(ROUTES.ARTICLES_ROUTE);
        return;
      }

      if (type === 'Knowledge Centre') {
        history.push(ROUTES.KNOWLEDGE_CENTRE_ROUTE);
        return;
      }

      if (type === 'Glossary') {
        history.push(ROUTES.GLOSSARY_ROUTE);
        return;
      }

      if (type === 'Tooltips') {
        history.push(ROUTES.TOOLTIPS_ROUTE);
        return;
      }

      if (type === 'User Administration' && isUserAdmin) {
        history.push(ROUTES.ORGANISATIONS_ADMIN_ROUTE);
        return;
      }

      if (type === 'Publish') {
        history.push(ROUTES.UPDATES_ROUTE);
        return;
      }

      // if (type === 'Usage') {
      //   history.push(ROUTES.USAGE_ROUTE);
      //   return;
      // }

      if (type === 'System') {
        history.push(ROUTES.SYSTEM_ROUTE);
        return;
      }
    },
    [history]
  );

  const theme = useTheme();
  const matchesMediaQuery = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <Box>
      {!!user.role && (
        <>
          {matchesMediaQuery ? (
            <DrawerDesktop onMenuItemClick={onMenuItemClick} logout={logout} />
          ) : (
            <DrawerMobile onMenuItemClick={onMenuItemClick} logout={logout} />
          )}
          {children}
        </>
      )}
    </Box>
  );
}

export default DashboardWrap;
