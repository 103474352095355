export enum ArticleTileSize {
  Large = 'large',
  Medium = 'medium',
  Small = 'small',
}

export const ARTICLE_TILE_SIZE_OPTIONS = [
  {
    name: 'Large',
    value: ArticleTileSize.Large,
  },
  {
    name: 'Medium',
    value: ArticleTileSize.Medium,
  },
  {
    name: 'Small',
    value: ArticleTileSize.Small,
  },
];

export enum ArticleTarget {
  Pro = 'pro',
  All = 'all',
  Go = 'go',
}

export const ARTICLE_TARGET_OPTIONS = [
  {
    name: 'Go',
    value: ArticleTarget.Go,
  },
  {
    name: 'Pro',
    value: ArticleTarget.Pro,
  },
  {
    name: 'All',
    value: ArticleTarget.All,
  },
];
