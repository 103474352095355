import React from 'react';
import ArticlesEditContent from '../../components/articles/ArticlesEditContent';

interface ArticlesAddPageProps {}

const ArticlesAddPage: React.FC<ArticlesAddPageProps> = (): JSX.Element => {
  return <ArticlesEditContent />;
};

export default ArticlesAddPage;
