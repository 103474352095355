import React from 'react';
import GlossaryEditContent from '../../components/glossary/GlossaryEditContent';

interface GlossaryEditPageProps {}

const GlossaryEditPage: React.FC<GlossaryEditPageProps> = (): JSX.Element => {
  return <GlossaryEditContent />;
};

export default GlossaryEditPage;
