import { Box, CircularProgress, Typography } from '@material-ui/core';
import React, { useContext } from 'react';
import { DeleteProfilesStore } from '../../providers/deleteProfiles';
import DeletedCompaniesList from './DeletedCompaniesList';
import DeletedProfilesLayout from './DeletedProfilesLayout';

interface DeletedCompaniesViewProps {}

const DeletedCompaniesView: React.FC<
  DeletedCompaniesViewProps
> = (): JSX.Element => {
  const {
    state: { loading, deletedCompanies, error },
  } = useContext(DeleteProfilesStore);

  const total = deletedCompanies ? deletedCompanies.length : 0;

  return (
    <DeletedProfilesLayout>
      <Box minHeight={'600px'}>
        {!loading && total === 0 && (
          <Typography>
            {'No results are matching your search criteria'}
          </Typography>
        )}
        {error && <Typography>{error}</Typography>}
        {total > 0 && !loading && (
          <DeletedCompaniesList
            deletedCompaniesList={deletedCompanies || []}
          ></DeletedCompaniesList>
        )}
        {loading && <CircularProgress />}
      </Box>
    </DeletedProfilesLayout>
  );
};

export default DeletedCompaniesView;
