import { format } from 'date-fns';
import React, { useCallback, useContext, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';
import { ArticlesStore } from '../../providers/articles';
import { ARTICLES_EDIT_ROUTE } from '../../routes/Types';
import { LAYOUT } from '../../utils/constants/layout.constants';
import { Article } from '../../utils/types/article';
import VirtualizedListItem from '../common/VirtualizedListItem';
import RemoveConfirmationDialog from '../manage-tiles/RemoveConfirmationDialog';
import ArticlesListItemContent from './ArticlesListItemContent';

interface ArticlesListProps {
  articlesList: Article[];
  loadMoreItemsHandler: (lastDoc: Article) => void;
}

interface ListRowProps {
  style: any;
  index: number;
  data: Article[];
}

const Row = ({ style, index, data }: ListRowProps) => {
  let history = useHistory();
  const [isRemoveDialogOpened, setIsRemoveDialogOpened] = useState(false);
  const [article, setArticle] = useState<Article | null>(null);
  const { remove } = useContext(ArticlesStore);

  const onConfirmDialogClose = () => {
    setIsRemoveDialogOpened(false);
  };

  const onRemove = () => {
    setIsRemoveDialogOpened(true);
    setArticle(data[index]);
  };

  const onEdit = useCallback(
    (id: string) => {
      history.push(ARTICLES_EDIT_ROUTE.replace(':id', id));
    },
    [history]
  );
  return (
    <VirtualizedListItem
      style={{
        ...style,
        width: 'calc(100% - 40px)',
        marginLeft: 20,
        marginRight: 20,
        marginTop: 10,
      }}
    >
      <ArticlesListItemContent
        date={format(data[index].published, "MMM, dd, yyyy hh:mm aaaaa'm'")}
        title={data[index].title}
        id={data[index].id}
        image={data[index].image}
        onRemove={() => onRemove()}
        onEdit={() => onEdit(data[index].id || '')}
      ></ArticlesListItemContent>
      {article && (
        <RemoveConfirmationDialog
          isDialogOpened={isRemoveDialogOpened}
          onDialogClose={onConfirmDialogClose}
          dataToRemove={article}
          title="Do you want to remove this article?"
          remove={remove}
        />
      )}
    </VirtualizedListItem>
  );
};

const ArticlesList: React.FC<ArticlesListProps> = ({
  articlesList,
  loadMoreItemsHandler,
}): JSX.Element => {
  const itemsCount = articlesList.length;
  const listRef = useRef<FixedSizeList>(null);

  const {
    state: { loading },
  } = useContext(ArticlesStore);

  const loadMoreItems = useCallback(
    (startIndex: number, stopIndex: number) => {
      const shouldReload = itemsCount - stopIndex <= 2;

      if (shouldReload && !loading) {
        loadMoreItemsHandler(articlesList[itemsCount - 1]);
      }
    },
    [loading, itemsCount, loadMoreItemsHandler]
  );

  const isItemLoaded = useCallback((_index: number) => {
    //TODO: dynamic loading is not available yet
    // console.log(index);
    return false;
  }, []);

  return (
    <AutoSizer>
      {({ height, width }: any) => (
        <InfiniteLoader
          isItemLoaded={isItemLoaded}
          itemCount={itemsCount}
          loadMoreItems={loadMoreItems}
        >
          {({ onItemsRendered }) => (
            <FixedSizeList
              onItemsRendered={onItemsRendered}
              height={height}
              itemCount={itemsCount}
              itemSize={LAYOUT.VirtuilizedArticlesListItemHeight}
              width={width}
              itemData={articlesList}
              ref={listRef}
            >
              {Row}
            </FixedSizeList>
          )}
        </InfiniteLoader>
      )}
    </AutoSizer>
  );
};

export default ArticlesList;
