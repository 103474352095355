import { createContext, useCallback, useEffect, useReducer } from 'react';
import {
  DELETE_PROFILE_ACTIONS,
  DeleteProfilesReducer,
  DeleteProfilesState,
  initialDeleteProfileState,
} from '../reducers/deleteProfiles';
import {
  addUploadToStore,
  getCompanies,
  getIndividuals,
  listenForStatusUpdate,
} from '../services/deleteProfiles';
import { uploadToFirebase } from '../services/uploads';
import {
  DeleteProfileTab,
  DeletedProfileType,
} from '../utils/types/deleteProfiles';

interface DeleteProfilesContext {
  state: DeleteProfilesState;
  upload: (file: File) => void;
  getAllIndividuals: () => void;
  reset: () => void;
  onTabChange: (tab: DeleteProfileTab) => void;
}

export const DeleteProfilesStore = createContext<DeleteProfilesContext>({
  state: initialDeleteProfileState,
  upload: (_file: File) => {},
  getAllIndividuals: () => {},
  reset: () => {},
  onTabChange: () => {},
});

const { Provider } = DeleteProfilesStore;

export const DeleteProfilesProvider = ({ children }: any) => {
  const [state, dispatch] = useReducer(
    DeleteProfilesReducer,
    initialDeleteProfileState
  );

  const reset = useCallback(async () => {
    dispatch({ type: DELETE_PROFILE_ACTIONS.RESET });
  }, []);

  const getAllIndividuals = useCallback(async () => {
    dispatch({ type: DELETE_PROFILE_ACTIONS.FETCH });
    try {
      const deletedIndividualsList = await getIndividuals();
      dispatch({
        type: DELETE_PROFILE_ACTIONS.FETCH_DELETED_INDIVIDUALS_SUCCESS,
        data: deletedIndividualsList,
      });
    } catch (error: any) {
      dispatch({
        type: DELETE_PROFILE_ACTIONS.SET_ERROR,
        error: error?.toString(),
      });
    }
  }, []);

  const getAllCompanies = useCallback(async () => {
    dispatch({ type: DELETE_PROFILE_ACTIONS.FETCH });
    try {
      const deletedCompaniesList = await getCompanies();
      dispatch({
        type: DELETE_PROFILE_ACTIONS.FETCH_DELETED_COMPANIES_SUCCESS,
        data: deletedCompaniesList,
      });
    } catch (error: any) {
      dispatch({
        type: DELETE_PROFILE_ACTIONS.SET_ERROR,
        error: error?.toString(),
      });
    }
  }, []);

  useEffect(() => {
    getAllIndividuals();
  }, [getAllIndividuals]);

  const onTabChange = useCallback(
    (tab: DeleteProfileTab) => {
      dispatch({
        type: DELETE_PROFILE_ACTIONS.SET_SELECTED_TAB,
        selectedTab: tab,
      });
      if (tab === DeleteProfileTab.companies && !state.deletedCompanies) {
        getAllCompanies();
      }
    },
    [state.selectedTab, state.deletedCompanies, getAllCompanies]
  );

  const reloadOnPublish = useCallback(
    async (id: string) => {
      const filePublished = await listenForStatusUpdate(id);
      if (filePublished) {
        state.selectedTab === DeleteProfileTab.individuals
          ? getAllIndividuals()
          : getAllCompanies();
      }
    },
    [state.selectedTab]
  );

  const upload = useCallback(
    async (file: File) => {
      try {
        dispatch({ type: DELETE_PROFILE_ACTIONS.UPLOAD_START });
        const url = await uploadToFirebase(file, false);
        const uploadedFileType =
          state.selectedTab === DeleteProfileTab.individuals
            ? DeletedProfileType.individuals
            : DeletedProfileType.companies;
        if (url && file.name) {
          const uploadId = await addUploadToStore(
            url,
            file.name,
            uploadedFileType
          );
          await reloadOnPublish(uploadId);
        }
        dispatch({
          type: DELETE_PROFILE_ACTIONS.UPLOAD_SUCCESS,
          loading: false,
        });
      } catch (error) {
        dispatch({
          type: DELETE_PROFILE_ACTIONS.SET_ERROR,
          loading: false,
          error:
            'Wrong file format. Please upload a CSV file with the right format.',
        });
        // You may want to handle or log the error accordingly
      }
    },
    [state.selectedTab]
  );

  return (
    <Provider
      value={{
        state,
        upload,
        reset,
        getAllIndividuals,
        onTabChange,
      }}
    >
      {children}
    </Provider>
  );
};
