import 'firebase/functions';
import { httpsCallable } from 'firebase/functions';
import { getElasticToken } from './elasticAuth';
import { fbFunctions } from './firebase';

export type UpdateStatus = 'updateSuccess' | 'updateRejected' | 'updatePending';
export interface DirectoryListItem {
  id: string;
  name: string;
  logo?: string;
  role: string;
  type: string;
  status: string;
  updateStatus: UpdateStatus;
  data: any;
  score: number;
}

export interface DirectoryFilterType {
  search?: {
    keyword?: string;
    match?: string;
  };
  types?: Array<string>;
  statuses?: Array<string>;
  letter?: string;
  additionalTypes?: Array<string>;
}

const parseIndividualResult = (result: any): DirectoryListItem => {
  return {
    id: result._source.IND_FPIND_ID,
    name: `${result._source.IND_First_Name} ${result._source.IND_Last_Name}`,
    role: `${result._source.IND_Job_Title} at ${result._source.COM_Company_Name}`,
    type: 'individual',
    status: 'healthy',
    updateStatus: 'updateSuccess',
    data: result._source,
    score: result._score || 1,
  };
};

const parseCompanyResult = (result: any): DirectoryListItem => {
  return {
    id: result._source.COM_FPCOM_ID,
    name: result._source.COM_Company_Name,
    logo: result._source.COM_Logo,
    role: result._source.COM_Company_Type,
    type: 'company',
    status: 'healthy',
    updateStatus: 'updateSuccess',
    data: result._source,
    score: result._score || 1,
  };
};

const parseBranchResult = (result: any): DirectoryListItem => {
  return {
    id: result._source.BRA_FPBRA_ID,
    name: `${result._source.BRA_Address_City} (${result._source.BRA_Office_Type})`,
    role: `${result._source.BRA_Address_Region}, ${result._source.BRA_Address_Country}`,
    type: 'branch',
    status: 'healthy',
    updateStatus: 'updateSuccess',
    data: result._source,
    score: result._score || 1,
  };
};

export const fetchDirectoryData = async (
  _page?: number
): Promise<{ total: number; page: number; data: Array<DirectoryListItem> }> => {
  return await searchDirectoryData({});
};

const searchIndividuals = async (
  elasticCredentials: any,
  filter: DirectoryFilterType,
  _page?: number
) => {
  const {
    search: { keyword, match = 'standard' } = { search: { match: 'standard' } },
    letter,
    statuses = [],
    additionalTypes = [],
  } = filter;
  let shouldFilters: any[] = [];
  let mustFilters: any[] = [];
  let mustNotFilters: any[] = [];

  if (letter) {
    shouldFilters.push({
      wildcard: {
        IND_First_Name: {
          value: `${letter}`,
          case_insensitive: true,
        },
      },
    });
  } else {
    if (match === 'standard') {
      const keywords = keyword?.trim().split(' ');
      keywords?.forEach((kw: string, index: number) => {
        shouldFilters.push({
          wildcard: {
            IND_First_Name: {
              value: `*${kw}*`,
              case_insensitive: true,
              boost: index === 0 && keywords.length > 1 ? 1.5 : 1,
            },
          },
        });
        shouldFilters.push({
          wildcard: {
            IND_Last_Name: {
              value: `*${kw}*`,
              case_insensitive: true,
              boost: index === 1 ? 1.5 : 1,
            },
          },
        });
        shouldFilters.push({
          wildcard: {
            COM_Company_Name: {
              value: `*${kw}*`,
              case_insensitive: true,
              boost: 0.5,
            },
          },
        });
        shouldFilters.push({
          wildcard: {
            IND_FPIND_ID: {
              value: `*${kw}*`,
              case_insensitive: true,
              boost: 0.5,
            },
          },
        });
        shouldFilters.push({
          wildcard: {
            BRA_FPBRA_ID: {
              value: `*${kw}*`,
              case_insensitive: true,
              boost: 0.5,
            },
          },
        });
      });
    }
  }
  mustFilters = [
    {
      bool: {
        should: shouldFilters,
      },
    },
  ];

  if (match === 'exact') {
    const keywords = keyword?.trim().split(' ');
    mustFilters.push({
      bool: {
        should: [
          {
            bool: {
              must: [
                {
                  match: {
                    IND_First_Name: `${keywords && keywords[0]}`,
                  },
                },
                {
                  match: {
                    IND_Last_Name: `${keywords && keywords[1]}`,
                  },
                },
              ],
            },
          },
          {
            match: {
              COM_FPCOM_ID: `${keyword}`,
            },
          },
          {
            match: {
              BRA_FPBRA_ID: `${keyword}`,
            },
          },
        ],
      },
    });
  }

  if (statuses.length > 0) {
    //place statuses in must query in order to filter results from shold filters
    const queryStatuses = [...statuses];
    if (queryStatuses.includes('Needs Reviewing')) {
      queryStatuses.push('Due for Review');
    }

    mustFilters.push({
      terms: {
        IND_Record_Status: queryStatuses,
      },
    });
  }

  if (additionalTypes.length > 0) {
    if (additionalTypes.includes('live')) {
      mustFilters.push({
        term: {
          IND_Record_Is_Live: true,
        },
      });
    }

    if (additionalTypes.includes('notLive')) {
      mustNotFilters.push({
        term: {
          IND_Record_Is_Live: true,
        },
      });
    }

    if (additionalTypes.includes('missingLogo')) {
      mustNotFilters.push({
        exists: {
          field: 'COM_Logo',
        },
      });
    }
  }

  try {
    let query = undefined;
    query = {
      query: {
        bool: {
          must: mustFilters,
          must_not: mustNotFilters,
        },
      },
      size: 1000,
    };

    if (!isSearchWithKeyword(keyword)) {
      query = {
        ...query,
        sort: [{ IND_First_Name: 'asc' }, { IND_Last_Name: 'asc' }],
      };
    }

    const response = await fetch(
      `${elasticCredentials.host}/individuals/_search`,
      {
        method: 'POST',
        headers: {
          Authorization: `${elasticCredentials.type} ${elasticCredentials.access_token}`,
          'Content-type': 'application/json',
        },
        body: JSON.stringify({
          ...query,
        }),
      }
    );
    const data = await response.json();
    const results = data.hits.hits.map((hit: any) =>
      parseIndividualResult(hit)
    );

    const total = data.hits.total.value;

    return {
      results,
      total,
    };
  } catch (e) {
    return null;
  }
};

const isSearchWithKeyword = (keyword: string | undefined) => {
  const keywords = keyword?.split(' ');
  return keywords && keywords.join('');
};

const searchCompanies = async (
  elasticCredentials: any,
  filter: DirectoryFilterType,
  _page?: number
) => {
  const {
    search: { keyword, match = 'standard' } = {},
    letter,
    statuses = [],
    additionalTypes = [],
  } = filter;

  let shouldFilters: any[] = [];
  let mustFilters: any[] = [];
  let mustNotFilters: any[] = [];

  if (letter) {
    if (letter.indexOf('#') > -1) {
      for (let i = 0; i < 10; i++) {
        shouldFilters.push({
          wildcard: {
            COM_Company_Name: {
              value: `${i}*`,
              case_insensitive: true,
            },
          },
        });
      }
    } else {
      shouldFilters.push({
        wildcard: {
          COM_Company_Name: {
            value: `${letter}`,
            case_insensitive: true,
          },
        },
      });
    }
  } else {
    if (match === 'standard') {
      const keywords = keyword?.trim().split(' ');

      keywords?.forEach((kw: string) => {
        shouldFilters.push({
          wildcard: {
            COM_Company_Name: {
              value: `*${kw}*`,
              case_insensitive: true,
              boost: 1.5,
            },
          },
        });
        shouldFilters.push({
          wildcard: {
            COM_Company_Also_Known_as_1: {
              value: `*${kw}*`,
              case_insensitive: true,
              boost: 1.5,
            },
          },
        });
        shouldFilters.push({
          wildcard: {
            COM_Company_Also_Known_as_2: {
              value: `*${kw}*`,
              case_insensitive: true,
              boost: 1.5,
            },
          },
        });
        shouldFilters.push({
          wildcard: {
            COM_Company_Also_Known_as_3: {
              value: `*${kw}*`,
              case_insensitive: true,
              boost: 1.5,
            },
          },
        });
        shouldFilters.push({
          wildcard: {
            COM_FPCOM_ID: {
              value: `*${kw}*`,
              case_insensitive: true,
              boost: 1.5,
            },
          },
        });
        shouldFilters.push({
          wildcard: {
            BRA_FPBRA_ID: {
              value: `*${kw}*`,
              case_insensitive: true,
              boost: 1.5,
            },
          },
        });
      });
    }
  }

  mustFilters = [
    {
      bool: {
        should: shouldFilters,
      },
    },
  ];

  if (match === 'exact') {
    mustFilters.push({
      bool: {
        should: [
          {
            match: {
              COM_Company_Name: `${keyword}`,
            },
          },
          {
            match: {
              COM_FPCOM_ID: `${keyword}`,
            },
          },
          {
            match: {
              BRA_FPBRA_ID: `${keyword}`,
            },
          },
        ],
      },
    });
  }

  if (statuses.length > 0) {
    const queryStatuses = [...statuses];
    if (queryStatuses.includes('Needs Reviewing')) {
      queryStatuses.push('Due for Review');
    }

    mustFilters.push({
      terms: {
        COM_Record_Status: queryStatuses,
      },
    });
  }

  if (additionalTypes.length > 0) {
    if (additionalTypes.includes('live')) {
      mustFilters.push({
        term: {
          COM_Record_Is_Live: true,
        },
      });
    }

    if (additionalTypes.includes('notLive')) {
      mustNotFilters.push({
        term: {
          COM_Record_Is_Live: true,
        },
      });
    }

    if (additionalTypes.includes('missingLogo')) {
      mustNotFilters.push({
        exists: {
          field: 'COM_Logo',
        },
      });
    }
  }

  try {
    let query = undefined;
    query = {
      query: {
        bool: {
          must: mustFilters,
          must_not: mustNotFilters,
        },
      },
      size: 1000,
    };

    if (!isSearchWithKeyword(keyword)) {
      query = {
        ...query,
        sort: [{ COM_Company_Name: 'asc' }],
      };
    }

    const response = await fetch(
      `${elasticCredentials.host}/companies/_search`,
      {
        method: 'POST',
        headers: {
          Authorization: `${elasticCredentials.type} ${elasticCredentials.access_token}`,
          'Content-type': 'application/json',
        },
        body: JSON.stringify({
          ...query,
        }),
      }
    );
    const data = await response.json();
    const results = data.hits.hits.map((hit: any) => parseCompanyResult(hit));

    const total = data.hits.total.value;

    return {
      results,
      total,
    };
  } catch (e) {
    return null;
  }
};

export const searchDirectoryData = async (
  filter: DirectoryFilterType,
  page?: number
): Promise<{ total: number; page: number; data: Array<DirectoryListItem> }> => {
  const { types } = filter;

  let elasticCredentials;
  try {
    elasticCredentials = await getElasticToken();
  } catch (e) {
    throw Error('Error authenticating with search database.');
  }

  try {
    let individualResults: any;
    if (!types || !types.length || types.indexOf('individual') > -1) {
      individualResults = await searchIndividuals(
        elasticCredentials,
        filter,
        page
      );
    }

    let companiesResults: any;
    if (!types || !types.length || types.indexOf('company') > -1) {
      companiesResults = await searchCompanies(
        elasticCredentials,
        filter,
        page
      );
    }

    let mergedData = [...(individualResults?.results || [])];
    mergedData = mergedData.concat(companiesResults?.results || []);

    const { search: { keyword } = {} } = filter;

    //sort merged data either by relevance or alphabetically
    if (isSearchWithKeyword(keyword)) {
      mergedData = mergedData.sort(
        (d1: DirectoryListItem, d2: DirectoryListItem) => d2.score - d1.score
      );
    } else {
      mergedData = mergedData.sort(
        (d1: DirectoryListItem, d2: DirectoryListItem) => {
          return d1.name > d2.name ? 1 : -1;
        }
      );
    }

    return new Promise((resolve) => {
      resolve({
        total: (individualResults?.total || 0) + (companiesResults?.total || 0),
        page: page || 0,
        data: mergedData,
      });
    });
  } catch (e) {
    console.log(e);
    throw Error('Error connecting the database.');
  }
};

export const updateCompanyLogo = async (
  companyId: string,
  logoPath: string,
  shouldGenerateTile: boolean
) => {
  try {
    const companyUpdate = httpsCallable(fbFunctions, 'companyUpdate');

    const result = await companyUpdate({
      id: companyId,
      logo: logoPath,
      shouldGenerateTile,
    });

    return result.data;
  } catch (error) {
    console.error('Error updating company logo:', error);
    throw error;
  }
};

export const getElasticBranches = async (id: string) => {
  let elasticCredentials;
  try {
    elasticCredentials = await getElasticToken();
  } catch (e) {
    throw Error('Error authenticating with search database.');
  }

  try {
    const query = {
      query: {
        term: {
          COM_FPCOM_ID: { value: id },
        },
      },
      size: 1000,
      sort: [{ COM_Company_Name: 'asc' }],
    };
    const response = await fetch(
      `${elasticCredentials.host}/branches/_search`,
      {
        method: 'POST',
        headers: {
          Authorization: `${elasticCredentials.type} ${elasticCredentials.access_token}`,
          'Content-type': 'application/json',
        },
        body: JSON.stringify({
          ...query,
          size: 50,
          from: 0,
          sort: [{ COM_Company_Name: 'asc' }],
        }),
      }
    );

    const data = await response.json();
    const results = data.hits.hits.map((hit: any) => parseBranchResult(hit));

    const total = data.hits.total.value;

    return {
      results,
      total,
    };
  } catch (e) {
    console.log(e);
  }
};

export const getElasticKeyPeople = async (id: string) => {
  let elasticCredentials;
  try {
    elasticCredentials = await getElasticToken();
  } catch (e) {
    throw Error('Error authenticating with search database.');
  }

  try {
    const query = {
      query: {
        bool: {
          must: [
            {
              term: {
                COM_FPCOM_ID: { value: id },
              },
            },
          ],
        },
      },
      size: 1000,
      sort: [{ IND_First_Name: 'asc' }, { IND_Last_Name: 'asc' }],
    };
    const response = await fetch(
      `${elasticCredentials.host}/individuals/_search`,
      {
        method: 'POST',
        headers: {
          Authorization: `${elasticCredentials.type} ${elasticCredentials.access_token}`,
          'Content-type': 'application/json',
        },
        body: JSON.stringify({
          ...query,
          size: 50,
          from: 0,
          sort: [{ COM_Company_Name: 'asc' }],
        }),
      }
    );

    const data = await response.json();
    const results = data.hits.hits.map((hit: any) =>
      parseIndividualResult(hit)
    );

    const total = data.hits.total.value;

    return {
      results,
      total,
    };
  } catch (e) {
    console.log(e);
  }
};

export const getIndividual = async (id: string) => {
  let elasticCredentials;
  try {
    elasticCredentials = await getElasticToken();
  } catch (e) {
    throw Error('Error authenticating with search database.');
  }

  try {
    const query = {
      query: {
        term: {
          IND_FPIND_ID: { value: id },
        },
      },
    };
    const response = await fetch(
      `${elasticCredentials.host}/individuals/_search`,
      {
        method: 'POST',
        headers: {
          Authorization: `${elasticCredentials.type} ${elasticCredentials.access_token}`,
          'Content-type': 'application/json',
        },
        body: JSON.stringify({
          ...query,
          size: 1,
        }),
      }
    );

    const data = await response.json();
    const results = data.hits.hits.map((hit: any) =>
      parseIndividualResult(hit)
    );

    return results?.[0] ?? null;
  } catch (e) {
    console.log(e);
  }
};

export const getBranch = async (id: string) => {
  let elasticCredentials;
  try {
    elasticCredentials = await getElasticToken();
  } catch (e) {
    throw Error('Error authenticating with search database.');
  }

  try {
    const query = {
      query: {
        term: {
          BRA_FPBRA_ID: { value: id },
        },
      },
    };
    const response = await fetch(
      `${elasticCredentials.host}/branches/_search`,
      {
        method: 'POST',
        headers: {
          Authorization: `${elasticCredentials.type} ${elasticCredentials.access_token}`,
          'Content-type': 'application/json',
        },
        body: JSON.stringify({
          ...query,
          size: 1,
        }),
      }
    );

    const data = await response.json();
    const results = data.hits.hits.map((hit: any) => hit);

    return results?.[0] ?? null;
  } catch (e) {
    console.log(e);
  }
};
