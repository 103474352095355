import { createContext, useCallback, useReducer } from 'react';
import {
  UPLOADS_ACTIONS,
  UploadsReducer,
  UploadsState,
  initialUploadsState,
} from '../reducers/uploads';
import {
  addUploadToStore,
  getUploads,
  uploadToFirebase,
} from '../services/uploads';
import { UploadRecord } from '../utils/types/upload';
interface UploadsContext {
  state: UploadsState;
  upload: (file: File) => void;
  send: (type: string) => void;
  getAll: () => void;
  reset: () => void;
  getScheduled: () => void;
}

export const UploadsStore = createContext<UploadsContext>({
  state: initialUploadsState,
  upload: (_file: File) => {},
  send: (_type: string) => {},
  getAll: () => {},
  reset: () => {},
  getScheduled: () => {},
});

const { Provider } = UploadsStore;

export const UploadsProvider = ({ children }: any) => {
  const [state, dispatch] = useReducer(UploadsReducer, initialUploadsState);

  const upload = useCallback(async (file: File) => {
    dispatch({ type: UPLOADS_ACTIONS.UPLOAD_START });
    const url = await uploadToFirebase(file, false);
    dispatch({
      type: UPLOADS_ACTIONS.UPLOAD_SUCCESS,
      loading: false,
      url,
      fileName: file.name,
    });
  }, []);

  const send = useCallback(
    async (type: string) => {
      if (state.url && state.fileName)
        await addUploadToStore(state.url, state.fileName, type);
    },
    [state.url, state.fileName]
  );

  const reset = useCallback(async () => {
    dispatch({ type: UPLOADS_ACTIONS.RESET });
  }, []);

  const getAll = useCallback(async () => {
    dispatch({ type: UPLOADS_ACTIONS.UPLOAD_START, loading: true });
    getUploads((uploads: Array<UploadRecord>) => {
      const withoutScheduled = uploads.filter(
        (upload: UploadRecord) => upload.scheduledDate === undefined
      );

      dispatch({
        type: UPLOADS_ACTIONS.FETCH_DATA_SUCCESS,
        loading: false,
        data: withoutScheduled,
      });
    });
  }, []);

  const getScheduled = useCallback(async () => {
    dispatch({ type: UPLOADS_ACTIONS.UPLOAD_START, loading: true });
    getUploads((uploads: Array<UploadRecord>) => {
      const scheduled = uploads.filter(
        (upload: UploadRecord) => upload.scheduledDate !== undefined
      );

      dispatch({
        type: UPLOADS_ACTIONS.FETCH_DATA_SUCCESS,
        loading: false,
        data: scheduled,
      });
    });
  }, []);

  return (
    <Provider
      value={{
        state,
        upload,
        reset,
        send,
        getAll,
        getScheduled,
      }}
    >
      {children}
    </Provider>
  );
};
