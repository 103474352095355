import { makeStyles } from '@material-ui/core/styles';

interface Props {}
const useStyles = makeStyles({
  visibleSpan: {
    display: 'flex',
    alignItems: 'center',
    paddingRight: 30,
    fontSize: 14,
    fontWeight: 'bold',
  },
});

const FlexSpan = (props: Props) => {
  const { ...other } = props;
  const classes = useStyles(props);
  return <span className={classes.visibleSpan} {...other} />;
};

export default FlexSpan;
