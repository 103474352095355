import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { AuthStore } from '../../providers/auth';
import {
  addOrganization,
  updateOrganization,
} from '../../services/organisations';
import { OrganisationAddFormData } from '../../utils/types/organisation';

export const useManageOrganisation = (
  mode: 'create' | 'edit',
  organisationId?: number
) => {
  const [{ user }] = useContext(AuthStore);
  const { id: orgId } = useParams<{ id: string }>();
  const id = Number(organisationId || orgId);
  const queryClient = useQueryClient();

  const organisationHandler = async (
    organisationData: OrganisationAddFormData
  ) => {
    if (user.role !== 'superadmin') {
      return Promise.reject(new Error('Unauthorized attempt'));
    }

    if (mode === 'create') {
      return addOrganization(organisationData);
    } else if (mode === 'edit' && id !== undefined) {
      return updateOrganization(id, organisationData);
    } else {
      return Promise.reject(new Error('Missing ID for updating organisation'));
    }
  };

  return useMutation(organisationHandler, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['organizations', 'lists'] });
      if (mode === 'edit') {
        queryClient.invalidateQueries({
          queryKey: ['organizations', 'details', id],
        });
      }
    },

    onError: (error) => {
      console.error(
        `Error ${mode === 'create' ? 'creating' : 'updating'} organisation:`,
        error
      );
    },
  });
};
