import {
  DeleteProfileTab,
  DeletedProfileListItem,
} from '../utils/types/deleteProfiles';

export interface DeleteProfilesState {
  deletedIndividuals: DeletedProfileListItem[] | null;
  deletedCompanies: DeletedProfileListItem[] | null;
  selectedTab: DeleteProfileTab;
  loading: boolean;
  uploadProgress: boolean;
  error: string | null;
}

export const initialDeleteProfileState: DeleteProfilesState = {
  deletedIndividuals: null,
  deletedCompanies: null,
  selectedTab: DeleteProfileTab.individuals,
  loading: false,
  uploadProgress: false,
  error: null,
};

export const DELETE_PROFILE_ACTIONS = {
  FETCH: 'DELETE_PROFILE/FETCH',
  FETCH_DELETED_INDIVIDUALS_SUCCESS:
    'DELETE_PROFILE/FETCH_DELETED_INDIVIDUALS_SUCCESS',
  FETCH_DELETED_COMPANIES_SUCCESS:
    'DELETE_PROFILE/FETCH_DELETED_COMPANIES_SUCCESS',
  SET_ERROR: 'DELETE_PROFILE/FAIL',
  UPLOAD_START: 'DELETE_PROFILE/UPLOAD_START',
  UPLOAD_SUCCESS: 'DELETE_PROFILE/UPLOAD_SUCCESS',
  SET_SELECTED_TAB: 'DELETE_PROFILE/SET_SELECTED_TAB',
  RESET: 'DELETE_PROFILE/RESET',
};

export const DeleteProfilesReducer = (
  state: DeleteProfilesState,
  action: any
): DeleteProfilesState => {
  switch (action.type) {
    case DELETE_PROFILE_ACTIONS.FETCH:
      return { ...state, uploadProgress: false, loading: true, error: null };
    case DELETE_PROFILE_ACTIONS.FETCH_DELETED_INDIVIDUALS_SUCCESS:
      return {
        ...state,
        deletedIndividuals: action.data,
        uploadProgress: false,
        loading: false,
        error: null,
      };
    case DELETE_PROFILE_ACTIONS.FETCH_DELETED_COMPANIES_SUCCESS:
      return {
        ...state,
        deletedCompanies: action.data,
        uploadProgress: false,
        loading: false,
        error: null,
      };
    case DELETE_PROFILE_ACTIONS.SET_ERROR:
      return {
        ...state,
        uploadProgress: false,
        loading: false,
        error: action.error,
      };
    case DELETE_PROFILE_ACTIONS.UPLOAD_START:
      return { ...state, uploadProgress: true };
    case DELETE_PROFILE_ACTIONS.SET_SELECTED_TAB:
      return { ...state, selectedTab: action.selectedTab };
    case DELETE_PROFILE_ACTIONS.UPLOAD_SUCCESS:
      return {
        ...state,
        uploadProgress: false,
        loading: false,
        error: null,
      };
    case DELETE_PROFILE_ACTIONS.RESET:
      return { ...initialDeleteProfileState };
    default:
      throw new Error();
  }
};
