import { Box, TextField, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { DatePicker, TimePicker } from '@material-ui/pickers';
import React from 'react';
import { Control, Controller } from 'react-hook-form';
import { PublishFormFields } from '../../utils/types/manage-tiles';
import { UploadFormFields } from '../../utils/types/upload';
import { ArticlesFormFields } from '../articles/ArticlesFormSection';
import { GlossaryFormFields } from '../glossary/GlossaryFormSection';
import { KnowledgeCentreFormFields } from '../knowledge-centre/KnowledgeCentreFormSection';
import { TooltipsFormFields } from '../tooltips/TooltipsFormSection';

type ArticleFormFieldName = keyof ArticlesFormFields;
type GlossaryFormFieldName = keyof GlossaryFormFields;
type TooltipsFormFieldName = keyof TooltipsFormFields;
type KnowledgeCentreFormFiledName = keyof KnowledgeCentreFormFields;
type PublishFormFiledName = keyof PublishFormFields;
type UploadFormFiledName = keyof UploadFormFields;

interface PubslishDateTimeSectionProps {
  control: Control<any>;
  publishDateName:
    | ArticleFormFieldName
    | GlossaryFormFieldName
    | TooltipsFormFieldName
    | KnowledgeCentreFormFiledName
    | PublishFormFiledName
    | UploadFormFiledName;
  publishTimeName:
    | ArticleFormFieldName
    | GlossaryFormFieldName
    | TooltipsFormFieldName
    | KnowledgeCentreFormFiledName
    | PublishFormFiledName
    | UploadFormFiledName;
  minDate?: Date;
}

const useStyles = makeStyles((theme) => ({
  input: {
    width: 287,
    color: theme.palette.text.secondary,
  },
}));

const PubslishDateTimeSection: React.FC<PubslishDateTimeSectionProps> = ({
  control,
  publishDateName,
  publishTimeName,
  minDate,
}: PubslishDateTimeSectionProps): JSX.Element => {
  const classes = useStyles();
  return (
    <>
      <Typography variant="subtitle1" align="left">
        Publish date
      </Typography>
      <Box textAlign="left">
        <Controller
          name={publishDateName}
          control={control}
          render={({ field: { onChange, value } }) => (
            <DatePicker
              value={value}
              onChange={onChange}
              InputProps={{ className: classes.input }}
              renderInput={(props) => <TextField {...props} />}
              minDate={minDate}
            />
          )}
        />
      </Box>

      <Box mt={10}>
        <Typography variant="subtitle1" align="left">
          Publish time
        </Typography>
        <Box textAlign="left">
          <Controller
            name={publishTimeName}
            control={control}
            render={({ field: { onChange, value } }) => (
              <TimePicker
                value={value}
                onChange={onChange}
                InputProps={{ className: classes.input }}
                renderInput={(props) => <TextField {...props} />}
                minTime={minDate}
              />
            )}
          />
        </Box>
      </Box>
    </>
  );
};

export default PubslishDateTimeSection;
