import {
  FormControl,
  FormLabel,
  Input,
  InputBaseComponentProps,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { Control, Controller } from 'react-hook-form';

type StyledInputProps = InputBaseComponentProps & {
  name: string;
  label: string;
  control: Control<any>;
  defaultValue?: string;
  error: boolean;
  disabled?: boolean;
};

const useStyles = makeStyles((_theme) => ({
  formControll: {
    width: '100%',
  },
}));

const StyledInput: React.FC<StyledInputProps> = ({
  name,
  label,
  _defaultValue,
  control,
  error,
  disabled,
  classnames,
  ...props
}: StyledInputProps): JSX.Element => {
  const classes = useStyles();
  return (
    <FormControl className={classes.formControll}>
      <FormLabel>
        <Typography variant="subtitle1" align="left" color="textPrimary">
          {label}
        </Typography>
      </FormLabel>
      <Controller
        name={name}
        control={control}
        defaultValue=""
        render={({ field }) => (
          <Input
            inputProps={{ ...props }}
            classes={classnames}
            {...field}
            error={error}
            disabled={disabled}
          />
        )}
      />
    </FormControl>
  );
};

export default StyledInput;
