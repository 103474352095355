export const LOGIN_ROUTE = '/login';
export const VERIFICATION_ROUTE = '/verification';

export const DIRECTORY_ROUTE = '/app/directory';
export const DIRECTORY_PROFILE_ROUTE = '/app/directory/profile';
export const DIRECTORY_BRANCH_ROUTE = '/app/directory/branch';

export const UPLOAD_DATA_ROUTE = '/app/upload';
export const DELETE_PROFILES_ROUTE = '/app/delete-profiles';

export const ORGANISATIONS_ADMIN_ROUTE = '/app/organisations';
export const ORGANISATIONS_VIEW_ROUTE = '/app/organisations/view/:orgId';
export const ORGANISATIONS_EDIT_ROUTE = '/app/organisations/edit/:orgId';
export const ORGANISATIONS_ADD_ROUTE = '/app/organisations/add';

export const USER_EDIT_ROUTE = `${ORGANISATIONS_VIEW_ROUTE}/users/edit`;
export const USERS_ADD_ROUTE = `${ORGANISATIONS_VIEW_ROUTE}/users/add`;

export const INVITES_EDIT_ROUTE = `${ORGANISATIONS_VIEW_ROUTE}/invites/edit`;

export const UPDATES_ROUTE = '/app/updates';
export const PROFILE_COMPARISON_ROUTE = '/app/updates/compare';

export const ARTICLES_ROUTE = '/app/articles';
export const ARTICLES_ADD_ROUTE = '/app/articles/add-article';
export const ARTICLES_EDIT_ROUTE = '/app/articles/edit/:id';

export const KNOWLEDGE_CENTRE_ROUTE = '/app/knowledge-centre';
export const KNOWLEDGE_CENTRE_ADD_ROUTE = '/app/knowledge-centre/add-article';
export const KNOWLEDGE_CENTRE_EDIT_ROUTE = '/app/knowledge-centre/edit/:id';

export const GLOSSARY_ROUTE = '/app/glossary';
export const GLOSSARY_ADD_ROUTE = '/app/glossary/add-term';
export const GLOSSARY_EDIT_ROUTE = '/app/glossary/edit/:id';

export const TOOLTIPS_ROUTE = '/app/tooltips';
export const TOOLTIPS_ADD_ROUTE = '/app/tooltips/add-term';
export const TOOLTIPS_EDIT_ROUTE = '/app/tooltips/edit/:id';

export const SYSTEM_ROUTE = '/app/system';

export const MANAGE_TILES = '/app/manage-tiles';
// export const USAGE_ROUTE = '/app/usage';
