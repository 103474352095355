import {
  Box,
  CircularProgress,
  Divider,
  Grid,
  Typography,
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import React, { useContext, useEffect } from 'react';
import { UpdatesStore } from '../../providers/updates';
import { LAYOUT } from '../../utils/constants/layout.constants';
import { UPDATES_PAGE } from '../../utils/constants/pageNames.constants';
import TopPannel from '../common/TopPannel';
import PendingUpdatesList from './PendingUpdatesList';
import PublishHistoryList from './PublishHistoryList';

interface UpdatesContentProps {}

const useStyles = makeStyles((theme) => ({
  content: {
    [theme.breakpoints.up('md')]: {
      marginTop: LAYOUT.TopPanelHeight + LAYOUT.TopPanelBottomMargin,
      width: `calc(100% - ${LAYOUT.DrawerWidth}px)`,
      marginLeft: LAYOUT.DrawerWidth,
    },
  },
}));

const UpdatesContent: React.FC<UpdatesContentProps> = (): JSX.Element => {
  const classes = useStyles();
  const {
    getUpdatesList,
    state: { updatesList, loading },
  } = useContext(UpdatesStore);

  useEffect(() => {
    getUpdatesList();
  }, [getUpdatesList]);

  const theme = useTheme();

  const matchesMediaQuery = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <Box className={classes.content}>
      {matchesMediaQuery ? (
        <TopPannel>
          <Typography variant="h4" color="textPrimary">
            {UPDATES_PAGE}
          </Typography>
        </TopPannel>
      ) : (
        <Box ml={4} mb={4}>
          <Typography variant="h4" color="textPrimary" align="left">
            {UPDATES_PAGE}
          </Typography>
        </Box>
      )}

      <Box
        mr={{ xs: 4, sm: 4, md: 4, lg: 10 }}
        ml={{ xs: 4, sm: 4, md: 4, lg: 10 }}
      >
        <Box mb={7}>
          <Grid container direction="row">
            <Grid item xs={12} sm={12} md={8}>
              <Typography variant="h6" align="left">
                {updatesList?.length || 0} total updates
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              {matchesMediaQuery && (
                <Typography variant="h6" align="left">
                  Publish history (last 5)
                </Typography>
              )}
            </Grid>
          </Grid>
        </Box>
        {matchesMediaQuery && <Divider />}
        <Grid container direction="row">
          <Grid item xs={12} sm={12} md={8}>
            <Box mr={{ xs: 0, sm: 0, md: 5, lg: 15 }}>
              {loading && <CircularProgress />}
              {updatesList && <PendingUpdatesList />}
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            {!matchesMediaQuery && (
              <Typography variant="h6" align="left">
                Publish history (last 5)
              </Typography>
            )}
            <Box mt={6} mb={6}>
              {loading && <CircularProgress />}
              {updatesList && (
                <PublishHistoryList
                  list={updatesList
                    .filter((u: any) => u.status === 'published')
                    .slice(0, 5)}
                />
              )}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default UpdatesContent;
