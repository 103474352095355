import { Box, Grid, Typography } from '@material-ui/core';
import React from 'react';
import {
  branchFields,
  companyFields,
  EntityTypes,
  individualFields,
} from '../../utils/constants/profileFields.constants';
import ProfileRecord from './ProfileRecord';

interface ProfileViewProps {
  id: String;
  type: String;
  oldProfileRecord: Map<string, any>;
  updatedProfileRecord: Map<string, any>;
}

const getMapping = (type: String, changeSet: Array<String>) => {
  if (type === EntityTypes.Individuals) {
    const mapped = changeSet
      .map((c: String) => {
        let field = individualFields.find(
          (f) => Object.keys(f).indexOf(c.toString()) > -1
        );
        if (field !== undefined) return field;
      })
      .filter((c) => c !== undefined);
    return mapped;
  }
  if (type === EntityTypes.Companies) {
    const mapped = changeSet
      .map((c: String) => {
        let field = companyFields.find(
          (f) => Object.keys(f).indexOf(c.toString()) > -1
        );
        if (field !== undefined) return field;
      })
      .filter((c) => c !== undefined);
    return mapped;
  }
  if (type === EntityTypes.Branches) {
    const mapped = changeSet
      .map((c: String) => {
        let field = branchFields.find(
          (f) => Object.keys(f).indexOf(c.toString()) > -1
        );
        if (field !== undefined) return field;
      })
      .filter((c) => c !== undefined);
    return mapped;
  }
};

const ProfileView: React.FC<ProfileViewProps> = ({
  id,
  type,
  oldProfileRecord,
  updatedProfileRecord,
}: ProfileViewProps): JSX.Element => {
  return (
    <Box>
      <Grid container direction="row">
        <Box ml={10} mt={4}>
          <Typography variant="subtitle1" color="primary" align="left">
            Record ID: {id}
          </Typography>
        </Box>
      </Grid>
      {oldProfileRecord && updatedProfileRecord ? (
        <Grid container direction="row">
          <Grid item xs={12} sm={6} md={6}>
            <Box ml={10} mt={4}>
              <ProfileRecord
                data={oldProfileRecord}
                mapping={getMapping(type, Object.keys(oldProfileRecord))}
                title="Old record"
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Box ml={10} mr={10} mt={4}>
              <ProfileRecord
                data={updatedProfileRecord}
                mapping={getMapping(type, Object.keys(updatedProfileRecord))}
                title="Updated record"
              />
            </Box>
          </Grid>
        </Grid>
      ) : (
        <Box mt={10}>
          <Typography variant="body1" color="secondary" align="left">
            {"Record update doesn't have changes"}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default ProfileView;
