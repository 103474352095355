import { Box, CircularProgress } from '@material-ui/core';
import React, { useCallback, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { ArticlesStore } from '../../providers/articles';
import * as ROUTES from '../../routes/Types';
import { ARTICLES_PAGE } from '../../utils/constants/pageNames.constants';
import SectionContent from '../common/SectionContent';
import ArticlesList from './ArticlesList';

interface ArticlesContentProps {}

export interface ArticlesListItem {
  id: string;
  date: string;
  title: string;
}

const ArticlesContent: React.FC<ArticlesContentProps> = (): JSX.Element => {
  const {
    state: { data, loading },
    getBatch,
  } = useContext(ArticlesStore);

  let history = useHistory();

  const handleAddArticleClick = useCallback(() => {
    history.push(ROUTES.ARTICLES_ADD_ROUTE);
  }, [history]);

  useEffect(() => {
    getBatch(null);
  }, []);

  return (
    <SectionContent
      handleAddClick={handleAddArticleClick}
      addButtonText={'Add article'}
      pageName={ARTICLES_PAGE}
    >
      <Box height={'100%'}>
        {data && (
          <ArticlesList articlesList={data} loadMoreItemsHandler={getBatch} />
        )}
        {loading && <CircularProgress />}
      </Box>
    </SectionContent>
  );
};

export default ArticlesContent;
