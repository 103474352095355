import { httpsCallable } from '@firebase/functions';
import {
  Organisation,
  OrganisationAddFormData,
  UserOrganisationDetails,
} from '../utils/types/organisation';
import { fbFunctions } from './firebase';

export const fetchAllOrganizations = async (): Promise<Organisation[]> => {
  const fetchAllOrganizationsFn = httpsCallable(
    fbFunctions,
    'getAllOrganizations'
  );
  try {
    const response = await fetchAllOrganizationsFn();
    return response.data as Organisation[];
  } catch (error) {
    console.error('Error fetching organizations:', error);
    throw error;
  }
};

export const addOrganization = async (
  data: OrganisationAddFormData
): Promise<Organisation> => {
  const addOrganizationFn = httpsCallable(fbFunctions, 'createOrganization');
  try {
    const response = await addOrganizationFn(data);
    return response.data as Organisation;
  } catch (error) {
    console.error('Error adding organization:', error);
    throw error;
  }
};

export const fetchOrganization = async (id: number): Promise<Organisation> => {
  const fetchOrganizationFn = httpsCallable(fbFunctions, 'getOrganization');
  try {
    const response = await fetchOrganizationFn({ id });
    return response.data as Organisation;
  } catch (error) {
    console.error('Error fetching organization:', error);
    throw error;
  }
};

export const updateOrganization = async (
  id: number,
  data: OrganisationAddFormData
): Promise<Organisation> => {
  const updateOrganizationFn = httpsCallable(fbFunctions, 'updateOrganization');
  try {
    const response = await updateOrganizationFn({ id, ...data });
    return response.data as Organisation;
  } catch (error) {
    console.error('Error updating organization:', error);
    throw error;
  }
};

export const updateOrganisationUser = async (
  userId: string,
  user: any
): Promise<UserOrganisationDetails> => {
  const updateRole = httpsCallable(fbFunctions, 'updateUser');
  try {
    const result = await updateRole({
      id: userId,
      user,
    });
    return result.data as UserOrganisationDetails;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
