import { Box } from '@material-ui/core';
import React from 'react';
import { Route, useLocation } from 'react-router-dom';
import ArticlesContent from '../../components/articles/ArticlesContent';
import { ArticlesProvider } from '../../providers/articles';
import { DirectoryProvider } from '../../providers/directory';
import {
  ARTICLES_ADD_ROUTE,
  ARTICLES_EDIT_ROUTE,
  ARTICLES_ROUTE,
} from '../../routes/Types';
import ArticlesAddPage from '../admin/ArticlesAddPage';
import ArticlesEditPage from '../admin/ArticlesEditPage';

interface ArticlesPageProps {}

const ArticlesPage: React.FC<ArticlesPageProps> = (): JSX.Element => {
  const currentLocation = useLocation();
  return (
    <DirectoryProvider>
      <ArticlesProvider>
        <Box
          style={
            currentLocation.pathname !== ARTICLES_ROUTE
              ? { display: 'none' }
              : {}
          }
        >
          <ArticlesContent />
        </Box>

        <Route path={ARTICLES_ADD_ROUTE}>
          <ArticlesAddPage />
        </Route>

        <Route path={ARTICLES_EDIT_ROUTE}>
          <ArticlesEditPage />
        </Route>
      </ArticlesProvider>
    </DirectoryProvider>
  );
};

export default ArticlesPage;
