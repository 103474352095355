export const DIRECTORY_PAGE = 'Directory';
export const UPLOAD_PAGE = 'Upload Data';
export const USER_ADMIN_PAGE = 'Client User Admin';
export const UPDATES_PAGE = 'Pending updates';
export const ARTICLES_PAGE = 'Articles';
export const KNOWLEDGE_CENTRE_PAGE = 'Knowledge Centre';
export const GLOSSARY_PAGE = 'Glossary';
export const TOOLTIPS_PAGE = 'Tooltips';
export const USAGE_PAGE = 'Usage';
export const DELETE_PROFILES_PAGE = 'Delete Profiles';
