import React, { useCallback, useRef } from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';
import { LAYOUT } from '../../utils/constants/layout.constants';
import { DeletedProfileListItem } from '../../utils/types/deleteProfiles';
import VirtualizedListItem from '../common/VirtualizedListItem';
import DeletedProfileListItemContent from './DeletedProfileListItemContent';

interface DeletedCompaniesListProps {
  deletedCompaniesList: DeletedProfileListItem[];
}

interface ListRowProps {
  style: any;
  index: number;
  data: DeletedProfileListItem[];
}

const Row = ({ style, index, data }: ListRowProps) => {
  const { name, role } = data[index] || {};
  return (
    <VirtualizedListItem
      style={{
        ...style,
        width: 'calc(100% - 40px)',
        marginLeft: 20,
        marginRight: 20,
        marginTop: 10,
      }}
    >
      <DeletedProfileListItemContent name={name} role={role} />
    </VirtualizedListItem>
  );
};

const DeletedCompaniesList: React.FC<DeletedCompaniesListProps> = ({
  deletedCompaniesList,
}): JSX.Element => {
  const listRef = useRef<FixedSizeList>(null);

  const loadMoreItems = useCallback(() => {
    //TODO: dynamic loading is not available yet
    //console.log("load more");
  }, []);

  const isItemLoaded = useCallback((_index: number) => {
    //TODO: dynamic loading is not available yet
    //console.log(index);
    return false;
  }, []);

  return (
    <AutoSizer>
      {({ height, width }: any) => (
        <InfiniteLoader
          isItemLoaded={isItemLoaded}
          itemCount={deletedCompaniesList.length}
          loadMoreItems={loadMoreItems}
        >
          {({ onItemsRendered }) => (
            <FixedSizeList
              onItemsRendered={onItemsRendered}
              height={height}
              itemCount={deletedCompaniesList.length}
              itemSize={LAYOUT.VirtuilizedListItemHeight}
              width={width}
              itemData={deletedCompaniesList}
              ref={listRef}
            >
              {Row}
            </FixedSizeList>
          )}
        </InfiniteLoader>
      )}
    </AutoSizer>
  );
};

export default DeletedCompaniesList;
