import { Box, Button, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import React, { useCallback } from 'react';
import Searchbar from '../common/Searchbar';

interface UsersFiltersProps {
  keyword: string;
  onSearch: () => void;
  onClear: () => void;
  onChange: (value: string) => void;
}

const useStyles = makeStyles((theme) => {
  return {
    root: {
      minWidth: 55,
      minHeight: theme.spacing(10),
      marginLeft: theme.spacing(5),
      borderRadius: 10,
      border: 0,
    },
  };
});

const UsersFilters = ({
  onChange,
  onClear,
  onSearch,
  keyword,
}: UsersFiltersProps) => {
  const classes = useStyles();

  const handleOnSearch = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      onSearch();
    },
    [onSearch]
  );

  return (
    <Box>
      <Box
        ml={{ xs: 4, sm: 4, md: 4, lg: 22.5 }}
        mr={{ xs: 'auto', sm: 'auto', md: 'auto', lg: 22.5 }}
      >
        <Typography variant="subtitle1" align="left">
          Find a subscriber
        </Typography>
        <Box display={'flex'}>
          <Searchbar keyword={keyword} onChange={onChange} onClear={onClear} />
          <Button
            classes={{
              root: classes.root,
            }}
            variant="contained"
            color="primary"
            onClick={handleOnSearch}
            type="submit"
          >
            <SearchIcon fontSize="medium" />
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default UsersFilters;
