import { ButtonProps as MuiButtonProps } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

interface Props {}
const useStyles = makeStyles({
  publishButton: {
    backgroundColor: '#FF3C00',
    border: 0,
    color: 'white',
    fontSize: 15,
    borderRadius: 5,
    padding: '10px 12px',
    display: 'flex',
    width: 200,
    '&:hover': {
      cursor: 'pointer',
    },
    '&:disabled': {
      opacity: 0.3,
    },
  },
});

const PublishButton = (props: Props & Omit<MuiButtonProps, keyof Props>) => {
  const { ...other } = props;
  const classes = useStyles(props);
  return <button className={classes.publishButton} {...other} />;
};

export default PublishButton;
