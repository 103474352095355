import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  FormControl,
  Grid,
  NativeSelect,
  Typography,
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import * as yup from 'yup';
import { UPDATES_ROUTE } from '../../routes/Types';
import { get, update } from '../../services/manage-tiles';
import {
  ARROW_NEXT,
  ARROW_PREV,
  MANAGE_TILES,
  YES,
} from '../../utils/constants/manageTiles';
import {
  ActivityTile,
  ListTilesUpdated,
  PublishFormFields,
} from '../../utils/types/manage-tiles';
import PubslishDateTimeSection from '../common/PublishDateTimeSection';
import Title from '../common/Title';
import TopPannel from '../common/TopPannel';
import ConfirmationDialog from './ConfirmationDialog';
import ErrorDialog from './ErrorDialog';
import ManageTilesList from './ManageTilesList';
import ScheduledConfirmationDialog from './ScheduledConfirmationDialog';
import {
  ArrowButton,
  InformativeSpan,
  PublishButton,
  RedSpan,
  WrapperContent,
  WrapperRowFlex,
} from './styled';
interface ParamsType {
  id: string;
}

const PAGE_SIZE = 20;

const defaultValues: PublishFormFields = {
  tilesPublishDate: new Date(),
  tilesPublishTime: new Date(),
};

const ManageTilesContent: React.FC = (): JSX.Element => {
  const history = useHistory();
  const [originalListOptions, setOriginalListOptions] = useState<
    Array<ActivityTile>
  >([]);
  const [totalPages, setTotalPages] = useState<Array<number>>([]);
  const [loading, setLoading] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [defaultValuePaginator, setDefaultValuePaginator] = useState(1);
  const [showPaginator, setShowPaginator] = useState(false);
  const [disabledNextButton, setDisabledNextButton] = useState(false);
  const [disabledPrevButton, setDisabledPrevButton] = useState(true);
  const [isConfirmDialogOpened, setIsConfirmDialogOpened] = useState(false);
  const [isScheduledOpened, setIsScheduledDialogOpened] = useState(false);
  const [isErrorDialogOpened, setIsErrorDialogOpened] = useState(false);
  const [manageTilesError, setManageTilesError] = useState<string | null>(null);
  const [scheduledDate, setScheduledDate] = useState<Date | null>(null);
  const { id } = useParams<ParamsType>();

  const validationSchema = yup.object().shape({
    glossaryTermPublishDate: yup
      .date()
      .min(new Date(), 'Date must be in the present or future'),
    glossaryTermPublishTime: yup
      .date()
      .min(new Date(), 'Time must be in the present or future'),
  });

  const { handleSubmit, control, reset } = useForm<PublishFormFields>({
    defaultValues: defaultValues,
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues]);

  useEffect(() => {
    if (id) {
      loadData();
    }
  }, [id]);

  /**
   * Description: Start all the process in the component.
   * It is the root method of the component
   */
  const loadData = async () => {
    setLoading(true);
    try {
      const result: any = await get(id);
      setShowPaginator(result.length < PAGE_SIZE ? false : true);
      findTotalPages(result);
      setOriginalListOptions(result);
    } catch (error) {
      setManageTilesError('Could not load tiles! Please try again later!');
    }
    setLoading(false);
  };

  /**
   * Description: Function which find how the pagination should be divided.
   * @param array array to calculate the length
   */
  const findTotalPages = (array: ActivityTile[]) => {
    let paginatorNumbers: any = [];
    const arrayDividedByTen = array.length / PAGE_SIZE;
    paginatorNumbers = [...new Array(Math.ceil(arrayDividedByTen))].map(
      (item, index) => index + 1
    );
    setTotalPages(paginatorNumbers);
  };

  const visibleListItems = useMemo(() => {
    if (originalListOptions.length > defaultValuePaginator * PAGE_SIZE) {
      return originalListOptions.slice(
        (defaultValuePaginator - 1) * PAGE_SIZE,
        defaultValuePaginator * PAGE_SIZE
      );
    }

    return originalListOptions.slice((defaultValuePaginator - 1) * PAGE_SIZE);
  }, [defaultValuePaginator, originalListOptions]);

  /**
   * Description: Main function to handle the paginator by using the selection in the Dropdown and Arrow.
   * @param pageSelected page have been selected in the Dropdown or Arrow.
   */
  const handleGeneralPaginator = useCallback(
    (pageSelected: any) => {
      setDefaultValuePaginator(pageSelected);
      const lastPage: number = totalPages[totalPages.length - 1];
      const firstPage: number = totalPages[0];
      const _previousPage: number = totalPages[pageSelected - 2];
      if (pageSelected === lastPage) {
        setDisabledNextButton(true);
        setDisabledPrevButton(false);
      } else if (pageSelected === firstPage) {
        setDisabledPrevButton(true);
        setDisabledNextButton(false);
      } else {
        setDisabledPrevButton(false);
        setDisabledNextButton(false);
      }
    },
    [originalListOptions, totalPages]
  );

  /**
   * Description: Function which handle the Dropdown selection component.
   * @param event event emitted by the dropdown.
   */
  const handleDropdownPaginator = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const {
      target: { value },
    } = event;
    const pageSelected: number = Number.parseInt(value);
    handleGeneralPaginator(pageSelected);
  };

  /**
   * Description: Function which handle the Arrow selection component.
   * @param item arrow which have been selected.
   */
  const handleArrowPaginator = useCallback(
    (item: string) => {
      const lastPage: number = totalPages[totalPages.length - 1];
      const firstPage: number = totalPages[0];
      if (item === ARROW_NEXT) {
        if (defaultValuePaginator === lastPage) {
          handleGeneralPaginator(defaultValuePaginator);
        } else if (defaultValuePaginator === firstPage) {
          handleGeneralPaginator(defaultValuePaginator + 1);
        } else {
          handleGeneralPaginator(defaultValuePaginator + 1);
        }
      }
      if (item === ARROW_PREV) {
        if (defaultValuePaginator === lastPage) {
          handleGeneralPaginator(defaultValuePaginator - 1);
        } else if (defaultValuePaginator === firstPage) {
          handleGeneralPaginator(defaultValuePaginator);
        } else {
          handleGeneralPaginator(defaultValuePaginator - 1);
        }
      }
    },
    [defaultValuePaginator, handleGeneralPaginator, totalPages]
  );

  /**
   * Description: Function which update the original array with the new values.
   * @param id id in the array.
   * @param valueSelected value have been selected in the radio button.
   */
  const updateOriginal = useCallback(
    (id: number, valueSelected: string) => {
      const objIndex = originalListOptions.findIndex((obj) => obj.id === id);
      originalListOptions[objIndex].visible =
        valueSelected === YES ? true : false;
      setOriginalListOptions([...originalListOptions]);
    },
    [originalListOptions]
  );

  /**
   * Description: Publish data.
   */
  const handlePublishData = async (formData: PublishFormFields) => {
    setUpdating(true);
    const listModified: Array<ListTilesUpdated> = originalListOptions.map(
      (item) => {
        return { id: `${item.id}`, visible: item.visible };
      }
    );

    const combinedDate = new Date(
      formData.tilesPublishDate.getFullYear(),
      formData.tilesPublishDate.getMonth(),
      formData.tilesPublishDate.getDate(),
      formData.tilesPublishTime.getHours(),
      formData.tilesPublishTime.getMinutes()
    );

    const currentDate = new Date();

    const unixTimestamp = combinedDate.getTime();

    try {
      if (combinedDate <= currentDate) {
        await update(listModified);
        setIsConfirmDialogOpened(true);
      } else {
        await update(listModified, unixTimestamp, id);
        setScheduledDate(combinedDate);
        setIsScheduledDialogOpened(true);
      }
    } catch (e) {
      setIsErrorDialogOpened(true);
    }

    setUpdating(false);
  };

  const selectedCount = useMemo(() => {
    return originalListOptions.filter((item) => item.visible === true).length;
  }, [originalListOptions]);

  const onSelectAll = useCallback(() => {
    const resultList = originalListOptions.map((o) => ({
      ...o,
      visible: true,
    }));
    setOriginalListOptions(resultList);
  }, [originalListOptions]);

  const onUnselectAll = useCallback(() => {
    const resultList = originalListOptions.map((o) => ({
      ...o,
      visible: false,
    }));
    setOriginalListOptions(resultList);
  }, [originalListOptions]);

  const onConfirmDialogClose = () => {
    setIsConfirmDialogOpened(false);
  };

  const onScheduledDialogClose = () => {
    setIsScheduledDialogOpened(false);
  };

  const onErrorDialogClose = () => {
    setIsErrorDialogOpened(false);
  };

  const handleRedirectLinkClick = () => {
    history.push(`${UPDATES_ROUTE}`);
  };

  const theme = useTheme();
  const matchesMediaQuery = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <WrapperContent>
      {matchesMediaQuery ? (
        <TopPannel>
          <Title
            onRedirectLinkClick={handleRedirectLinkClick}
            pageName={'Back to all updates'}
          />
        </TopPannel>
      ) : (
        <Box ml={4} mb={4}>
          <Title
            onRedirectLinkClick={handleRedirectLinkClick}
            pageName={'Back to all updates'}
          />
        </Box>
      )}

      <Box ml={matchesMediaQuery ? 10 : 4} mb={2} display={'flex'}>
        <Typography variant="h4" color="textPrimary" align="left">
          {MANAGE_TILES}
        </Typography>
      </Box>

      <Box
        mr={{ xs: 4, sm: 4, md: 4, lg: 10 }}
        ml={{ xs: 4, sm: 4, md: 4, lg: 10 }}
      >
        <Grid container direction="row">
          <Grid item xs={12} sm={12} md={8}>
            <WrapperRowFlex>
              <Typography variant="subtitle2" color="textPrimary" align="left">
                Upload ID <RedSpan>{id}</RedSpan>
              </Typography>
            </WrapperRowFlex>
            <Box mr={{ xs: 0, sm: 0, md: 5, lg: 15 }}>
              {loading && <CircularProgress />}
              {visibleListItems && (
                <ManageTilesList
                  listOptions={visibleListItems}
                  updateOriginal={updateOriginal}
                />
              )}
              {visibleListItems.length === 0 && (
                <Box mt={16}>
                  {manageTilesError ? (
                    <Typography color="error">{manageTilesError}</Typography>
                  ) : (
                    <Typography>
                      {"This update doesn't have any tiles"}
                    </Typography>
                  )}
                </Box>
              )}
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <Box display={'flex'} flexDirection={'column'}>
              <Box>
                <PublishButton
                  disabled={updating}
                  onClick={handleSubmit(handlePublishData)}
                >
                  <span> Publish to clients </span>
                </PublishButton>
              </Box>
              <Box mt={4}>
                <PubslishDateTimeSection
                  control={control}
                  publishDateName="tilesPublishDate"
                  publishTimeName="tilesPublishTime"
                  minDate={new Date()}
                />
              </Box>
              {updating && (
                <Box ml={2}>
                  <CircularProgress />
                </Box>
              )}
            </Box>
            <InformativeSpan>
              {selectedCount} selected tiles for publishing /all pages/
            </InformativeSpan>
            <Box
              mt={2}
              display={'flex'}
              flexDirection={'row'}
              alignItems={'center'}
              justifyContent={'flex-start'}
            >
              <Button
                color="secondary"
                variant="contained"
                disableElevation
                onClick={onSelectAll}
              >
                Select all
              </Button>
              <Box ml={2} mr={2}>
                or
              </Box>
              <Button
                color="secondary"
                variant="contained"
                disableElevation
                onClick={onUnselectAll}
              >
                Unselect all
              </Button>
            </Box>
            {showPaginator && (
              <Box mt={4}>
                <Divider />
                <Box
                  mt={6}
                  mb={6}
                  sx={{ maxWidth: 250, justifyContent: 'space-between' }}
                  display="flex"
                >
                  <ArrowButton
                    onClick={() => handleArrowPaginator('previous')}
                    disabled={disabledPrevButton}
                  >
                    <KeyboardArrowLeft />
                  </ArrowButton>
                  <Box sx={{ minWidth: 120 }}>
                    <span>Select page</span>
                    <FormControl fullWidth>
                      <NativeSelect
                        value={defaultValuePaginator}
                        onChange={handleDropdownPaginator}
                      >
                        {totalPages &&
                          totalPages.map((item) => {
                            return (
                              <option key={`page-${item}`} value={item}>
                                {item}
                              </option>
                            );
                          })}
                      </NativeSelect>
                    </FormControl>
                  </Box>
                  <ArrowButton
                    onClick={() => handleArrowPaginator(ARROW_NEXT)}
                    disabled={disabledNextButton}
                  >
                    <KeyboardArrowRight />
                  </ArrowButton>
                </Box>
              </Box>
            )}
          </Grid>
        </Grid>
      </Box>
      <ConfirmationDialog
        isDialogOpened={isConfirmDialogOpened}
        onDialogClose={onConfirmDialogClose}
      />
      <ScheduledConfirmationDialog
        isDialogOpened={isScheduledOpened}
        onDialogClose={onScheduledDialogClose}
        scheduledDate={scheduledDate}
      />
      <ErrorDialog
        isDialogOpened={isErrorDialogOpened}
        onDialogClose={onErrorDialogClose}
      />
    </WrapperContent>
  );
};

export default ManageTilesContent;
