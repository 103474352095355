import {
  Box,
  Button,
  CircularProgress,
  Divider,
  SvgIcon,
  Typography,
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import { ReactComponent as Cancel } from '../../assets/icons/cancel.svg';
import { ReactComponent as PublishIcon } from '../../assets/icons/publish.svg';
import { UpdatesStore } from '../../providers/updates';
import { UPDATES_ROUTE } from '../../routes/Types';
import { PendingUpdatesListItem } from '../../services/updates';
import { LAYOUT } from '../../utils/constants/layout.constants';
import { UploadStatusType } from '../../utils/types/upload';
import Title from '../common/Title';
import TopPannel from '../common/TopPannel';
import ApproveDialog from './ApproveDialog';
import { RejectDialogFormFields } from './PendingUpdatesList';
import ProfileView from './ProfileView';
import RejectDialog from './RejectDialog';

interface ProfileComparisonContentProps {}

interface ParamsType {
  id: string;
}

const useStyles = makeStyles((theme) => ({
  content: {
    marginTop: 64,
    background: theme.palette.background.paper,
    [theme.breakpoints.up('md')]: {
      marginTop: 136,
      width: `calc(100% - ${LAYOUT.DrawerWidth}px)`,
      marginLeft: LAYOUT.DrawerWidth,
    },
  },
  button: {
    borderRadius: 10,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    marginRight: theme.spacing(1),
  },
  icon: {
    paddingRight: theme.spacing(2),
  },
}));

const ProfileComparisonContent: React.FC<
  ProfileComparisonContentProps
> = (): JSX.Element => {
  const {
    state: { updatesList, updatedRecords, totalChanges, loading, updating },
    getReviewUpdatedRecordById,
    approveUpdate,
    rejectUpdate,
    revertUpdate,
  } = useContext(UpdatesStore);

  const [selectedUpdate, setSelectedUpdate] = useState<any>(undefined);
  const [isApproveDialogOpened, setApproveDialogOpen] = useState(false);
  const [isRejectDialogOpened, setRejectDialogOpen] = useState(false);
  const [publishTypeValue, setPublishTypeValue] = useState('1');

  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<RejectDialogFormFields>({
    defaultValues: {
      rejectionReasons: '',
    },
  });

  const classes = useStyles();
  const theme = useTheme();

  const matchesMediaQuery = useMediaQuery(theme.breakpoints.up('md'), {
    noSsr: true,
  });

  const history = useHistory();

  const handleRedirectLinkClick = () => {
    history.push(`${UPDATES_ROUTE}`);
  };

  const { id } = useParams<ParamsType>();

  //TODO: add proper return type and resolve error with types
  const getCurrentUpdateListItem = (id: string): any =>
    updatesList?.find(
      (updatesListItem: PendingUpdatesListItem) => updatesListItem.id === id
    );

  useEffect(() => {
    setSelectedUpdate(updatesList?.find((u) => u.id === id));
    getReviewUpdatedRecordById(id);
  }, [updatesList, getReviewUpdatedRecordById, id]);

  useEffect(() => {
    if (
      selectedUpdate?.status === UploadStatusType.published &&
      isApproveDialogOpened
    ) {
      setApproveDialogOpen(false);
    }
    if (
      selectedUpdate?.status === UploadStatusType.rejected &&
      isRejectDialogOpened
    ) {
      setRejectDialogOpen(false);
    }
  }, [selectedUpdate, isApproveDialogOpened, isRejectDialogOpened, updating]);

  const handleApproveDialogClose = useCallback(() => {
    setApproveDialogOpen(false);
  }, [setApproveDialogOpen]);

  const handleRejectDialogClose = useCallback(() => {
    setRejectDialogOpen(false);
  }, [setRejectDialogOpen]);

  const handlePublishUpdates = useCallback(() => {
    approveUpdate(id, publishTypeValue === '1' ? true : false);
  }, [approveUpdate, publishTypeValue, id]);

  const handlePublishTypeChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setPublishTypeValue((e.target as HTMLInputElement).value);
    },
    [setPublishTypeValue]
  );

  const handleRejectContinue = useCallback(
    (data: RejectDialogFormFields) => {
      rejectUpdate(id, data.rejectionReasons);
    },
    [rejectUpdate, id]
  );

  const onApproveClicked = useCallback(() => {
    setApproveDialogOpen(true);
  }, [setApproveDialogOpen]);

  const onRejectClicked = useCallback(() => {
    setRejectDialogOpen(true);
  }, [setRejectDialogOpen]);

  const onRevertClicked = useCallback(() => {
    revertUpdate(id);
  }, [id, revertUpdate]);

  const { status, title, date, user } = getCurrentUpdateListItem(id) || {};

  return (
    <Box className={classes.content}>
      {matchesMediaQuery ? (
        <TopPannel>
          <Title
            onRedirectLinkClick={handleRedirectLinkClick}
            pageName={'Back to all updates'}
          />
        </TopPannel>
      ) : (
        <Box textAlign="left" mr={4} ml={4}>
          <Title
            onRedirectLinkClick={handleRedirectLinkClick}
            pageName={'Back to all updates'}
          />
        </Box>
      )}
      <Box mr={10} ml={10} mb={4} mt={4}>
        <Box display="flex" justifyContent="space-between">
          <Box
            mb={3}
            display={'flex'}
            flexDirection={'column'}
            alignItems={'flex-start'}
          >
            <Typography variant="h6" align="left">
              {title}
            </Typography>
            <Typography variant="caption" color="textSecondary" align="left">
              {`Updated ${new Date(date).toLocaleString()} by ${user}`}
            </Typography>
            <Typography variant="caption" color="textPrimary" align="left">
              {`${updatedRecords?.length} out of ${totalChanges} for preview`}
            </Typography>
          </Box>
          {status === UploadStatusType.pending && (
            <Box>
              <Button
                onClick={onRejectClicked}
                color="secondary"
                variant="contained"
                disableElevation
                classes={{ root: classes.button }}
              >
                <SvgIcon
                  component={Cancel}
                  classes={{ root: classes.icon }}
                ></SvgIcon>
                Reject
              </Button>
              <Button
                onClick={onApproveClicked}
                variant="contained"
                color="primary"
                classes={{ root: classes.button }}
                disableElevation
              >
                <SvgIcon component={PublishIcon}></SvgIcon>
                Publish
              </Button>
            </Box>
          )}
          {status === UploadStatusType.published && (
            <Box display={'flex'} flexDirection={'row'}>
              <Box mr={2}>{updating && <CircularProgress />}</Box>
              <Box>
                <Button
                  disabled={updating}
                  onClick={onRevertClicked}
                  color="secondary"
                  variant="contained"
                  disableElevation
                  classes={{ root: classes.button }}
                >
                  <SvgIcon
                    component={Cancel}
                    classes={{ root: classes.icon }}
                  ></SvgIcon>
                  Revert
                </Button>
              </Box>
            </Box>
          )}
        </Box>
        <Divider />
      </Box>

      {!loading &&
        updatesList &&
        updatedRecords &&
        updatedRecords?.map((updatedRecord: any, i: number) => (
          <ProfileView
            key={`${selectedUpdate?.id}-${i}`}
            id={updatedRecord?.id}
            type={selectedUpdate?.type}
            oldProfileRecord={updatedRecord.oldValues}
            updatedProfileRecord={updatedRecord.newValues}
          />
        ))}
      {!loading &&
        (!updatesList || !updatedRecords || updatedRecords.length === 0) && (
          <Box mt={10} ml={10}>
            <Typography variant="body1" align="left">
              {"This update doesn't have changes"}
            </Typography>
          </Box>
        )}
      {loading && <CircularProgress />}
      <ApproveDialog
        loading={updating}
        isDialogOpened={isApproveDialogOpened}
        onDialogClose={handleApproveDialogClose}
        onPublishUpdates={handlePublishUpdates}
        onPublishTypeChange={handlePublishTypeChange}
        publishTypeValue={publishTypeValue}
        title={title}
        date={date}
        user={user}
      />
      <RejectDialog
        isDialogOpened={isRejectDialogOpened}
        onDialogClose={handleRejectDialogClose}
        onRejectContinue={handleSubmit(handleRejectContinue)}
        control={control}
        loading={updating}
        errors={errors}
        title={title}
        date={date}
        user={user}
      />
    </Box>
  );
};

export default ProfileComparisonContent;
