import { Box, Grid, Tab, Tabs, Typography } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { TabContext, TabPanel } from '@material-ui/lab';
import React, { useCallback, useState } from 'react';
import { useUserRoleRestrictions } from '../../hooks/services/useUserRoleRestrictions';
import { LAYOUT } from '../../utils/constants/layout.constants';
import { UPLOAD_PAGE } from '../../utils/constants/pageNames.constants';
import { UserRole } from '../../utils/types/user';
import TopPannel from '../common/TopPannel';
import ScheduleList from './ScheduleList';
import UploadList from './UploadList';
import UploadTab from './UploadTab';

interface UploadContentProps {}

const useStyles = makeStyles((theme) => {
  return {
    content: {
      marginTop: 64,
      [theme.breakpoints.up('md')]: {
        marginTop: LAYOUT.TopPanelHeight + LAYOUT.TopPanelBottomMargin,
        width: `calc(100% - ${LAYOUT.DrawerWidth}px)`,
        marginLeft: LAYOUT.DrawerWidth,
      },
    },
    itemOrder: {
      order: 1,
      height: '480px',
      [theme.breakpoints.up('md')]: {
        order: 'initial',
        height: 'auto',
      },
    },
  };
});

const UploadContent: React.FC<UploadContentProps> = (): JSX.Element => {
  const { isUserRole: isUserAdmin } = useUserRoleRestrictions(
    UserRole.SuperAdmin
  );
  const [selectedTab, setSelectedTab] = useState('0');

  const onTabChange = useCallback((evt, val) => {
    setSelectedTab(val);
  }, []);

  const classes = useStyles();
  const theme = useTheme();
  const matchesMediaQuery = useMediaQuery(theme.breakpoints.up('md'));

  const handleResetTab = () => {
    setSelectedTab('0');
  };

  return (
    <Box className={classes.content}>
      {matchesMediaQuery ? (
        <TopPannel>
          <Typography variant="h4" color="textPrimary">
            {UPLOAD_PAGE}
          </Typography>
        </TopPannel>
      ) : (
        <Box ml={4} mb={4}>
          <Typography variant="h4" color="textPrimary" align="left">
            {UPLOAD_PAGE}
          </Typography>
        </Box>
      )}
      <Grid item xs={12} sm={12} md={12} lg={12} className={classes.itemOrder}>
        <TabContext value={selectedTab}>
          <Tabs
            value={selectedTab}
            onChange={onTabChange}
            aria-label="basic tabs example"
          >
            <Tab label="History" value="0" />
            <Tab label="Schedule" value="1" />
            {isUserAdmin && <Tab label="Upload" value="2" />}
          </Tabs>
          <TabPanel value={'0'}>
            <Box mt={8} height={'700px'}>
              <Box mt={8} ml={{ xs: 0, sm: 0, md: 0, lg: 5 }} height={'100%'}>
                <UploadList />
              </Box>
            </Box>
          </TabPanel>
          <TabPanel value={'1'}>
            <Box mt={8} height={'700px'}>
              <Box mt={8} ml={{ xs: 0, sm: 0, md: 0, lg: 5 }} height={'100%'}>
                <ScheduleList />
              </Box>
            </Box>
          </TabPanel>
          <TabPanel value={'2'}>
            <Box mt={8} height={'700px'}>
              <UploadTab handleResetTab={handleResetTab} />
            </Box>
          </TabPanel>
        </TabContext>
      </Grid>
    </Box>
  );
};

export default UploadContent;
