import { User } from '../utils/types/user';

export interface InvitesState {
  invites: Array<User> | null;
  selectedInvite?: User;
  loadingInv: boolean;
  updatingInv: boolean;
  errorInv: any;
}

export const initialInvitesState: InvitesState = {
  invites: null,
  loadingInv: false,
  updatingInv: false,
  errorInv: null,
};

export const INVITES_ACTIONS = {
  FETCH: 'INVITES/FETCH',
  FETCH_INVITES_SUCCESS: 'INVITES/SUCCESS',
  FETCH_INVITES_FAIL: 'INVITES/FAIL',
  LOAD_MORE_INVITES_SUCCESS: 'INVITES/LOAD_MORE_INVITES_SUCCESS',
  LOAD_MORE_INVITES_FAIL: 'INVITES/LOAD_MORE_FAIL',
  LOAD_MORE_INVITES_START: 'INVITES/LOAD_MORE_INVITES_START',
  FETCH_DETAILS: 'INVITES/FETCH_DETAILS',
  FETCH_DETAILS_SUCCESS: 'INVITES/FETCH_DETAILS_SUCCESS',
  UPDATE_START: 'INVITES/UPDATE_START',
  UPDATE_SUCCESS: 'INVITES/UPDATE_SUCCESS',
  UPDATE_FAIL: 'INVITES/UPDATE_FAIL',
  REMOVE_START: 'INVITES/REMOVE_START',
  REMOVE_SUCCESS: 'INVITES/REMOVE_SUCCESS',
};

export const InvitesReducer = (
  state: InvitesState,
  action: any
): InvitesState => {
  switch (action.type) {
    case INVITES_ACTIONS.FETCH:
      return {
        invites: null,
        loadingInv: true,
        updatingInv: false,
        errorInv: null,
      };
    case INVITES_ACTIONS.FETCH_INVITES_SUCCESS:
      return {
        ...state,
        invites: action.invites,
        loadingInv: false,
        errorInv: null,
      };
    case INVITES_ACTIONS.LOAD_MORE_INVITES_START:
      return {
        ...state,
        loadingInv: true,
        errorInv: null,
      };
    case INVITES_ACTIONS.LOAD_MORE_INVITES_SUCCESS:
      return {
        ...state,
        invites: [...(state.invites ? state.invites : []), ...action.invites],
        loadingInv: false,
        errorInv: null,
      };
    case INVITES_ACTIONS.FETCH_DETAILS:
      return {
        ...state,
        loadingInv: true,
      };
    case INVITES_ACTIONS.FETCH_DETAILS_SUCCESS:
      return {
        ...state,
        selectedInvite: action.invite,
        loadingInv: false,
      };
    case INVITES_ACTIONS.UPDATE_START:
      return { ...state, updatingInv: true };
    case INVITES_ACTIONS.UPDATE_SUCCESS:
      return {
        ...state,
        updatingInv: false,
        loadingInv: false,
        errorInv: null,
      };
    case INVITES_ACTIONS.UPDATE_FAIL:
      return {
        ...state,
        updatingInv: false,
        loadingInv: false,
        errorInv: action.errorInv,
      };
    case INVITES_ACTIONS.REMOVE_START:
      return { ...state, updatingInv: true };
    case INVITES_ACTIONS.REMOVE_SUCCESS:
      return {
        ...state,
        updatingInv: false,
        loadingInv: false,
        errorInv: null,
        invites: action.invites,
      };
    case INVITES_ACTIONS.LOAD_MORE_INVITES_FAIL:
      return {
        ...state,
        loadingInv: false,
      };
    default:
      return state;
  }
};
