import { MenuItem, Select } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useContext } from 'react';
import { GlossaryStore } from '../../providers/glossary';
import {
  GlossarySort,
  GLOSSARY_SORT_OPTIONS,
} from '../../utils/constants/glossarySort.constants';

interface GlossarySortSelectProps {}

const useStyles = makeStyles(() => ({
  select: {
    paddingLeft: 0,
  },
}));

const GlossarySortSelect: React.FC<
  GlossarySortSelectProps
> = (): JSX.Element => {
  const classes = useStyles();
  const { sort, onSortChange } = useContext(GlossaryStore);

  return (
    <Select
      value={sort}
      onChange={(e: React.ChangeEvent<{ value: unknown }>) =>
        onSortChange(e.target.value as GlossarySort)
      }
      MenuProps={{
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'left',
        },
        getContentAnchorEl: null,
      }}
      classes={{ select: classes.select }}
    >
      {GLOSSARY_SORT_OPTIONS.map((option, i) => (
        <MenuItem key={i} value={option.value}>
          {option.name}
        </MenuItem>
      ))}
    </Select>
  );
};

export default GlossarySortSelect;
