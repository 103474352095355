import {
  Box,
  CircularProgress,
  Divider,
  Grid,
  Typography,
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { DirectoryStore } from '../../providers/directory';
import { LAYOUT } from '../../utils/constants/layout.constants';
import { DIRECTORY_PAGE } from '../../utils/constants/pageNames.constants';
import TopPannel from '../common/TopPannel';
import DirectoryFilter from './DirectoryFilter';
import DirectoryLetters from './DirectoryLetters';
import DirectoryList from './DirectoryList';
import DirectorySearch from './DirectorySearch';

interface DirectoryContentProps {}

const useStyles = makeStyles((theme) => {
  const LettersBlockBottomMargin = 32;
  const LettersBlockHeight = 25 + LettersBlockBottomMargin;
  const ListTopMargin = 32;
  return {
    content: {
      [theme.breakpoints.up('md')]: {
        marginTop: LAYOUT.TopPanelHeight + LAYOUT.TopPanelBottomMargin,
        width: `calc(100% - ${LAYOUT.DrawerWidth}px)`,
        marginLeft: LAYOUT.DrawerWidth,
      },
    },
    divider: {
      margin: 0,
      [theme.breakpoints.up('md')]: {
        marginRight: theme.spacing(5),
        marginLeft: theme.spacing(5),
      },
    },
    fullsize: {
      height: '100%',
      [theme.breakpoints.up('md')]: {
        height: `calc(100vh - ${LAYOUT.TopPanelHeight}px - ${LAYOUT.TopPanelBottomMargin}px - ${LettersBlockHeight}px - ${ListTopMargin}px)`,
      },
    },
    itemOrder: {
      order: 1,
      height: '480px',
      [theme.breakpoints.up('md')]: {
        order: 'initial',
        height: 'auto',
      },
    },
  };
});

const DirectoryContent: React.FC<DirectoryContentProps> = (): JSX.Element => {
  const classes = useStyles();

  const { directoryState, getDirectoryList, search } =
    useContext(DirectoryStore);
  const { directoryList, total, error, loading } = directoryState;

  const [selectedLetter, setSelectedLetter] = useState({ letter: '#' });

  const handleLetterClick = useCallback(
    (letter: string) => {
      setSelectedLetter({ letter });
      search({
        letter: `${letter}*`,
        search: { keyword: undefined },
      });
    },
    [setSelectedLetter, search]
  );

  useEffect(() => {
    getDirectoryList();
  }, [getDirectoryList]);

  const theme = useTheme();

  const matchesMediaQuery = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <Box className={classes.content}>
      {matchesMediaQuery ? (
        <TopPannel>
          <Typography variant="h4" color="textPrimary">
            {DIRECTORY_PAGE}
          </Typography>
        </TopPannel>
      ) : (
        <Box ml={4} mb={4}>
          <Typography variant="h4" color="textPrimary" align="left">
            {DIRECTORY_PAGE}
          </Typography>
        </Box>
      )}

      <Box mr={5} ml={5}>
        <Box
          mb={{ xs: 0, sm: 0, md: 8, lg: 8 }}
          ml={{ xs: 0, sm: 0, md: 5, lg: 5 }}
        >
          {matchesMediaQuery && (
            <DirectoryLetters
              handleLetterClick={handleLetterClick}
              selectedLetter={selectedLetter.letter}
            />
          )}
        </Box>
        <Divider classes={{ root: classes.divider }} />
        <Grid container direction={'row'} className={classes.fullsize}>
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={6}
            className={classes.itemOrder}
          >
            <Box mt={8} height={'100%'}>
              {!loading && !total && (
                <Typography>
                  {'No results are matching your search criteria'}
                </Typography>
              )}
              {error && <Typography>{error}</Typography>}
              {directoryList && !loading && (
                <DirectoryList
                  directoryList={directoryList}
                  letterEvent={selectedLetter}
                ></DirectoryList>
              )}
              {loading && <CircularProgress />}
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Box
              mt={8}
              ml={{ xs: 'auto', sm: 'auto', md: 'auto', lg: 28 }}
              mr={{ xs: 'auto', sm: 'auto', md: 'auto', lg: 15 }}
            >
              <Typography variant="body2" color="textSecondary" align="left">
                Showing
              </Typography>
              <Typography variant="h4" color="textPrimary" align="left">
                {directoryList?.length || 0} of {total || 0} profiles
              </Typography>
              <Box mt={8} mb={8}>
                <Typography variant="body1" color="textSecondary" align="left">
                  Find a company or individual by browsing the directory or use
                  the search field.
                </Typography>
              </Box>

              <Typography variant="subtitle1" align="left">
                Search for a company or individual
              </Typography>
              <DirectorySearch></DirectorySearch>
              <Box mt={10}>
                <Typography variant="subtitle1" align="left">
                  Filter by
                </Typography>
              </Box>
              <Box mr={19}>
                <DirectoryFilter></DirectoryFilter>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default DirectoryContent;
