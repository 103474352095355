import {
  Dialog,
  DialogProps,
  DialogTitle,
  IconButton,
  SvgIcon,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { ReactComponent as CloseIcon } from '../../assets/icons/close-icon.svg';
import { LAYOUT } from '../../utils/constants/layout.constants';

type WrappedDialogProps = DialogProps & {
  dialogTitle: string;
  open: boolean;
  handleDialogClose: () => void;
  withCloseIcon?: boolean;
};

const useStyles = makeStyles((theme) => ({
  dialog: {
    maxWidth: LAYOUT.DialogMaxWidthSmall,
    [theme.breakpoints.up('md')]: {
      width: LAYOUT.DialogMaxWidth,
      maxWidth: LAYOUT.DialogMaxWidth,
    },
  },
  title: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingRight: theme.spacing(4),
  },
  icon: {
    width: 32,
    height: 32,
    padding: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
}));

const WrappedDialog: React.FC<WrappedDialogProps> = (
  props: WrappedDialogProps
): JSX.Element => {
  const {
    open,
    handleDialogClose,
    dialogTitle,
    children,
    withCloseIcon = true,
    ...other
  } = props;
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      onClose={handleDialogClose}
      {...other}
      classes={{ paperWidthSm: classes.dialog }}
    >
      <DialogTitle disableTypography classes={{ root: classes.title }}>
        <Typography variant="h4">{dialogTitle}</Typography>
        {withCloseIcon && (
          <IconButton
            aria-label="delete button"
            onClick={handleDialogClose}
            classes={{
              root: classes.icon,
            }}
          >
            <SvgIcon component={CloseIcon}></SvgIcon>
          </IconButton>
        )}
      </DialogTitle>
      {children}
    </Dialog>
  );
};

export default WrappedDialog;
