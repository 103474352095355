import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, SvgIcon, TextField, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect } from 'react';
import { useForm, useFormState } from 'react-hook-form';
import * as yup from 'yup';
import { ReactComponent as BrowseIcon } from '../../assets/icons/browse.svg';
import { ReactComponent as UploadIconDisabled } from '../../assets/icons/upload-disabled.svg';
import { ReactComponent as UploadIcon } from '../../assets/icons/upload-white.svg';

interface FileUploaderProps {
  title: string;
  value?: string;
  uploadButtonTitle?: string;
  filepathPlaceholder?: string;
  disabled?: boolean;
  onUpload: (data: { upload: FileList }) => void;
  onFileSelected?: (files: FileList) => void;
  supportedFileFormats: string[];
}

const useStyles = makeStyles((theme) => ({
  button: {
    borderRadius: 10,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    marginRight: theme.spacing(1),
  },
  buttonDisabled: {
    backgroundColor: theme.palette.secondary.main,
  },
  textField: {
    width: '100%',
  },
  error: {
    height: 28,
  },
  icon: {
    paddingRight: theme.spacing(2),
  },
}));

const FileUploader: React.FC<FileUploaderProps> = ({
  title,
  value,
  uploadButtonTitle = 'Upload',
  filepathPlaceholder = '',
  disabled,
  onUpload,
  onFileSelected,
  supportedFileFormats,
}: FileUploaderProps): JSX.Element => {
  const classes = useStyles();

  const validationSchema = yup.object().shape({
    upload: yup
      .mixed()
      .nullable()
      .required()
      .test(
        'FILE_FORMAT',
        'File has unsupported format',
        (value) =>
          !value ||
          value.length === 0 ||
          supportedFileFormats.includes(
            `.${value[0].name.toLowerCase().split('.').pop()}`
          )
      ),
  });

  const {
    register,
    handleSubmit,
    control,
    watch,
    reset,
    formState: { errors, isValid, isDirty },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
  });
  const { dirtyFields } = useFormState({ control });

  const watchFileUpload = watch('upload');

  useEffect(() => {
    const subscription = watch((data, { name }) => {
      if (name === 'upload' && onFileSelected) {
        onFileSelected(data.upload);
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, onFileSelected]);

  useEffect(() => {}, [value, reset]);

  return (
    <form>
      <Box>
        <Typography variant="subtitle1" align="left">
          {title}
        </Typography>
        <TextField
          label=""
          classes={{ root: classes.textField }}
          value={
            dirtyFields.upload
              ? watchFileUpload?.[0]?.['name']
              : filepathPlaceholder
          }
          inputProps={{ readOnly: true }}
        />
        <Typography variant="body1" color="error" className={classes.error}>
          {errors.upload?.message}
        </Typography>
      </Box>
      <Box display="flex" alignContent="left">
        <Button
          variant="contained"
          color="secondary"
          disableElevation
          classes={{ root: classes.button }}
          component="label"
          disabled={disabled}
        >
          <SvgIcon
            component={BrowseIcon}
            classes={{ root: classes.icon }}
          ></SvgIcon>
          Browse
          <input
            {...register('upload')}
            type="file"
            accept={supportedFileFormats.join(', ')}
            hidden
          />
        </Button>

        <Button
          type="submit"
          variant="contained"
          color="primary"
          onClick={handleSubmit(onUpload)}
          disableElevation
          classes={{ root: classes.button, disabled: classes.buttonDisabled }}
          disabled={!isValid || disabled || !isDirty}
        >
          {uploadButtonTitle === 'Upload' && (
            <SvgIcon
              component={
                !isValid || disabled || !isDirty
                  ? UploadIconDisabled
                  : UploadIcon
              }
              color="secondary"
            ></SvgIcon>
          )}
          {uploadButtonTitle}
        </Button>
      </Box>
    </form>
  );
};

export default FileUploader;
