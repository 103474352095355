import { Box, ListItemText, SvgIcon, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { ReactComponent as ViewDetails } from '../../assets/icons/right-arrow.svg';
import { ORGANISATIONS_VIEW_ROUTE } from '../../routes/Types';
import { OrganisationListItem } from '../../utils/types/organisation';

const useStyles = makeStyles((theme) => ({
  listItemText: {
    padding:  `${theme.spacing(2)}px ${theme.spacing(4)}px`,
    '&:hover': {
      '& $iconWrapper': {
        opacity: 1,
      },
    },
  },
  listItemTextWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  name: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    textTransform: 'capitalize',
    fontWeight: 600,
  },
  iconWrapper: {
    opacity: 0,
    width: 32,
    height: 32,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
    backgroundColor: theme.palette.secondary.main,
    transition: 'opacity 0.2s ease-in-out',
  },
  iconSvg: {
    width: 20,
    height: 14,
  },
}));

const OrganisationListItemContent = ({
  id,
  name,
  created_at,
}: OrganisationListItem) => {
  const classes = useStyles();
  const history = useHistory();

  const onViewOrganisationDetails = () => {
    history.push(ORGANISATIONS_VIEW_ROUTE.replace(':orgId', id.toString()));
  };

  return (
    <ListItemText
      className={classes.listItemText}
      onClick={onViewOrganisationDetails}
    >
      <Box className={classes.listItemTextWrapper}>
        <Box display={'flex'} flexDirection={'column'}>
          <Typography variant="body2" className={classes.name}>
            {name}
          </Typography>
          <Typography variant="body2">Created at: {created_at}</Typography>
        </Box>

        <Box className={classes.iconWrapper}>
          <SvgIcon width={20} height={14} component={ViewDetails} viewBox="0 0 20 14" classes={{root: classes.iconSvg}}></SvgIcon>
        </Box>
      </Box>
    </ListItemText>
  );
};

export default OrganisationListItemContent;
