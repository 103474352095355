import React from 'react';
import TooltipsAddContent from '../../components/tooltips/TooltipsAddContent';

interface TooltipsAddPageProps {}

const TooltipsAddPage: React.FC<TooltipsAddPageProps> = (): JSX.Element => {
  return <TooltipsAddContent />;
};

export default TooltipsAddPage;
