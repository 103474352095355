import { Box, Button, Popover, TextField, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Editor, Transforms } from 'slate';
import { useFocused } from 'slate-react';
import { DirectoryListItem, DirectoryStore } from '../../providers/directory';
import useKeyboardEvents from '../../utils/hooks/useKeyboardEvents';

interface TextEditorPopoverProps {
  shouldShowPopover: boolean;
  setShouldShowPopover: (shouldShowPopover: boolean) => void;
  isUrl: (url: string) => boolean;
  wrapLink: (editor: Editor, url: string) => void;
  editor: Editor;
}

const useStyles = makeStyles((theme) => ({
  error: {
    padding: theme.spacing(2),
    fontSize: 12,
    color: theme.palette.error.main,
  },
  typography: {
    padding: theme.spacing(2),
  },
}));

const TextEditorPopover: React.FC<TextEditorPopoverProps> = ({
  shouldShowPopover,
  isUrl,
  editor,
  setShouldShowPopover,
  wrapLink,
}: TextEditorPopoverProps): JSX.Element => {
  const { x, y, key, mode, resetKey, resetMode } = useKeyboardEvents();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [popoverValue, setPopoverValue] = useState('');
  const [searchTo, setSearchTo] = useState<any>(undefined);
  const { directoryState, reset, search } = useContext(DirectoryStore);
  const isFocused = useFocused();

  const classes = useStyles();
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  useEffect(() => {
    if (mode === 'internal') {
      if (searchTo) {
        clearTimeout(searchTo);
      }

      if (popoverValue.length < 3) {
        reset();
        return;
      }

      let to = setTimeout(() => {
        search({ search: { keyword: popoverValue } });
      }, 500);

      setSearchTo(to);
    }
  }, [popoverValue, mode]);

  useEffect(() => {
    if (key === 'Escape' && shouldShowPopover) {
      setShouldShowPopover(false);
      resetKey();
      //keep the cursor on its current position after popup close
      Transforms.insertFragment(editor, [{ text: '' }]);
    }

    if (key === 'Enter' && shouldShowPopover) {
      if (mode === 'link' && isUrl(popoverValue)) {
        handleLinkSubmit();
        setShouldShowPopover(false);
      }
    }

    if (key === '@' && isFocused) {
      setTimeout(() => {
        setShouldShowPopover(true);
      }, 0);
    }
  }, [key, mode]);

  const handleLinkSubmit = () => {
    if (!popoverValue) return;

    if (!isUrl(popoverValue)) return;

    insertLink(editor, popoverValue);
  };

  const insertLink = (editor: any, url: string) => {
    if (editor.selection) {
      wrapLink(editor, url);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getPlaceholderText = useCallback(() => {
    if (mode === 'internal') {
      return 'Search individual or company';
    }

    return 'Enter a url';
  }, [mode]);

  const handleLinkChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPopoverValue(event.target.value);
  };

  const searchResults = useMemo(() => {
    return directoryState.directoryList || [];
  }, [directoryState]);

  const handleInternalLinkSubmit = (id: string, name: string, type: string) => {
    Editor.deleteBackward(editor, { unit: 'character' });

    const url = `https://app.fundpath.co.uk/${
      type === 'individual' ? 'individual-profile' : 'company-profile'
    }?id=${id}`;

    const link: any = {
      type: 'link',
      url,
      children: [{ text: name }],
    };

    Transforms.insertNodes(editor, link);
    Transforms.insertFragment(editor, [{ text: ' ' }]);
    setShouldShowPopover(false);
    reset();
    resetMode();
  };

  return (
    <Popover
      id={id}
      open={shouldShowPopover}
      anchorReference={'anchorPosition'}
      anchorPosition={{
        top: y || 0,
        left: x || 0,
      }}
      onClose={handleClose}
    >
      <Box>
        {mode === 'link' && !isUrl(popoverValue) && (
          <Typography classes={{ root: classes.error }}>
            Please provide a valid url address.
          </Typography>
        )}
        <TextField
          autoFocus={true}
          placeholder={getPlaceholderText()}
          multiline={false}
          rows={1}
          classes={{ root: classes.typography }}
          onChange={handleLinkChange}
        ></TextField>
      </Box>
      {searchResults.length > 0 && mode === 'internal' && (
        <Box
          display={'flex'}
          flexDirection={'column'}
          alignItems={'flex-start'}
          maxHeight={200}
          style={{ overflowY: 'scroll' }}
        >
          {searchResults.map((result: DirectoryListItem) => (
            <Button
              key={result.id}
              onClick={() => {
                handleInternalLinkSubmit(result.id, result.name, result.type);
                //TODO: add link in the editor where replacing @ with link text = result.name and link url = "https://app.fundpath.co.uk/?fpid=result.id"
              }}
            >
              {result.name}
            </Button>
          ))}
        </Box>
      )}
      <Box
        component={'div'}
        p={2}
        pr={4}
        display={'flex'}
        justifyContent={'center'}
      >
        <Typography variant="body2">
          Esc to cancel / Enter to confirm
        </Typography>
      </Box>
    </Popover>
  );
};

export default TextEditorPopover;
