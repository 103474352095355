import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { Link } from 'react-router-dom';
import { DIRECTORY_PROFILE_ROUTE } from '../../routes/Types';
import StyledPaper from '../common/StyledPaper';

interface PersonnelTableProps {
  people: Array<any>;
}

const useStyles = makeStyles((theme) => ({
  link: {
    textDecoration: 'none',
    '&:visited': {
      color: theme.palette.text.primary,
    },
    '&:hover': {
      color: theme.palette.primary.main,
    },
    '&:active': {
      color: theme.palette.primary.main,
    },
  },
}));

const PersonnelTable: React.FC<PersonnelTableProps> = ({
  people,
}: PersonnelTableProps): JSX.Element => {
  const classes = useStyles();
  return (
    <Box mt={4} mb={4}>
      <StyledPaper>
        <Box pt={8} pr={8} pl={8} pb={6}>
          <Typography variant="h5" color="primary" align="left">
            Employees
          </Typography>
        </Box>
        {people.length === 0 && (
          <Box mr={8} ml={8} pb={8}>
            <Typography variant="body1" color="textSecondary" align="left">
              {"This company doesn't have employees in Fundpath."}
            </Typography>
          </Box>
        )}
        {people.length > 0 && (
          <Box mr={8} ml={8}>
            <Typography variant="body1" color="textSecondary" align="left">
              Change an individuals personnel status by going to their profile.
            </Typography>
          </Box>
        )}
        {people.length > 0 && (
          <Box pr={4} pl={4} pb={4}>
            <TableContainer>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <Typography variant="subtitle1">Name</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="subtitle1">Role</Typography>
                    </TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {people.map((person: any, idx: number) => (
                    <TableRow key={idx}>
                      <TableCell>
                        <Link
                          to={`${DIRECTORY_PROFILE_ROUTE}/${person.id}`}
                          className={classes.link}
                        >
                          <Typography variant="caption" color="primary">
                            {person.name}
                          </Typography>
                        </Link>
                      </TableCell>
                      <TableCell>
                        <Typography variant="caption" color="textSecondary">
                          {person.role}
                        </Typography>
                      </TableCell>
                      <TableCell align="right">
                        <Link
                          to={`${DIRECTORY_PROFILE_ROUTE}/${person.id}`}
                          className={classes.link}
                        >
                          <Typography variant="caption" color="primary">
                            View profile
                          </Typography>
                        </Link>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        )}
      </StyledPaper>
    </Box>
  );
};

export default PersonnelTable;
