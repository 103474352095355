import React from 'react';
import {
  default as LogoBasic,
  default as LogoPrimary,
} from '../../assets/fundpath-new-admin-logo.svg';

interface LogoProps {
  variant: 'primary' | 'default';
  width?: number;
  height?: number;
  className?: string;
}
const Logo: React.FC<LogoProps> = ({
  variant,
  width,
  height,
  className,
}: LogoProps): JSX.Element => {
  const logoSrc = variant === 'primary' ? LogoPrimary : LogoBasic;

  return (
    <img
      src={logoSrc}
      width={width}
      height={height}
      className={className}
      alt="Fundpath Logo"
    />
  );
};

export default Logo;
