import { Box, Link, SvgIcon, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { ReactComponent as BackIcon } from '../../assets/icons/back-arrow.svg';

interface TitleProps {
  onRedirectLinkClick: React.MouseEventHandler;
  pageName: string;
}

const useStyles = makeStyles((theme) => ({
  backArrowIcon: {
    width: 32,
    height: 32,
    marginRight: theme.spacing(3),
  },
}));

const Title: React.FC<TitleProps> = ({
  onRedirectLinkClick,
  pageName,
}: TitleProps): JSX.Element => {
  const classes = useStyles();
  return (
    <Link
      component="button"
      variant="h6"
      underline="none"
      onClick={onRedirectLinkClick}
    >
      <Box display="flex">
        <SvgIcon
          component={BackIcon}
          viewBox="0 0 32 32"
          classes={{ root: classes.backArrowIcon }}
        ></SvgIcon>
        <Typography variant="h6" color="textPrimary">
          {pageName}
        </Typography>
      </Box>
    </Link>
  );
};

export default Title;
