import { createContext, useContext } from 'react';
import { getAllInvitesFirebase } from '../services/invites';
import { UsersStore } from './users';
import { useOrganisation } from '../hooks/services';

interface ExportContext {
  exportCsv: () => void;
}

export const ExportStore = createContext<ExportContext>({
  exportCsv: () => {},
});

const { Provider } = ExportStore;

export const ExportProvider = ({ children }: any) => {
  const { state : {data} } = useContext(UsersStore);
  const { organisation } = useOrganisation();
  const exportCsv = async () => {
    if (!organisation || !organisation.id) {
      return;
    }
    const allUsers = data;
    const allInvites = await getAllInvitesFirebase(organisation?.id);

    const exportUsers = allUsers;

    let rows = exportUsers?.map((d: any) => {
      let lastActivityDate = '-';
      let expiryDate = '-';
      try {
        lastActivityDate = new Date(d['activityFeedLastSeen']).toString();
        expiryDate = new Date(d['expiry']).toString();
      } catch (e) {
        console.log(e);
      }
      return [
        d['email'],
        d['firstName'],
        d['lastName'],
        d['company'],
        d['jobTitle']?.replaceAll(',', '-'),
        d['role']?.replaceAll(',', '-'),
        d['organization_role'],
        d['phone'],
        d['mobileNumber'],
        d['archived'],
        d['regions']?.replaceAll(',', ';'),
        d['clientTypes']?.replaceAll(',', ';'),
        lastActivityDate,
        expiryDate,
        'Signup',
      ];
    });

    const exportInvites = allInvites;
    let invites = exportInvites?.map((inv: any) => {
      return [
        inv['email'],
        inv['firstName'],
        inv['lastName'],
        inv['company'],
        '',
        '',
        '',
        '',
        'N/A',
        inv['organization_role'],
        inv['expiry'],
        'Invited but not Signup',
      ];
    });

    let csvContent =
      'data:text/csv;charset=utf-8,' +
      rows?.map((e) => e.join(',')).join('\n') +
      '\n' +
      invites?.map((e) => e.join(',')).join('\n');

    var encodedUri = encodeURI(csvContent);
    var link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', `users-export-${Date.now()}.csv`);
    document.body.appendChild(link); // Required for FF

    link.click();
  };

  return <Provider value={{ exportCsv }}>{children}</Provider>;
};
