import { createContext, useCallback, useEffect, useReducer } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { AUTH_ACTIONS, AuthState, auth } from '../reducers/auth';
import {
  DIRECTORY_ROUTE,
  LOGIN_ROUTE,
  VERIFICATION_ROUTE,
} from '../routes/Types';
import {
  checkFirebaseAuth,
  loginWithFirebase,
  logoutFirebase,
} from '../services/auth';
import { getUserDetailsFirebaseByEmail } from '../services/users';

interface stateType {
  pathname: string;
}

export const AuthStore = createContext<
  [
    AuthState,
    (username: string, password: string) => any | undefined,
    () => any | undefined,
    () => any | undefined
  ]
>([
  { user: undefined, error: undefined, init: true, loading: false },
  () => {},
  () => {},
  () => {},
]);

const { Provider } = AuthStore;

export const AuthProvider = ({ children }: any) => {
  const [authState, dispatch] = useReducer(auth, {
    init: true,
    loading: false,
  });
  let history = useHistory();
  const { pathname } = useLocation<stateType>();

  const redirectToLogin = useCallback(() => {
    history.replace(LOGIN_ROUTE);
  }, [history]);

  const redirectToPrivateHome = useCallback(() => {
    let { from } = { from: { pathname } };

    history.replace(from.pathname === '/login' ? DIRECTORY_ROUTE : from);
    // eslint-disable-next-line
  }, [history]);

  const initialLogin = useCallback(async () => {
    dispatch({ type: AUTH_ACTIONS.LOGIN_INIT });
    try {
      const result = await checkFirebaseAuth();

      if (!result) {
        dispatch({
          type: AUTH_ACTIONS.LOGIN_FAIL,
          payload: { error: '' },
        });

        redirectToLogin();

        return;
      }

      const { email, role } = result;
      if (email) {
        const _userDetails = await getUserDetailsFirebaseByEmail(email);
        dispatch({
          type: AUTH_ACTIONS.LOGIN_SUCCESS,
          payload: {
            user: email,
            role,
            avatar: _userDetails?.avatar,
            firstName: _userDetails?.firstName,
            lastName: _userDetails?.lastName,
          },
        });

        redirectToPrivateHome();
      } else {
        dispatch({
          type: AUTH_ACTIONS.LOGIN_FAIL,
          payload: { error: '' },
        });

        redirectToLogin();
      }
    } catch (e) {
      dispatch({
        type: AUTH_ACTIONS.LOGIN_FAIL,
        payload: { error: e },
      });
    }
  }, [redirectToLogin, redirectToPrivateHome]);

  const login = async (email: string, password: string) => {
    dispatch({ type: AUTH_ACTIONS.LOGIN_START });
    try {
      const result = await loginWithFirebase(email, password);

      const { user } = result;
      if (user) {
        dispatch({
          type: AUTH_ACTIONS.LOGIN_SUCCESS,
          payload: { user: user?.email },
        });

        initialLogin();
      } else {
        dispatch({
          type: AUTH_ACTIONS.LOGIN_FAIL,
          payload: { error: 'No such user!' },
        });
      }
    } catch (e) {
      const error = e as { code?: string; message?: string };
      if (error.code === 'auth/multi-factor-auth-required') {
        // Initiate second factor sign-in flow
        dispatch({ type: AUTH_ACTIONS.START_MFA, payload: e });
        history.push(`${VERIFICATION_ROUTE}`);
      } else {
        dispatch({
          type: AUTH_ACTIONS.LOGIN_FAIL,
          payload: { error: e },
        });
        console.log(e);
      }
    }

    return;
  };

  const logout = async () => {
    dispatch({ type: AUTH_ACTIONS.LOGIN_START });
    try {
      await logoutFirebase();

      dispatch({
        type: AUTH_ACTIONS.LOGOUT_SUCCESS,
        payload: null,
      });

      redirectToLogin();
    } catch (e) {
      dispatch({
        type: AUTH_ACTIONS.LOGIN_FAIL,
        payload: { error: e },
      });
      console.log(e);
    }
  };

  useEffect(() => {
    initialLogin();
  }, [initialLogin]);

  return (
    <Provider value={[authState, login, logout, initialLogin]}>
      {children}
    </Provider>
  );
};
