import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useContext } from 'react';
import { Control, Controller } from 'react-hook-form';
import { Descendant } from 'slate';
import { TooltipsStore } from '../../providers/tooltips';
import StyledInput from '../common/StyledInput';
import TextEditor from '../common/TextEditor';

export interface TooltipsFormFields {
  id?: string;
  tooltipTitle: string;
  tooltipKey: string;
  tooltipText: Descendant[];
  tooltipPublishDate: Date;
  tooltipPublishTime: Date;
}
interface TooltipsFormSectionProps {
  control: Control<TooltipsFormFields>;
  errors: any;
  editMode: boolean;
}

const useStyles = makeStyles((theme) => ({
  input: {
    'label + &': {
      marginTop: 0,
      color: theme.palette.text.secondary,
    },
  },
  textField: {
    width: '100%',
  },
  errorMessage: {
    height: theme.spacing(6),
  },
}));

const TooltipsFormSection: React.FC<TooltipsFormSectionProps> = ({
  control,
  errors,
  editMode,
}: TooltipsFormSectionProps): JSX.Element => {
  const classes = useStyles();
  const {
    state: { contentImageUrl },
    uploadContentImage,
  } = useContext(TooltipsStore);

  return (
    <>
      <Box>
        <StyledInput
          name="tooltipTitle"
          label="Tooltip title"
          control={control}
          error={!!errors.tooltipTitle}
          classnames={{ formControl: classes.input }}
        />
        {errors.tooltipTitle && (
          <Typography
            variant="body1"
            color="error"
            className={classes.errorMessage}
            align="left"
          >
            {errors.tooltipTitle.message}
          </Typography>
        )}
      </Box>
      <Box mt={8}>
        <StyledInput
          name="tooltipKey"
          label="Tooltip key"
          control={control}
          error={!!errors.tooltipKey}
          classnames={{ formControl: classes.input }}
          disabled={editMode}
        />
        {errors.tooltipKey && (
          <Typography
            variant="body1"
            color="error"
            className={classes.errorMessage}
            align="left"
          >
            {errors.tooltipKey.message}
          </Typography>
        )}
      </Box>
      <Box mt={{ xs: 4, sm: 4, md: 4, lg: 17 }}>
        <Box mt={10}>
          <Controller
            control={control}
            name="tooltipText"
            render={({ field: { onChange, value } }) => {
              return control?.defaultValuesRef?.current?.tooltipTitle ||
                !control?.defaultValuesRef?.current?.id?.length ? (
                <TextEditor
                  title="Tooltip body"
                  value={value}
                  onChange={onChange}
                  placeholder="Add tooltip description..."
                  contentImageUrl={contentImageUrl}
                  uploadContentImage={uploadContentImage}
                />
              ) : (
                <></>
              );
            }}
          />
          {errors.tooltipText && (
            <Typography
              variant="body1"
              color="error"
              className={classes.errorMessage}
              align="left"
            >
              {errors.tooltipText.message}
            </Typography>
          )}
        </Box>
      </Box>
    </>
  );
};

export default TooltipsFormSection;
