import { IconButton, Menu } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import React from 'react';

interface VirualizedListMenuProps {
  anchorEl: null | HTMLElement;
  open: boolean;
  onClose: () => void;
  onClick: (e: React.MouseEvent<HTMLElement>) => void;
  children: any;
}

const useStyles = makeStyles((_theme) => ({
  button: {
    padding: 0,
  },
}));

const VirualizedListMenu: React.FC<VirualizedListMenuProps> = ({
  anchorEl,
  open,
  onClose,
  onClick,
  children,
}: VirualizedListMenuProps): JSX.Element => {
  const classes = useStyles();

  return (
    <div>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={onClick}
        color="primary"
        classes={{ root: classes.button }}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={onClose}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          style: {
            width: 152,
          },
        }}
      >
        {children}
      </Menu>
    </div>
  );
};

export default VirualizedListMenu;
