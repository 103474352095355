import {
  collection,
  getDocs,
  limit,
  orderBy,
  query,
} from '@firebase/firestore';
import { getElasticToken } from './elasticAuth';
import { db } from './firebase';
import individual_old_record from './individual_profile_partial.json';

type UpdateType =
  | 'Individual profile update'
  | 'Profile update'
  | 'Company Profile update'
  | 'Data upload';
export interface PendingUpdatesListItem {
  id: string;
  title: string;
  type: UpdateType;
  date: number;
  status: string;
  user: string;
  scheduledDate?: number;
}

export interface CompanyProfile {}

export const fetchUpdatesData = async (): Promise<
  Array<PendingUpdatesListItem>
> => {
  const uploadsCollection = collection(db, 'uploads');
  const uploadsQuery = query(
    uploadsCollection,
    orderBy('created', 'desc'),
    limit(200)
  );

  const uploads = await getDocs(uploadsQuery);

  return uploads.docs
    .map((doc) => ({
      id: doc.id,
      date: doc.data().created,
      title: doc.data().fileName,
      status: doc.data().status,
      type: doc.data().type,
      user: doc.data().user || '-',
      scheduledDate: doc.data().scheduledDate,
    }))
    .filter(
      (upload) =>
        upload.status === 'pending review' ||
        upload.status === 'published' ||
        upload.status === 'publishing failed' ||
        upload.status === 'rejected' ||
        upload.status === 'reverted' ||
        upload.status === 'partially uploaded'
    );
};

const reviewIndividualOldRecord = individual_old_record;
//const reviewCompanyUpdatedRecord = company_old_record;

export const fetchReviewOldRecord = async (): Promise<any> =>
  new Promise((resolve, _reject) => {
    setTimeout(() => {
      resolve(reviewIndividualOldRecord);
    }, 1000);
  });

export const getReviewOldRecordData = async () => {
  const data = await fetchReviewOldRecord();
  const reviewOldRecord = data;
  return reviewOldRecord;
};

const reviewIndividuaUpdtedRecord = individual_old_record;
//const reviewCompanyUpdatedRecord = company_updated_record;

export const fetchReviewUpdatedRecord = async (): Promise<any> =>
  new Promise((resolve, _reject) => {
    setTimeout(() => {
      resolve(reviewIndividuaUpdtedRecord);
    }, 1000);
  });

export const getReviewUpdatedRecordData = async (id: string) => {
  let elasticCredentials;
  try {
    elasticCredentials = await getElasticToken();
  } catch (e) {
    throw Error('Error authenticating with search database.');
  }

  try {
    let query = undefined;
    if (id) {
      query = {
        query: {
          term: {
            IMPORT_BATCH_DOCUMENT_ID: id,
          },
        },
      };
    }
    const response = await fetch(
      `${elasticCredentials.host}/data-updates/_search`,
      {
        method: 'POST',
        headers: {
          Authorization: `${elasticCredentials.type} ${elasticCredentials.access_token}`,
          'Content-type': 'application/json',
        },
        body: JSON.stringify({
          ...query,
          size: 20,
        }),
      }
    );
    const data = await response.json();
    const results = data.hits.hits.map((hit: any) => {
      let refId = hit['_source']['REFERENCE'];
      let remappedData = hit['_source']['CHANGE_SET'].reduce(
        (acc: any, next: any) => {
          acc.oldValues[next.fieldName] = next.oldValue;
          acc.newValues[next.fieldName] = next.newValue;
          return acc;
        },
        { oldValues: {}, newValues: {} }
      );

      return {
        id: refId,
        oldValues: remappedData.oldValues,
        newValues: remappedData.newValues,
      };
    });

    return {
      results,
      total: data?.hits?.total?.value,
    };
  } catch (e) {
    return null;
  }
};
