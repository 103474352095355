import { httpsCallable } from '@firebase/functions';
import { fbFunctions } from './firebase';

interface TokenData {
  expires_in: number;
  [key: string]: any; // for the rest of the properties that you don't know in advance
}

export const getElasticToken = async () => {
  let credentials = getToken();

  if (!credentials) {
    try {
      const getElasticAccessCredentials = httpsCallable(
        fbFunctions,
        'getElasticAccessCredentials'
      );

      const result = await getElasticAccessCredentials();

      const resultData = result.data as TokenData;

      localStorage.setItem(
        '__et__',
        JSON.stringify({
          ...resultData,
          expires: Date.now() + resultData.expires_in * 1000,
        })
      );

      return result.data;
    } catch (error) {
      console.error('Error fetching elastic token:', error);
      throw error;
    }
  }

  return credentials;
};

export const renewToken = async () => {
  //TODO
};

const getToken = () => {
  try {
    const elasticToken = JSON.parse(localStorage.getItem('__et__') || '{}');
    if (elasticToken.expires > Date.now()) {
      return elasticToken;
    }

    return null;
  } catch (e) {
    return null;
  }
};
