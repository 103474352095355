import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  Divider,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import WrappedDialog from '../common/WrappedDialog';

interface ErrorDialogProps {
  isDialogOpened: boolean;
  onDialogClose: () => void;
}

const useStyles = makeStyles((theme) => ({
  button: {
    width: 100,
    borderRadius: 10,
    marginLeft: theme.spacing(2),
  },
  content: {
    padding: 0,
  },
  text: {
    fontFamily: 'Hero New Bold',
    fontSize: 12,
  },
  boldText: {
    fontFamily: 'Hero New ExtraBold',
    fontSize: 16,
  },
  label: {
    color: theme.palette.text.secondary,
  },
}));

const ErrorDialog: React.FC<ErrorDialogProps> = ({
  isDialogOpened,
  onDialogClose,
}: ErrorDialogProps): JSX.Element => {
  const classes = useStyles();
  return (
    <WrappedDialog
      open={isDialogOpened}
      handleDialogClose={onDialogClose}
      dialogTitle={'Manage tiles'}
    >
      <DialogContent classes={{ root: classes.content }}>
        <Divider />
        <Box mr={10} ml={6}>
          <Box mt={6} mb={6}>
            <Typography variant="body1">
              There was an error while saving tiles. Contact admins for more
              information.
            </Typography>
          </Box>
          <Divider />
        </Box>
        <Divider />
      </DialogContent>
      <DialogActions>
        <Box
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
          mt={2}
          mb={2}
          mr={8}
          ml={8}
        >
          <Box>
            <Button
              onClick={onDialogClose}
              color="secondary"
              variant="contained"
              disableElevation
              classes={{ root: classes.button }}
            >
              OK
            </Button>
          </Box>
        </Box>
      </DialogActions>
    </WrappedDialog>
  );
};

export default ErrorDialog;
