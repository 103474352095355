import { format } from 'date-fns';
import React, { useCallback, useContext, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';
import { TooltipsStore } from '../../providers/tooltips';
import { TOOLTIPS_EDIT_ROUTE } from '../../routes/Types';
import { LAYOUT } from '../../utils/constants/layout.constants';
import { Tooltip } from '../../utils/types/tooltip';
import VirtualizedListItem from '../common/VirtualizedListItem';
import RemoveConfirmationDialog from '../manage-tiles/RemoveConfirmationDialog';
import TooltipsListItemContent from './TooltipsListItemContent';

interface TooltipsListProps {
  tooltipsList: Tooltip[];
  loadMoreItemsHandler: (lastDoc: Tooltip) => void;
}

interface ListRowProps {
  style: any;
  index: number;
  data: Tooltip[];
}

const Row = ({ style, index, data }: ListRowProps) => {
  const { remove } = useContext(TooltipsStore);
  const [isRemoveDialogOpened, setIsRemoveDialogOpened] = useState(false);
  const [tooltipsListItem, setTooltipsListItem] = useState<Tooltip | null>(
    null
  );

  let history = useHistory();

  const onConfirmDialogClose = () => {
    setIsRemoveDialogOpened(false);
  };
  const onRemove = () => {
    setIsRemoveDialogOpened(true);
    setTooltipsListItem(data[index]);
  };

  const onEdit = useCallback(
    (id: string) => {
      history.push(TOOLTIPS_EDIT_ROUTE.replace(':id', id));
    },
    [history]
  );
  return (
    <VirtualizedListItem
      style={{
        ...style,
        width: 'calc(100% - 40px)',
        marginLeft: 20,
        marginRight: 20,
        marginTop: 10,
      }}
    >
      <TooltipsListItemContent
        date={format(data[index].updated, "MMM, dd, yyyy hh:mm aaaaa'm'")}
        tooltipTitle={data[index].title}
        id={data[index].id}
        onRemove={() => onRemove()}
        onEdit={() => onEdit(data[index].id || '')}
      ></TooltipsListItemContent>
      {tooltipsListItem && (
        <RemoveConfirmationDialog
          isDialogOpened={isRemoveDialogOpened}
          onDialogClose={onConfirmDialogClose}
          dataToRemove={tooltipsListItem}
          title="Do you want to remove this tooltip?"
          remove={remove}
        />
      )}
    </VirtualizedListItem>
  );
};

const TooltipsList: React.FC<TooltipsListProps> = ({
  tooltipsList,
  loadMoreItemsHandler,
}): JSX.Element => {
  const itemsCount = tooltipsList.length;
  const listRef = useRef<FixedSizeList>(null);

  const {
    state: { loading },
  } = useContext(TooltipsStore);

  const loadMoreItems = useCallback(
    (startIndex: number, stopIndex: number) => {
      const shouldReload = itemsCount - stopIndex <= 2;

      if (shouldReload && !loading) {
        loadMoreItemsHandler(tooltipsList[itemsCount - 1]);
      }
    },
    [loading, itemsCount, loadMoreItemsHandler]
  );

  const isItemLoaded = useCallback((_index: number) => {
    //TODO: dynamic loading is not available yet
    // console.log(index);
    return false;
  }, []);

  return (
    <AutoSizer>
      {({ height, width }: any) => (
        <InfiniteLoader
          isItemLoaded={isItemLoaded}
          itemCount={itemsCount}
          loadMoreItems={loadMoreItems}
        >
          {({ onItemsRendered }) => (
            <FixedSizeList
              onItemsRendered={onItemsRendered}
              height={height}
              itemCount={itemsCount}
              itemSize={LAYOUT.VirtuilizedListItemHeight}
              width={width}
              itemData={tooltipsList}
              ref={listRef}
            >
              {Row}
            </FixedSizeList>
          )}
        </InfiniteLoader>
      )}
    </AutoSizer>
  );
};

export default TooltipsList;
