import { AppBar, Box, Toolbar } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { LAYOUT } from '../../utils/constants/layout.constants';
import UserAvatar from './UserAvatar';

interface TopPannelProps {
  children: any;
}

const useStyles = makeStyles((theme) => ({
  appBar: {
    backgroundColor: theme.palette.common.white,
    boxShadow: 'none',
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${LAYOUT.DrawerWidth}px)`,
      marginLeft: LAYOUT.DrawerWidth,
    },
  },
  toolBar: {
    justifyContent: 'space-between',
    padding: 0,
  },
  menuButton: {
    marginRight: theme.spacing(4),
  },
}));

const TopPannel: React.FC<TopPannelProps> = ({
  children,
}: TopPannelProps): JSX.Element => {
  const classes = useStyles();

  return (
    <AppBar position="fixed" className={classes.appBar}>
      <Toolbar className={classes.toolBar}>
        <Box m={10}>{children}</Box>

        <UserAvatar />
      </Toolbar>
    </AppBar>
  );
};

export default TopPannel;
