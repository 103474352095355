import { Box, Typography, makeStyles } from '@material-ui/core';
import { LAYOUT } from '../../utils/constants/layout.constants';
import Logo from './Logo';

const useStyles = makeStyles((theme) => ({
  logo: {
    [theme.breakpoints.up('sm')]: {
      width: LAYOUT.LoginLogoSize.width,
      height: LAYOUT.LoginLogoSize.height,
    },
    width: LAYOUT.LoginLogoSizeSmall.width,
    height: LAYOUT.LoginLogoSizeSmall.height,
  },
}));

const WelcomeMessage = () => {
  const classes = useStyles();

  return (
    <>
      <Typography variant="h4">Welcome to</Typography>
      <Logo variant="primary" className={classes.logo} />
      <Box m={2}>
        <Typography variant="h5">Administration Portal</Typography>
      </Box>
    </>
  );
};

export default WelcomeMessage;
