import { createSvgIcon } from '@material-ui/core/utils';

export const Directory = createSvgIcon(
  <path d="M21 3H3C1.9 3 1 3.9 1 5V19C1 20.1 1.9 21 3 21H21C22.1 21 23 20.1 23 19V5C23 3.9 22.1 3 21 3ZM12 11H3V9H12V11ZM12 7H3V5H12V7Z" />,
  'DirectoryIcon'
);

export const UploadData = createSvgIcon(
  <path d="M14 2H6C4.9 2 4.01 2.9 4.01 4L4 20C4 21.1 4.89 22 5.99 22H18C19.1 22 20 21.1 20 20V8L14 2ZM18 20H6V4H13V9H18V20ZM8 15.01L9.41 16.42L11 14.84V19H13V14.84L14.59 16.43L16 15.01L12.01 11L8 15.01Z" />,
  'UploadData'
);

export const UserAdministration = createSvgIcon(
  <path d="M12 12C14.21 12 16 10.21 16 8C16 5.79 14.21 4 12 4C9.79 4 8 5.79 8 8C8 10.21 9.79 12 12 12ZM12 14C9.33 14 4 15.34 4 18V19C4 19.55 4.45 20 5 20H19C19.55 20 20 19.55 20 19V18C20 15.34 14.67 14 12 14Z" />,
  'UserAdministration'
);

export const Publish = createSvgIcon(
  <path d="M20 6H12L10 4H4C2.9 4 2 4.9 2 6V18C2 19.1 2.9 20 4 20H20C21.1 20 22 19.1 22 18V8C22 6.9 21.1 6 20 6ZM14 18V15H10V11H14V8L19 13L14 18Z" />,
  'Publish'
);
export const Articles = createSvgIcon(
  <path d="M20.0304 6.76406L14.9867 1.72031C14.846 1.57969 14.6562 1.5 14.457 1.5H4.50073C4.08589 1.5 3.75073 1.83516 3.75073 2.25V21.75C3.75073 22.1648 4.08589 22.5 4.50073 22.5H19.5007C19.9156 22.5 20.2507 22.1648 20.2507 21.75V7.29609C20.2507 7.09687 20.171 6.90469 20.0304 6.76406ZM18.521 7.64062H14.1101V3.22969L18.521 7.64062ZM18.5632 20.8125H5.43823V3.1875H12.5164V8.25C12.5164 8.51107 12.6201 8.76145 12.8047 8.94606C12.9893 9.13066 13.2397 9.23438 13.5007 9.23438H18.5632V20.8125ZM11.8132 14.4844H7.50073C7.39761 14.4844 7.31323 14.5688 7.31323 14.6719V15.7969C7.31323 15.9 7.39761 15.9844 7.50073 15.9844H11.8132C11.9164 15.9844 12.0007 15.9 12.0007 15.7969V14.6719C12.0007 14.5688 11.9164 14.4844 11.8132 14.4844ZM7.31323 11.4844V12.6094C7.31323 12.7125 7.39761 12.7969 7.50073 12.7969H16.5007C16.6039 12.7969 16.6882 12.7125 16.6882 12.6094V11.4844C16.6882 11.3813 16.6039 11.2969 16.5007 11.2969H7.50073C7.39761 11.2969 7.31323 11.3813 7.31323 11.4844Z" />,
  'Articles'
);
