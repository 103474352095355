import { Box, CircularProgress } from '@material-ui/core';
import { useCallback, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { KnowledgeCentreStore } from '../../providers/knowledgeCentre';
import * as ROUTES from '../../routes/Types';
import { KNOWLEDGE_CENTRE_PAGE } from '../../utils/constants/pageNames.constants';
import SectionContent from '../common/SectionContent';
import KnowledgeCentreList from './KnowledgeCentreList';

const KnowledgeCentreContent = () => {
  const {
    state: { data, loading },
    getBatch,
  } = useContext(KnowledgeCentreStore);

  let history = useHistory();

  const handleAddClick = useCallback(() => {
    history.push(ROUTES.KNOWLEDGE_CENTRE_ADD_ROUTE);
  }, [history]);

  useEffect(() => {
    getBatch(null);
  }, []);

  return (
    <SectionContent
      handleAddClick={handleAddClick}
      addButtonText={'Add article'}
      pageName={KNOWLEDGE_CENTRE_PAGE}
    >
      <Box height={'100%'}>
        {data && (
          <KnowledgeCentreList
            itemsList={data}
            loadMoreItemsHandler={getBatch}
          />
        )}
        {loading && <CircularProgress />}
      </Box>
    </SectionContent>
  );
};

export default KnowledgeCentreContent;
