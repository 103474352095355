import chroma from 'chroma-js';
import { prominent } from 'color.js';

export const getImageBrightness = async (imageUrl: string): Promise<number> => {
  const countColorLuminance = (
    color: [r: number, g: number, b: number]
  ): number => {
    const colorString = `rgb(${color[0]}, ${color[1]}, ${color[2]})`;
    const luminance = chroma(colorString).luminance();
    return luminance;
  };

  const color = (await prominent(imageUrl, { amount: 1 })) as [
    r: number,
    g: number,
    b: number
  ];
  let brightness = countColorLuminance(color);
  if (Number.isInteger(brightness)) {
    brightness -= 0.01;
  }
  return brightness;
};
