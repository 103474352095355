import {
  Box,
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  Divider,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { Control } from 'react-hook-form';
import StyledInput from '../common/StyledInput';
import WrappedDialog from '../common/WrappedDialog';
import { RejectDialogFormFields } from './PendingUpdatesList';

interface RejectDialogProps {
  isDialogOpened: boolean;
  control: Control<RejectDialogFormFields>;
  errors: any;
  onDialogClose: () => void;
  onRejectContinue: () => void;
  loading: boolean;
  title: string;
  date: Date;
  user: string;
}

const useStyles = makeStyles((theme) => ({
  button: {
    width: 100,
    borderRadius: 10,
    marginLeft: theme.spacing(2),
  },
  content: {
    padding: 0,
  },
  text: {
    fontFamily: 'Hero New Bold',
    fontSize: 12,
  },
  boldText: {
    fontFamily: 'Hero New ExtraBold',
    fontSize: 16,
  },
  label: {
    color: theme.palette.text.secondary,
  },
  input: {
    'label + &': {
      marginTop: 0,
      color: theme.palette.text.secondary,
    },
  },
}));

const RejectDialog: React.FC<RejectDialogProps> = ({
  isDialogOpened,
  onDialogClose,
  onRejectContinue,
  control,
  errors,
  title,
  date,
  user,
  loading,
}: RejectDialogProps): JSX.Element => {
  const classes = useStyles();

  return (
    <WrappedDialog
      open={isDialogOpened}
      handleDialogClose={onDialogClose}
      dialogTitle={'Reject updates'}
    >
      <DialogContent classes={{ root: classes.content }}>
        <Divider />
        <Box mr={10} ml={10}>
          <Box mt={6} mb={6}>
            <Typography variant="caption" className={classes.text}>
              File
            </Typography>
            <Typography
              variant="caption"
              className={classes.text}
              color="primary"
            >
              {` ${title}`}
            </Typography>
            <Typography variant="caption" color="textSecondary">
              {' '}
              Updated {date?.toLocaleString()} by {user}
            </Typography>
          </Box>
          <Divider />
          {!loading && (
            <Box mt={10} mb={6}>
              <Box mb={10}>
                <StyledInput
                  name="rejectionReasons"
                  label="Please give a reason for rejecting"
                  placeholder={'Enter description'}
                  control={control}
                  error={!!errors.rejectionReasons}
                  classnames={{ formControl: classes.input }}
                />
              </Box>
              <Box>
                <Typography variant="body1" component="span">
                  Are you sure you want to
                </Typography>
                <Typography
                  variant="body1"
                  component="span"
                  className={classes.boldText}
                >
                  {' '}
                  reject{' '}
                </Typography>
                <Typography variant="body1" component="span">
                  the above update?
                </Typography>
              </Box>
            </Box>
          )}
          {loading && (
            <Box
              display={'flex'}
              alignItems={'center'}
              justifyContent={'center'}
              mt={8}
              mb={8}
            >
              <CircularProgress />
            </Box>
          )}
        </Box>
        <Divider />
      </DialogContent>
      <DialogActions>
        <Box
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
          mt={2}
          mb={2}
          mr={8}
          ml={8}
        >
          <Box>
            <Button
              onClick={onDialogClose}
              disabled={loading}
              color="secondary"
              variant="contained"
              disableElevation
              classes={{ root: classes.button }}
            >
              Cancel
            </Button>
          </Box>
          <Box>
            <Button
              onClick={onRejectContinue}
              disabled={loading}
              color="secondary"
              variant="contained"
              disableElevation
              classes={{ root: classes.button }}
            >
              Continue
            </Button>
          </Box>
        </Box>
      </DialogActions>
    </WrappedDialog>
  );
};

export default RejectDialog;
