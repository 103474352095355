import { makeStyles } from '@material-ui/core/styles';

interface Props {}
const useStyles = makeStyles({
  wrapperRowFlex: {
    display: 'flex',
    justifyContent: 'space-between',
    marginRight: 60,
    borderTop: '1px solid #ECEFF1',
    borderBottom: '1px solid #ECEFF1',
    padding: 4,
  },
});

const WrapperRowFlex = (props: Props) => {
  const { ...other } = props;
  const classes = useStyles(props);
  return <span className={classes.wrapperRowFlex} {...other} />;
};

export default WrapperRowFlex;
