import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { ArticlesStore } from '../../providers/articles';
import { serialize } from '../../utils/helpers/htmlSerializer.helpers';
import ArticlesAddEditForm from './ArticlesAddEditForm';
import { ArticlesFormFields } from './ArticlesFormSection';

interface ArticlesAddContentProps {}

const ArticlesAddContent: React.FC<
  ArticlesAddContentProps
> = (): JSX.Element => {
  const history = useHistory();

  const { create } = useContext(ArticlesStore);

  const getArticlesPublishDateTime = (date: Date, time: Date): Date => {
    const dateTime = new Date(date);
    dateTime.setHours(time.getHours());
    dateTime.setMinutes(time.getMinutes());
    return dateTime;
  };

  const handleCreate = async (data: ArticlesFormFields) => {
    const article = {
      title: data.articleTitle,
      image: '',
      published: getArticlesPublishDateTime(
        data.articlePublishDate,
        data.articlePublishTime
      ),
      extract: data.articleExtract,
      rawText: data.articleText,
      text: serialize({ children: data.articleText }),
      brightness: data.articleImageInfo?.brightness || 0.99,
      ratio: data.articleImageInfo?.aspectRatio || 0.99,
      tileSize: data.articleTileSize,
      target: data.articleTarget,
    };
    await create(article);
    history.goBack();
  };

  return (
    <ArticlesAddEditForm handleUpdate={handleCreate} title="Add Article" />
  );
};

export default ArticlesAddContent;
