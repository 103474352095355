import {
  Box,
  Button,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import React, { useCallback, useContext } from 'react';
import { DirectoryStore } from '../../providers/directory';
import Searchbar from '../common/Searchbar';

interface DirectorySearchProps {}

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 55,
    minHeight: theme.spacing(10),
    marginLeft: theme.spacing(5),
    borderRadius: 10,
    border: 0,
  },
  radioGroup: {
    marginTop: theme.spacing(2),
  },
}));

const DirectorySearch: React.FC<DirectorySearchProps> = (): JSX.Element => {
  const classes = useStyles();
  const {
    search,
    filter: {
      search: { keyword, match } = { keyword: '', match: 'standard' },
    } = { search: { keyword: '', match: 'standard' } },
    onKeywordChange,
    onMatchRadioChange,
  } = useContext(DirectoryStore);

  const onSearch = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      search({ search: { keyword, match } });
    },
    [search, keyword]
  );

  const onChange = useCallback(
    (value: string) => {
      onKeywordChange(value, match);
    },
    [onKeywordChange]
  );

  const onRadioChange = useCallback(
    (event: any) => {
      onMatchRadioChange(event.target.value, keyword);
    },
    [onMatchRadioChange]
  );

  const onClear = useCallback(() => {
    onKeywordChange('');
    onMatchRadioChange(match || 'standard', '');
    search({
      search: {
        keyword: '',
        match: 'standard',
      },
    });
  }, [search, onKeywordChange]);

  return (
    <form>
      <Grid container direction={'column'}>
        <Grid item xs={12} md={12}>
          <Box display="flex">
            <Searchbar
              keyword={keyword || ''}
              onChange={onChange}
              onClear={onClear}
            />
            <Button
              classes={{
                root: classes.root,
              }}
              variant="contained"
              color="primary"
              onClick={onSearch}
              type="submit"
            >
              <SearchIcon fontSize="medium" />
            </Button>
          </Box>
        </Grid>
        <Grid item xs={12} md={12} className={classes.radioGroup}>
          <RadioGroup
            row
            name="row-radio-buttons-group"
            onChange={onRadioChange}
            value={match}
          >
            <FormControlLabel
              checked={match === 'standard'}
              value={'standard'}
              control={<Radio color="primary" size="small" />}
              label="Standard"
            />
            <FormControlLabel
              checked={match === 'exact'}
              value={'exact'}
              control={<Radio color="primary" size="small" />}
              label="Exact match"
            />
          </RadioGroup>
        </Grid>
      </Grid>
    </form>
  );
};

export default DirectorySearch;
