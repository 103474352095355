import { createTheme } from '@material-ui/core/styles';

declare module '@material-ui/core/styles/createPalette' {
  interface Palette {
    statuses: {
      success: {
        background: string;
        text: string;
      };
      warning: {
        background: string;
        text: string;
      };
      error: {
        background: string;
        text: string;
      };
      info: {
        background: string;
        text: string;
      };
    };
    ui: {
      lightGrey: { main: '#ECECEC' };
    };
  }
  interface PaletteOptions {
    statuses?: {
      success: {
        background: string;
        text: string;
      };
      warning: {
        background: string;
        text: string;
      };
      error: {
        background: string;
        text: string;
      };
      info: {
        background: string;
        text: string;
      };
    };
    ui: {
      lightGrey: { main: '#ECECEC' };
    };
  }
}

const theme = createTheme({
  palette: {
    primary: {
      main: 'rgba(255, 60, 0, 1)',
    },
    secondary: {
      main: 'rgba(243, 244, 247, 1)',
    },
    background: {
      default: 'rgba(240, 241, 245, 1)',
    },
    text: {
      primary: 'rgba(33, 43, 53, 1)',
      secondary: 'rgba(33, 43, 53, 0.6)',
    },
    statuses: {
      success: {
        background: 'rgba(0, 200, 0, 0.08)',
        text: 'rgba(0, 169, 0, 1)',
      },
      warning: {
        background: 'rgba(255, 130, 0, 0.08)',
        text: 'rgba(240, 123, 1, 1)',
      },
      error: {
        background: 'rgba(255, 240, 235, 1)',
        text: 'rgba(255, 60, 0, 1)',
      },
      info: {
        background: 'rgba(235, 251, 255, 1)',
        text: 'rgba(1, 191, 243, 1)',
      },
    },
    ui: {
      lightGrey: { main: '#ECECEC' }
    },
  },
  typography: {
    fontFamily: 'Hero New Regular',
    h4: {
      fontFamily: 'Hero New SemiBold',
      fontSize: 34,
    },
    h5: {
      fontFamily: 'Hero New SemiBold',
      fontSize: 24,
    },
    h6: {
      fontFamily: 'Hero New SemiBold',
      fontSize: 20,
    },
    subtitle1: {
      fontFamily: 'Hero New SemiBold',
      fontSize: 16,
    },
    subtitle2: {
      fontFamily: 'Hero New SemiBold',
      fontSize: 14,
    },
    body1: {
      fontFamily: 'Hero New Regular',
      fontSize: 16,
    },
    body2: {
      fontFamily: 'Hero New Regular',
      fontSize: 14,
    },
    button: {
      fontFamily: 'Hero New SemiBold',
      fontSize: 14,
      textTransform: 'capitalize',
    },
    overline: {
      fontFamily: 'Hero New SemiBold',
      fontSize: 32,
      textTransform: 'initial',
      fontWeight: 600,
      lineHeight: 1.25,
    },
    caption: {
      fontFamily: 'Hero New Regular',
      fontSize: 12,
    },
  },
  spacing: 4,
  overrides: {
    MuiDialog: {
      paper: {
        borderRadius: 12,
        padding: 0,
      },
    },
    MuiMenuItem: {
      root: {
        paddingTop: 16,
        paddingBottom: 16,
        fontFamily: 'Hero New Regular',
        fontSize: 12,
      },
    },
    MuiLink: {
      root: {
        '&:hover': {
          color: 'rgba(255, 60, 0, 1)',
        },
        '&:active': {
          color: 'rgba(255, 60, 0, 1)',
        },
      },
    },
    MuiInput: {
      multiline: {
        fontSize: '16px',
        color: 'rgba(33, 43, 53, 0.6)',
      },
    },
    MuiInputBase: {
      root: { width: '100%' },
    },
    MuiSelect: {
      select: {
        padding: 8,
        textAlign: 'left',
        width: '100%',
        color: 'rgba(33, 43, 53, 0.6)',
      },
      icon: {
        color: 'rgba(255, 60, 0, 1)',
      },
    },
    MuiTableContainer: {
      root: { width: 'auto', padding: 16 },
    },
    MuiTableCell: {
      root: {
        paddingLeft: 0,
        paddingRight: 0,
        paddingBottom: 18,
      },
      head: { borderBottom: 'none', paddingTop: 4, paddingBottom: 4 },
    },
  },
});

export default theme;
