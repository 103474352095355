export type GlossarySort =
  | 'title-asc'
  | 'title-desc'
  | 'updated-asc'
  | 'updated-desc';

export const GLOSSARY_SORT_OPTIONS = [
  {
    name: 'Alphabetical (asc)',
    value: 'title-asc',
  },
  {
    name: 'Alphabetical (desc)',
    value: 'title-desc',
  },
  {
    name: 'Last update (asc)',
    value: 'updated-asc',
  },
  {
    name: 'Last update (desc)',
    value: 'updated-desc',
  },
];
