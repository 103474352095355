import { Box, Divider, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

interface ProfileRecordRowProps {
  title: string;
  value: string;
}
const useStyles = makeStyles((_theme) => ({
  text: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));
const ProfileRecordRow: React.FC<ProfileRecordRowProps> = ({
  title,
  value,
}: ProfileRecordRowProps): JSX.Element => {
  const classes = useStyles();
  return (
    <Box mb={4} mt={4}>
      <Box mb={3} height={24}>
        <Typography variant="subtitle1" align="left" className={classes.text}>
          {title}
        </Typography>
      </Box>
      <Box mb={4} height={24}>
        <Typography
          variant="body1"
          color="textSecondary"
          align="left"
          className={classes.text}
        >
          {value}
        </Typography>
      </Box>
      <Divider />
    </Box>
  );
};

export default ProfileRecordRow;
