import { useContext } from 'react';
import { AuthStore } from '../../providers/auth';
import { UserRole } from '../../utils/types/user';

export const useUserRoleRestrictions = (role: UserRole) => {
  const [{ user }] = useContext(AuthStore);

  const isUserRole = user.role === role;

  return {
    isUserRole,
  };
};
