import { Box, CircularProgress } from '@material-ui/core';
import { OrganisationEditContent } from '../../components/organisations';
import { useOrganisation } from '../../hooks/services';

const OrganisationEditPage = () => {
  const { organisation, isLoading } = useOrganisation();

  if (!organisation || isLoading) {
    return (
      <Box
        display={'flex'}
        justifyContent={'center'}
        alignItems={'center'}
        mt={10}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <OrganisationEditContent
      organisation={organisation}
      isLoading={isLoading}
    />
  );
};

export default OrganisationEditPage;
