import { createContext, useCallback, useState } from 'react';
import { updateCompanyLogo } from '../services/directory';
import data from '../services/logos.json';

interface SystemContext {
  updateCount: number;
  updateLogos: (data: []) => void;
  loadLogos: () => void;
}

export const SystemStore = createContext<SystemContext>({
  updateCount: 0,
  updateLogos: (_data: []) => {},
  loadLogos: () => {},
});

const { Provider } = SystemStore;

export const SystemProvider = ({ children }: any) => {
  const [updateCount, setUpdateCount] = useState(0);
  const updateLogos = useCallback(async (data: []) => {
    for (let i = 0; i < data.length; i++) {
      const { id, logo } = data[i] || {};
      await updateCompanyLogo(id, logo, false);
      setUpdateCount(i + 1);
    }
  }, []);
  const loadLogos = useCallback(() => {
    return data;
  }, []);
  return (
    <Provider
      value={{
        updateCount,
        updateLogos,
        loadLogos,
      }}
    >
      {children}
    </Provider>
  );
};
