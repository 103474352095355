import { UploadRecord } from '../utils/types/upload';

export interface UploadsState {
  data: UploadRecord[] | null;
  url?: string;
  fileName?: string;
  loading: boolean;
  uploadProgress: boolean;
  error: any;
}

export const initialUploadsState: UploadsState = {
  data: null,
  url: undefined,
  fileName: undefined,
  loading: false,
  uploadProgress: false,
  error: null,
};

export const UPLOADS_ACTIONS = {
  FETCH: 'UPLOADS/FETCH',
  FETCH_DATA_SUCCESS: 'UPLOADS/SUCCESS',
  FETCH_DATA_FAIL: 'UPLOADS/FAIL',
  UPLOAD_START: 'UPLOADS/UPLOAD_START',
  UPLOAD_SUCCESS: 'UPLOADS/UPLOAD_SUCCESS',
  RESET: 'UPLOADS/RESET',
};

export const UploadsReducer = (
  state: UploadsState,
  action: any
): UploadsState => {
  switch (action.type) {
    case UPLOADS_ACTIONS.FETCH:
      return { data: null, uploadProgress: false, loading: true, error: null };
    case UPLOADS_ACTIONS.FETCH_DATA_SUCCESS:
      return {
        data: action.data,
        uploadProgress: false,
        loading: false,
        error: null,
      };
    case UPLOADS_ACTIONS.FETCH_DATA_FAIL:
      return { data: null, uploadProgress: false, loading: false, error: null };
    case UPLOADS_ACTIONS.UPLOAD_START:
      return { ...state, uploadProgress: true };
    case UPLOADS_ACTIONS.UPLOAD_SUCCESS:
      return {
        ...state,
        url: action.url,
        fileName: action.fileName,
        uploadProgress: false,
        loading: false,
        error: null,
      };
    case UPLOADS_ACTIONS.RESET:
      return { ...state, url: undefined, fileName: undefined };
    default:
      throw new Error();
  }
};
