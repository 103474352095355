import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import isHotkey from 'is-hotkey';
import React, { useCallback } from 'react';
import { Descendant, Editor, Transforms } from 'slate';
import {
  Editable,
  ReactEditor,
  useFocused,
  useSelected,
  useSlateStatic,
} from 'slate-react';
import Leaf from './TextEditorLeaf';

interface TextEditorContentProps {
  setIsLinkSelected: (isLinkSelected: boolean) => void;
  value: Descendant[];
  toggleMark: (editor: Editor, format: string) => void;
  editor: Editor;
  placeholder: string;
}

const useStyles = makeStyles((theme) => ({
  text: {
    color: theme.palette.text.primary,
    fontSize: 16,
    textAlign: 'left',
    paddingBottom: theme.spacing(2),
  },
  icon: {
    width: 14,
    fill: theme.palette.text.primary,
  },
  link: {
    color: 'blue',
    fontSize: 16,
    paddingBottom: theme.spacing(2),
  },
  image: {
    display: 'block',
    maxWidth: '100%',
    maxHeight: '20em',
    zIndex: 0,
  },
  imgButton: {
    position: 'absolute',
    top: '0.5em',
    left: '0.5em',
    backgroundColor: 'white',
    minWidth: 30,
    minHeight: 30,
    maxHeight: 30,
    padding: 3,
    zIndex: 1,
  },
}));

const HOTKEYS = {
  'mod+b': 'bold',
  'mod+i': 'italic',
  'mod+u': 'underline',
  'mod+l': 'link',
  'mod+k': 'image',
  'mod+`': 'code',
};

const TextEditorContent: React.FC<TextEditorContentProps> = ({
  setIsLinkSelected,
  value,
  toggleMark,
  editor,
  placeholder,
}: TextEditorContentProps): JSX.Element => {
  const classes = useStyles();

  const renderElement = useCallback(
    (props: any) => {
      const editor = useSlateStatic();
      const path = ReactEditor.findPath(editor, props.element);
      const selected = useSelected();
      const focused = useFocused();

      if (props.element.type === 'link' && selected) {
        setIsLinkSelected(true);
      } else {
        setIsLinkSelected(false);
      }

      switch (props.element.type) {
        case 'link':
          return (
            <a
              className={classes.link}
              {...props.attributes}
              href={props.element.url}
            >
              {props.children}
            </a>
          );
        case 'image':
          return (
            <div {...props.attributes}>
              {props.children}
              <div contentEditable={false} style={{ position: 'relative' }}>
                <div>
                  <img
                    src={props.element.url}
                    style={{
                      boxShadow:
                        selected && focused ? '0 0 0 3px #B4D5FF' : 'none',
                    }}
                    className={classes.image}
                  />
                </div>
                <Button
                  onClick={() => Transforms.removeNodes(editor, { at: path })}
                  style={{
                    display: selected ? 'inline' : 'none',
                  }}
                  className={classes.imgButton}
                >
                  <DeleteIcon classes={{ root: classes.icon }} />
                </Button>
              </div>
            </div>
          );
        default:
          return (
            <div className={classes.text} {...props.attributes}>
              {props.children}
            </div>
          );
      }
    },
    // eslint-disable-next-line
    [value]
  );

  const renderLeaf = useCallback((props) => <Leaf {...props} />, []);

  return (
    <Editable
      style={{ minHeight: '200px' }}
      renderElement={renderElement}
      renderLeaf={renderLeaf}
      placeholder={placeholder}
      onKeyDown={(event) => {
        for (const hotkey in HOTKEYS) {
          if (isHotkey(hotkey, event as React.KeyboardEvent<HTMLDivElement>)) {
            event.preventDefault();
            const mark = HOTKEYS[hotkey as keyof typeof HOTKEYS];
            toggleMark(editor, mark);
          }
        }
      }}
    />
  );
};

export default TextEditorContent;
