import { Redirect, Route, Switch } from 'react-router-dom';
import DashboardWrap from '../components/common/DashboardWrap';
import { useUserRoleRestrictions } from '../hooks/services/useUserRoleRestrictions';
import ArticlesPage from '../pages/admin/ArticlesPage';
import DeleteProfilesPage from '../pages/admin/DeleteProfilesPage';
import DirectoryPage from '../pages/admin/DirectoryPage';
import GlossaryPage from '../pages/admin/GlossaryPage';
import KnowledgeCentrePage from '../pages/admin/KnowledgeCentrePage';
import ManageTiles from '../pages/admin/ManageTiles';
import SystemPage from '../pages/admin/SystemPage';
import TooltipsPage from '../pages/admin/TooltipsPage';
import UpdatesPage from '../pages/admin/UpdatesPage';
import UploadPage from '../pages/admin/UploadPage';
import UsersPage from '../pages/admin/UserAdminPage';
import { UserRole } from '../utils/types/user';
import {
  ARTICLES_ROUTE,
  DELETE_PROFILES_ROUTE,
  DIRECTORY_ROUTE,
  GLOSSARY_ROUTE,
  KNOWLEDGE_CENTRE_ROUTE,
  MANAGE_TILES,
  ORGANISATIONS_ADMIN_ROUTE,
  SYSTEM_ROUTE,
  TOOLTIPS_ROUTE,
  UPDATES_ROUTE,
  UPLOAD_DATA_ROUTE,
} from './Types';

function AdminRoutes() {
  const { isUserRole: isUserAdmin } = useUserRoleRestrictions(
    UserRole.SuperAdmin
  );

  return (
    <DashboardWrap>
      <Switch>
        <Route path={DIRECTORY_ROUTE}>
          <DirectoryPage />
        </Route>
        <Route path={UPLOAD_DATA_ROUTE}>
          <UploadPage />
        </Route>
        <Route path={DELETE_PROFILES_ROUTE}>
          <DeleteProfilesPage />
        </Route>
        <Route path={UPDATES_ROUTE}>
          <UpdatesPage />
        </Route>
        <Route path={ARTICLES_ROUTE}>
          <ArticlesPage />
        </Route>
        <Route path={KNOWLEDGE_CENTRE_ROUTE}>
          <KnowledgeCentrePage />
        </Route>
        <Route path={GLOSSARY_ROUTE}>
          <GlossaryPage />
        </Route>
        <Route path={TOOLTIPS_ROUTE}>
          <TooltipsPage />
        </Route>
        {isUserAdmin && (
          <Route path={ORGANISATIONS_ADMIN_ROUTE}>
            <UsersPage />
          </Route>
        )}
        <Route path={SYSTEM_ROUTE}>
          <SystemPage />
        </Route>
        <Route path={`${MANAGE_TILES}/:id`}>
          <ManageTiles />
        </Route>
        {/* <Route path={USAGE_ROUTE}>
          <Usage />
        </Route> */}
        <Route exact path="/">
          <Redirect to={DIRECTORY_ROUTE} />
        </Route>
      </Switch>
    </DashboardWrap>
  );
}

export default AdminRoutes;
