import { httpsCallable } from '@firebase/functions';
import { ListTilesUpdated } from '../utils/types/manage-tiles';
import { fbFunctions } from './firebase';
import { updateUploadById } from './uploads';

export const get = async function (id: string) {
  try {
    const reviewActivityFeed = httpsCallable(fbFunctions, 'reviewActivityFeed');
    const result: any = await reviewActivityFeed({
      document_id: id,
      limit: 10000,
      offset: 0,
    });
    if (
      typeof result.data === 'object' &&
      result.data !== null &&
      'items' in result.data
    ) {
      return result.data.items;
    } else {
      throw new Error('Invalid data');
    }
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

export const update = async function (
  list: Array<ListTilesUpdated>,
  scheduledDate?: number,
  id?: string
) {
  try {
    const manageActivityFeed = httpsCallable(fbFunctions, 'manageActivityFeed');
    const result = await manageActivityFeed({
      items: list,
      scheduledDate: scheduledDate,
    });
    if (id) {
      await updateUploadById(id, scheduledDate);
    }

    return result;
  } catch (error) {
    console.error('Error updating data:', error);
    throw error;
  }
};
