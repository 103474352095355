export enum UploadStatusType {
  new = 'new',
  validating = 'validating',
  validated = 'validated',
  validation_fail = 'validation failed',
  import_existing = 'no updates detected',
  importing = 'importing',
  import_fail = 'import failed',
  pending = 'pending review',
  publishing = 'publishing',
  publish_fail = 'publishing failed',
  published = 'published',
  rejecting = 'rejecting',
  rejected = 'rejected',
  reject_fail = 'reject failed',
  reverting = 'reverting',
  reverted = 'reverted',
  revert_fail = 'revert fail',
  partially_uploaded = 'partially uploaded',
  partially_imported = 'partially imported',
  partially_validated = 'partially validated',
}

export interface UploadRecord {
  id: string;
  created: number;
  fileName: string;
  status: UploadStatusType;
  url: string;
  user: string;
  rejectReport?: any;
  validationReport?: any;
  scheduledDate?: number;
}

export interface UploadFormFields {
  upload: any;
  uploadPublishDate: Date;
  uploadPublishTime: Date;
}
