import { Avatar, Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useContext } from 'react';
import { AuthStore } from '../../providers/auth';

interface UserAvatarProps {}

const useStyles = makeStyles((theme) => ({
  userName: {
    [theme.breakpoints.up('md')]: {
      color: theme.palette.text.primary,
      alignSelf: 'center',
    },
    color: theme.palette.common.white,
  },
  userRole: {
    [theme.breakpoints.up('md')]: {
      color: theme.palette.text.secondary,
    },
    alignSelf: 'center',
    color: theme.palette.common.white,
  },
  avatar: {
    margin: theme.spacing(1),
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.common.white,
    width: 48,
    height: 48,
    [theme.breakpoints.up('md')]: {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.primary.main,
      width: 62,
      height: 62,
    },
  },
}));

const UserAvatar: React.FC<UserAvatarProps> = (): JSX.Element => {
  const classes = useStyles();
  const [{ user }] = useContext(AuthStore);

  const userName =
    user.firstName && user.lastName ? `${user.firstName} ${user.lastName}` : '';
  const abbreviation =
    user.firstName && user.lastName
      ? user.firstName[0] + user.lastName[0]
      : 'AD';

  return (
    <Box display="flex" alignItems="center" mr={15}>
      <Avatar src={user.avatar} alt="Admin" className={classes.avatar}>
        {(!user.avatar && abbreviation) || 'A'}
      </Avatar>
      <Box>
        <Typography
          variant="subtitle1"
          className={classes.userName}
        ></Typography>
        <Typography variant="body2" className={classes.userRole}>
          {userName || user.user}
        </Typography>
      </Box>
    </Box>
  );
};

export default UserAvatar;
