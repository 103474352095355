import {
  Box,
  CircularProgress,
  TextField,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useContext, useEffect, useRef } from 'react';
import { Control, Controller } from 'react-hook-form';
import { Descendant } from 'slate';
import { ArticlesStore } from '../../providers/articles';
import {
  ArticleTarget,
  ArticleTileSize,
} from '../../utils/constants/articles.constants';
import FileUploader from '../common/FileUploader';
import StyledInput from '../common/StyledInput';
import TextEditor from '../common/TextEditor';

export interface ArticlesFormFields {
  id?: string;
  articleTitle: string;
  articleExtract: string;
  articleText: Descendant[];
  articlePublishDate: Date;
  articlePublishTime: Date;
  articleImageInfo?: {
    storageUrl: string;
    brightness: number;
    aspectRatio: number;
  };
  articleTileSize: ArticleTileSize;
  articleTarget: ArticleTarget;
}
interface ArticlesFormSectionProps {
  control: Control<ArticlesFormFields>;
  errors: any;
  handleUpload: any;
  supportedFileFormats: string[];
  tileSize: ArticleTileSize;
}

const useStyles = makeStyles((theme) => ({
  input: {
    'label + &': {
      marginTop: 0,
      color: theme.palette.text.secondary,
    },
  },
  textField: {
    width: '100%',
  },
  errorMessage: {
    height: theme.spacing(6),
  },
}));

const ArticlesFormSection: React.FC<ArticlesFormSectionProps> = ({
  control,
  errors,
  handleUpload,
  supportedFileFormats,
  tileSize,
}: ArticlesFormSectionProps): JSX.Element => {
  const classes = useStyles();
  const textErrorRef = useRef<HTMLInputElement>(null);
  const {
    state: { contentImageUrl, uploading },
    uploadContentImage,
  } = useContext(ArticlesStore);

  useEffect(() => {
    if (errors.articleText && !errors.articleTitle) {
      textErrorRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [errors.articleText]);

  return (
    <>
      <Box>
        <StyledInput
          name="articleTitle"
          label="Article Title"
          control={control}
          error={!!errors.articleTitle}
          classnames={{ formControl: classes.input }}
        />
        {errors.articleTitle && (
          <Typography
            variant="body1"
            color="error"
            className={classes.errorMessage}
            align="left"
          >
            {errors.articleTitle.message}
          </Typography>
        )}
      </Box>
      <Box mt={10}>
        <FileUploader
          title={'Article image'}
          filepathPlaceholder={
            control.defaultValuesRef.current?.articleImageInfo?.storageUrl
          }
          onUpload={handleUpload}
          disabled={uploading || tileSize === ArticleTileSize.Small}
          supportedFileFormats={supportedFileFormats}
        />
        {errors.articleImageInfo && (
          <Typography
            variant="body1"
            color="error"
            className={classes.errorMessage}
            align="left"
          >
            {errors.articleImageInfo.message}
          </Typography>
        )}
        <Box mt={5}>{uploading && <CircularProgress />}</Box>
      </Box>
      <Box mt={{ xs: 4, sm: 4, md: 4, lg: 17 }}>
        <Typography variant="subtitle1" align="left">
          Article extract (max 245 characters)
        </Typography>
        <Controller
          control={control}
          name="articleExtract"
          render={({ field: { onChange, value } }) => (
            <TextField
              multiline={true}
              rows={5}
              classes={{ root: classes.textField }}
              value={value}
              onChange={onChange}
            ></TextField>
          )}
        />
        {errors.articleExtract && (
          <Typography
            variant="body1"
            color="error"
            className={classes.errorMessage}
            align="left"
          >
            {errors.articleExtract.message}
          </Typography>
        )}
        <Box mt={10} mb={10}>
          <Controller
            control={control}
            name="articleText"
            render={({ field: { onChange, value } }) => {
              return control?.defaultValuesRef?.current?.articleTitle ||
                !control?.defaultValuesRef?.current?.id?.length ? (
                <TextEditor
                  title="Article body"
                  value={value}
                  onChange={onChange}
                  placeholder="Add the main article text..."
                  contentImageUrl={contentImageUrl}
                  uploadContentImage={uploadContentImage}
                />
              ) : (
                <></>
              );
            }}
          />
          {errors.articleText && (
            <Typography
              variant="body1"
              color="error"
              className={classes.errorMessage}
              align="left"
              ref={textErrorRef}
            >
              {errors.articleText.message}
            </Typography>
          )}
        </Box>
      </Box>
    </>
  );
};

export default ArticlesFormSection;
