import {
  Box,
  Button,
  CircularProgress,
  Grid,
  SvgIcon,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useCallback, useContext, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { ReactComponent as AddIcon } from '../../assets/icons/add-icon.svg';
import { useUserRoleRestrictions } from '../../hooks/services/useUserRoleRestrictions';
import { UsersStore } from '../../providers/users';
import { USERS_ADD_ROUTE } from '../../routes/Types';
import { LAYOUT } from '../../utils/constants/layout.constants';
import { UserListType, UserRole } from '../../utils/types/user';
import UsersExportWrapper from './UsersExportWrapper';
import UsersFilters from './UsersFilters';
import UsersList from './UsersList';

const useStyles = makeStyles((theme) => {
  const LettersBlockBottomMargin = 32;
  const LettersBlockHeight = 25 + LettersBlockBottomMargin;
  const ListTopMargin = 32;
  const SubtitleBlockHeight = 145;
  return {
    content: {
      [theme.breakpoints.up('md')]: {
        marginTop: LAYOUT.TopPanelHeight + LAYOUT.TopPanelBottomMargin,
        width: `calc(100% - ${LAYOUT.DrawerWidth}px)`,
        marginLeft: LAYOUT.DrawerWidth,
      },
    },
    itemOrder: {
      order: 1,
      height: '480px',
      [theme.breakpoints.up('md')]: {
        order: 'initial',
        height: 'auto',
      },
    },
    button: {
      borderRadius: 10,
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      marginRight: theme.spacing(1),
    },
    fullsize: {
      height: '100%',
      [theme.breakpoints.up('md')]: {
        height: `calc(100vh - ${LAYOUT.TopPanelHeight}px - ${LAYOUT.TopPanelBottomMargin}px - ${LettersBlockHeight}px - ${ListTopMargin}px - ${SubtitleBlockHeight}px)`,
      },
    },
  };
});

const UsersView = () => {
  const classes = useStyles();
  let history = useHistory();
  const { isUserRole: isUserAdmin } = useUserRoleRestrictions(
    UserRole.SuperAdmin
  );
  const [searchQuery, setSearchQuery] = useState('');
  const [inputValue, setInputValue] = useState('');
  const { orgId } = useParams<{
    orgId: string;
  }>();

  const {
    state: { data, loading, updating },
  } = useContext(UsersStore);

  const handleAddUserClick = useCallback(() => {
    history.push(USERS_ADD_ROUTE.replace(':orgId', orgId));
  }, [history, orgId]);

  const handleSearch = () => {
    setSearchQuery(inputValue);
  };

  const handleClearSearch = () => {
    setInputValue('');
    setSearchQuery('');
  };

  const filteredUsers = data
    ? data
        .map((user) => ({
          ...user,
          name: `${user.firstName} ${user.lastName}`,
          license_expires_at: user.license_expires_at
            ? new Date(user.license_expires_at).toLocaleDateString()
            : undefined, // Convert null to undefined
        }))
        .filter((user) => {
          return user.name.toLowerCase().includes(searchQuery.toLowerCase());
        })
    : [];

  return (
    <Box>
      <Grid container direction={'row'} className={classes.fullsize}>
        <Grid item xs={12} sm={12} md={6} lg={6} className={classes.itemOrder}>
          {filteredUsers && (
            <UsersList
              data={filteredUsers}
              listType={UserListType.Users}
            ></UsersList>
          )}

          {loading || (updating && <CircularProgress />)}
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Box
            display="flex"
            width={'100%'}
            flex={1}
            alignContent="left"
            mt={2}
            mb={6}
            ml={{ xs: 4, sm: 4, md: 4, lg: 22.5 }}
          >
            <Button
              variant="contained"
              color="secondary"
              disableElevation
              classes={{ root: classes.button }}
              component="label"
              onClick={handleAddUserClick}
              disabled={!isUserAdmin}
            >
              <SvgIcon component={AddIcon}></SvgIcon>
              Add user
            </Button>
            <Box ml={2}>
              <UsersExportWrapper />
            </Box>
          </Box>
          <form>
            <UsersFilters
              onChange={setInputValue}
              onSearch={handleSearch}
              onClear={handleClearSearch}
              keyword={inputValue}
            />
          </form>
        </Grid>
      </Grid>
    </Box>
  );
};

export default UsersView;
