import { createContext, useCallback, useReducer, useState } from 'react';
import {
  ARTICLES_ACTIONS,
  ArticlesReducer,
  ArticlesState,
  initialArticlesState,
} from '../reducers/articles';
import {
  createFirebase,
  deleteFirebase,
  getBatchFirebase,
  updateFirebase,
  uploadImageToFirebase,
} from '../services/articles';

import { Article } from '../utils/types/article';
interface ArticlesContext {
  state: ArticlesState;
  create: (article: Article) => void;
  update: (article: Article) => void;
  remove: (article: Article) => void;
  upload: (file: File) => void;
  uploadContentImage: (_file: File) => void;
  getBatch: (lastDoc: Article | null) => void;
}

export const ArticlesStore = createContext<ArticlesContext>({
  state: initialArticlesState,
  create: (_article: Article) => {},
  update: (_article: Article) => {},
  remove: (_article: Article) => {},
  upload: (_file: File) => {},
  uploadContentImage: (_file: File) => {},
  getBatch: async () => {},
});

const { Provider } = ArticlesStore;

export const ArticlesProvider = ({ children }: any) => {
  const [imageUrl, setImageUrl] = useState<string | undefined>();
  const [state, dispatch] = useReducer(ArticlesReducer, initialArticlesState);

  const create = useCallback(
    async (article: Article) => {
      dispatch({ type: ARTICLES_ACTIONS.UPDATE_START });
      const _createdArticle = await createFirebase({
        ...article,
        image: imageUrl || '',
      });
      setImageUrl(undefined);
      dispatch({
        type: ARTICLES_ACTIONS.UPDATE_SUCCESS,
        data: [...(state.data || []), _createdArticle],
      });
    },
    [imageUrl, state]
  );

  const update = useCallback(
    async (article: Article) => {
      dispatch({ type: ARTICLES_ACTIONS.UPDATE_START });
      const result = await updateFirebase({
        ...article,
        image: imageUrl || article.image,
      });
      setImageUrl(undefined);
      let editedArticleIndex = state.data?.findIndex((a: Article) => {
        return a.id === article.id;
      });
      let newData = state.data ? [...state.data] : undefined;
      if (
        newData &&
        editedArticleIndex !== undefined &&
        editedArticleIndex > -1
      ) {
        newData[editedArticleIndex] = result;
      }
      dispatch({ type: ARTICLES_ACTIONS.UPDATE_SUCCESS, data: newData });
    },
    [imageUrl, state]
  );

  const remove = useCallback(
    async (article: Article) => {
      dispatch({ type: ARTICLES_ACTIONS.REMOVE_START });
      await deleteFirebase(article);
      let index = state.data?.findIndex((a: Article) => {
        return a.id === article.id;
      });
      if (index !== undefined && index > -1) {
        let data = state.data;
        data?.splice(index, 1);
        dispatch({ type: ARTICLES_ACTIONS.REMOVE_SUCCESS, data });
      }
    },
    [state]
  );

  const getBatch = useCallback(async (lastDoc: Article | null) => {
    dispatch({ type: ARTICLES_ACTIONS.FETCH });
    const articles = await getBatchFirebase(lastDoc);

    dispatch({
      type: ARTICLES_ACTIONS.FETCH_DATA_SUCCESS,
      loading: false,
      data: articles,
    });
  }, []);

  const upload = useCallback(async (file: File) => {
    dispatch({ type: ARTICLES_ACTIONS.IMAGE_UPLOAD_START });
    const _imageUrl = await uploadImageToFirebase(file);
    setImageUrl(_imageUrl);
    dispatch({ type: ARTICLES_ACTIONS.IMAGE_UPLOAD_SUCCESS });
  }, []);

  const uploadContentImage = useCallback(async (file: File) => {
    dispatch({ type: ARTICLES_ACTIONS.IMAGE_UPLOAD_START });
    const _imageUrl = await uploadImageToFirebase(file);
    dispatch({
      type: ARTICLES_ACTIONS.IMAGE_UPLOAD_SUCCESS,
      data: { contentImageUrl: _imageUrl },
    });
  }, []);

  return (
    <Provider
      value={{
        state,
        create,
        update,
        remove,
        upload,
        uploadContentImage,
        getBatch,
      }}
    >
      {children}
    </Provider>
  );
};
