import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList } from 'react-window';
import { LAYOUT } from '../../utils/constants/layout.constants';
import { OrganisationListItemContent } from '.';
import {
  OrganisationListItem,
  OrganisationListItems,
} from '../../utils/types/organisation';
import VirtualizedListItem from '../common/VirtualizedListItem';

interface ListRowProps {
  style: any;
  index: number;
  data: OrganisationListItem[];
}

const Row = ({ style, index, data }: ListRowProps) => {
  const { id, name, created_at } = data[index] || {};

  return (
    <VirtualizedListItem
      hoverEffect
      noPadding
      style={{
        ...style,
        width: 'calc(100% - 40px)',
        marginLeft: 20,
        marginTop: 10,
      }}
    >
      <OrganisationListItemContent
        id={id}
        name={name}
        created_at={created_at}
      />
    </VirtualizedListItem>
  );
};

const OrganisationsList = ({ organisations }: OrganisationListItems) => {
  return (
    <AutoSizer>
      {({ height, width }) => (
        // <div style={{ width, height, background: 'red' }}>Check Visibility</div>
        <FixedSizeList
          height={height}
          itemCount={organisations.length}
          itemSize={LAYOUT.VirtuilizedListItemHeight}
          width={width}
          itemData={organisations}
        >
          {({ style, index }) => (
            <Row style={style} index={index} data={organisations} />
          )}
        </FixedSizeList>
      )}
    </AutoSizer>
  );
};

export default OrganisationsList;
