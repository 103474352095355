import Paper, { PaperProps } from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';

type StyledPaperProps = PaperProps;

const useStyles = makeStyles({
  root: {
    borderRadius: 12,
    boxShadow: '0px 4px 16px 1px rgba(76, 83, 94, 0.2)',
  },
});

const StyledPaper = (props: StyledPaperProps) => {
  const { children, ...other } = props;
  const classes = useStyles();

  return (
    <Paper
      classes={{
        root: classes.root,
      }}
      {...other}
    >
      {children}
    </Paper>
  );
};

export default StyledPaper;
