import { Box, Button, Divider, SvgIcon, Typography } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useHistory } from 'react-router-dom';
import { ReactComponent as AddIcon } from '../../assets/icons/add-icon.svg';
import { ORGANISATIONS_ADMIN_ROUTE } from '../../routes/Types';
import { LAYOUT } from '../../utils/constants/layout.constants';
import Title from '../common/Title';
import TopPannel from '../common/TopPannel';

const useStyles = makeStyles((theme) => ({
  content: {
    marginTop: 64,
    background: theme.palette.background.paper,
    [theme.breakpoints.up('md')]: {
      marginTop: LAYOUT.TopPanelHeight + LAYOUT.TopPanelBottomMargin,
      width: `calc(100% - ${LAYOUT.DrawerWidth}px)`,
      marginLeft: LAYOUT.DrawerWidth,
    },
  },
  title: {
    marginTop: theme.spacing(7),
    marginBottom: theme.spacing(5),
  },
  button: {
    borderRadius: 10,
    marginTop: theme.spacing(6),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  fontBold: {
    fontWeight: 'bold',
  },
}));

const OrganisationAddSuccess = ({
  setShowSuccess,
}: {
  setShowSuccess: () => void;
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const matchesMediaQuery = useMediaQuery(theme.breakpoints.up('md'), {
    noSsr: true,
  });
  const history = useHistory();

  const handleRedirectLinkClick = () => {
    history.push(`${ORGANISATIONS_ADMIN_ROUTE}`);
  };

  const handleAddUserClick = () => {
    setShowSuccess();
  };

  return (
    <Box className={classes.content}>
      {matchesMediaQuery ? (
        <TopPannel>
          <Title
            onRedirectLinkClick={handleRedirectLinkClick}
            pageName={'Back to all organisations'}
          />
        </TopPannel>
      ) : (
        <Box textAlign="left" mr={4} ml={4}>
          <Title
            onRedirectLinkClick={handleRedirectLinkClick}
            pageName={'Back to all organisations'}
          />
        </Box>
      )}

      <Box
        ml={{ xs: 4, sm: 4, md: 4, lg: 10 }}
        mr={{ xs: 4, sm: 4, md: 4, lg: 20 }}
        mb={10}
      >
        <Typography
          variant="h4"
          color="textPrimary"
          align="left"
          classes={{ root: classes.title }}
        >
          Thank you
        </Typography>
        <Divider />
        <Box
          display={'flex'}
          flexDirection={'column'}
          alignItems={'flex-start'}
        >
          <Box mt={6}>
            <Typography variant="body1" color="textPrimary" className={classes.fontBold}>
              The organisation has been added!
            </Typography>
          </Box>
          <Button
            variant="contained"
            color="secondary"
            disableElevation
            onClick={handleAddUserClick}
            classes={{ root: classes.button }}
            component="label"
          >
            <SvgIcon component={AddIcon}></SvgIcon>
            Add another new organisation
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default OrganisationAddSuccess;
