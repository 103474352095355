import { Box } from '@material-ui/core';
import { Route, useLocation, useParams } from 'react-router-dom';
import { OrganisationViewContent } from '../../components/organisations';
import { InvitesProvider } from '../../providers/invites';
import { UsersProvider } from '../../providers/users';
import {
  INVITES_EDIT_ROUTE,
  ORGANISATIONS_VIEW_ROUTE,
  USERS_ADD_ROUTE,
  USER_EDIT_ROUTE,
} from '../../routes/Types';
import InviteEditPage from './InviteEditPage';
import UserAddPage from './UserAddPage';
import UserEditPage from './UserEditPage';

const OrganisationViewPage = () => {
  const currentLocation = useLocation();
  const { orgId } = useParams<{ orgId: string }>();

  return (
    <InvitesProvider>
      <UsersProvider>
        <Box
          style={
            currentLocation.pathname !== ORGANISATIONS_VIEW_ROUTE.replace(':orgId', orgId)
              ? { display: 'none' }
              : {}
          }
        >
          <OrganisationViewContent />
        </Box>
        <Route path={`${USER_EDIT_ROUTE}/:id`}>
          <UserEditPage />
        </Route>
        <Route path={`${USERS_ADD_ROUTE}`}>
          <UserAddPage />
        </Route>
        <Route path={`${INVITES_EDIT_ROUTE}/:id`}>
          <InviteEditPage />
        </Route>
      </UsersProvider>
    </InvitesProvider>
  );
};

export default OrganisationViewPage;
