import {
  QueryDocumentSnapshot,
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  limit,
  orderBy,
  query,
  startAfter,
  updateDoc,
  where,
} from 'firebase/firestore';
import { clearUndefined } from '../utils/helpers/firebaseSanitize.helpers';
import { User } from '../utils/types/user';
import { db } from './firebase';

const transformInvite = (doc: QueryDocumentSnapshot) => {
  const data = doc.data();
  return {
    id: doc.id,
    firstName: data.firstName,
    email: data.email,
    lastName: data.lastName,
    company: data.company,
    officeAddress: data.officeAddress,
    expiry: new Date(data.expiry.seconds * 1000),
    type: 'invite',
    license: data.license,
    organization_id: data.organization_id,
    organization_role: data.organization_role,
  };
};

export const getAllInvitesFirebase = async (
  organization_id: number,
  limitNumber?: number
): Promise<Array<User>> => {
  let invitesQuery = query(
    collection(db, 'invites'),
    where('isRegistered', '==', false),
    where('organization_id', '==', organization_id)
  );

  if (limitNumber) {
    invitesQuery = query(invitesQuery, limit(limitNumber));
  }

  const invites = await getDocs(invitesQuery);

  return invites.docs.map((doc) => transformInvite(doc));
};

const getInviteByID = async (id: string): Promise<User> => {
  const inviteDocRef = doc(db, 'invites', id);
  const invite = await getDoc(inviteDocRef);

  return {
    id: invite.id,
    firstName: invite.data()?.firstName,
    email: invite.data()?.email,
    lastName: invite.data()?.lastName,
    company: invite.data()?.company,
    officeAddress: invite.data()?.officeAddress,
    expiry: new Date(invite.data()?.expiry.seconds * 1000),
    license: invite.data()?.license,
    type: 'invite',
    organization_id: invite.data()?.organization_id,
    organization_role: invite.data()?.organization_role,
  };
};

export const getInvitesOnScrollFirebase = async (
  lastUser: User,
  organization_id?: number
): Promise<Array<User>> => {
  let invites;
  const invitesCollection = collection(db, 'invites');
  let invitesQuery = query(
    invitesCollection,
    where('organization_id', '==', organization_id),
    where('isRegistered', '==', false),
    orderBy('firstName')
  );

  if (lastUser) {
    const lastDoc = doc(db, 'invites', lastUser.id);
    const lastDocSnapshot = await getDoc(lastDoc);
    invitesQuery = query(invitesQuery, startAfter(lastDocSnapshot), limit(20));
  } else {
    invitesQuery = query(invitesQuery, limit(20));
  }

  invites = await getDocs(invitesQuery);

  return invites.docs.map((d) => transformInvite(d));
};

export const getAllRegisteredInvitesFirebase = async (
  organization_id: number
): Promise<Array<User>> => {
  const invitesCollection = collection(db, 'invites');
  const invitesQuery = query(
    invitesCollection,
    where('organization_id', '==', organization_id),
    where('isRegistered', '==', true),
    limit(500)
  );

  const invites = await getDocs(invitesQuery);

  return invites.docs.map((d) => transformInvite(d));
};

export const addUserInviteFirebase = async (
  user: User,
  organizationId?: number
): Promise<any> => {
  if (user.organization_id !== organizationId) {
    throw new Error('Organization ID mismatch. Cannot create invite.');
  }

  const invitesCollection = collection(db, 'invites');
  const userDoc = await addDoc(invitesCollection, user);

  return getInviteByID(userDoc.id); // Ensure this function also checks organization_id
};

export const updateInviteFirebase = async (
  user: User,
  organizationId?: number
): Promise<void> => {
  const inviteDocRef = doc(db, 'invites', user.id);
  const inviteDoc = await getDoc(inviteDocRef);

  if (
    inviteDoc.exists() &&
    inviteDoc.data().organization_id === organizationId
  ) {
    await updateDoc(inviteDocRef, clearUndefined(user));
  } else {
    throw new Error('Organization ID mismatch or invite not found.');
  }
};

export const deleteInviteFirebase = async (
  id: string,
  organizationId?: number
): Promise<void> => {
  const inviteDocRef = doc(db, 'invites', id);
  const inviteDoc = await getDoc(inviteDocRef);

  if (
    inviteDoc.exists() &&
    inviteDoc.data().organization_id === organizationId
  ) {
    await deleteDoc(inviteDocRef);
  } else {
    throw new Error('Organization ID mismatch or invite not found.');
  }
};

export const getAllInvitesForAllOrganisations = async (
  limitNumber?: number
): Promise<Array<User>> => {
  let invitesQuery = query(
    collection(db, 'invites'),
    where('isRegistered', '==', false)
  );

  if (limitNumber) {
    invitesQuery = query(invitesQuery, limit(limitNumber));
  }

  const invites = await getDocs(invitesQuery);

  return invites.docs.map((doc) => transformInvite(doc));
};
