const FIREBASE_CONFIGS: {
  [k: string]: any;
} = {
  local: {
    apiKey: 'AIzaSyAmpkC2Oq_5T1meInK40KJ7rGFaTzcedK8',
    authDomain: 'fundpath-dev.firebaseapp.com',
    projectId: 'fundpath-dev',
    storageBucket: 'fundpath-dev.appspot.com',
    messagingSenderId: '1002401379404',
    appId: '1:1002401379404:web:1b98de3d4e09b1389370e9',
  },
  dev: {
    apiKey: 'AIzaSyAmpkC2Oq_5T1meInK40KJ7rGFaTzcedK8',
    authDomain: 'fundpath-dev.firebaseapp.com',
    projectId: 'fundpath-dev',
    storageBucket: 'fundpath-dev.appspot.com',
    messagingSenderId: '1002401379404',
    appId: '1:1002401379404:web:1b98de3d4e09b1389370e9',
  },
  staging: {
    apiKey: 'AIzaSyAkTVzuPoky-xweG_xh-XO1B9cYMvjNeow',
    authDomain: 'fundpath-staging.firebaseapp.com',
    projectId: 'fundpath-staging',
    storageBucket: 'fundpath-staging.appspot.com',
    messagingSenderId: '869332953288',
    appId: '1:869332953288:web:10be9ccf65251f2aba1dfd',
  },
  prod: {
    apiKey: 'AIzaSyCN5T5sNPjuOfHjRErvsYf31kg-23c5SeE',
    authDomain: 'fundpath-2ac4e.firebaseapp.com',
    projectId: 'fundpath-2ac4e',
    storageBucket: 'fundpath-2ac4e.appspot.com',
    messagingSenderId: '809007890780',
    appId: '1:809007890780:web:12ce3f8f260e397f4ac199',
    measurementId: 'G-NNNCK4J46G',
  },
  uat: {},
  test: {},
};

export const REGIONS: any = {
  local: 'europe-west2',
  dev: 'europe-west2',
  staging: 'europe-west2',
  prod: 'europe-west2',
};

export const getCurrentRegion = (): string => {
  return REGIONS[process.env.REACT_APP_ENV || 'dev'];
};

export const FIREBASE = FIREBASE_CONFIGS[process.env.REACT_APP_ENV || 'dev'];
