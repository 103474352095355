import {
  Box,
  FormControlLabel,
  List,
  ListItem,
  Radio,
  RadioGroup,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useCallback } from 'react';
import { NO, YES } from '../../utils/constants/manageTiles';
import { ActivityTile } from '../../utils/types/manage-tiles';
import { FlexSpan } from './styled';

export interface ManageTilesProps {
  listOptions: ActivityTile[];
  updateOriginal: Function;
}

const useStyles = makeStyles((theme) => ({
  listItem: {
    justifyContent: 'space-between',
    paddingTop: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingBottom: theme.spacing(0),
    paddingLeft: theme.spacing(4),
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    boxShadow: '0px 4px 16px 1px rgba(76, 83, 94, 0.2)',
    borderRadius: 12,
  },
  title: {},
  type: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  date: {
    display: 'inline-block',
    width: '100%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  link: {
    textDecoration: 'none',
    '&:visited': {
      color: theme.palette.text.primary,
    },
    '&:hover': {
      color: theme.palette.primary.main,
    },
    '&:active': {
      color: theme.palette.primary.main,
    },
  },
  tag: {
    position: 'absolute',
    bottom: 10,
    right: 16,
  },
}));

const ManageTilesList: React.FC<ManageTilesProps> = ({
  listOptions,
  updateOriginal,
}: ManageTilesProps) => {
  const classes = useStyles();

  /**
   * Description: Function which update the listOptions array with the new values.
   * @param id id in the array.
   * @param event event emitted by the radio button
   */
  const handleChangeRadio = useCallback(
    (id: number, event: React.ChangeEvent<HTMLInputElement>) => {
      const valueSelected = (event.target as HTMLInputElement).value;
      const _listOptionsUpdated = listOptions.map((obj) => {
        if (obj.id === id) {
          return { ...obj, visible: valueSelected === YES ? true : false };
        }
        return obj;
      });
      updateOriginal(id, valueSelected);
    },
    [listOptions, updateOriginal]
  );

  return (
    <>
      <List>
        {listOptions?.map((item: ActivityTile) => (
          <ListItem
            key={`activitytile-${item.id}`}
            classes={{ root: classes.listItem }}
          >
            <Box display={'flex'} flexDirection={'column'}>
              <Box display={'flex'}>
                <Typography variant="body1" className={classes.title}>
                  {item.meta.description}
                </Typography>
              </Box>
              <Box mt={4} display={'flex'} flexDirection={'row'}>
                <FlexSpan>Visible</FlexSpan>
                <RadioGroup
                  row
                  name="row-radio-buttons-group"
                  onChange={(e) => handleChangeRadio(item.id, e)}
                  value={item.visible ? YES : NO}
                >
                  <FormControlLabel
                    checked={item.visible}
                    value={YES}
                    control={<Radio color="primary" size="small" />}
                    label="Yes"
                  />
                  <FormControlLabel
                    checked={!item.visible}
                    value={NO}
                    control={<Radio color="primary" size="small" />}
                    label="No"
                  />
                </RadioGroup>
              </Box>
            </Box>
          </ListItem>
        ))}
      </List>
    </>
  );
};

export default ManageTilesList;
