import React from 'react';
import { ExportProvider } from '../../providers/export';
import UsersExportButton from './UsersExportButton';

const UsersExportWrapper: React.FC<any> = (): JSX.Element => {
  return (
    <ExportProvider>
      <UsersExportButton />
    </ExportProvider>
  );
};

export default UsersExportWrapper;
