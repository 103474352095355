import { Box } from '@material-ui/core';
import React from 'react';
import { Route, useLocation } from 'react-router-dom';
import { OrganisationContent } from '../../components/organisations';
import {
  ORGANISATIONS_ADD_ROUTE,
  ORGANISATIONS_ADMIN_ROUTE,
  ORGANISATIONS_EDIT_ROUTE,
  ORGANISATIONS_VIEW_ROUTE,
} from '../../routes/Types';
import OrganisationAddPage from './OrganisationAddPage';
import OrganisationEditPage from './OrganisationEditPage';
import OrganisationViewPage from './OrganisationViewPage';

interface UsersPageProps {}

const UsersPage: React.FC<UsersPageProps> = (): JSX.Element => {
  const currentLocation = useLocation();

  return (
    <Box>
      <Box
        style={
          currentLocation.pathname !== ORGANISATIONS_ADMIN_ROUTE
            ? { display: 'none' }
            : {}
        }
      >
        <OrganisationContent />
      </Box>
      <Route path={`${ORGANISATIONS_ADD_ROUTE}`}>
        <OrganisationAddPage />
      </Route>
      <Route path={`${ORGANISATIONS_VIEW_ROUTE}`}>
        <OrganisationViewPage />
      </Route>
      <Route path={`${ORGANISATIONS_EDIT_ROUTE}`}>
        <OrganisationEditPage />
      </Route>
    </Box>
  );
};

export default UsersPage;
