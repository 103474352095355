import { LocalizationProvider } from '@material-ui/pickers';
import DateFnsUtils from '@material-ui/pickers/adapter/date-fns';
import { BrowserRouter as Router } from 'react-router-dom';
import './App.css';
import AppContent from './AppContent';
import { AuthProvider } from './providers/auth';
import { QueryProvider } from './providers/queryProvider';

function App() {
  return (
    <div className="App">
      <Router>
        <QueryProvider>
          <LocalizationProvider dateAdapter={DateFnsUtils}>
            <AuthProvider>
              <AppContent />
            </AuthProvider>
          </LocalizationProvider>
        </QueryProvider>
      </Router>
    </div>
  );
}

export default App;
