import React from 'react';
import TooltipsEditContent from '../../components/tooltips/TooltipsEditContent';

interface TooltipsEditPageProps {}

const TooltipsEditPage: React.FC<TooltipsEditPageProps> = (): JSX.Element => {
  return <TooltipsEditContent />;
};

export default TooltipsEditPage;
