import {
  UploadTaskSnapshot,
  getDownloadURL,
  ref,
  uploadBytesResumable,
} from 'firebase/storage';

import { storage } from './firebase';

export const uploadImageToFirebase = async (
  file: File,
  collection: string
): Promise<string> => {
  const storageRef = ref(
    storage,
    `${collection}/${Date.now()}_${file.name.replace(/\s+/g, '_')}`
  );
  const uploadTask = uploadBytesResumable(storageRef, file);

  return new Promise<string>((resolve, reject) => {
    uploadTask.on(
      'state_changed',
      (_snapshot: UploadTaskSnapshot) => {
        // TODO: at some point show progress
        // let progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes)) * 100
      },
      (error) => {
        reject(error);
      },
      async () => {
        try {
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
          resolve(downloadURL);
        } catch (error) {
          reject(error);
        }
      }
    );
  });
};
