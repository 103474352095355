import { Box, ListItem, ListItemProps } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import StyledPaper from './StyledPaper';

type UsersListProps = ListItemProps & {
  hoverEffect?: boolean;
  noPadding?: boolean;
};

const useStyles = makeStyles((_theme) => ({
  paper: {
    display: 'flex',
    alignItems: 'center',
  },
  container: {
    height: 64,
  },
  hoverStyle: {
    '&:hover': {
      border: `1px solid ${_theme.palette.primary.main}`,
      borderRadius: 12,
      cursor: 'pointer',
    }
  },
  noPadding: {
    padding: 0
  }
}));

const VirtualizedListItem: React.FC<UsersListProps> = ({
  style,
  children,
  hoverEffect = false,
  noPadding = false,
}: UsersListProps): JSX.Element => {
  const classes = useStyles();

  return (
    <Box style={style}>
      <StyledPaper className={`${classes.paper} ${hoverEffect ? classes.hoverStyle : ''}`}>
        <ListItem classes={{ container: classes.container }} className={`${noPadding ? classes.noPadding : ''}`}>
          {children}
        </ListItem>
      </StyledPaper>
    </Box>
  );
};

export default VirtualizedListItem;
