import { Button, makeStyles } from '@material-ui/core';
import React, { useContext } from 'react';
import { ExportStore } from '../../providers/export';

const useStyles = makeStyles((theme) => {
  return {
    button: {
      borderRadius: 10,
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      marginRight: theme.spacing(1),
    },
  };
});

const UsersExportButton: React.FC<any> = (): JSX.Element => {
  const { exportCsv } = useContext(ExportStore);
  const classes = useStyles();

  return (
    <Button
      variant="text"
      color="primary"
      disableElevation
      classes={{ root: classes.button }}
      component="label"
      onClick={() => {
        exportCsv();
      }}
    >
      Export
    </Button>
  );
};

export default UsersExportButton;
