import {
  Avatar,
  Box,
  Chip,
  Grid,
  ListItemText,
  MenuItem,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useCallback, useContext, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useUserRoleRestrictions } from '../../hooks/services/useUserRoleRestrictions';
import { AuthStore } from '../../providers/auth';
import { INVITES_EDIT_ROUTE, USER_EDIT_ROUTE } from '../../routes/Types';
import { triggerResetPasswordEmail } from '../../services/users';
import { UserListType, UserRole } from '../../utils/types/user';
import VirualizedListMenu from '../common/VirtualizedListMenu';
import ChaseUserDialog from './ChaseUserDialog';
import ResetPasswordDialog from './ResetPasswordDialog';

interface UsersListItemContentProps {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  company: string;
  jobTitle?: string;
  expiry?: Date;
  children?: JSX.Element;
  type: string;
  archived?: boolean;
  permission?: string;
  organization_role?: string;
  onRemove: () => void;
  onResetDevice: Function;
  listType: UserListType;
  onChaseInvitedUser: Function;
  accessLevel?: string;
  license?: string;
  disabled?: boolean;
  license_expires_at?: Date | string | undefined;
  resetMFA: Function;
}

const useStyles = makeStyles((theme) => ({
  listItemText: {
    paddingLeft: theme.spacing(2),
  },
  name: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontWeight: 600,
  },
  job: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  expiry: {
    fontWeight: 400,
    paddingRight: '4px',
  },
  chipDisabled: {
    background: theme.palette.grey[500],
    color: '#ffffff',
    borderRadius: 4,
    height: 20,
    marginBottom: theme.spacing(1),
    marginRight: 2,
  },
  chipAdmin: {
    background: theme.palette.info.main,
    color: '#ffffff',
    borderRadius: 4,
    height: 20,
    marginBottom: theme.spacing(1),
  },
  chipEditor: {
    background: theme.palette.warning.main,
    color: '#ffffff',
    borderRadius: 4,
    height: 20,
    marginLeft: theme.spacing(1),
  },
  chipOrganisationRole: {
    background: 'rgba(0, 200, 0, 0.08)',
    color: theme.palette.success.main,
    borderRadius: 4,
    height: 20,
    marginLeft: theme.spacing(1),
  },
  chipAccessLevel: {
    background: theme.palette.info.main,
    color: '#ffffff',
    borderRadius: 4,
    height: 20,
    marginLeft: theme.spacing(1),
  },
  button: {
    height: 40,
    borderRadius: 10,
    justifyContent: 'flex-start',
    paddingTop: theme.spacing(3),
    paddingLeft: theme.spacing(4),
    paddingBottom: theme.spacing(3),
    paddingRight: theme.spacing(4),
    marginBottom: theme.spacing(3),
  },
}));

const UsersListItemContent: React.FC<UsersListItemContentProps> = ({
  id,
  firstName,
  lastName,
  email,
  company,
  jobTitle,
  expiry,
  type,
  children,
  organization_role,
  onRemove,
  onResetDevice,
  listType,
  onChaseInvitedUser,
  accessLevel,
  license,
  disabled,
  license_expires_at,
  resetMFA,
}: UsersListItemContentProps): JSX.Element => {
  const classes = useStyles();
  const history = useHistory();
  const { isUserRole: isUserAdmin } = useUserRoleRestrictions(
    UserRole.SuperAdmin
  );
  const { orgId } = useParams<{
    orgId: string;
  }>();

  const [
    {
      user: { role },
    },
  ] = useContext(AuthStore);
  const [isDialogOpened, setDialogOpen] = useState(false);
  const [isChaseDialogOpened, setChaseDialogOpen] = useState(false);
  const [resetPasswordLink, setResetPasswordLink] = useState('');
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isMenuOpened = Boolean(anchorEl);

  const handleMenuClick = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
    },
    [setAnchorEl]
  );

  const handleMenuClose = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  const handleDialogOpen = useCallback(() => {
    handleMenuClose();
    setDialogOpen(true);
  }, [setDialogOpen, handleMenuClose]);

  const handleDialogClose = useCallback(() => {
    setDialogOpen(false);
  }, [setDialogOpen]);

  const handleChaseDialogOpen = useCallback(() => {
    setChaseDialogOpen(true);
  }, [setChaseDialogOpen]);

  const handleChaseDialogClose = useCallback(() => {
    setChaseDialogOpen(false);
  }, [setChaseDialogOpen]);

  const handleDelete = () => {
    setAnchorEl(null);
    onRemove();
  };

  const handleResetDevice = () => {
    setAnchorEl(null);
    onResetDevice();
  };

  const handleChaseInvitedUser = () => {
    setAnchorEl(null);
    onChaseInvitedUser();
    handleChaseDialogOpen();
  };

  const handleResetMFA = () => {
    setAnchorEl(null);
    // Reset MFA
    resetMFA();
  };

  const onEditDetails = () => {
    setAnchorEl(null);
    const editRoute =
      listType === UserListType.Users
        ? `${USER_EDIT_ROUTE.replace(':orgId', orgId.toString())}/${id}`
        : `${INVITES_EDIT_ROUTE.replace(':orgId', orgId.toString())}/${id}`;
    history.push(editRoute);
  };

  const recoverPassword = async () => {
    setAnchorEl(null);
    const link = await triggerResetPasswordEmail(email);
    setResetPasswordLink(link);
    handleDialogOpen();
  };

  return (
    <>
      <Avatar alt={firstName}></Avatar>
      <ListItemText className={classes.listItemText}>
        <Grid container>
          <Grid item xs={8}>
            <Box>
              <Typography
                title={firstName}
                variant="body2"
                className={classes.name}
              >
                {firstName || lastName ? `${firstName} ${lastName}` : '-'}
              </Typography>
            </Box>
            <Box>
              <Typography
                title={firstName}
                variant="body2"
                className={classes.job}
              >
                {jobTitle || company ? `${jobTitle} at ${company}` : `-`}
              </Typography>
            </Box>
            <Box display={'flex'} flexDirection={'row'}>
              <Typography variant="body2" className={classes.expiry}>
                Subscription expiry date:
              </Typography>
              <Typography variant="body2" className={classes.job}>
                {license_expires_at
                  ? license_expires_at
                  : expiry
                  ? expiry.toLocaleDateString()
                  : 'N/A'}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box textAlign={'right'}>
              {organization_role && !disabled && (
                <Chip
                  className={classes.chipOrganisationRole}
                  label={organization_role}
                ></Chip>
              )}
              {accessLevel && !disabled && (
                <Chip
                  className={classes.chipAccessLevel}
                  label={accessLevel}
                ></Chip>
              )}
              {license && !disabled && (
                <Chip className={classes.chipEditor} label={license}></Chip>
              )}
              {disabled && (
                <Chip
                  className={classes.chipDisabled}
                  label={'Disabled'}
                ></Chip>
              )}
            </Box>
          </Grid>
        </Grid>
      </ListItemText>
      <Box display="flex" alignSelf="flex-start" alignItems="center" mt={1}>
        <Box mr={2}>{children}</Box>
        {isUserAdmin && (
          <VirualizedListMenu
            anchorEl={anchorEl}
            open={isMenuOpened}
            onClose={handleMenuClose}
            onClick={handleMenuClick}
          >
            <MenuItem onClick={onEditDetails}>Edit details</MenuItem>
            {role === 'superadmin' && type === 'user' && (
              <MenuItem onClick={recoverPassword}>Password recovery</MenuItem>
            )}
            {role === 'superadmin' && type === 'invite' && (
              <MenuItem onClick={handleDelete}>Delete invite</MenuItem>
            )}
            {role === 'superadmin' && type === 'user' && (
              <MenuItem onClick={handleResetDevice}>Reset device</MenuItem>
            )}
            {role === 'superadmin' && type === 'invite' && (
              <MenuItem onClick={handleChaseInvitedUser}>Chase user</MenuItem>
            )}
            {role === 'superadmin' && type === 'user' && (
              <MenuItem onClick={handleResetMFA}>Reset MFA</MenuItem>
            )}
          </VirualizedListMenu>
        )}
      </Box>
      <ResetPasswordDialog
        onDialogOpen={handleDialogOpen}
        onDialogClose={handleDialogClose}
        isDialogOpened={isDialogOpened}
        link={resetPasswordLink}
      />
      <ChaseUserDialog
        isDialogOpened={isChaseDialogOpened}
        onDialogClose={handleChaseDialogClose}
      />
    </>
  );
};

export default UsersListItemContent;
