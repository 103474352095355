import React, { useCallback, useContext, useEffect, useRef } from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';
import { UploadsStore } from '../../providers/uploads';
import {
  CHIPS,
  ChipStatus,
} from '../../utils/constants/chipStatuses.constants';
import { LAYOUT } from '../../utils/constants/layout.constants';
import { UploadStatusType } from '../../utils/types/upload';
import StatusChip from '../common/StatusChip';
import VirtualizedListItem from '../common/VirtualizedListItem';
import UploadListItemContent from './UploadListItemContent';

interface ScheduleListProps {}
interface ListRowProps {
  style: any;
  index: number;
  data: any[];
}

const formatDate = (inputDate: Date) => {
  const options: Intl.DateTimeFormatOptions = {
    weekday: 'long',
    day: 'numeric',
    month: 'long',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
    timeZoneName: 'short',
    timeZone: 'GMT',
  };

  const formattedDate = inputDate.toLocaleDateString('en-GB', options);

  return formattedDate.replace(/(\d+)(th|st|nd|rd)/, (_, day, suffix) => {
    return `${day}${suffix}`;
  });
};

const getUpdateStatus = (status: string, scheduled?: number): any => {
  if (scheduled) {
    return {
      label: `Publish: ${formatDate(new Date(scheduled))}`,
      status: 'info',
    };
  }
  if (status === UploadStatusType.new) {
    return CHIPS['validationPending'];
  }
  if (status === UploadStatusType.validation_fail) {
    return CHIPS['validationErrors'];
  }
  if (status === UploadStatusType.pending) {
    return CHIPS['reviewPending'];
  }
  if (status === UploadStatusType.published) {
    return CHIPS['updateSuccess'];
  }
  if (status === UploadStatusType.publish_fail) {
    return CHIPS['publishErrors'];
  }
  if (status === UploadStatusType.import_existing) {
    return CHIPS['importExists'];
  }
  if (status === UploadStatusType.rejected) {
    return CHIPS['rejected'];
  }
  if (status === UploadStatusType.reverted) {
    return CHIPS['reverted'];
  }
  if (status === UploadStatusType.partially_uploaded) {
    return CHIPS['partiallyUploaded'];
  }
  return CHIPS['updatePending'];
};

const Row = ({ style, index, data }: ListRowProps) => {
  return (
    <VirtualizedListItem
      style={{
        ...style,
        width: 'calc(100% - 40px)',
        marginLeft: 20,
        marginRight: 20,
        marginTop: 10,
      }}
    >
      <UploadListItemContent
        fileName={data[index].fileName}
        status={data[index].status}
        created={data[index].created}
        errorReport={data[index].validationReport?.report}
        errorMessage={data[index].validationReport?.error_message}
        rejectionNotes={data[index].rejectReport?.rejection_notes}
        user={data[index].user}
      >
        {data[index]?.status && (
          <StatusChip
            label={
              getUpdateStatus(
                (data[index]?.status).label,
                data[index]?.scheduledDate
              ).label
            }
            variant={
              getUpdateStatus(data[index]?.status, data[index]?.scheduledDate)
                .status as ChipStatus
            }
          />
        )}
      </UploadListItemContent>
    </VirtualizedListItem>
  );
};

const ScheduleList: React.FC<ScheduleListProps> = (): JSX.Element => {
  const { state, getScheduled } = useContext(UploadsStore);
  const uploadListRef = useRef<FixedSizeList>(null);

  const loadMoreItems = useCallback(() => {
    //TODO: dynamic loading is not available yet
    //console.log("load more");
  }, []);

  const isItemLoaded = useCallback((_index: number) => {
    //TODO: dynamic loading is not available yet
    //console.log(index);
    return false;
  }, []);

  useEffect(() => {
    getScheduled();
  }, [getScheduled]);

  return (
    <AutoSizer>
      {({ height, width }: { height: number; width: number }) => (
        <InfiniteLoader
          isItemLoaded={isItemLoaded}
          loadMoreItems={loadMoreItems}
          itemCount={state.data?.length || 0}
        >
          {({ onItemsRendered }) => (
            <FixedSizeList
              height={height}
              width={width}
              itemCount={state.data?.length || 0}
              itemSize={LAYOUT.VirtuilizedListItemHeight}
              itemData={state.data || []}
              onItemsRendered={onItemsRendered}
              ref={uploadListRef}
            >
              {Row}
            </FixedSizeList>
          )}
        </InfiniteLoader>
      )}
    </AutoSizer>
  );
};

export default ScheduleList;
