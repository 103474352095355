import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import WrappedDialog from '../common/WrappedDialog';

interface ChaseUserDialogProps {
  isDialogOpened: boolean;
  onDialogClose: () => void;
}

const useStyles = makeStyles((theme) => ({
  button: {
    borderRadius: 10,
    marginLeft: theme.spacing(2),
  },
  content: {
    padding: 0,
  },
  link: {
    wordWrap: 'break-word',
  },
}));

const ChaseUserDialog: React.FC<ChaseUserDialogProps> = ({
  isDialogOpened,
  onDialogClose,
}: ChaseUserDialogProps): JSX.Element => {
  const classes = useStyles();

  return (
    <WrappedDialog
      open={isDialogOpened}
      handleDialogClose={onDialogClose}
      dialogTitle={'User has been chased'}
    >
      <DialogContent>
        <Box display="flex">
          <Typography>An email is send to the user.</Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Box mt={3} mb={3} mr={8}>
          <Button
            onClick={onDialogClose}
            color="secondary"
            variant="contained"
            disableElevation
            classes={{ root: classes.button }}
          >
            Close
          </Button>
        </Box>
      </DialogActions>
    </WrappedDialog>
  );
};

export default ChaseUserDialog;
