import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import FormatBoldIcon from '@material-ui/icons/FormatBold';
import FormatItalicIcon from '@material-ui/icons/FormatItalic';
import FormatUnderlinedIcon from '@material-ui/icons/FormatUnderlined';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import FullscreenExitIcon from '@material-ui/icons/FullscreenExit';
import ImageIcon from '@material-ui/icons/Image';
import LinkIcon from '@material-ui/icons/Link';
import LinkOffIcon from '@material-ui/icons/LinkOff';
import React, { useEffect, useMemo, useState } from 'react';
import {
  BaseEditor,
  createEditor,
  Descendant,
  Editor,
  Element as SlateElement,
  Range,
  Transforms,
} from 'slate';
import { ReactEditor, Slate, withReact } from 'slate-react';
import TextEditorContent from './TextEditorContent';
import TextEditorMarkButton from './TextEditorMarkButton';
import TextEditorLinkPopover from './TextEditorPopover';
import Toolbar from './TextEditorToolbar';

export type CustomElement = { type: 'paragraph'; children: CustomText[] };
export type CustomText = { text: string };

declare module 'slate' {
  interface CustomTypes {
    Editor: BaseEditor & ReactEditor;
    Element: CustomElement;
    Text: CustomText & {
      bold?: boolean;
      italic?: boolean;
      underline?: boolean;
      link?: boolean;
      image?: boolean;
    };
  }
}

interface TextEditorProps {
  value: Descendant[];
  onChange: (value: Descendant[]) => void;
  title: string;
  placeholder: string;
  contentImageUrl: string | undefined;
  uploadContentImage: (_file: File) => void;
}

const useStyles = makeStyles((theme) => ({
  icon: {
    width: 14,
    fill: theme.palette.text.primary,
  },
  textEditorField: {
    height: 'auto',
    borderBottom: `1px solid ${theme.palette.secondary.dark}`,
    marginBottom: theme.spacing(4),
    padding: 4,
    '&:focus-within': {
      borderBottom: `2px solid ${theme.palette.primary.main}`,
    },
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  fullscreen: {
    position: 'fixed',
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    zIndex: 1200,
    width: 'calc(100vw - 32px)',
    minHeight: 'calc(100vh - 32px)',
    height: '100%',
    background: '#fff',
    overflowY: 'scroll',
    padding: 16,
  },
  nonfullscreen: {},
  toolbar: {
    borderBottom: `solid 1px ${theme.palette.secondary.light}`,
    padding: '0px 4px 4px 4px',
    marginBottom: 8,
  },
  fileupload: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    opacity: 0,
    cursor: 'hand',
  },
}));

const isUrl = (url: string) => {
  const regex = new RegExp(
    '^((ft|htt)ps?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i'
  ); // fragment locator

  return regex.test(url);
};

const TextEditor: React.FC<TextEditorProps> = ({
  value,
  onChange,
  title,
  placeholder,
  contentImageUrl,
  uploadContentImage,
}: TextEditorProps): JSX.Element => {
  const [isFullscreenMode, setFullscreenMode] = useState<boolean>(false);
  const [isLinkSelected, setIsLinkSelected] = useState<boolean>(false);

  const withInlines = (editor: any) => {
    const { insertData, insertText, isInline } = editor;

    editor.isInline = (element: any) =>
      ['image', 'link', 'button'].includes(element.type) || isInline(element);

    editor.insertText = (text: any) => {
      if (text && isUrl(text)) {
        wrapLink(editor, text);
      } else {
        insertText(text);
      }
    };

    editor.insertData = (data: any) => {
      const text = data.getData('text/plain');

      if (text && isUrl(text)) {
        wrapLink(editor, text);
      } else {
        insertData(data);
      }
    };

    return editor;
  };

  const withImages = (editor: any) => {
    const { insertData, isVoid } = editor;

    editor.isVoid = (element: any) => {
      return element.type === 'image' ? true : isVoid(element);
    };

    editor.insertData = (data: any) => {
      const { files } = data;

      if (files && files.length > 0) {
        for (const file of files) {
          const reader = new FileReader();
          const [mime] = file.type.split('/');

          if (mime === 'image') {
            reader.addEventListener('load', () => {
              const url = reader.result;
              insertImage(editor, url);
            });

            reader.readAsDataURL(file);
          }
        }
      } else {
        insertData(data);
      }
    };

    return editor;
  };

  const editor = useMemo(
    () => withImages(withInlines(withReact(createEditor()))),
    []
  );

  const classes = useStyles();

  useEffect(() => {
    if (contentImageUrl) {
      insertImage(editor, contentImageUrl);
    }
  }, [contentImageUrl]);

  const isMarkActive = (editor: Editor, format: string) => {
    if (format === 'fullscreen') {
      return isFullscreenMode;
    }
    const marks = Editor.marks(editor);
    return marks ? marks[format as keyof typeof marks] === true : false;
  };

  const toggleMark = (editor: Editor, format: string) => {
    if (format === 'fullscreen') {
      setFullscreenMode(!isFullscreenMode);
      return;
    }

    const isActive = isMarkActive(editor, format);

    if (isActive) {
      Editor.removeMark(editor, format);
    } else {
      Editor.addMark(editor, format, true);
    }
  };

  const isLinkActive = (editor: Editor) => {
    const [link]: any = Editor.nodes(editor, {
      match: (n) =>
        !Editor.isEditor(n) &&
        SlateElement.isElement(n) &&
        n.type.toString() === 'link',
    });

    return !!link;
  };

  const formatUrl = (url: string) => {
    if (/^https/.test(url)) {
      return url;
    }
    if (/^www/.test(url)) {
      return 'https://' + url;
    }
    return 'https://www.' + url;
  };

  const wrapLink = (editor: any, url: string) => {
    if (isLinkActive(editor)) {
      unwrapLink(editor);
    }

    const { selection } = editor;
    const isCollapsed = selection && Range.isCollapsed(selection);

    const validUrl = formatUrl(url);

    const link: any = {
      type: 'link',
      url: validUrl,
      children: isCollapsed ? [{ text: url }] : [],
    };

    if (isCollapsed) {
      Transforms.insertNodes(editor, link);
    } else {
      Transforms.wrapNodes(editor, link, { split: true });
      Transforms.collapse(editor, { edge: 'end' });
    }

    Transforms.insertFragment(editor, [{ text: ' ' }]);
  };

  const unwrapLink = (editor: any) => {
    Transforms.unwrapNodes(editor, {
      match: (n: any) =>
        !Editor.isEditor(n) &&
        SlateElement.isElement(n) &&
        n.type.toString() === 'link',
    });
  };

  const insertImage = (editor: Editor, url: string | ArrayBuffer | null) => {
    const text = { text: '' };
    const image: any = { type: 'image', url, children: [text] };
    Transforms.insertNodes(editor, image);
  };

  const handleClick = (_event: React.MouseEvent<HTMLButtonElement>) => {
    const _isLinkActive = isLinkActive(editor);

    if (_isLinkActive) {
      if (editor.selection) {
        unwrapLink(editor);
      }
      setShouldShowPopover(false);
    } else {
      setShouldShowPopover(true);
    }
  };

  const [shouldShowPopover, setShouldShowPopover] = useState<boolean>(false);

  return (
    <Box
      className={isFullscreenMode ? classes.fullscreen : classes.nonfullscreen}
    >
      <Slate
        editor={editor}
        value={value}
        onChange={(newValue) => onChange(newValue)}
      >
        <Box className={classes.toolbar}>
          <Toolbar title={title}>
            <TextEditorMarkButton
              format="bold"
              isMarkActive={isMarkActive}
              toggleMark={toggleMark}
            >
              <FormatBoldIcon classes={{ root: classes.icon }} />
            </TextEditorMarkButton>
            <TextEditorMarkButton
              format="italic"
              isMarkActive={isMarkActive}
              toggleMark={toggleMark}
            >
              <FormatItalicIcon classes={{ root: classes.icon }} />
            </TextEditorMarkButton>
            <TextEditorMarkButton
              format="underline"
              isMarkActive={isMarkActive}
              toggleMark={toggleMark}
            >
              <FormatUnderlinedIcon classes={{ root: classes.icon }} />
            </TextEditorMarkButton>
            <TextEditorMarkButton
              format="link"
              isMarkActive={isMarkActive}
              toggleMark={toggleMark}
              onMouseDown={handleClick}
            >
              {isLinkActive(editor) || isLinkSelected ? (
                <LinkOffIcon classes={{ root: classes.icon }} />
              ) : (
                <LinkIcon classes={{ root: classes.icon }} />
              )}
            </TextEditorMarkButton>
            <TextEditorMarkButton
              format="image"
              isMarkActive={isMarkActive}
              toggleMark={toggleMark}
            >
              <ImageIcon classes={{ root: classes.icon }} />
              <input
                type="file"
                className={classes.fileupload}
                onChange={(evt) => {
                  if (evt.target.files) uploadContentImage(evt.target.files[0]);
                }}
              />
            </TextEditorMarkButton>
            <TextEditorMarkButton
              format="fullscreen"
              isMarkActive={isMarkActive}
              toggleMark={toggleMark}
            >
              {isFullscreenMode ? (
                <FullscreenExitIcon classes={{ root: classes.icon }} />
              ) : (
                <FullscreenIcon classes={{ root: classes.icon }} />
              )}
            </TextEditorMarkButton>
          </Toolbar>
        </Box>
        <Box>
          <TextEditorLinkPopover
            shouldShowPopover={shouldShowPopover}
            setShouldShowPopover={setShouldShowPopover}
            isUrl={isUrl}
            wrapLink={wrapLink}
            editor={editor}
          />
        </Box>
        <Box
          minHeight={{ xs: 'auto', sm: 'auto', md: 'auto', lg: 264 }}
          className={classes.textEditorField}
        >
          <TextEditorContent
            setIsLinkSelected={setIsLinkSelected}
            value={value}
            toggleMark={toggleMark}
            editor={editor}
            placeholder={placeholder}
          />
        </Box>
      </Slate>
    </Box>
  );
};

export default TextEditor;
