import { addDoc, collection, doc, onSnapshot } from 'firebase/firestore';
import {
  DeletedCompanySource,
  DeletedIndividualSource,
  DeletedProfileListItem,
} from '../utils/types/deleteProfiles';
import { UploadStatusType } from '../utils/types/upload';
import { getElasticToken } from './elasticAuth';
import { auth, db } from './firebase';

const parseIndividualResult = (
  result: DeletedIndividualSource
): DeletedProfileListItem => {
  return {
    name: `${result.First_Name} ${result.Surname}`,
    role: `${result.Job_Title} at ${result.Company_Name}`,
    data: result,
  };
};

const parseCompanyResult = (
  result: DeletedCompanySource
): DeletedProfileListItem => {
  return {
    name: result.Company_Name,
    role: result.Company_Type,
    data: result,
  };
};

const getDeletedProfiles = async (collection: string) => {
  let elasticCredentials;
  try {
    elasticCredentials = await getElasticToken();
  } catch (e) {
    throw new Error('Error authenticating with search database.');
  }

  try {
    const query = {
      size: 1000,
    };

    const response = await fetch(
      `${elasticCredentials.host}/${collection}/_search`,
      {
        method: 'POST',
        headers: {
          Authorization: `${elasticCredentials.type} ${elasticCredentials.access_token}`,
          'Content-type': 'application/json',
        },
        body: JSON.stringify({
          ...query,
        }),
      }
    );
    const data = await response.json();

    return data;
  } catch (e) {
    throw new Error('Error connecting to the database.');
  }
};

export const getIndividuals = async () => {
  const data = await getDeletedProfiles('removed-individuals');
  const results = data?.hits?.hits.map((hit: any) =>
    parseIndividualResult(hit._source)
  );

  return results;
};

export const getCompanies = async () => {
  const data = await getDeletedProfiles('removed-companies');
  const results = data?.hits?.hits.map((hit: any) =>
    parseCompanyResult(hit._source)
  );

  return results;
};

export const addUploadToStore = async (
  url: string,
  fileName: string,
  type: string
): Promise<string> => {
  try {
    const docRef = await addDoc(collection(db, 'deleted_records_uploads'), {
      path: url,
      fileName,
      created: Date.now(),
      user: auth.currentUser?.uid,
      status: UploadStatusType.new,
      type,
    });
    return docRef.id;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const listenForStatusUpdate = (docId: string) => {
  return new Promise((resolve, reject) => {
    const query = doc(collection(db, 'deleted_records_uploads'), docId);

    const _unsubscribe = onSnapshot(query, (snapshot) => {
      const docData = snapshot.data();
      if (docData?.status === UploadStatusType.published) {
        resolve('Document published!');
      }
      if (docData?.status === UploadStatusType.validation_fail) {
        reject('Document rejected!');
      }
    });
  });
};
