import { Box, CircularProgress, Typography } from '@material-ui/core';
import React, { useContext } from 'react';
import { DeleteProfilesStore } from '../../providers/deleteProfiles';
import DeletedIndividualsList from './DeletedIndividualsList';
import DeletedProfilesLayout from './DeletedProfilesLayout';

interface DeletedIndividualsViewProps {}

const DeletedIndividualsView: React.FC<
  DeletedIndividualsViewProps
> = (): JSX.Element => {
  const {
    state: { loading, deletedIndividuals, error },
  } = useContext(DeleteProfilesStore);

  const total = deletedIndividuals ? deletedIndividuals.length : 0;

  return (
    <DeletedProfilesLayout>
      <Box minHeight={'600px'}>
        {!loading && total === 0 && (
          <Typography>
            {'No results are matching your search criteria'}
          </Typography>
        )}
        {error && <Typography>{error}</Typography>}
        {total > 0 && !loading && (
          <DeletedIndividualsList
            deletedIndividualsList={deletedIndividuals || []}
          ></DeletedIndividualsList>
        )}
        {loading && <CircularProgress />}
      </Box>
    </DeletedProfilesLayout>
  );
};

export default DeletedIndividualsView;
