import {
  Box,
  Button,
  CircularProgress,
  Grid,
  SvgIcon,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useCallback, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { ReactComponent as AddIcon } from '../../assets/icons/add-icon.svg';
import { GlossaryStore } from '../../providers/glossary';
import * as ROUTES from '../../routes/Types';
import { LAYOUT } from '../../utils/constants/layout.constants';
import { GLOSSARY_PAGE } from '../../utils/constants/pageNames.constants';
import SectionTitle from '../common/SectionTitle';
import GlossaryList from './GlossaryList';
import GlossarySearch from './GlossarySearch';
import GlossarySort from './GlossarySort';

interface GlossaryContentProps {}

const useStyles = makeStyles((theme) => ({
  content: {
    marginTop: 64,
    [theme.breakpoints.up('md')]: {
      marginTop: LAYOUT.TopPanelHeight + LAYOUT.TopPanelBottomMargin,
      width: `calc(100% - ${LAYOUT.DrawerWidth}px)`,
      marginLeft: LAYOUT.DrawerWidth,
    },
  },
  fullsize: {
    height: `calc(100vh - ${LAYOUT.TopPanelHeight}px - ${LAYOUT.TopPanelBottomMargin}px)`,
  },
  itemOrder: {
    order: 1,
    height: '100%',
    [theme.breakpoints.up('md')]: {
      order: 'initial',
      height: 'auto',
    },
  },
  button: {
    borderRadius: 10,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    marginRight: theme.spacing(1),
  },
}));

export interface GlossaryListItem {
  id: string;
  date: string;
  title: string;
}

const GlossaryContent: React.FC<GlossaryContentProps> = (): JSX.Element => {
  const {
    state: { data, loading },
    getGlossaryBatch,
  } = useContext(GlossaryStore);
  const classes = useStyles();

  let history = useHistory();

  const handleAddGlossaryClick = useCallback(() => {
    history.push(ROUTES.GLOSSARY_ADD_ROUTE);
  }, [history]);

  useEffect(() => {
    getGlossaryBatch(null);
  }, []);

  return (
    <Box className={classes.content}>
      <SectionTitle title={GLOSSARY_PAGE} />
      <Box
        mr={{ xs: 5, sm: 5, md: 0, lg: 5 }}
        ml={{ xs: 5, sm: 5, md: 0, lg: 5 }}
      >
        <Grid container direction="row" className={classes.fullsize}>
          <Grid item xs={12} sm={12} md={6} className={classes.itemOrder}>
            <Box height={'100%'}>
              {data && (
                <GlossaryList
                  glossaryList={data}
                  loadMoreItemsHandler={getGlossaryBatch}
                />
              )}
              {loading && <CircularProgress />}
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Box
              display="flex"
              alignContent="left"
              ml={{ xs: 4, sm: 4, md: 4, lg: 25 }}
            >
              <Button
                variant="contained"
                color="secondary"
                disableElevation
                classes={{ root: classes.button }}
                component="label"
                onClick={handleAddGlossaryClick}
              >
                <SvgIcon component={AddIcon}></SvgIcon>
                {'Add Glossary Term'}
              </Button>
            </Box>
            <Box mt={8} ml={{ md: 5, lg: 28 }} mr={{ md: 5, lg: 15 }}>
              <Typography variant="subtitle1" align="left">
                Find a term
              </Typography>
              <GlossarySearch />
            </Box>
            <Box mt={8} ml={{ md: 5, lg: 28 }} mr={{ md: 5, lg: 33 }} mb={5}>
              <Typography variant="subtitle1" align="left">
                Sort terms
              </Typography>
              <GlossarySort />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default GlossaryContent;
