import { Box, Button } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import { SystemProvider, SystemStore } from '../../providers/system';

const SystemContent: React.FC = () => {
  const [logos, setLogos] = useState<any>([]);
  const { updateCount, loadLogos, updateLogos } = useContext(SystemStore);

  /*
  const handleExportLogos = useCallback(() => {
    exportLogos();
  }, [exportLogos]);
*/
  useEffect(() => {
    setLogos(loadLogos());
  }, [loadLogos]);
  return (
    <>
      <Box>
        <Button
          onClick={() => {
            updateLogos(logos);
          }}
        >
          Update logos
        </Button>
      </Box>
      <Box>
        <h3>{logos.length} logos</h3>
        <h4>{updateCount} logos updated</h4>
        <ul>
          {logos?.map((logo: any, idx: number) => (
            <li key={idx}>
              <img width={60} src={logo.logo} alt={'logo'} />
              {logo.id}
            </li>
          ))}
        </ul>
      </Box>
      <Box mt={4} textAlign={'left'}>
        <Button onClick={() => {}}>Export Logos</Button>
      </Box>
    </>
  );
};

interface SystemPageProps {}

const SystemPage: React.FC<SystemPageProps> = (): JSX.Element => {
  return (
    <SystemProvider>
      <Box>
        <h1>System activities</h1>
      </Box>
      <SystemContent />
    </SystemProvider>
  );
};

export default SystemPage;
