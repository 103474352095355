import { Box } from '@material-ui/core';
import React from 'react';
import { Route, useLocation } from 'react-router-dom';
import TooltipsContent from '../../components/tooltips/TooltipsContent';
import { DirectoryProvider } from '../../providers/directory';
import { TooltipsProvider } from '../../providers/tooltips';
import {
  TOOLTIPS_ADD_ROUTE,
  TOOLTIPS_EDIT_ROUTE,
  TOOLTIPS_ROUTE,
} from '../../routes/Types';
import TooltipsAddPage from './TooltipsAddPage';
import TooltipsEditPage from './TooltipsEditPage';

interface TooltipsPageProps {}

const TooltipsPage: React.FC<TooltipsPageProps> = (): JSX.Element => {
  const currentLocation = useLocation();
  return (
    <DirectoryProvider>
      <TooltipsProvider>
        <Box
          style={
            currentLocation.pathname !== TOOLTIPS_ROUTE
              ? { display: 'none' }
              : {}
          }
        >
          <TooltipsContent />
        </Box>
        <Route path={TOOLTIPS_ADD_ROUTE}>
          <TooltipsAddPage />
        </Route>

        <Route path={TOOLTIPS_EDIT_ROUTE}>
          <TooltipsEditPage />
        </Route>
      </TooltipsProvider>
    </DirectoryProvider>
  );
};

export default TooltipsPage;
