import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useContext } from 'react';
import { Control, Controller } from 'react-hook-form';
import { Descendant } from 'slate';
import { GlossaryStore } from '../../providers/glossary';
import StyledInput from '../common/StyledInput';
import TextEditor from '../common/TextEditor';

export interface GlossaryFormFields {
  id?: string;
  glossaryTermTitle: string;
  glossaryTermText: Descendant[];
  glossaryTermPublishDate: Date;
  glossaryTermPublishTime: Date;
}
interface GlossaryFormSectionProps {
  control: Control<GlossaryFormFields>;
  errors: any;
}

const useStyles = makeStyles((theme) => ({
  input: {
    'label + &': {
      marginTop: 0,
      color: theme.palette.text.secondary,
    },
  },
  textField: {
    width: '100%',
  },
  errorMessage: {
    height: theme.spacing(6),
  },
}));

const GlossaryFormSection: React.FC<GlossaryFormSectionProps> = ({
  control,
  errors,
}: GlossaryFormSectionProps): JSX.Element => {
  const classes = useStyles();
  const {
    state: { contentImageUrl },
    uploadContentImage,
  } = useContext(GlossaryStore);

  return (
    <>
      <Box>
        <StyledInput
          name="glossaryTermTitle"
          label="Glossary term title"
          control={control}
          error={!!errors.glossaryTermTitle}
          classnames={{ formControl: classes.input }}
        />
        {errors.glossaryTermTitle && (
          <Typography
            variant="body1"
            color="error"
            className={classes.errorMessage}
            align="left"
          >
            {errors.glossaryTermTitle.message}
          </Typography>
        )}
      </Box>
      <Box mt={{ xs: 4, sm: 4, md: 4, lg: 17 }}>
        <Box mt={10}>
          <Controller
            control={control}
            name="glossaryTermText"
            render={({ field: { onChange, value } }) => {
              return control?.defaultValuesRef?.current?.glossaryTermTitle ||
                !control?.defaultValuesRef?.current?.id?.length ? (
                <TextEditor
                  title="Glossary term body"
                  value={value}
                  onChange={onChange}
                  placeholder="Add glossary term description..."
                  contentImageUrl={contentImageUrl}
                  uploadContentImage={uploadContentImage}
                />
              ) : (
                <></>
              );
            }}
          />
          {errors.glossaryTermText && (
            <Typography
              variant="body1"
              color="error"
              className={classes.errorMessage}
              align="left"
            >
              {errors.glossaryTermText.message}
            </Typography>
          )}
        </Box>
      </Box>
    </>
  );
};

export default GlossaryFormSection;
