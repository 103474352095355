import { makeStyles } from '@material-ui/core/styles';

interface Props {}
const useStyles = makeStyles({
  redSpan: {
    color: '#FF3C00',
  },
});

const RedSpan = (props: Props) => {
  const { ...other } = props;
  const classes = useStyles(props);
  return <span className={classes.redSpan} {...other} />;
};

export default RedSpan;
