import { TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Autocomplete } from '@material-ui/lab';
import React from 'react';

type DropdownOption = {
  optionName: string;
  id: string;
  type?: string;
};
interface WrappedAutocompleteProps {
  label: string;
  options: DropdownOption[];
  value?: DropdownOption[];
  handleAutocompleteChange: Function;
}

const useStyles = makeStyles((theme) => ({
  option: {
    padding: theme.spacing(4),
    fontSize: 12,
  },
  tag: {
    fontFamily: theme.typography.fontFamily,
    background: theme.palette.background.default,
    borderRadius: 22,
    '& .MuiChip-deleteIcon': {
      color: theme.palette.common.black,
    },
  },
}));

const WrappedAutocomplete: React.FC<WrappedAutocompleteProps> = ({
  label,
  options,
  value,
  handleAutocompleteChange,
}: WrappedAutocompleteProps): JSX.Element => {
  const classes = useStyles();

  return (
    <Autocomplete
      multiple
      options={options}
      getOptionLabel={(option) => option.optionName}
      value={value}
      onChange={(event, selectedOptions) => {
        handleAutocompleteChange(selectedOptions);
      }}
      classes={{
        option: classes.option,
        tag: classes.tag,
      }}
      filterSelectedOptions
      getOptionSelected={(option, value) =>
        option.optionName === value.optionName
      }
      renderInput={(params) => (
        <TextField {...params} variant="standard" label={label} />
      )}
    />
  );
};

export default WrappedAutocomplete;
