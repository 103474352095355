import {
  Avatar,
  Box,
  ListItemText,
  MenuItem,
  SvgIcon,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useCallback, useState } from 'react';
import { ReactComponent as UploadIcon } from '../../assets/icons/upload.svg';
import { UploadStatusType } from '../../utils/types/upload';
import VirualizedListMenu from '../common/VirtualizedListMenu';
import RejectionNotesDialog from './RejectionNotesDialog';
import UploadErrorsDialog from './UploadErrorsDialog';

interface UploadListItemContentProps {
  fileName: string;
  status: string;
  created: number;
  rejectionNotes?: string;
  errorReport?: Array<{ error_messages: Array<string>; row_number: number }>;
  errorMessage: string;
  user: string;
  children?: JSX.Element;
}

const useStyles = makeStyles((theme) => ({
  listItemText: {
    paddingLeft: theme.spacing(2),
  },
  avatar: {
    backgroundColor: theme.palette.secondary.main,
  },
  fileName: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  date: {
    display: 'inline-block',
    width: '100%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));

const UploadListItemContent: React.FC<UploadListItemContentProps> = ({
  fileName,
  status,
  created,
  rejectionNotes,
  errorReport,
  errorMessage,
  user,
  children,
}: UploadListItemContentProps): JSX.Element => {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isUploadErrorsDialogOpened, setUploadErrorsDialogOpen] =
    useState(false);
  const [isRejectionDialogOpened, setRejectionDialogOpen] = useState(false);
  const isMenuOpened = Boolean(anchorEl);

  const handleMenuClick = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
    },
    [setAnchorEl]
  );

  const handleMenuClose = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  const shouldShowErrors = useCallback((): boolean => {
    if (
      status === UploadStatusType.validation_fail ||
      status === UploadStatusType.partially_uploaded
    ) {
      return true;
    }

    return false;
  }, [status]);

  const shouldShowRejection = useCallback((): boolean => {
    if (status === UploadStatusType.rejected) {
      return true;
    }

    return false;
  }, [status]);

  const shouldShowMenu = useCallback((): boolean => {
    if (
      status === UploadStatusType.rejected ||
      status === UploadStatusType.validation_fail ||
      status === UploadStatusType.partially_uploaded
    ) {
      return true;
    }

    return false;
  }, [status]);

  const handleUploadErrorsDialogOpen = useCallback(() => {
    handleMenuClose();
    setUploadErrorsDialogOpen(true);
  }, [setUploadErrorsDialogOpen, handleMenuClose]);

  const handleUploadErrorsDialogClose = useCallback(() => {
    setUploadErrorsDialogOpen(false);
  }, [setUploadErrorsDialogOpen]);

  const handleRejectionDialogOpen = useCallback(() => {
    handleMenuClose();
    setRejectionDialogOpen(true);
  }, [setRejectionDialogOpen, handleMenuClose]);

  const handleRejectionDialogClose = useCallback(() => {
    setRejectionDialogOpen(false);
  }, [setRejectionDialogOpen]);

  return (
    <>
      <Avatar classes={{ colorDefault: classes.avatar }}>
        <SvgIcon component={UploadIcon}></SvgIcon>
      </Avatar>
      <ListItemText className={classes.listItemText}>
        <Typography variant="subtitle2" className={classes.fileName}>
          {fileName}
        </Typography>
        <Typography
          variant="caption"
          color="textSecondary"
          className={classes.date}
        >
          {`Uploaded ${new Date(created).toUTCString()} by ${user}`}
        </Typography>
      </ListItemText>
      <Box display="flex" alignItems="center">
        <Box mr={2}>{children}</Box>
        {shouldShowMenu() && (
          <VirualizedListMenu
            anchorEl={anchorEl}
            open={isMenuOpened}
            onClose={handleMenuClose}
            onClick={handleMenuClick}
          >
            {shouldShowErrors() && (
              <MenuItem onClick={handleUploadErrorsDialogOpen}>
                View Errors
              </MenuItem>
            )}
            {shouldShowRejection() && (
              <MenuItem onClick={handleRejectionDialogOpen}>
                Rejection Notes
              </MenuItem>
            )}
          </VirualizedListMenu>
        )}
      </Box>
      <UploadErrorsDialog
        errorReport={errorReport}
        errorMessage={errorMessage}
        onDialogClose={handleUploadErrorsDialogClose}
        isDialogOpened={isUploadErrorsDialogOpened}
      />
      <RejectionNotesDialog
        rejectionNotes={rejectionNotes}
        onDialogClose={handleRejectionDialogClose}
        isDialogOpened={isRejectionDialogOpened}
      />
    </>
  );
};

export default UploadListItemContent;
