export type AuthState = {
  user?: any;
  loading: boolean;
  init?: boolean;
  error?: any;
  mfaError?: any;
};

export const AUTH_ACTIONS = {
  LOGIN_INIT: 'LOGIN/INIT',
  LOGIN_START: 'LOGIN/START',
  LOGIN_SUCCESS: 'LOGIN/SUCCESS',
  LOGOUT_SUCCESS: 'LOGOUT/SUCCESS',
  LOGIN_FAIL: 'LOGIN/FAIL',
  START_MFA: 'START_MFA',
};

export const auth = (state: AuthState, action: any): AuthState => {
  switch (action.type) {
    case AUTH_ACTIONS.LOGIN_INIT:
      return { user: undefined, error: undefined, loading: true, init: true };
    case AUTH_ACTIONS.LOGIN_START:
      return { user: undefined, error: undefined, loading: true, init: false };
    case AUTH_ACTIONS.LOGIN_SUCCESS:
      return {
        user: { ...action.payload },
        error: undefined,
        loading: false,
        init: false,
      };
    case AUTH_ACTIONS.LOGOUT_SUCCESS:
      return { user: undefined, error: undefined, loading: false, init: false };
    case AUTH_ACTIONS.LOGIN_FAIL:
      return {
        user: undefined,
        error: action.payload.error,
        loading: false,
        init: false,
      };
    case AUTH_ACTIONS.START_MFA:
      return {
        ...state,
        loading: false,
        mfaError: action.payload,
      };
    default:
      throw new Error();
  }
};
