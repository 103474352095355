import { KnowledgeItem } from '../utils/types/knowledgeCentre';

export interface KnowledgeCentreState {
  data: Array<KnowledgeItem> | null;
  contentImageUrl?: string;
  loading: boolean;
  updating: boolean;
  uploading: boolean;
  error: any;
}

export const initialKnowledgeCentreState: KnowledgeCentreState = {
  data: null,
  contentImageUrl: undefined,
  loading: false,
  updating: false,
  uploading: false,
  error: null,
};

export const KNOWLEDGE_CENTRE_ACTIONS = {
  FETCH: 'KNOWLEDGE_CENTRE/FETCH',
  FETCH_DATA_SUCCESS: 'KNOWLEDGE_CENTRE/SUCCESS',
  FETCH_DATA_FAIL: 'KNOWLEDGE_CENTRE/FAIL',
  UPDATE_START: 'KNOWLEDGE_CENTRE/UPDATE_START',
  UPDATE_SUCCESS: 'KNOWLEDGE_CENTRE/UPDATE_SUCCESS',
  IMAGE_UPLOAD_START: 'KNOWLEDGE_CENTRE/IMAGE_UPLOAD_START',
  IMAGE_UPLOAD_SUCCESS: 'KNOWLEDGE_CENTRE/IMAGE_UPLOAD_SUCCESS',
  REMOVE_START: 'KNOWLEDGE_CENTRE/REMOVE_START',
  REMOVE_SUCCESS: 'KNOWLEDGE_CENTRE/REMOVE_SUCCESS',
};

export const KnowledgeCentreReducer = (
  state: KnowledgeCentreState,
  action: any
): KnowledgeCentreState => {
  switch (action.type) {
    case KNOWLEDGE_CENTRE_ACTIONS.FETCH:
      return { ...state, loading: true };
    case KNOWLEDGE_CENTRE_ACTIONS.FETCH_DATA_SUCCESS:
      return {
        data: (state.data || []).concat(action.data),
        updating: false,
        loading: false,
        uploading: false,
        error: null,
      };
    case KNOWLEDGE_CENTRE_ACTIONS.FETCH_DATA_FAIL:
      return {
        data: null,
        updating: false,
        loading: false,
        uploading: false,
        error: null,
      };
    case KNOWLEDGE_CENTRE_ACTIONS.UPDATE_START:
      return { ...state, updating: true };
    case KNOWLEDGE_CENTRE_ACTIONS.UPDATE_SUCCESS:
      return {
        data: [...action.data].sort(
          (item1: KnowledgeItem, item2: KnowledgeItem) => {
            return item1.published > item2.published ? -1 : 1;
          }
        ),
        updating: false,
        loading: false,
        uploading: false,
        error: null,
      };
    case KNOWLEDGE_CENTRE_ACTIONS.IMAGE_UPLOAD_START:
      return { ...state, uploading: true };
    case KNOWLEDGE_CENTRE_ACTIONS.IMAGE_UPLOAD_SUCCESS:
      return { ...state, uploading: false, ...action.data };
    case KNOWLEDGE_CENTRE_ACTIONS.REMOVE_START:
      return { ...state, updating: true };
    case KNOWLEDGE_CENTRE_ACTIONS.REMOVE_SUCCESS:
      return { ...state, data: action.data, updating: false };
    default:
      throw new Error();
  }
};
