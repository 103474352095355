import React from 'react';
import GlossaryAddContent from '../../components/glossary/GlossaryAddContent';

interface GlossaryAddPageProps {}

const GlossaryAddPage: React.FC<GlossaryAddPageProps> = (): JSX.Element => {
  return <GlossaryAddContent />;
};

export default GlossaryAddPage;
