export type ChipStatus = 'success' | 'warning' | 'info' | 'error';
export const CHIPS = Object.freeze({
  updateSuccess: {
    label: 'Published',
    status: 'success',
  },
  updatePending: {
    label: 'Update pending',
    status: 'warning',
  },
  updateRejected: {
    label: 'Update Rejected',
    status: 'error',
  },
  validationPending: {
    label: 'Pending validation',
    status: 'info',
  },
  reviewPending: {
    label: 'Pending review',
    status: 'info',
  },
  validationErrors: {
    label: 'Validation errors',
    status: 'error',
  },
  publishErrors: {
    label: 'Publish errors',
    status: 'error',
  },
  importExists: {
    label: 'No new data',
    status: 'error',
  },
  rejected: {
    label: 'Rejected',
    status: 'error',
  },
  reverted: {
    label: 'Reverted',
    status: 'error',
  },
  scheduled: {
    label: 'Publish scheduled',
    status: 'warning',
  },
  partiallyUploaded: {
    label: 'Partially uploaded',
    status: 'warning',
  },
});
