import React from 'react';
import { Route } from 'react-router-dom';
import DirectoryContent from '../../components/directory/DirectoryContent';
import { DirectoryProvider } from '../../providers/directory';
import {
  DIRECTORY_BRANCH_ROUTE,
  DIRECTORY_PROFILE_ROUTE,
} from '../../routes/Types';
import BranchProfilePage from './BranchProfilePage';
import DirectoryProfilePage from './DirectoryProfilePage';

interface DirectoryPageProps {}

const DirectoryPage: React.FC<DirectoryPageProps> = (): JSX.Element => {
  return (
    <DirectoryProvider>
      <DirectoryContent />

      <Route path={`${DIRECTORY_PROFILE_ROUTE}/:id`}>
        <DirectoryProfilePage />
      </Route>
      <Route path={`${DIRECTORY_BRANCH_ROUTE}/:id`}>
        <BranchProfilePage />
      </Route>
    </DirectoryProvider>
  );
};

export default DirectoryPage;
