import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  Divider,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import WrappedDialog from '../common/WrappedDialog';

interface ScheduledConfirmationDialogProps {
  isDialogOpened: boolean;
  onDialogClose: () => void;
  scheduledDate?: Date | null;
}

const useStyles = makeStyles((theme) => ({
  button: {
    width: 100,
    borderRadius: 10,
    marginLeft: theme.spacing(2),
  },
  content: {
    padding: 0,
  },
  text: {
    fontFamily: 'Hero New Bold',
    fontSize: 12,
  },
  boldText: {
    fontFamily: 'Hero New ExtraBold',
    fontSize: 16,
  },
  label: {
    color: theme.palette.text.secondary,
  },
  date: {
    color: theme.palette.text.secondary,
  },
}));

const ScheduledConfirmationDialog: React.FC<
  ScheduledConfirmationDialogProps
> = ({
  isDialogOpened,
  onDialogClose,
  scheduledDate,
}: ScheduledConfirmationDialogProps): JSX.Element => {
  const classes = useStyles();

  const formatDate = (inputDate: Date) => {
    const dateObject = new Date(inputDate);

    const options: Intl.DateTimeFormatOptions = {
      weekday: 'long',
      day: 'numeric',
      month: 'long',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      timeZone: 'GMT',
    };

    const formattedDateString = dateObject.toLocaleString('en-GB', options);

    return formattedDateString;
  };

  return (
    <WrappedDialog
      open={isDialogOpened}
      handleDialogClose={onDialogClose}
      dialogTitle={'Thank you'}
    >
      <DialogContent classes={{ root: classes.content }}>
        <Divider />
        <Box mr={10} ml={6}>
          <Box mt={6} mb={6}>
            <Typography variant="body1">
              The update will be published at the following date and time:
            </Typography>
          </Box>
          {scheduledDate && (
            <Box mt={6} mb={6}>
              <Typography variant="body1" classes={{ root: classes.date }}>
                {formatDate(scheduledDate)}
              </Typography>
            </Box>
          )}
          <Divider />
        </Box>
        <Divider />
      </DialogContent>
      <DialogActions>
        <Box
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
          mt={2}
          mb={2}
          mr={8}
          ml={8}
        >
          <Box>
            <Button
              onClick={onDialogClose}
              color="secondary"
              variant="contained"
              disableElevation
              classes={{ root: classes.button }}
            >
              Close
            </Button>
          </Box>
        </Box>
      </DialogActions>
    </WrappedDialog>
  );
};

export default ScheduledConfirmationDialog;
