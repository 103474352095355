import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { GlossaryStore } from '../../providers/glossary';
import { serialize } from '../../utils/helpers/htmlSerializer.helpers';
import { GlossaryTerm } from '../../utils/types/glossary-term';
import { CustomElement } from '../common/TextEditor';
import GlossaryAddEditForm from './GlossaryAddEditForm';
import { GlossaryFormFields } from './GlossaryFormSection';

interface GlossaryEditContentProps {}

const GlossaryEditContent: React.FC<
  GlossaryEditContentProps
> = (): JSX.Element => {
  const [defaultValues, setDefaultValues] = useState<GlossaryFormFields>({
    id: 'initial_id',
    glossaryTermTitle: '',
    glossaryTermPublishDate: new Date(),
    glossaryTermPublishTime: new Date(),
    glossaryTermText: [] as CustomElement[],
  });
  const { id } = useParams<any>();
  const history = useHistory();

  const {
    state: { data },
    update,
  } = useContext(GlossaryStore);

  useEffect(() => {
    const selectedGlossaryTerm = data?.find(
      (glossaryTerm: GlossaryTerm) => glossaryTerm.id === id
    );

    if (selectedGlossaryTerm) {
      const _defaultValues = {
        id: selectedGlossaryTerm.id,
        glossaryTermTitle: selectedGlossaryTerm.title,
        glossaryTermPublishDate: selectedGlossaryTerm.created,
        glossaryTermPublishTime: selectedGlossaryTerm.created,
        glossaryTermText: selectedGlossaryTerm.rawText
          ? (selectedGlossaryTerm.rawText as CustomElement[])
          : ([
              {
                type: 'paragraph',
                children: [{ text: '' }],
              },
            ] as CustomElement[]),
      };
      setDefaultValues(_defaultValues);
    }
  }, [id, data]);

  const getGlossaryTermPublishDateTime = (date: Date, time: Date): Date => {
    const dateTime = new Date(date);
    dateTime.setHours(time.getHours());
    dateTime.setMinutes(time.getMinutes());
    return dateTime;
  };

  const handleUpdate = async (formData: GlossaryFormFields) => {
    const selectedGlossaryTerm = data?.find(
      (glossaryTerm: GlossaryTerm) => glossaryTerm.id === id
    );
    const glossaryTerm = {
      id: selectedGlossaryTerm?.id,
      title: formData.glossaryTermTitle,
      created: getGlossaryTermPublishDateTime(
        formData.glossaryTermPublishDate,
        formData.glossaryTermPublishTime
      ),
      updated: new Date(),
      rawText: formData.glossaryTermText,
      text: serialize({ children: formData.glossaryTermText }),
    };
    update(glossaryTerm);
    history.goBack();
  };

  return (
    <GlossaryAddEditForm
      defaultValues={defaultValues}
      handleUpdate={handleUpdate}
      title="Edit Glossary Term"
    />
  );
};

export default GlossaryEditContent;
