export enum EntityTypes {
  Individuals = 'individuals',
  Companies = 'companies',
  Branches = 'branches',
}

export const individualFields = [
  {
    IND_FPIND_ID: 'Record ID',
    order: 0,
  },
  {
    IND_First_Name: 'First Name',
    order: 1,
  },
  {
    IND_Last_Name: 'Last Name',
    order: 2,
  },
  {
    COM_Company_Name: 'Company',
    order: 3,
  },
  {
    IND_Sex: 'Sex',
    order: 4,
  },
  {
    IND_Job_Title: 'Job Title',
    order: 5,
  },
  {
    IND_Job_Category: 'Job Category',
    order: 6,
  },
  {
    IND_Email_Address: 'Email Address',
    order: 7,
  },
  {
    IND_Direct_Telephone: 'Direct Number',
    order: 8,
  },
  {
    IND_Responsibilities: 'Responsibilites',
    order: 9,
  },
  {
    IND_Mobile_Phone: 'Mobile Phone',
    order: 9,
  },
  {
    IND_Talk_To_Me_About: 'Talk To Me About',
    order: 10,
  },
  {
    IND_Do_Not_Talk_To_Me_About: 'Do Not Talk To Me About',
    order: 11,
  },
  {
    IND_Geographic_Specialisms: 'Geographic Specialisms',
    order: 12,
  },
  {
    IND_Committees: 'Committees',
    order: 13,
  },
  {
    IND_Key_Personnel: 'Key Personel',
    order: 14,
  },
  {
    IND_Notes: 'Notes',
    order: 15,
  },
  {
    IND_Asset_Class_Specialisms: 'Asset Class Specialisms',
    order: 16,
  },
  {
    IND_Gatekeeper: 'Gatekeeeper',
    order: 17,
  },
  {
    IND_Profile: 'Profile',
    order: 18,
  },
  {
    IND_Associations_1: 'Associations 1',
    order: 19,
  },
  {
    IND_Associations_2: 'Associations 2',
    order: 20,
  },
  {
    IND_Associations_3: 'Associations 3',
    order: 21,
  },
  {
    IND_Experience_1: 'Experience 1',
    order: 22,
  },
  {
    IND_Experience_2: 'Experience 2',
    order: 23,
  },
  {
    IND_Experience_3: 'Experience 3',
    order: 24,
  },
  {
    IND_Experience_4: 'Experience 4',
    order: 25,
  },
  {
    IND_Experience_5: 'Experience 5',
    order: 26,
  },
  {
    IND_Experience_6: 'Experience 6',
    order: 27,
  },
  {
    IND_Awards_1: 'Awards 1',
    order: 28,
  },
  {
    IND_Awards_2: 'Awards 2',
    order: 29,
  },
  {
    IND_Awards_3: 'Awards 3',
    order: 30,
  },
  {
    IND_Education_1: 'Education 1',
    order: 31,
  },
  {
    IND_Education_2: 'Education 2',
    order: 32,
  },
  {
    IND_Education_3: 'Education 3',
    order: 33,
  },
  {
    IND_Education_4: 'Education 4',
    order: 34,
  },
  {
    IND_Education_5: 'Education 5',
    order: 35,
  },
  {
    IND_Language: 'Language',
    order: 36,
  },
  {
    IND_Twitter_Profile: 'Twitter Profile',
    order: 37,
  },
  {
    IND_Facebook_Profile: 'Facebook Profile',
    order: 38,
  },
  {
    IND_Linkedin_Profile: 'Linkedin Profile',
    order: 39,
  },
  {
    IND_Subscribe_To_Fundpath_Marketing_Email:
      'Subscribe To Fundpath Marketing Email',
    order: 40,
  },
  {
    IND_Qualification_1: 'Qualification 1',
    order: 41,
  },
  {
    IND_Qualification_2: 'Qualification 2',
    order: 42,
  },
  {
    IND_Qualification_3: 'Qualification 3',
    order: 43,
  },
  {
    IND_Qualification_4: 'Qualification 4',
    order: 44,
  },
  {
    IND_Qualification_5: 'Qualification 5',
    order: 45,
  },
  {
    IND_Talk_To_Me_About_Update_Date:
      "Last date 'Talk to me about' ( do not) updated",
    order: 46,
  },
  {
    IND_Date_The_Record_Was_Updated: 'Record last updated',
    order: 47,
  },
  {
    IND_Record_Is_Live: 'Record Is Live',
    order: 48,
  },
  {
    IND_What_Level_Of_Engagement_with_Fundpath:
      'What Level Of Engagement with Fundpath?',
    order: 49,
  },
  {
    IND_Interest_Survey: 'Send Interest Survey',
    order: 50,
  },
  {
    ADMIN_Person_Who_Updated_Record: 'Person Who Updated The Record',
    order: 51,
  },
  {
    IND_Date_Last_Contacted: 'Date Last Contacted ',
    order: 52,
  },
  {
    IND_Manual_Priority: 'Manual Priority',
    order: 53,
  },
  {
    IND_Record_Status: 'Record Status',
    order: 54,
  },
  {
    IND_Profile_Source: 'Profile Source',
    order: 55,
  },
  {
    IND_Subscribe_To_Status_Updates_Email: 'Subscribe To Status Updates Email',
    order: 56,
  },
  {
    IND_Frequency_Of_Communication: 'Frequency Of Communication',
    order: 57,
  },
  {
    IND_Subscribe_To_3rd_Party_Marketing_Email:
      'Subscribe To 3rd Party Marketing Email',
    order: 58,
  },
  {
    IND_Subscribe_To_Jobs_Email: 'Subscribe To Jobs Email',
    order: 59,
  },
  {
    IND_Subscribe_To_Survey_Email: 'Subscribe To Survey Email',
    order: 60,
  },
  {
    IND_Preferred_Methods_Of_Communication:
      'Preferred Methods Of Communication',
    order: 61,
  },
  {
    IND_Auto_Priority: 'Auto Priority',
    order: 62,
  },
];

export const companyFields = [
  {
    COM_Track_Record_Preferences: 'Track Record Preferences',
    order: -1,
  },
  {
    COM_FPCOM_ID: 'Company Record ID',
    order: 0,
  },
  {
    COM_Buy_List_Preferences_Reasons: '',
    order: -1,
  },
  {
    COM_New_Funds_Preferences_Reasons: '',
    order: -1,
  },
  {
    COM_New_Funds_Preferences_Seeding: '',
    order: -1,
  },
  {
    COM_Company_Name: 'Company Name',
    order: 2,
  },
  {
    COM_Company_Type: 'Company Type',
    order: 3,
  },
  {
    COM_Website: 'Website',
    order: 12,
  },
  {
    COM_Number_of_Employees: 'Number of employees',
    order: 13,
  },
  {
    COM_AuM: 'Company AuM',
    order: 15,
  },
  {
    COM_Company_Profile: 'Company profile',
    order: 16,
  },
  {
    COM_AuM_3rd_Party_Funds: 'AuM 3rd Party Funds',
    order: 25,
  },
  {
    COM_Fund_Platforms: 'Fund Platform(s) Used',
    order: 27,
  },
  {
    COM_DFMs_Use: 'Do they use DFMs',
    order: 29,
  },
  {
    COM_DFMS_Offered: 'DFM(s) offered',
    order: 30,
  },
  {
    COM_BuyList_Total_Number:
      'BuyList Breakdown: Total number of funds on the buylist',
    order: 32,
  },
  {
    COM_BuyList_Active_Managed: 'BuyList Breakdown: Active managed',
    order: 33,
  },
  {
    COM_BuyList_Investment_Trusts:
      'BuyList Breakdown: Investment trusts/ closed-ended funds',
    order: 34,
  },
  {
    COM_BuyList_Passive: 'BuyList Breakdown: Passively managed unitised funds',
    order: 35,
  },
  {
    COM_BuyList_ETFs: 'BuyList Breakdown: ETFs',
    order: 36,
  },
  {
    COM_BuyList_Smart_Beta: 'BuyList Breakdown: Smart beta',
    order: 37,
  },
  {
    COM_BuyList_Alternative_Ucits: 'BuyList Breakdown: Alternative Ucits',
    order: 38,
  },
  {
    COM_BuyList_Alternatives: 'BuyList Breakdown: Alternatives',
    order: 39,
  },
  {
    COM_BuyList_Segregated_Manadate: 'BuyList Breakdown: Segregated mandates',
    order: 40,
  },
  {
    COM_BuyList_Sub_Advisory: 'BuyList Breakdown: Sub-advisory arrangements',
    order: 41,
  },
  {
    COM_3rd_Party_Employ_Client_Portfolios:
      '3rd Party Employ Client Portfolios',
    order: 42,
  },
  {
    COM_3rd_Party_Employ_FoF: '3rd Party Employ FoF',
    order: 43,
  },
  {
    COM_3rd_Party_Employ_Model_Portfolios: '3rd Party Employ Model Portfolios',
    order: 44,
  },
  {
    COM_3rd_Party_Employ_Multi_Asset: '3rd Party Employ Multi Asset',
    order: 45,
  },
  {
    COM_Typical_Fund_Approval_Process: 'Typical Fund Approval Process',
    order: 47,
  },
  {
    COM_Asset_Allocation_Meeting_Frequency:
      'Asset Allocation Meeting Frequency',
    order: 48,
  },
  {
    COM_Investment_Committee_Meeting_Frequency:
      'Investment Committee Meeting Frequency',
    order: 49,
  },
  {
    COM_Fund_Review_Frequency: 'Fund Review Frequency',
    order: 50,
  },
  {
    COM_Buy_List_Preferences: 'Buy List Preferences',
    order: 51,
  },
  {
    COM_Track_Record_Preferences_Reasons: 'Track Record Preferences Reasons',
    order: 52,
  },
  {
    COM_New_Funds_Preferences: 'New Funds Preferences',
    order: 53,
  },
  {
    COM_Minimum_Fund_Size_Preferences: 'Minimum Fund Size Preferences',
    order: 54,
  },
  {
    COM_Maximum_Ownership_Limit_Preferences:
      'Maximum Ownership Limit Preferences',
    order: 55,
  },
  {
    COM_Allocation_Forecast_Item_1: 'COM Allocation Forecast Item 1 Equity: UK',
    order: 56,
  },
  {
    COM_Allocation_Forecast_Item_2:
      'COM Allocation Forecast Item 2 Equity: UK Income',
    order: 57,
  },
  {
    COM_Allocation_Forecast_Item_3:
      'COM Allocation Forecast Item 3 Equity: Europe ex UK',
    order: 58,
  },
  {
    COM_Allocation_Forecast_Item_4:
      'COM Allocation Forecast Item 4 Equity: Pan Europe',
    order: 59,
  },
  {
    COM_Allocation_Forecast_Item_5: 'COM Allocation Forecast Item 5 Equity: US',
    order: 60,
  },
  {
    COM_Allocation_Forecast_Item_6:
      'COM Allocation Forecast Item 6 Equity: Japan',
    order: 61,
  },
  {
    COM_Allocation_Forecast_Item_7:
      'COM Allocation Forecast Item 7 Equity: Asian',
    order: 62,
  },
  {
    COM_Allocation_Forecast_Item_8:
      'COM Allocation Forecast Item 8 Equity: GEM',
    order: 63,
  },
  {
    COM_Allocation_Forecast_Item_9:
      'COM Allocation Forecast Item 9 Equity: Global',
    order: 64,
  },
  {
    COM_Allocation_Forecast_Item_10:
      'COM Allocation Forecast Item 10 Equity: Thematic',
    order: 65,
  },
  {
    COM_Allocation_Forecast_Item_11:
      'COM Allocation Forecast Item 11 Fixed Income: UK',
    order: 66,
  },
  {
    COM_Allocation_Forecast_Item_12:
      'COM Allocation Forecast Item 12 Fixed Income: GEM',
    order: 67,
  },
  {
    COM_Allocation_Forecast_Item_13:
      'COM Allocation Forecast Item 13 Fixed Income: Global',
    order: 68,
  },
  {
    COM_Allocation_Forecast_Item_14:
      'COM Allocation Forecast Item 14 Fixed Income: High Yield ',
    order: 69,
  },
  {
    COM_Allocation_Forecast_Item_15:
      'COM Allocation Forecast Item 15 Alternatives: L/S Equity',
    order: 70,
  },
  {
    COM_Allocation_Forecast_Item_16:
      'COM Allocation Forecast Item 16 Alternatives: Multi-Strategy ',
    order: 71,
  },
  {
    COM_Allocation_Forecast_Item_17:
      'COM Allocation Forecast Item 17 Private Markets: Private Equity',
    order: 72,
  },
  {
    COM_Allocation_Forecast_Item_18:
      'COM Allocation Forecast Item 18 Private Markets: Private Debt',
    order: 73,
  },
  {
    COM_Allocation_Forecast_Item_19:
      'COM Allocation Forecast Item 19 Infrastructure',
    order: 74,
  },
  {
    COM_Allocation_Forecast_Item_20:
      'COM Allocation Forecast Item 20 Real Estate',
    order: 75,
  },
  {
    COM_Last_Date_of_Allocation_Update:
      'Allocation Forecast: Date last updated',
    order: 76,
  },
  {
    COM_Linkedin_Profile: 'Company Linkedin Profile',
    order: 91,
  },
  {
    COM_Twitter_Profile: 'Company Twitter Profile',
    order: 92,
  },
  {
    COM_Facebook_Profile: 'Company Facebook Profile',
    order: 93,
  },
  {
    COM_Date_The_Record_Was_Updated: 'Date The Record Was Updated',
    order: 96,
  },
  {
    COM_Interest_Survey: 'Send Interest Survey',
    order: 98,
  },
  {
    COM_Notes: 'Notes',
    order: 100,
  },
  {
    COM_Level_Of_Engagement_with_Fundpath: 'Level Of Engagement with Fundpath',
    order: 101,
  },
  {
    COM_Organisation_Category: 'Organisation Category',
    order: 106,
  },
  {
    COM_Company_Also_Known_as_1: 'Company Also Known as 1',
    order: 107,
  },
  {
    COM_Company_Also_Known_as_2: 'Company Also Known as 2',
    order: 108,
  },
  {
    COM_Company_Also_Known_as_3: 'Company Also Known as 3',
    order: 109,
  },
  {
    COM_Is_the_Firm_on_Fundpath_Research_Panel:
      'Is the Firm on Fundpath Research Panel?',
    order: 110,
  },
  {
    COM_Company_Short_Code: 'Company Short Code',
    order: 111,
  },
];

export const branchFields = [
  {
    BRA_FPBRA_ID: 'Record Id',
    order: 1,
  },
  {
    BRA_Office_Type: 'Office type',
    order: 2,
  },
  {
    BRA_Address_1: 'Address 1',
    order: 3,
  },
  {
    BRA_Address_2: 'Address 2',
    order: 4,
  },
  {
    BRA_Address_3: 'Address 3',
    order: 5,
  },
  {
    BRA_Address_City: 'City',
    order: 6,
  },
  {
    BRA_Postcode: 'Postcode',
    order: 7,
  },
  {
    BRA_Address_Country: 'Country',
    order: 8,
  },
  {
    BRA_Address_Region: 'Region',
    order: 9,
  },
  {
    BRA_Switchboard_Telephone: 'Switchboard Telephone',
    order: 10,
  },
  {
    BRA_AuM: 'AuM',
    order: 11,
  },
  {
    BRA_AuM_Change: 'AuM Change',
    order: 12,
  },
  {
    BRA_Date_The_Record_Was_Updated: 'Date updated',
    order: 13,
  },
];
