import { Box, MenuItem, Select, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { Control, Controller } from 'react-hook-form';
import {
  ARTICLE_TARGET_OPTIONS,
  ArticleTarget,
} from '../../utils/constants/articles.constants';
import { ArticlesFormFields } from './ArticlesFormSection';

interface ArticlesTargetSelectProps {
  control: Control<ArticlesFormFields>;
  //setTileSize: (tileSize: ArticleTileSize) => void;
}

const useStyles = makeStyles(() => ({
  input: {
    width: 287,
  },
  select: {
    paddingLeft: 0,
  },
}));

const ArticlesTargetSelect = ({
  control,
}: //setTileSize,
ArticlesTargetSelectProps): JSX.Element => {
  const classes = useStyles();

  return (
    <Box mt={10}>
      <Typography variant="subtitle1" align="left">
        Article target
      </Typography>
      <Box textAlign="left">
        <Controller
          name={'articleTarget'}
          control={control}
          render={({ field: { onChange, value } }) => (
            <Select
              value={value}
              onChange={(e: React.ChangeEvent<{ value: unknown }>) => {
                //setTileSize(e.target.value as ArticleTileSize);
                onChange(e.target.value as ArticleTarget);
              }}
              className={classes.input}
              classes={{ select: classes.select }}
              MenuProps={{
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left',
                },
                getContentAnchorEl: null,
              }}
            >
              {ARTICLE_TARGET_OPTIONS.map((option, i) => (
                <MenuItem key={i} value={option.value}>
                  {option.name}
                </MenuItem>
              ))}
            </Select>
          )}
        />
      </Box>
    </Box>
  );
};

export default ArticlesTargetSelect;
