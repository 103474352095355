import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { TabContext, TabPanel } from '@material-ui/lab';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useCallback, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useOrganisation, useOrganisations } from '../../hooks/services';
import { useUserRoleRestrictions } from '../../hooks/services/useUserRoleRestrictions';
import {
  ORGANISATIONS_ADMIN_ROUTE,
  ORGANISATIONS_EDIT_ROUTE,
  ORGANISATIONS_VIEW_ROUTE,
} from '../../routes/Types';
import { LAYOUT } from '../../utils/constants/layout.constants';
import { UserRole } from '../../utils/types/user';
import Title from '../common/Title';
import TopPannel from '../common/TopPannel';
import InvitesView from '../users/InvitesView';
import UsersView from '../users/UsersView';

const useStyles = makeStyles((theme) => ({
  content: {
    marginTop: 64,
    background: theme.palette.background.paper,
    [theme.breakpoints.up('md')]: {
      marginTop: LAYOUT.TopPanelHeight + LAYOUT.TopPanelBottomMargin,
      width: `calc(100% - ${LAYOUT.DrawerWidth}px)`,
      marginLeft: LAYOUT.DrawerWidth,
    },
  },
  title: {
    textTransform: 'capitalize',
  },
  icon: {
    paddingRight: theme.spacing(2),
  },
  button: {
    height: 40,
    borderRadius: 10,
    justifyContent: 'flex-start',
    paddingTop: theme.spacing(3),
    paddingLeft: theme.spacing(4),
    paddingBottom: theme.spacing(3),
    paddingRight: theme.spacing(4),
  },
  errorMessage: {
    height: theme.spacing(6),
  },
  dropdown: {
    width: '100%',
    maxWidth: 410,
    padding: `${theme.spacing(4)}px ${theme.spacing(4)}px`,
    backgroundColor: theme.palette.background.default,
    borderRadius: 8,
  },
  titleWrapper: {
    width: '100%',
    maxWidth: 440,
  },
  underline: {
    '&:before': {
      borderBottom: `1px solid ${theme.palette.primary.main}`,
    },
  },
  inputRoot: {
    '& .MuiAutocomplete-endAdornment button:last-child .MuiSvgIcon-root': {
      fill: `${theme.palette.primary.main}`,
    },
  },
  itemOrder: {
    order: 1,
    height: '480px',
    [theme.breakpoints.up('md')]: {
      order: 'initial',
      height: 'auto',
    },
  },
  tabBar: {
    backgroundColor: theme.palette.primary.main,
  },
  tabPanel: {
    padding: 0,
  },
  tabButton: {
    minWidth: 'auto',
    fontSize: theme.spacing(4),
    marginRight: theme.spacing(6),
    padding: theme.spacing(0),
  },
  divider: {
    marginTop: theme.spacing(6),
  },
}));

const OrganisationViewContent = () => {
  const classes = useStyles();
  const theme = useTheme();
  const matchesMediaQuery = useMediaQuery(theme.breakpoints.up('md'), {
    noSsr: true,
  });
  const { isUserRole: isUserAdmin } = useUserRoleRestrictions(
    UserRole.SuperAdmin
  );
  const [selectedTab, setSelectedTab] = useState('0');
  const history = useHistory();
  const { orgId } = useParams<{ orgId: string }>();
  const { organisation, isLoading } = useOrganisation();
  const { organisations } = useOrganisations();

  const onTabChange = useCallback((evt, val) => {
    setSelectedTab(val);
  }, []);

  const handleRedirectLinkClick = () => {
    history.push(`${ORGANISATIONS_ADMIN_ROUTE}`);
  };

  const handleEditOrganisationClick = () => {
    history.push(ORGANISATIONS_EDIT_ROUTE.replace(':orgId', orgId));
  };

  const handleAutocompleteChange = (event: any, newValue: any) => {
    if (newValue) {
      history.push(
        ORGANISATIONS_VIEW_ROUTE.replace(':orgId', newValue.id.toString())
      );
    }
  };

  return (
    <Box className={classes.content}>
      {matchesMediaQuery ? (
        <TopPannel>
          <Title
            onRedirectLinkClick={handleRedirectLinkClick}
            pageName={'Back to all organisations'}
          />
        </TopPannel>
      ) : (
        <Box textAlign="left" mr={4} ml={4}>
          <Title
            onRedirectLinkClick={handleRedirectLinkClick}
            pageName={'Back to all organisations'}
          />
        </Box>
      )}

      <Box
        ml={{ xs: 4, sm: 4, md: 4, lg: 10 }}
        mr={{ xs: 4, sm: 4, md: 4, lg: 20 }}
        mb={10}
      >
        <Box
          display={'flex'}
          gridGap={18}
          mt={7}
          justifyContent={'space-between'}
          alignItems={'center'}
          flexDirection={matchesMediaQuery ? 'row' : 'column'}
        >
          <Box>
            <Box>{isLoading && <CircularProgress />}</Box>
            {organisation?.name && (
              <Box display={'flex'} gridGap={18} flexWrap={'wrap'}>
                <Typography
                  variant="h4"
                  color="textPrimary"
                  align="left"
                  classes={{ root: classes.title }}
                >
                  {organisation?.name}
                </Typography>
                <Button
                  color="secondary"
                  disabled={isLoading || !isUserAdmin}
                  variant="contained"
                  disableElevation
                  onClick={handleEditOrganisationClick}
                  classes={{ root: classes.button }}
                >
                  Edit Details
                </Button>
              </Box>
            )}
          </Box>
          <Box className={classes.dropdown}>
            <Typography variant="subtitle1" align="left">
              View another organisation
            </Typography>
            <Autocomplete
              options={organisations || []}
              getOptionLabel={(option) => option.name}
              onChange={handleAutocompleteChange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder={organisation?.name || 'Select an organisation'}
                  InputProps={{
                    ...params.InputProps,
                    classes: { root: classes.inputRoot },
                  }}
                  variant="standard"
                />
              )}
              classes={{ inputRoot: classes.underline }}
            />
          </Box>
        </Box>
        <Grid container direction="row">
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            className={classes.itemOrder}
          >
            <TabContext value={selectedTab}>
              <Tabs
                value={selectedTab}
                onChange={onTabChange}
                aria-label="basic tabs example"
                classes={{ indicator: classes.tabBar }}
              >
                <Tab
                  label="Invites"
                  value="0"
                  classes={{ root: classes.tabButton }}
                />
                <Tab
                  label="Users"
                  value="1"
                  classes={{ root: classes.tabButton }}
                />
              </Tabs>
              <TabPanel
                value={'0'}
                classes={{
                  root: classes.tabPanel,
                }}
              >
                <Divider className={classes.divider} />
                <Box mt={8} height={matchesMediaQuery ? '100%' : '100vh'}>
                  <InvitesView />
                </Box>
              </TabPanel>
              <TabPanel
                value={'1'}
                classes={{
                  root: classes.tabPanel,
                }}
              >
                <Divider className={classes.divider} />
                <Box mt={8} height={matchesMediaQuery ? '100%' : '100vh'}>
                  <UsersView />
                </Box>
              </TabPanel>
            </TabContext>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default OrganisationViewContent;
