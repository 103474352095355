import { Box, Link, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { ALPHABET_LETTERS } from '../../utils/constants/alphabetLetters.constants';

interface DirectoryLettersProps {
  handleLetterClick: (letter: string) => void;
  selectedLetter?: string;
}
const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: '100%',
    flexWrap: 'wrap',
    [theme.breakpoints.up('md')]: {
      maxWidth: 795,
    },
  },
  letterWrapper: {
    width: `calc((100% - 16px) / 9)`,
    [theme.breakpoints.up('md')]: {
      width: 'auto',
    },
  },
  selectedLetter: {
    fontWeight: 'bold',
  },
}));

const DirectoryLetters: React.FC<DirectoryLettersProps> = ({
  handleLetterClick,
  selectedLetter,
}: DirectoryLettersProps): JSX.Element => {
  const classes = useStyles();

  return (
    <Box display="flex" justifyContent="space-around" className={classes.root}>
      {ALPHABET_LETTERS.map((letter) => (
        <Box className={classes.letterWrapper} key={`box-${letter}`}>
          <Link
            key={letter}
            component="button"
            onClick={() => handleLetterClick(letter)}
          >
            <Typography
              className={
                selectedLetter === letter ? classes.selectedLetter : undefined
              }
            >
              {letter}
            </Typography>
          </Link>
        </Box>
      ))}
    </Box>
  );
};

export default DirectoryLetters;
