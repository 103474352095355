import MuiListItem from '@material-ui/core/ListItem';
import { Theme, withStyles } from '@material-ui/core/styles';

const styles = (theme: Theme) => ({
  root: {
    color: '#000',
    borderRadius: '8px',
    '& .MuiListItemIcon-root': {
      color: '#000',
    },
    '&$selected, &$selected:hover': {
      backgroundColor: '#ff3c00',
      color: 'white',
      borderRadius: '8px',
      '& .MuiListItemIcon-root': {
        color: 'white',
      },
    },
    [theme.breakpoints.up('sm')]: {
      '&:hover': {
        backgroundColor: 'white',
        borderRadius: '8px',
        color: '#ff3c00',
        '& .MuiListItemIcon-root': {
          color: '#ff3c00',
        },
      },
    },
  },
  selected: {},
});

const StyledListItem = withStyles(styles, { withTheme: true })(MuiListItem);

export default StyledListItem;
