import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { ArticlesStore } from '../../providers/articles';
import {
  ArticleTarget,
  ArticleTileSize,
} from '../../utils/constants/articles.constants';
import { serialize } from '../../utils/helpers/htmlSerializer.helpers';
import { Article } from '../../utils/types/article';
import { CustomElement } from '../common/TextEditor';
import ArticlesAddEditForm from './ArticlesAddEditForm';
import { ArticlesFormFields } from './ArticlesFormSection';

interface ArticlesEditContentProps {}

const ArticlesEditContent: React.FC<
  ArticlesEditContentProps
> = (): JSX.Element => {
  const [defaultValues, setDefaultValues] = useState<ArticlesFormFields>({
    id: 'initial_id',
    articleTitle: '',
    articleExtract: '',
    articlePublishDate: new Date(),
    articlePublishTime: new Date(),
    articleText: [] as CustomElement[],
    articleTileSize: ArticleTileSize.Large,
    articleTarget: ArticleTarget.Go,
  });
  const { id } = useParams<any>();
  const history = useHistory();

  const {
    state: { data },
    update,
  } = useContext(ArticlesStore);

  useEffect(() => {
    const selectedArticle = data?.find((article: Article) => article.id === id);
    if (selectedArticle) {
      let _defaultValues: ArticlesFormFields = {
        id: selectedArticle.id,
        articleTitle: selectedArticle.title,
        articleExtract: selectedArticle.extract,
        articlePublishDate: selectedArticle.published,
        articlePublishTime: selectedArticle.published,
        articleText: selectedArticle.rawText
          ? (selectedArticle.rawText as CustomElement[])
          : ([
              {
                type: 'paragraph',
                children: [{ text: '' }],
              },
            ] as CustomElement[]),
        articleTileSize: selectedArticle.tileSize || ArticleTileSize.Large,
        articleTarget: selectedArticle.target || ArticleTarget.Go,
      };
      if (
        selectedArticle.image &&
        selectedArticle.brightness &&
        selectedArticle.ratio
      ) {
        _defaultValues.articleImageInfo = {
          storageUrl: selectedArticle.image,
          brightness: selectedArticle.brightness,
          aspectRatio: selectedArticle.ratio,
        };
      }
      setDefaultValues(_defaultValues);
    }
  }, [id, data]);

  const getArticlesPublishDateTime = (date: Date, time: Date): Date => {
    const dateTime = new Date(date);
    dateTime.setHours(time.getHours());
    dateTime.setMinutes(time.getMinutes());
    return dateTime;
  };

  const handleUpdate = async (formData: ArticlesFormFields) => {
    const selectedArticle = data?.find((article: Article) => article.id === id);
    const article = {
      id: selectedArticle?.id,
      title: formData.articleTitle,
      image: selectedArticle?.image || '',
      published: getArticlesPublishDateTime(
        formData.articlePublishDate,
        formData.articlePublishTime
      ),
      extract: formData.articleExtract,
      rawText: formData.articleText,
      text: serialize({ children: formData.articleText }),
      brightness: formData.articleImageInfo?.brightness || 0.99,
      ratio: formData.articleImageInfo?.aspectRatio || 0.99,
      tileSize: formData.articleTileSize,
      target: formData.articleTarget,
    };
    update(article);
    history.goBack();
  };

  return (
    <ArticlesAddEditForm
      defaultValues={defaultValues}
      handleUpdate={handleUpdate}
      title="Edit Article"
    />
  );
};

export default ArticlesEditContent;
