import {
  Box,
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  Divider,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import WrappedDialog from '../common/WrappedDialog';

interface AppropveDialogProps {
  isDialogOpened: boolean;
  publishTypeValue: string;
  onDialogClose: () => void;
  onPublishUpdates: () => void;
  onPublishTypeChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  loading: boolean;
  title: string;
  date: Date;
  user: string;
}

const useStyles = makeStyles((theme) => ({
  button: {
    width: 100,
    borderRadius: 10,
    marginLeft: theme.spacing(2),
  },
  content: {
    padding: 0,
  },
  text: {
    fontFamily: 'Hero New Bold',
    fontSize: 12,
  },
  boldText: {
    fontFamily: 'Hero New ExtraBold',
    fontSize: 16,
  },
  label: {
    color: theme.palette.text.secondary,
  },
}));

const ApproveDialog: React.FC<AppropveDialogProps> = ({
  isDialogOpened,
  onDialogClose,
  onPublishUpdates,
  onPublishTypeChange,
  publishTypeValue,
  title,
  date,
  user,
  loading,
}: AppropveDialogProps): JSX.Element => {
  const classes = useStyles();
  return (
    <WrappedDialog
      open={isDialogOpened}
      handleDialogClose={onDialogClose}
      dialogTitle={'Publish updates'}
    >
      <DialogContent classes={{ root: classes.content }}>
        <Divider />
        <Box mr={10} ml={10}>
          <Box mt={6} mb={6}>
            <Typography variant="caption" className={classes.text}>
              File
            </Typography>
            <Typography
              variant="caption"
              className={classes.text}
              color="primary"
            >
              {` ${title}`}
            </Typography>
            <Typography variant="caption" color="textSecondary">
              {' '}
              Updated {date?.toLocaleString()} by {user}
            </Typography>
          </Box>
          <Divider />
          {!loading && (
            <Box mt={10} mb={6}>
              <Typography variant="h6">Publish type</Typography>
              <Box mt={4} mb={10}>
                <RadioGroup
                  row
                  aria-label="publish type"
                  name="publish type-radio-buttons"
                  value={publishTypeValue}
                  onChange={onPublishTypeChange}
                >
                  <FormControlLabel
                    value="1"
                    control={<Radio color="primary" />}
                    label="Generate tile(s)"
                    classes={{ label: classes.label }}
                  />
                  <FormControlLabel
                    value="2"
                    control={<Radio color="primary" />}
                    label="Don’t generate tile(s)"
                    classes={{ label: classes.label }}
                  />
                </RadioGroup>
              </Box>
              <Box>
                <Typography variant="body1" component="span">
                  Are you sure you want to
                </Typography>
                <Typography
                  variant="body1"
                  component="span"
                  className={classes.boldText}
                >
                  {' '}
                  publish{' '}
                </Typography>
                <Typography variant="body1" component="span">
                  the above update?
                </Typography>
              </Box>
            </Box>
          )}
          {loading && (
            <Box
              display={'flex'}
              alignItems={'center'}
              justifyContent={'center'}
              mt={8}
              mb={8}
            >
              <CircularProgress />
            </Box>
          )}
        </Box>
        <Divider />
      </DialogContent>
      <DialogActions>
        <Box
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
          mt={2}
          mb={2}
          mr={8}
          ml={8}
        >
          <Box>
            <Button
              disabled={loading}
              onClick={onDialogClose}
              color="secondary"
              variant="contained"
              disableElevation
              classes={{ root: classes.button }}
            >
              Cancel
            </Button>
          </Box>
          <Box>
            <Button
              disabled={loading}
              onClick={onPublishUpdates}
              color="secondary"
              variant="contained"
              disableElevation
              classes={{ root: classes.button }}
            >
              Publish
            </Button>
          </Box>
        </Box>
      </DialogActions>
    </WrappedDialog>
  );
};

export default ApproveDialog;
