import React, { useCallback, useEffect, useRef } from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';
import { DirectoryListItem } from '../../services/directory';
import {
  CHIPS,
  ChipStatus,
} from '../../utils/constants/chipStatuses.constants';
import { LAYOUT } from '../../utils/constants/layout.constants';
import StatusChip from '../common/StatusChip';
import VirtualizedListItem from '../common/VirtualizedListItem';
import DirectoryListItemContent from './DirectoryListItemContent';

interface DirectoryListProps {
  directoryList: DirectoryListItem[];
  letterEvent: { letter: string };
}

interface ListRowProps {
  style: any;
  index: number;
  data: DirectoryListItem[];
}

const Row = ({ style, index, data }: ListRowProps) => {
  const { updateStatus, name, logo, role, id } = data[index] || {};
  return (
    <VirtualizedListItem
      style={{
        ...style,
        width: 'calc(100% - 40px)',
        marginLeft: 20,
        marginRight: 20,
        marginTop: 10,
      }}
    >
      <DirectoryListItemContent name={name} logo={logo} role={role} id={id}>
        {updateStatus && (
          <StatusChip
            label={CHIPS[updateStatus].label}
            variant={CHIPS[updateStatus].status as ChipStatus}
          />
        )}
      </DirectoryListItemContent>
    </VirtualizedListItem>
  );
};

const DirectoryList: React.FC<DirectoryListProps> = ({
  directoryList,
  letterEvent,
}): JSX.Element => {
  const listRef = useRef<FixedSizeList>(null);

  const searchDirectoryListItemByLetter = (
    directoryList: DirectoryListItem[],
    selectedLetter: string
  ): number => {
    if (selectedLetter === '#') {
      return 0;
    }
    return directoryList.findIndex(
      (directoryListItem: DirectoryListItem) =>
        directoryListItem.name.charAt(0) >= selectedLetter
    );
  };

  useEffect(() => {
    const elementIndex = searchDirectoryListItemByLetter(
      directoryList,
      letterEvent.letter
    );
    const current = listRef.current;
    if (elementIndex >= 0 && current) {
      current.scrollToItem(elementIndex, 'start');
    }
  }, [letterEvent, directoryList]);

  const loadMoreItems = useCallback(() => {
    //TODO: dynamic loading is not available yet
    //console.log("load more");
  }, []);

  const isItemLoaded = useCallback((_index: number) => {
    //TODO: dynamic loading is not available yet
    //console.log(index);
    return false;
  }, []);

  return (
    <AutoSizer>
      {({ height, width }: any) => (
        <InfiniteLoader
          isItemLoaded={isItemLoaded}
          itemCount={directoryList.length}
          loadMoreItems={loadMoreItems}
        >
          {({ onItemsRendered }) => (
            <FixedSizeList
              onItemsRendered={onItemsRendered}
              height={height}
              itemCount={directoryList.length}
              itemSize={LAYOUT.VirtuilizedListItemHeight}
              width={width}
              itemData={directoryList}
              ref={listRef}
            >
              {Row}
            </FixedSizeList>
          )}
        </InfiniteLoader>
      )}
    </AutoSizer>
  );
};

export default DirectoryList;
