import React from 'react';
import { RenderLeafProps } from 'slate-react';

const Leaf: React.FC<RenderLeafProps> = ({
  attributes,
  children,
  leaf,
}: any): JSX.Element => {
  if (leaf.bold) {
    children = <strong>{children}</strong>;
  }

  if (leaf.italic) {
    children = <em>{children}</em>;
  }

  if (leaf.underline) {
    children = <u>{children}</u>;
  }

  return <span {...attributes}>{children}</span>;
};

export default Leaf;
