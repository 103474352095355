import { makeStyles } from '@material-ui/core/styles';

interface Props {}
const useStyles = makeStyles({
  informativeSpan: {
    display: 'flex',
    fontSize: 12,
    fontFamily: 'Hero New Regular',
    fontWeight: 400,
    lineHeight: 1.66,
    marginTop: 20,
    color: 'rgba(33, 43, 53, 0.6)',
  },
});

const InformativeSpan = (props: Props) => {
  const { ...other } = props;
  const classes = useStyles(props);
  return <span className={classes.informativeSpan} {...other} />;
};

export default InformativeSpan;
