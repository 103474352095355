import React from 'react';
import DirectoryProfileViewContent from '../../components/directory-profile/DirectoryProfileViewContent';

interface DirectoryProfilePageProps {}

const DirectoryProfilePage: React.FC<
  DirectoryProfilePageProps
> = (): JSX.Element => {
  return <DirectoryProfileViewContent />;
};

export default DirectoryProfilePage;
