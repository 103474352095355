import SvgIcon from '@material-ui/core/SvgIcon';
import * as ROUTES from '../../routes/Types';
import * as icons from '../helpers/navIcons.helpers';

export type PageName =
  | 'Directory'
  | 'Upload Data'
  | 'Delete Profiles'
  | 'User Administration'
  | 'Publish'
  | 'Articles'
  | 'System'
  | 'Usage'
  | 'Glossary'
  | 'Tooltips'
  | 'Knowledge Centre';

export interface NavItem {
  title: string;
  type: PageName;
  icon: typeof SvgIcon;
  route: string;
  role?: string;
  divider?: boolean;
}

const NAV_ITEMS: NavItem[] = [
  {
    title: 'User Administration',
    type: 'User Administration',
    icon: icons.UserAdministration,
    route: ROUTES.ORGANISATIONS_ADMIN_ROUTE,
    role: 'superadmin',
  },
  // {
  //   title: 'Usage',
  //   type: 'Usage',
  //   icon: icons.Articles,
  //   route: ROUTES.USAGE_ROUTE,
  //   divider: true,
  // },
  {
    title: 'Directory',
    type: 'Directory',
    icon: icons.Directory,
    route: ROUTES.DIRECTORY_ROUTE,
    divider: true,
  },
  {
    title: 'Upload Data',
    type: 'Upload Data',
    icon: icons.UploadData,
    route: ROUTES.UPLOAD_DATA_ROUTE,
  },
  {
    title: 'Publish',
    type: 'Publish',
    icon: icons.Publish,
    route: ROUTES.UPDATES_ROUTE,
    role: 'superadmin',
  },
  {
    title: 'Delete Profiles',
    type: 'Delete Profiles',
    icon: icons.UploadData,
    route: ROUTES.DELETE_PROFILES_ROUTE,
    divider: true,
  },
  {
    title: 'Articles',
    type: 'Articles',
    icon: icons.Articles,
    route: ROUTES.ARTICLES_ROUTE,
    divider: true,
  },
  {
    title: 'Knowledge Centre',
    type: 'Knowledge Centre',
    icon: icons.Articles,
    route: ROUTES.KNOWLEDGE_CENTRE_ROUTE,
  },
  {
    title: 'Glossary',
    type: 'Glossary',
    icon: icons.Articles,
    route: ROUTES.GLOSSARY_ROUTE,
  },
  {
    title: 'Tooltips',
    type: 'Tooltips',
    icon: icons.Articles,
    route: ROUTES.TOOLTIPS_ROUTE,
  },

  /*{
    title: "System",
    type: "System",
    icon: icons.Articles,
    route: ROUTES.SYSTEM_ROUTE
  }*/
];

export default NAV_ITEMS;
