import {
  Box,
  Divider,
  List,
  ListItemIcon,
  ListItemText,
  SvgIcon,
  Typography,
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import React, { useContext, useState } from 'react';
import { useLocation } from 'react-router-dom';
import packageJson from '../../../package.json';
import { ReactComponent as LogoutIcon } from '../../assets/logout.svg';
import { AuthStore } from '../../providers/auth';
import NAV_ITEMS, { NavItem } from '../../utils/constants/navigation.constants';
import ListItem from './StyledListItem';

interface NavigationProps {
  onMenuItemClick: Function;
  logout: Function;
}

const useStyles = makeStyles((theme) => ({
  list: {
    margin: theme.spacing(2),
  },
  listIcon: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  listItem: {
    marginBottom: theme.spacing(1),
  },
  dividerLight: {
    backgroundColor: '#eaeaea',
  },
  appVersion: {
    display: 'flex',
    alignItems: 'end',
    height: '100%',
    paddingBottom: theme.spacing(2),
  },
  listDivider: {
    backgroundColor: '#eaeaea',
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
}));

const Navigation: React.FC<NavigationProps> = ({
  onMenuItemClick,
  logout,
}: NavigationProps): JSX.Element => {
  const [
    {
      user: { role },
    },
  ] = useContext(AuthStore);
  const [selectedListItem, setSelectedListItem] = useState('');

  const classes = useStyles();
  const theme = useTheme();

  const matchesMediaQuery = useMediaQuery(theme.breakpoints.up('md'));

  const handleListItemClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    item: NavItem
  ) => {
    setSelectedListItem(item.type);
    onMenuItemClick(item);
  };
  const { pathname } = useLocation();

  return (
    <>
      {!matchesMediaQuery && <Divider className={classes.dividerLight} />}
      <Box mt={!matchesMediaQuery ? 2 : 8}>
        <List className={classes.list}>
          {NAV_ITEMS.filter((navItem: NavItem) => {
            if (navItem.role && navItem.role !== role) {
              return false;
            }

            return true;
          }).map((navItem: NavItem) => {
            const Icon = navItem.icon;
            return (
              <Box key={navItem.type}>
                <ListItem
                  button
                  key={navItem.type}
                  selected={
                    selectedListItem === navItem.type ||
                    pathname.includes(navItem.route)
                  }
                  onClick={(event) => handleListItemClick(event, navItem)}
                  className={classes.listItem}
                >
                  <ListItemIcon>
                    <Icon className={classes.listIcon} />
                  </ListItemIcon>
                  <ListItemText primary={navItem.title} />
                </ListItem>
                {navItem.divider && <Divider className={classes.listDivider} />}
              </Box>
            );
          })}
        </List>
      </Box>
      <Divider className={classes.dividerLight} />
      <Box mt={4} mb={4}>
        <List className={classes.list}>
          <ListItem
            button
            onClick={() => {
              logout();
            }}
            className={classes.listItem}
          >
            <ListItemIcon>
              <SvgIcon
                component={LogoutIcon}
                className={classes.listIcon}
              ></SvgIcon>
            </ListItemIcon>

            <ListItemText primary={'Sign out'} />
          </ListItem>
        </List>
      </Box>
      <Divider className={classes.dividerLight} />
      <Box m={2} className={classes.appVersion}>
        <Typography
          variant="body1"
          color="textSecondary"
        >{`version: ${packageJson.version}`}</Typography>
      </Box>
    </>
  );
};

export default Navigation;
