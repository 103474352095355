import { Box, CircularProgress } from '@material-ui/core';
import { Fragment, useContext } from 'react';
import { AuthStore } from './providers/auth';
import PrivateRoutes from './routes/Private';
import PublicRoutes from './routes/Public';

function AppContent() {
  const [{ user, init }] = useContext(AuthStore) || [];

  return (
    <Fragment>
      {init && (
        <Box mb={2} mt={8}>
          <CircularProgress />
        </Box>
      )}
      <PublicRoutes />
      {user && <PrivateRoutes />}
    </Fragment>
  );
}

export default AppContent;
