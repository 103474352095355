import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  CircularProgress,
  Divider,
  Grid,
  Typography,
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';
import { KnowledgeCentreStore } from '../../providers/knowledgeCentre';
import { KNOWLEDGE_CENTRE_ROUTE } from '../../routes/Types';
import { LAYOUT } from '../../utils/constants/layout.constants';
import { SUPPORTED_IMAGE_UPLOADER_FORMATS } from '../../utils/constants/uploadFileFormats.constants';
import EditContentButtons from '../common/EditContentButtons';
import PublishDateTimeSection from '../common/PublishDateTimeSection';
import { CustomElement, CustomText } from '../common/TextEditor';
import Title from '../common/Title';
import TopPannel from '../common/TopPannel';
import KnowledgeCentreFormSection, {
  KnowledgeCentreFormFields,
} from './KnowledgeCentreFormSection';

interface KnowledgeAddEditFormProps {
  defaultValues?: KnowledgeCentreFormFields;
  handleUpdate: (data: KnowledgeCentreFormFields) => void;
  title: string;
}

const useStyles = makeStyles((theme) => ({
  content: {
    marginTop: 64,
    background: theme.palette.background.paper,
    [theme.breakpoints.up('md')]: {
      marginTop: LAYOUT.TopPanelHeight + LAYOUT.TopPanelBottomMargin,
      width: `calc(100% - ${LAYOUT.DrawerWidth}px)`,
      marginLeft: LAYOUT.DrawerWidth,
    },
  },
  title: {
    marginTop: theme.spacing(7),
    marginBottom: theme.spacing(5),
  },
}));

const KnowledgeAddEditForm = ({
  defaultValues,
  handleUpdate,
  title,
}: KnowledgeAddEditFormProps) => {
  const classes = useStyles();
  const theme = useTheme();

  const matchesMediaQuery = useMediaQuery(theme.breakpoints.up('md'), {
    noSsr: true,
  });

  const history = useHistory();

  const handleRedirectLinkClick = () => {
    history.push(`${KNOWLEDGE_CENTRE_ROUTE}`);
  };

  const {
    state: { updating },
    upload,
  } = useContext(KnowledgeCentreStore);

  const validationSchema = yup.object().shape({
    title: yup.string().required('Title is required'),
    extract: yup.string().max(345, 'Must be no longer than 345 characters'),
    publishDate: yup.date().required('Date is required'),
    publishTime: yup.date().required('Time is required'),
    text: yup
      .array()
      .compact(
        (value) =>
          !value.children.some((child: CustomText) => child.text !== '')
      )
      .min(1, 'Text is required'),
    imageInfo: yup.object().required('Image needs to be uploaded'),
  });

  const {
    handleSubmit,
    setValue,
    setError,
    formState: { errors },
    control,
    reset,
  } = useForm<KnowledgeCentreFormFields>({
    defaultValues: defaultValues
      ? defaultValues
      : {
          title: '',
          extract: '',
          publishDate: new Date(),
          publishTime: new Date(),
          text: [
            {
              type: 'paragraph',
              children: [{ text: '' }],
            },
          ] as CustomElement[],
        },
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    if (!defaultValues) return;
    reset(defaultValues);
  }, [defaultValues]);

  const handleUpload = async (data: { upload: FileList }) => {
    setValue('imageInfo', undefined);
    setError('imageInfo', {});

    const imgUrl = window.URL.createObjectURL(data.upload[0]);

    try {
      upload(data.upload[0]);

      setValue('imageInfo', {
        storageUrl: '',
      });
    } catch (err) {
      console.log(err);
    }

    window.URL.revokeObjectURL(imgUrl);
  };

  return (
    <Box className={classes.content}>
      {matchesMediaQuery ? (
        <TopPannel>
          <Title
            onRedirectLinkClick={handleRedirectLinkClick}
            pageName={'Back to all knowledge articles'}
          />
        </TopPannel>
      ) : (
        <Box textAlign="left" mr={4} ml={4}>
          <Title
            onRedirectLinkClick={handleRedirectLinkClick}
            pageName={'Back to all knowledge articles'}
          />
        </Box>
      )}
      <Box
        ml={{ xs: 4, sm: 4, md: 4, lg: 10 }}
        mr={{ xs: 4, sm: 4, md: 4, lg: 20 }}
      >
        <Typography
          variant="h4"
          color="textPrimary"
          align="left"
          classes={{ root: classes.title }}
        >
          {title}
        </Typography>
        <Divider />
        <Grid container direction="row">
          <Grid item xs={12} sm={12} md={7}>
            <Box mt={8}>
              <KnowledgeCentreFormSection
                control={control}
                errors={errors}
                handleUpload={handleUpload}
                supportedFileFormats={SUPPORTED_IMAGE_UPLOADER_FORMATS}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={5}>
            <Box mt={8} ml={{ xs: 4, sm: 4, md: 4, lg: 15 }}>
              <PublishDateTimeSection
                control={control}
                publishDateName="publishDate"
                publishTimeName="publishTime"
              ></PublishDateTimeSection>
              <EditContentButtons
                updating={updating}
                handleRedirectLinkClick={handleRedirectLinkClick}
                handleSave={handleSubmit(handleUpdate)}
              />
              <Box mt={5}>{updating && <CircularProgress />}</Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default KnowledgeAddEditForm;
