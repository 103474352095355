import { Chip, ChipProps } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { ChipStatus } from '../../utils/constants/chipStatuses.constants';

type StatusChipProps = Omit<ChipProps, 'variant'> & {
  label: string;
  variant: ChipStatus;
};

const useStyles = makeStyles((theme) => ({
  root: (props: StatusChipProps) => {
    const status = props.variant;
    return {
      background: theme.palette.statuses[status].background,
      borderRadius: 4,
      height: 20,
      marginBottom: theme.spacing(1),
    };
  },
  label: (props: StatusChipProps) => {
    const status = props.variant;
    return {
      color: theme.palette.statuses[status].text,
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    };
  },
}));

const StatusChip: React.FC<StatusChipProps> = (
  props: StatusChipProps
): JSX.Element => {
  const { variant, label, ...other } = props;
  const classes = useStyles(props);
  const _variant = variant;

  return (
    <Chip
      label={label}
      classes={{ root: classes.root, label: classes.label }}
      {...other}
    ></Chip>
  );
};

export default StatusChip;
