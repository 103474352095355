import React from 'react';
import DeletedProfilesContent from '../../components/delete-profiles/DeleteProfilesContent';
import { DeleteProfilesProvider } from '../../providers/deleteProfiles';

interface DeleteProfilesPageProps {}

const DeleteProfilesPage: React.FC<
  DeleteProfilesPageProps
> = (): JSX.Element => {
  return (
    <DeleteProfilesProvider>
      <DeletedProfilesContent />
    </DeleteProfilesProvider>
  );
};

export default DeleteProfilesPage;
