import { Box, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { LAYOUT } from '../../utils/constants/layout.constants';
import SectionTitle from '../common/SectionTitle';

interface ContentLayoutProps {
  children: React.ReactNode;
  title: string;
}

const useStyles = makeStyles((theme) => {
  const TitleHeight = 32;
  const ListMarginBottom = 32;
  return {
    content: {
      marginTop: 64,
      [theme.breakpoints.up('md')]: {
        marginTop: LAYOUT.TopPanelHeight + LAYOUT.TopPanelBottomMargin,
        width: `calc(100% - ${LAYOUT.DrawerWidth}px)`,
        marginLeft: LAYOUT.DrawerWidth,
      },
    },
    fullsize: {
      height: '100%',
      [theme.breakpoints.up('md')]: {
        height: `calc(100vh - ${LAYOUT.TopPanelHeight}px - ${LAYOUT.TopPanelBottomMargin}px - ${TitleHeight}px - ${ListMarginBottom}px)`,
      },
    },
  };
});

const ContentLayout: React.FC<ContentLayoutProps> = ({
  children,
  title,
}: ContentLayoutProps): JSX.Element => {
  const classes = useStyles();

  return (
    <Box className={classes.content}>
      <SectionTitle title={title} />
      <Box mt={{ xs: 4, sm: 4, md: 16, lg: 34 }} mr={5} ml={5}>
        <Grid container direction="row" className={classes.fullsize}>
          {children}
        </Grid>
      </Box>
    </Box>
  );
};

export default ContentLayout;
