import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  limit,
  orderBy,
  query,
  startAfter,
  updateDoc,
} from 'firebase/firestore';
import {
  UploadTaskSnapshot,
  getDownloadURL,
  ref,
  uploadBytesResumable,
} from 'firebase/storage';
import { KnowledgeItem } from '../utils/types/knowledgeCentre';
import { db, storage } from './firebase';

export const createFirebase = async (
  item: KnowledgeItem
): Promise<KnowledgeItem> => {
  try {
    const docRef = await addDoc(collection(db, 'knowledge-items'), item);
    return { id: docRef.id, ...item };
  } catch (error) {
    console.error('Error creating knowledge item:', error);
    throw error;
  }
};

export const updateFirebase = async (
  item: KnowledgeItem
): Promise<KnowledgeItem> => {
  if (!item.id) throw new Error('No id provided');

  try {
    const itemRef = doc(db, 'knowledge-items', item.id);
    await updateDoc(itemRef, { ...item, user: '' });
    return item;
  } catch (error) {
    console.error('Error updating knowledge item:', error);
    throw error;
  }
};

export const deleteFirebase = async (
  item: KnowledgeItem
): Promise<KnowledgeItem> => {
  if (!item.id) throw new Error('No id provided');

  try {
    const itemRef = doc(db, 'knowledge-items', item.id);
    await deleteDoc(itemRef);
    return item;
  } catch (error) {
    console.error('Error deleting knowledge item:', error);
    throw error;
  }
};

export const getBatchFirebase = async (
  lastDoc: KnowledgeItem | null
): Promise<Array<KnowledgeItem>> => {
  let knowledgeItems;

  const knowledgeItemsCollection = collection(db, 'knowledge-items');

  const baseQuery = query(
    knowledgeItemsCollection,
    orderBy('published', 'desc'),
    limit(20)
  );

  if (lastDoc && lastDoc.id) {
    // Fetch the document snapshot of the lastDoc
    const lastDocRef = await getDoc(doc(db, 'knowledge-items', lastDoc.id)); // Use getDoc() to get a single document snapshot

    const paginatedQuery = query(
      knowledgeItemsCollection,
      orderBy('published', 'desc'),
      startAfter(lastDocRef), // Use the document snapshot in startAfter()
      limit(20)
    );

    knowledgeItems = await getDocs(paginatedQuery);
  } else {
    knowledgeItems = await getDocs(baseQuery);
  }

  return knowledgeItems.docs.map((doc) => ({
    id: doc.id,
    title: doc.data().title || '', // Handle undefined values safely
    image: doc.data().image || '',
    extract: doc.data().extract || '',
    rawText: doc.data().rawText || '',
    text: doc.data().text || '',
    user: doc.data().user || 'Unknown User',
    published: new Date(doc.data().published?.seconds * 1000 || Date.now()), // Handle missing published field
  }));
};

export const uploadImageToFirebase = async (file: File): Promise<string> => {
  const storageRef = ref(
    storage,
    `knowledge-items/${Date.now()}_${file.name.replace(/\s+/g, '_')}`
  );
  const uploadTask = uploadBytesResumable(storageRef, file);

  return new Promise<string>((resolve, reject) => {
    uploadTask.on(
      'state_changed',
      (_snapshot: UploadTaskSnapshot) => {
        // TODO: at some point show progress
        // let progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes)) * 100
      },
      (error) => {
        reject(error);
      },
      async () => {
        try {
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
          resolve(downloadURL);
        } catch (error) {
          reject(error);
        }
      }
    );
  });
};
