import React from 'react';
import ArticlesAddContent from '../../components/articles/ArticlesAddContent';

interface ArticlesAddPageProps {}

const ArticlesAddPage: React.FC<ArticlesAddPageProps> = (): JSX.Element => {
  return <ArticlesAddContent />;
};

export default ArticlesAddPage;
