import { httpsCallable } from '@firebase/functions';
import { createContext, useCallback, useReducer } from 'react';
import {
  UPDATES_ACTION,
  UpdatesReducer,
  UpdatesState,
  initialUpdatesState,
} from '../reducers/updates';
import { fbFunctions } from '../services/firebase';
import {
  fetchUpdatesData,
  getReviewUpdatedRecordData,
} from '../services/updates';

interface UpdatesContext {
  state: UpdatesState;
  getUpdatesList: () => void;
  getReviewOldRecordById: () => void;
  getReviewUpdatedRecordById: (id: string) => void;
  approveUpdate: (id: string, generate_activity_feed: boolean) => void;
  rejectUpdate: (id: string, rejection_notes: string) => void;
  revertUpdate: (id: string) => void;
}

export const UpdatesStore = createContext<UpdatesContext>({
  state: initialUpdatesState,
  getUpdatesList: async () => {},
  getReviewOldRecordById: async () => {},
  getReviewUpdatedRecordById: async (_id: string) => {},
  approveUpdate: async (_id: string, _generate_activity_feed: boolean) => {},
  rejectUpdate: async (_id: string, _rejection_notes: string) => {},
  revertUpdate: (_id: string) => {},
});

const { Provider } = UpdatesStore;

export const UpdatesProvider = ({ children }: any) => {
  const [state, dispatch] = useReducer(UpdatesReducer, initialUpdatesState);

  const getUpdatesList = useCallback(async () => {
    dispatch({ type: UPDATES_ACTION.FETCH, loading: true });
    try {
      const pendingUpdatesList = await fetchUpdatesData();
      dispatch({
        type: UPDATES_ACTION.FETCH_DATA_SUCCESS,
        data: pendingUpdatesList,
        loading: true,
      });
    } catch (error) {
      dispatch({ type: UPDATES_ACTION.FETCH_DATA_FAIL, loading: false });
    }
  }, []);

  const getReviewOldRecordById = useCallback(async () => {
    /*
    dispatch({ type: UPDATES_ACTION.FETCH_REVIEW_OLD_RECORD, loading: true });
    try {
      const reviewOldRecord = await getReviewOldRecordData();
      dispatch({
        type: UPDATES_ACTION.FETCH_REVIEW_OLD_RECORD_SUCCESS,
        loading: true,
        data: reviewOldRecord,
      });
    } catch (error) {
      dispatch({
        type: UPDATES_ACTION.FETCH_REVIEW_OLD_RECORD_ERROR,
        loading: true,
      });
    }
    */
  }, []);

  const getReviewUpdatedRecordById = useCallback(async (id: string) => {
    dispatch({
      type: UPDATES_ACTION.FETCH_REVIEW_UPDATED_RECORD,
      loading: true,
    });
    try {
      const reviewUpdatedRecord = await getReviewUpdatedRecordData(id);
      dispatch({
        type: UPDATES_ACTION.FETCH_REVIEW_UPDATED_RECORD_SUCCESS,
        loading: false,
        data: reviewUpdatedRecord?.results,
        totalChanges: reviewUpdatedRecord?.total,
      });
    } catch (error) {
      dispatch({
        type: UPDATES_ACTION.FETCH_REVIEW_UPDATED_RECORD_ERROR,
        loading: true,
        error: {},
      });
    }
  }, []);

  const approveUpdate = useCallback(
    async (id: string, generate_activity_feed: boolean) => {
      dispatch({
        type: UPDATES_ACTION.UPDATE,
      });

      try {
        const publishDataUpdates = httpsCallable(
          fbFunctions,
          'publishDataUpdates'
        );

        const result = await publishDataUpdates({
          csv_document_id: id,
          generate_activity_feed,
        });

        dispatch({
          type: UPDATES_ACTION.UPDATE_SUCCESS,
        });

        getUpdatesList();

        return result.data;
      } catch (error) {
        console.error('Error approving update:', error);
        dispatch({
          type: UPDATES_ACTION.UPDATE_ERROR,
          payload: error,
        });

        return null; // or handle the error accordingly
      }
    },
    [getUpdatesList]
  );

  const rejectUpdate = async (id: string, rejection_notes: string) => {
    dispatch({
      type: UPDATES_ACTION.UPDATE,
    });

    try {
      const rejectDataUpdates = httpsCallable(fbFunctions, 'rejectDataUpdates');

      const result = await rejectDataUpdates({
        csv_document_id: id,
        rejection_notes,
      });

      dispatch({
        type: UPDATES_ACTION.UPDATE_SUCCESS,
      });

      getUpdatesList();

      return result.data;
    } catch (error) {
      console.error('Error rejecting update:', error);
      dispatch({
        type: UPDATES_ACTION.UPDATE_ERROR,
        payload: error,
      });

      return null; // or handle the error accordingly
    }
  };

  const revertUpdate = async (id: string) => {
    dispatch({
      type: UPDATES_ACTION.UPDATE,
    });

    try {
      const revertDataUpdates = httpsCallable(fbFunctions, 'revertDataUpdates');

      const result = await revertDataUpdates({ csv_document_id: id });

      dispatch({
        type: UPDATES_ACTION.UPDATE_SUCCESS,
      });

      getUpdatesList();

      return result.data;
    } catch (error) {
      console.error('Error reverting update:', error);
      dispatch({
        type: UPDATES_ACTION.UPDATE_ERROR,
        payload: error,
      });

      return null;
    }
  };

  return (
    <Provider
      value={{
        state,
        getUpdatesList,
        getReviewOldRecordById,
        getReviewUpdatedRecordById,
        approveUpdate,
        rejectUpdate,
        revertUpdate,
      }}
    >
      {children}
    </Provider>
  );
};
