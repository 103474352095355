import React from 'react';
import ProfileComparisonContent from '../../components/updates/ProfileComparisonContent';

interface ProfileComparisonPageProps {}

const ProfileComparisonPage: React.FC<
  ProfileComparisonPageProps
> = (): JSX.Element => {
  return <ProfileComparisonContent />;
};

export default ProfileComparisonPage;
