const ImageSizeExtractor = (url: string, rejectTimeout: any): Promise<any> =>
  new Promise((resolve, reject) => {
    let timer: any = null;

    const img = new Image();

    img.addEventListener('load', () => {
      if (timer) {
        clearTimeout(timer);
      }

      resolve(img);
    });

    img.addEventListener('error', (event) => {
      if (timer) {
        clearTimeout(timer);
      }

      reject(`${event.type}: ${event.message}`);
    });

    img.src = url;

    if (rejectTimeout) {
      timer = setTimeout(() => reject('Timeout exception'), rejectTimeout);
    }
  });

export const getImageAspectRatio = async (
  imageUrl: string
): Promise<number> => {
  const { width, height } = await ImageSizeExtractor(imageUrl, 0);
  let aspectRatio = width / height;
  if (Number.isInteger(aspectRatio)) {
    aspectRatio -= 0.01;
  }

  return aspectRatio;
};
