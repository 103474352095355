import { initializeApp } from 'firebase/app';
import { connectAuthEmulator, getAuth } from 'firebase/auth';
import { connectFirestoreEmulator, getFirestore } from 'firebase/firestore';
import { connectFunctionsEmulator, getFunctions } from 'firebase/functions';
import { connectStorageEmulator, getStorage } from 'firebase/storage';
import { FIREBASE, getCurrentRegion } from '../config';

const app = initializeApp(FIREBASE);

export const db = getFirestore(app);
export const auth = getAuth(app);
export const fbFunctions = getFunctions(app, getCurrentRegion());
export const storage = getStorage();

if (process.env.REACT_APP_ENV === 'local') {
  connectFirestoreEmulator(db, 'localhost', 8080);
  connectAuthEmulator(auth, 'http://localhost:9099');
  connectFunctionsEmulator(fbFunctions, 'localhost', 5001);
  connectStorageEmulator(storage, 'localhost', 9199);
}

export default app;
