import { GlossaryTerm } from '../utils/types/glossary-term';

export interface GlossaryState {
  data: Array<GlossaryTerm> | null;
  contentImageUrl?: string;
  loading: boolean;
  updating: boolean;
  uploading: boolean;
  error: any;
}

export const initialGlossaryState: GlossaryState = {
  data: null,
  contentImageUrl: undefined,
  loading: false,
  updating: false,
  uploading: false,
  error: null,
};

export const GLOSSARY_ACTIONS = {
  FETCH: 'GLOSSARY/FETCH',
  FETCH_INITIAL_DATA_SUCCESS: 'GLOSSARY/INITIAL_DATA_SUCCESS',
  FETCH_DATA_SUCCESS: 'GLOSSARY/SUCCESS',
  FETCH_DATA_FAIL: 'GLOSSARY/FAIL',
  UPDATE_START: 'GLOSSARY/UPDATE_START',
  UPDATE_SUCCESS: 'GLOSSARY/UPDATE_SUCCESS',
  IMAGE_UPLOAD_START: 'GLOSSARY/IMAGE_UPLOAD_START',
  IMAGE_UPLOAD_SUCCESS: 'GLOSSARY/IMAGE_UPLOAD_SUCCESS',
  REMOVE_START: 'GLOSSARY/REMOVE_START',
  REMOVE_SUCCESS: 'GLOSSARY/REMOVE_SUCCESS',
};

export const GlossaryReducer = (
  state: GlossaryState,
  action: any
): GlossaryState => {
  switch (action.type) {
    case GLOSSARY_ACTIONS.FETCH:
      return { ...state, loading: true };
    case GLOSSARY_ACTIONS.FETCH_INITIAL_DATA_SUCCESS:
      return {
        ...initialGlossaryState,
        data: action.data,
      };
    case GLOSSARY_ACTIONS.FETCH_DATA_SUCCESS:
      return {
        ...initialGlossaryState,
        data: (state.data || []).concat(action.data),
      };
    case GLOSSARY_ACTIONS.FETCH_DATA_FAIL:
      return {
        ...initialGlossaryState,
        data: null,
      };
    case GLOSSARY_ACTIONS.UPDATE_START:
      return { ...state, updating: true };
    case GLOSSARY_ACTIONS.UPDATE_SUCCESS:
      return {
        ...initialGlossaryState,
        data: [...action.data].sort((g1: GlossaryTerm, g2: GlossaryTerm) => {
          return g1.updated > g2.updated ? -1 : 1;
        }),
      };
    case GLOSSARY_ACTIONS.IMAGE_UPLOAD_START:
      return { ...state, uploading: true };
    case GLOSSARY_ACTIONS.IMAGE_UPLOAD_SUCCESS:
      return { ...state, uploading: false, ...action.data };
    case GLOSSARY_ACTIONS.REMOVE_START:
      return { ...state, updating: true };
    case GLOSSARY_ACTIONS.REMOVE_SUCCESS:
      return { ...state, data: action.data, updating: false };
    default:
      throw new Error();
  }
};
