import React, { useContext } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { KnowledgeCentreStore } from '../../providers/knowledgeCentre';
import { serialize } from '../../utils/helpers/htmlSerializer.helpers';
import { KnowledgeItem } from '../../utils/types/knowledgeCentre';
import { CustomElement } from '../common/TextEditor';
import KnowledgeAddEditForm from './KnowledgeAddEditForm';
import { KnowledgeCentreFormFields } from './KnowledgeCentreFormSection';

interface KnowledgeCentreEditContentProps {}

const KnowledgeCentreEditContent: React.FC<
  KnowledgeCentreEditContentProps
> = (): JSX.Element => {
  const [defaultValues, setDefaultValues] =
    React.useState<KnowledgeCentreFormFields>({
      id: 'initial_id',
      title: '',
      extract: '',
      publishDate: new Date(),
      publishTime: new Date(),
      text: [] as CustomElement[],
    });

  const { id } = useParams<any>();
  const history = useHistory();

  const {
    state: { data },
    update,
  } = useContext(KnowledgeCentreStore);

  React.useEffect(() => {
    const selectedKnowledgeItem = data?.find(
      (knowledgeItem: KnowledgeItem) => knowledgeItem.id === id
    );

    if (selectedKnowledgeItem) {
      let _defaultValues: KnowledgeCentreFormFields = {
        id: selectedKnowledgeItem.id,
        title: selectedKnowledgeItem.title,
        extract: selectedKnowledgeItem.extract,
        publishDate: selectedKnowledgeItem.published,
        publishTime: selectedKnowledgeItem.published,
        text: selectedKnowledgeItem.rawText
          ? (selectedKnowledgeItem.rawText as CustomElement[])
          : ([
              {
                type: 'paragraph',
                children: [{ text: '' }],
              },
            ] as CustomElement[]),
      };
      if (selectedKnowledgeItem.image) {
        _defaultValues.imageInfo = {
          storageUrl: selectedKnowledgeItem.image,
        };
      }
      setDefaultValues(_defaultValues);
    }
  }, [id, data]);

  const handleUpdate = async (formData: KnowledgeCentreFormFields) => {
    const selectedKnowledgeItem = data?.find(
      (knowledgeItem: KnowledgeItem) => knowledgeItem.id === id
    );

    const knowledgeItem = {
      id: selectedKnowledgeItem?.id,
      title: formData.title,
      image: selectedKnowledgeItem?.image || '',
      published: formData.publishDate,
      extract: formData.extract,
      rawText: formData.text,
      text: serialize({ children: formData.text }),
    };

    await update(knowledgeItem);
    history.goBack();
  };

  return (
    <KnowledgeAddEditForm
      handleUpdate={handleUpdate}
      title="Edit Knowledge Article"
      defaultValues={defaultValues}
    />
  );
};

export default KnowledgeCentreEditContent;
