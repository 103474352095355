import {
  DocumentData,
  DocumentSnapshot,
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  limit,
  orderBy,
  query,
  startAfter,
  updateDoc,
} from '@firebase/firestore';
import {
  UploadTaskSnapshot,
  getDownloadURL,
  ref,
  uploadBytesResumable,
} from 'firebase/storage';
import { GlossarySort } from '../utils/constants/glossarySort.constants';
import { GlossaryTerm } from '../utils/types/glossary-term';
import { db, storage } from './firebase';
export interface GlossaryFilterType {
  keyword?: string;
}

export const createFirebase = async (
  glossaryTerm: GlossaryTerm
): Promise<GlossaryTerm> => {
  const glossaryCollection = collection(db, 'glossary');

  try {
    const createdDocRef = await addDoc(glossaryCollection, glossaryTerm);
    return { id: createdDocRef.id, ...glossaryTerm };
  } catch (error) {
    console.error('Error creating glossary term:', error);
    throw error;
  }
};

export const updateFirebase = async (
  glossaryTerm: GlossaryTerm
): Promise<GlossaryTerm> => {
  if (!glossaryTerm.id) {
    throw new Error('GlossaryTerm ID is undefined');
  }

  const glossaryTermRef = doc(db, 'glossary', glossaryTerm.id);

  try {
    await updateDoc(glossaryTermRef, { ...glossaryTerm, user: '' });
    return glossaryTerm;
  } catch (error) {
    console.error('Error updating glossary term:', error);
    throw error;
  }
};

export const deleteFirebase = async (
  glossaryTerm: GlossaryTerm
): Promise<GlossaryTerm> => {
  if (!glossaryTerm.id) {
    throw new Error('GlossaryTerm ID is undefined');
  }

  const glossaryTermRef = doc(db, 'glossary', glossaryTerm.id);

  try {
    await deleteDoc(glossaryTermRef);
    return glossaryTerm;
  } catch (error) {
    console.error('Error deleting glossary term:', error);
    throw error;
  }
};

const transformGlossary = (
  doc: DocumentSnapshot<DocumentData>
): GlossaryTerm => {
  const data = doc.data();
  if (!data) {
    throw new Error('Document data is undefined');
  }

  return {
    id: doc.id,
    title: data.title,
    rawText: data.rawText,
    text: data.text,
    created: new Date(data.created.seconds * 1000),
    updated: new Date(data.updated.seconds * 1000),
  };
};

export const getBatchFirebase = async (
  lastDoc: GlossaryTerm | null,
  sort: GlossarySort
): Promise<Array<GlossaryTerm>> => {
  let glossary;

  const [field, _sort] = sort.split('-');
  const sortType = _sort === 'desc' ? 'desc' : 'asc';

  const glossaryCollection = collection(db, 'glossary');

  try {
    const baseQuery = query(
      glossaryCollection,
      orderBy(field, sortType),
      limit(20)
    );

    if (lastDoc && lastDoc.id) {
      const lastDocRef = doc(db, 'glossary', lastDoc.id);
      const lastDocSnapshot = await getDoc(lastDocRef);
      glossary = await getDocs(query(baseQuery, startAfter(lastDocSnapshot)));
    } else {
      glossary = await getDocs(baseQuery);
    }

    return glossary.docs.map((doc: DocumentSnapshot) => transformGlossary(doc));
  } catch (error) {
    console.error('Error fetching glossary batch:', error);
    throw error;
  }
};

export const getFullGlossary = async (
  sort: GlossarySort
): Promise<Array<GlossaryTerm>> => {
  const [field, _sort] = sort.split('-');
  const sortType = _sort === 'desc' ? 'desc' : 'asc';

  try {
    const glossaryCollection = collection(db, 'glossary');
    const glossaryQuery = query(glossaryCollection, orderBy(field, sortType));
    const glossary = await getDocs(glossaryQuery);

    return glossary.docs.map((doc: DocumentSnapshot) => transformGlossary(doc));
  } catch (error) {
    console.error('Error fetching full glossary:', error);
    throw error;
  }
};

export const uploadImageToFirebase = async (file: File): Promise<string> => {
  const storageRef = ref(
    storage,
    `glossary/${Date.now()}_${file.name.replace(/\s+/g, '_')}`
  );
  const uploadTask = uploadBytesResumable(storageRef, file);

  return new Promise<string>((resolve, reject) => {
    uploadTask.on(
      'state_changed',
      (_snapshot: UploadTaskSnapshot) => {
        // TODO: at some point show progress
        // let progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes)) * 100
      },
      (error) => {
        reject(error);
      },
      async () => {
        try {
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
          resolve(downloadURL);
        } catch (error) {
          reject(error);
        }
      }
    );
  });
};
