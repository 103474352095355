import {
  Avatar,
  Box,
  Checkbox,
  FormControlLabel,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useContext, useEffect, useState } from 'react';
import { StorageStore } from '../../providers/storage';
import { SUPPORTED_IMAGE_UPLOADER_FORMATS } from '../../utils/constants/uploadFileFormats.constants';
import FileUploader from '../common/FileUploader';
import StyledPaper from '../common/StyledPaper';

interface CompanyLogoProps {
  profile: any;
  onLogoUpdate: any;
  name: string;
}

const useStyles = makeStyles((theme) => ({
  avatar: {
    width: 64,
    height: 64,
    marginBottom: theme.spacing(6),
  },
  checkbox: {
    '& .MuiIconButton-label': {
      color: '#FF3C00 !important',
    },
  },
}));

const CompanyLogo: React.FC<CompanyLogoProps> = ({
  profile,
  onLogoUpdate,
  name,
}: CompanyLogoProps): JSX.Element => {
  const [imgUrl, setImageUrl] = useState<string | null>(null);
  const [updateDone, setUpdateDone] = useState<boolean>(false);
  const [shouldGenerateTile, setShouldGenerateTile] = useState<boolean>(false);
  const {
    state: { data, updating },
    saveCompanyLogo,
    upload,
  } = useContext(StorageStore);

  const classes = useStyles();

  const handleLogoSelected = async (files: FileList) => {
    if (imgUrl) {
      window.URL.revokeObjectURL(imgUrl);
    }
    const newImgUrl = window.URL.createObjectURL(files[0]);
    setImageUrl(newImgUrl);
  };

  const handleLogoUpload = async (data: { upload: FileList }) => {
    upload(data.upload[0]);
  };

  useEffect(() => {
    if (data !== '') {
      saveCompanyLogo(profile.id, data, shouldGenerateTile);
      onLogoUpdate(data);
    }
  }, [data, onLogoUpdate, profile.id, saveCompanyLogo]);

  useEffect(() => {
    if (updating) {
      setUpdateDone(true);
    } else if (!updating && updateDone) {
      setTimeout(() => {
        setUpdateDone(false);
      }, 5000);
    }
  }, [updating, updateDone]);

  return (
    <StyledPaper>
      <Box pt={4} pb={4} pr={4} pl={4} mt={4} mb={4}>
        <Box display={'flex'} flexDirection={'row'} alignItems={'flex-end'}>
          {(profile.logo || imgUrl) && (
            <Box height={64} width={64} mb={6}>
              <img
                src={imgUrl! || profile.logo}
                alt="Company Avatar"
                width="64"
                height="64"
              />
            </Box>
          )}
          {!imgUrl && !profile.logo && (
            <Avatar alt={name ?? ''} className={classes.avatar}>
              {name.substring(0, 2)}
            </Avatar>
          )}
        </Box>
        <FileUploader
          title={`${name} logo image`}
          uploadButtonTitle={'Save'}
          onUpload={handleLogoUpload}
          supportedFileFormats={SUPPORTED_IMAGE_UPLOADER_FORMATS}
          onFileSelected={handleLogoSelected}
        />
        <Box textAlign={'left'} mt={2}>
          <FormControlLabel
            checked={shouldGenerateTile}
            control={
              <Checkbox
                className={classes.checkbox}
                onChange={(evt: any, value: any) => {
                  setShouldGenerateTile(value);
                }}
              />
            }
            label="Generate a tile on the feed"
          />
        </Box>
        {updating && (
          <Box textAlign={'left'} mt={2}>
            <Typography>Updating...</Typography>
          </Box>
        )}
        {!updating && updateDone && (
          <Box textAlign={'left'} mt={2}>
            <Typography>Logo has been updated!</Typography>
          </Box>
        )}
      </Box>
    </StyledPaper>
  );
};

export default CompanyLogo;
