import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  Divider,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import WrappedDialog from '../common/WrappedDialog';

interface UploadErrorsDialogProps {
  isDialogOpened: boolean;
  onDialogClose: () => void;
  errorReport?: Array<{ error_messages: Array<string>; row_number: number }>;
  errorMessage: string;
}

const useStyles = makeStyles((theme) => ({
  button: {
    width: 100,
    borderRadius: 10,
    marginLeft: theme.spacing(2),
  },
  content: {
    padding: 0,
  },
  text: {
    fontFamily: 'Hero New Bold',
    fontSize: 12,
  },
  scrollable: {
    width: '100%',
    height: '100%',
    overflow: 'scroll',
    padding: '8px 0',
  },
}));

const UploadErrorsDialog: React.FC<UploadErrorsDialogProps> = ({
  errorReport,
  errorMessage,
  isDialogOpened,
  onDialogClose,
}: UploadErrorsDialogProps): JSX.Element => {
  const classes = useStyles();

  return (
    <WrappedDialog
      open={isDialogOpened}
      handleDialogClose={onDialogClose}
      dialogTitle={'Error report'}
    >
      <DialogContent classes={{ root: classes.content }}>
        <Divider />
        <Box mr={10} ml={10}>
          <Box mt={6} mb={6}>
            <Typography variant="body1">
              The validation report found the following errors on this update:
            </Typography>
          </Box>
          <Divider />
          {errorMessage && (
            <Box className={classes.scrollable}>
              <Typography
                variant="body2"
                color="textSecondary"
                component={'pre'}
              >
                {errorMessage}
              </Typography>
            </Box>
          )}
          {errorReport?.map((report, index: number) => (
            <Box key={index}>
              <Box mt={6} mb={6}>
                <Box display="flex">
                  <Typography classes={{ root: classes.text }}>
                    Line {report.row_number}
                  </Typography>
                </Box>

                {report.error_messages.map((message, idx: number) => (
                  <Box className={classes.scrollable} key={idx}>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      component={'pre'}
                    >
                      {message}
                    </Typography>
                  </Box>
                ))}
              </Box>
            </Box>
          ))}
        </Box>
        <Divider />
      </DialogContent>
      <DialogActions>
        <Box mt={3} mb={3} mr={8}>
          <Button
            onClick={onDialogClose}
            color="secondary"
            variant="contained"
            disableElevation
            classes={{ root: classes.button }}
          >
            Close
          </Button>
        </Box>
      </DialogActions>
    </WrappedDialog>
  );
};

export default UploadErrorsDialog;
