import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  limit,
  orderBy,
  query,
  startAfter,
  updateDoc,
} from '@firebase/firestore';
import { Tooltip } from '../utils/types/tooltip';
import { db } from './firebase';

export const createFirebase = async (tooltip: Tooltip): Promise<Tooltip> => {
  const tooltipsCollection = collection(db, 'tooltips');
  const createdDocRef = await addDoc(tooltipsCollection, tooltip);
  return { id: createdDocRef.id, ...tooltip };
};

export const updateFirebase = async (tooltip: Tooltip): Promise<Tooltip> => {
  if (!tooltip.id) throw new Error('Tooltip ID is undefined');
  const tooltipDocRef = doc(db, 'tooltips', tooltip.id);
  await updateDoc(tooltipDocRef, { ...tooltip, user: '' });
  return tooltip;
};

export const deleteFirebase = async (tooltip: Tooltip): Promise<Tooltip> => {
  if (!tooltip.id) throw new Error('Tooltip ID is undefined');
  const tooltipDocRef = doc(db, 'tooltips', tooltip.id);
  await deleteDoc(tooltipDocRef);
  return tooltip;
};

export const getBatchFirebase = async (
  lastDoc: Tooltip | null
): Promise<Array<Tooltip>> => {
  let tooltips;

  const tooltipsCollection = collection(db, 'tooltips');

  const queryOptions = orderBy('created', 'desc');

  if (lastDoc && lastDoc.id) {
    const lastDocRef = doc(db, 'tooltips', lastDoc.id);
    const lastDocSnapshot = await getDoc(lastDocRef);
    tooltips = await getDocs(
      query(
        tooltipsCollection,
        queryOptions,
        startAfter(lastDocSnapshot),
        limit(20)
      )
    );
  } else {
    tooltips = await getDocs(
      query(tooltipsCollection, queryOptions, limit(20))
    );
  }

  return tooltips.docs.map((doc) => ({
    id: doc.id,
    title: doc.data().title,
    tooltipKey: doc.data().tooltipKey,
    rawText: doc.data().rawText,
    text: doc.data().text,
    created: new Date(doc.data().created.seconds * 1000),
    updated: new Date(doc.data().updated.seconds * 1000),
  }));
};

/* export const uploadImageToFirebase = async (file: File): Promise<string> => {
  let storage = firebase.storage();
  let storageRef = storage.ref();

  const name = `glossary/${Date.now()}_${file.name.replace(/\s+/g, '_')}`;

  let uploadTask = storageRef.child(name).put(file);

  const promise = new Promise<string>((resolve, _reject) => {
    uploadTask.on(
      firebase.storage.TaskEvent.STATE_CHANGED,
      (_snapshot: any) => {
        //TODO: at some point show progress
        //let progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes)) * 100
      },
      (error) => {
        throw error;
      },
      () => {
        uploadTask.snapshot.ref.getDownloadURL().then((url) => {
          resolve(url);
        });
      }
    );
  });

  return promise;
};
 */
