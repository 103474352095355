import {
  Box,
  Button,
  CircularProgress,
  Grid,
  SvgIcon,
  Typography,
  makeStyles,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { format } from 'date-fns';
import { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ReactComponent as AddIcon } from '../../assets/icons/add-icon.svg';
import { ORGANISATIONS_ADD_ROUTE } from '../../routes/Types';
import { LAYOUT } from '../../utils/constants/layout.constants';
import { USER_ADMIN_PAGE } from '../../utils/constants/pageNames.constants';
import TopPannel from '../common/TopPannel';

import { OrganisationsList, OrganisationsSearch } from '.';
import { useOrganisations } from '../../hooks/services';
import { useUserRoleRestrictions } from '../../hooks/services/useUserRoleRestrictions';
import { UserRole } from '../../utils/types/user';

const useStyles = makeStyles((theme) => {
  const LettersBlockBottomMargin = 32;
  const LettersBlockHeight = 25 + LettersBlockBottomMargin;
  const SubtitleBlockHeight = 20;
  const ListTopMargin = 24;
  return {
    content: {
      [theme.breakpoints.up('md')]: {
        marginTop: LAYOUT.TopPanelHeight + LAYOUT.TopPanelBottomMargin,
        width: `calc(100% - ${LAYOUT.DrawerWidth}px)`,
        marginLeft: LAYOUT.DrawerWidth,
      },
    },
    divider: {
      margin: 0,
      [theme.breakpoints.up('md')]: {
        marginRight: theme.spacing(5),
        marginLeft: theme.spacing(5),
      },
    },
    fullsize: {
      height: '100%',
      [theme.breakpoints.up('md')]: {
        height: `calc(100vh - ${LAYOUT.TopPanelHeight}px - ${LAYOUT.TopPanelBottomMargin}px - ${LettersBlockHeight}px - ${ListTopMargin}px - ${SubtitleBlockHeight}px)`,
        marginLeft: theme.spacing(5),
        marginRight: theme.spacing(5),
      },
      marginTop: theme.spacing(6),
    },
    itemOrder: {
      order: 1,
      [theme.breakpoints.up('md')]: {
        order: 'initial',
      },
    },
    button: {
      borderRadius: 10,
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
    borderBottom: {
      paddingBottom: theme.spacing(3),
      borderBottom: `1px solid ${theme.palette.ui.lightGrey.main}`,
    },
  };
});

const OrganisationContent = () => {
  const classes = useStyles();
  const theme = useTheme();
  const matchesMediaQuery = useMediaQuery(theme.breakpoints.up('md'));
  const history = useHistory();
  const { isUserRole: isUserAdmin } = useUserRoleRestrictions(UserRole.SuperAdmin);
  const { organisations, isLoading } = useOrganisations();
  const [searchQuery, setSearchQuery] = useState('');
  const [inputValue, setInputValue] = useState('');

  const handleAddUserClick = useCallback(() => {
    history.push(ORGANISATIONS_ADD_ROUTE);
  }, [history]);

  const handleSearch = () => {
    setSearchQuery(inputValue);
  };

  const handleClearSearch = () => {
    setInputValue('');
    setSearchQuery('');
  };

  const filteredOrganisations = organisations
    ?.map((organisation) => ({
      id: organisation.id,
      name: organisation.name,
      created_at: format(
        new Date(organisation.created_at),
        'MMM, dd, yyyy hh:mm aa'
      ),
    }))
    .filter((org) =>
      org.name.toLowerCase().includes(searchQuery.toLowerCase())
    );

  return (
    <Box className={classes.content}>
      {matchesMediaQuery ? (
        <TopPannel>
          <Typography variant="h4" color="textPrimary">
            {USER_ADMIN_PAGE}
          </Typography>
        </TopPannel>
      ) : (
        <Box mt={15} ml={4} mb={4}>
          <Typography variant="h5" color="textPrimary" align="left">
            {USER_ADMIN_PAGE}
          </Typography>
        </Box>
      )}

      <Box>
        <Box mx={matchesMediaQuery ? 10 : 4}>
          <Typography
            variant={matchesMediaQuery ? 'overline' : 'h6'}
            display="block"
            color="textPrimary"
            align="left"
            className={classes.borderBottom}
          >
            {'Organisations'}
          </Typography>
        </Box>
        <Grid
          container
          direction={matchesMediaQuery ? 'row' : 'column'}
          className={classes.fullsize}
        >
          <Grid item xs={12} sm={12} md={6} className={classes.itemOrder}>
            <Box height={matchesMediaQuery ? '100%' : '100vh'}>
              {isLoading ? (
                <CircularProgress />
              ) : (
                <>
                  {filteredOrganisations && filteredOrganisations.length > 0 ? (
                    <OrganisationsList organisations={filteredOrganisations} />
                  ) : (
                    <Box textAlign={'left'} ml={5} mt={5}>
                      <Typography variant="body1">
                        No Organisations match your query
                      </Typography>
                    </Box>
                  )}
                </>
              )}
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Box
              display="flex"
              mt={2}
              mb={6}
              ml={{ xs: 5, sm: 5, md: 5, lg: 25 }}
            >
              <Button
                variant="contained"
                color="secondary"
                disableElevation
                onClick={handleAddUserClick}
                classes={{ root: classes.button }}
                component="label"
                disabled={!isUserAdmin}
              >
                <SvgIcon component={AddIcon}></SvgIcon>
                Add new organisation
              </Button>
            </Box>
            <Box
              ml={{ xs: 4, sm: 4, md: 4, lg: 27.5 }}
              mr={{ xs: 'auto', sm: 'auto', md: 'auto', lg: 27.5 }}
            >
              <Typography variant="subtitle1" align="left">
                Find a subscriber
              </Typography>
              <form>
                <OrganisationsSearch
                  onChange={setInputValue}
                  onSearch={handleSearch}
                  onClear={handleClearSearch}
                  keyword={inputValue}
                />
              </form>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default OrganisationContent;
