import { ListItemText, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

interface DeletedProfileListItemContentProps {
  name: string;
  role: string;
}

const useStyles = makeStyles((theme) => ({
  listItemText: {
    paddingLeft: theme.spacing(2),
  },
  name: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  role: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));

const DeletedProfileListItemContent: React.FC<
  DeletedProfileListItemContentProps
> = ({ name, role }: DeletedProfileListItemContentProps): JSX.Element => {
  const classes = useStyles();

  return (
    <>
      <ListItemText className={classes.listItemText}>
        <Typography variant="subtitle2" className={classes.name}>
          {name}
        </Typography>
        <Typography title={role} variant="body2" className={classes.role}>
          {role}
        </Typography>
      </ListItemText>
    </>
  );
};

export default DeletedProfileListItemContent;
