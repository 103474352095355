import { Box, ListItemText, MenuItem, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useCallback, useState } from 'react';
import VirualizedListMenu from '../common/VirtualizedListMenu';

interface KnowledgeListItemContentProps {
  id?: string;
  date: string;
  title: string;
  image: string;
  onRemove: () => void;
  onEdit: () => void;
}

const useStyles = makeStyles((theme) => ({
  listItemText: {
    paddingLeft: theme.spacing(2),
  },
  title: {
    overflow: 'hidden',
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
  },
  imagePlaceholder: {
    width: 100,
    height: 90,
  },
}));

const KnowledgeListItemContent: React.FC<KnowledgeListItemContentProps> = ({
  date,
  title,
  image,
  onRemove,
  onEdit,
}: KnowledgeListItemContentProps): JSX.Element => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isMenuOpened = Boolean(anchorEl);

  const handleMenuClick = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
    },
    [setAnchorEl]
  );

  const handleMenuClose = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  const handleMenuDelete = useCallback(() => {
    onRemove();
    setAnchorEl(null);
  }, [onRemove, setAnchorEl]);

  const handleMenuEdit = useCallback(() => {
    onEdit();
    setAnchorEl(null);
    // eslint-disable-next-line
  }, [onRemove, setAnchorEl]);

  return (
    <>
      <ListItemText className={classes.listItemText}>
        <Box display="flex">
          {image ? (
            <img src={image} alt="Article thumbnail" width="100" height="90" />
          ) : (
            <div className={classes.imagePlaceholder}></div>
          )}
          <Box ml={4}>
            <Typography variant="subtitle2" className={classes.title}>
              {date}
            </Typography>
            <Typography
              variant="body2"
              color="textSecondary"
              className={classes.title}
            >
              {title}
            </Typography>
          </Box>
        </Box>
      </ListItemText>
      <Box display="flex" alignSelf="flex-start" alignItems="center" mt={1}>
        <VirualizedListMenu
          anchorEl={anchorEl}
          open={isMenuOpened}
          onClose={handleMenuClose}
          onClick={handleMenuClick}
        >
          <MenuItem onClick={handleMenuEdit}>Edit article</MenuItem>
          <MenuItem onClick={handleMenuDelete}>Remove article</MenuItem>
        </VirualizedListMenu>
      </Box>
    </>
  );
};

export default KnowledgeListItemContent;
