import {
  addDoc,
  collection,
  limit,
  onSnapshot,
  orderBy,
  query,
  updateDoc,
} from '@firebase/firestore';
import { getDownloadURL, ref, uploadBytes } from '@firebase/storage';
import { doc } from 'firebase/firestore';
import { UploadRecord, UploadStatusType } from '../utils/types/upload';
import { auth, db, storage } from './firebase';

export const uploadToFirebase = async (
  file: File,
  shouldExtractUrl: boolean
): Promise<string> => {
  const storageRef = ref(
    storage,
    `uploads/${Date.now()}_${file.name.replace(/\s+/g, '_')}`
  );

  const snapshot = await uploadBytes(storageRef, file);

  if (shouldExtractUrl) {
    const downloadURL = await getDownloadURL(snapshot.ref);
    return downloadURL;
  } else {
    return snapshot.ref.fullPath; // Return the full path if URL extraction is not required
  }
};

export const addUploadToStore = async (
  url: string,
  fileName: string,
  type: string
): Promise<void> => {
  try {
    await addDoc(collection(db, 'uploads'), {
      path: url,
      fileName,
      created: Date.now(),
      user: auth.currentUser?.uid,
      status: UploadStatusType.new,
      type,
    });
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getUploads = (
  onUpdate: (uploads: Array<UploadRecord>) => void
): void => {
  const uploadsCollection = collection(db, 'uploads');
  const uploadsQuery = query(
    uploadsCollection,
    orderBy('created', 'desc'),
    limit(50)
  );

  const _unsubscribe = onSnapshot(uploadsQuery, (querySnapshot) => {
    const uploads = querySnapshot.docs.map((doc) => {
      return {
        id: doc.id,
        created: doc.data().created,
        fileName: doc.data().fileName,
        status: doc.data().status,
        user: doc.data().user,
        url: doc.data().url,
        rejectReport: doc.data().rejectReport,
        validationReport: doc.data().validationReport,
        scheduledDate: doc.data().scheduledDate,
      };
    });

    onUpdate(uploads);
  });

  // Remember to unsubscribe when the component unmounts or when you no longer need updates
  // unsubscribe();
};

export const updateUploadById = async (
  id: string,
  scheduledDate?: number
): Promise<void> => {
  const uploadDoc = doc(db, 'uploads', id);

  try {
    await updateDoc(uploadDoc, { scheduledDate });
  } catch (error) {
    console.error(error);
    throw error;
  }
};
