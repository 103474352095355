import { createContext, useCallback, useReducer, useState } from 'react';
import {
  KNOWLEDGE_CENTRE_ACTIONS,
  KnowledgeCentreReducer,
  KnowledgeCentreState,
  initialKnowledgeCentreState,
} from '../reducers/knowledgeCentre';
import {
  createFirebase,
  deleteFirebase,
  getBatchFirebase,
  updateFirebase,
  uploadImageToFirebase,
} from '../services/knowledgeCentre';
import { KnowledgeItem } from '../utils/types/knowledgeCentre';

interface KnowledgeCentreContext {
  state: KnowledgeCentreState;
  create: (item: KnowledgeItem) => void;
  update: (item: KnowledgeItem) => void;
  remove: (item: KnowledgeItem) => void;
  upload: (file: File) => void;
  uploadContentImage: (_file: File) => void;
  getBatch: (lastDoc: KnowledgeItem | null) => void;
}

export const KnowledgeCentreStore = createContext<KnowledgeCentreContext>({
  state: initialKnowledgeCentreState,
  create: () => {},
  update: () => {},
  remove: () => {},
  upload: () => {},
  uploadContentImage: () => {},
  getBatch: async () => {},
});

const { Provider } = KnowledgeCentreStore;

export const KnowledgeCentreProvider = ({ children }: any) => {
  const [imageUrl, setImageUrl] = useState<string | undefined>();
  const [state, dispatch] = useReducer(
    KnowledgeCentreReducer,
    initialKnowledgeCentreState
  );

  const create = useCallback(
    async (item: KnowledgeItem) => {
      dispatch({ type: KNOWLEDGE_CENTRE_ACTIONS.UPDATE_START });
      const _createdItem = await createFirebase({
        ...item,
        image: imageUrl || '',
      });
      setImageUrl(undefined);
      dispatch({
        type: KNOWLEDGE_CENTRE_ACTIONS.UPDATE_SUCCESS,
        data: [...(state.data || []), _createdItem],
      });
    },
    [imageUrl, state]
  );

  const update = useCallback(
    async (item: KnowledgeItem) => {
      dispatch({ type: KNOWLEDGE_CENTRE_ACTIONS.UPDATE_START });
      const result = await updateFirebase({
        ...item,
        image: imageUrl || item.image,
      });
      setImageUrl(undefined);
      let editedItemIndex = state.data?.findIndex((a: KnowledgeItem) => {
        return a.id === item.id;
      });
      let newData = state.data ? [...state.data] : undefined;
      if (newData && editedItemIndex !== undefined && editedItemIndex > -1) {
        newData[editedItemIndex] = result;
      }
      dispatch({
        type: KNOWLEDGE_CENTRE_ACTIONS.UPDATE_SUCCESS,
        data: newData,
      });
    },
    [imageUrl, state]
  );

  const remove = useCallback(
    async (item: KnowledgeItem) => {
      dispatch({ type: KNOWLEDGE_CENTRE_ACTIONS.REMOVE_START });
      await deleteFirebase(item);
      let index = state.data?.findIndex((a: KnowledgeItem) => {
        return a.id === item.id;
      });
      if (index !== undefined && index > -1) {
        let data = state.data;
        data?.splice(index, 1);
        dispatch({ type: KNOWLEDGE_CENTRE_ACTIONS.REMOVE_SUCCESS, data });
      }
    },
    [state]
  );

  const getBatch = useCallback(async (lastDoc: KnowledgeItem | null) => {
    dispatch({ type: KNOWLEDGE_CENTRE_ACTIONS.FETCH });
    const knowledgeItems = await getBatchFirebase(lastDoc);

    dispatch({
      type: KNOWLEDGE_CENTRE_ACTIONS.FETCH_DATA_SUCCESS,
      loading: false,
      data: knowledgeItems,
    });
  }, []);

  const upload = useCallback(async (file: File) => {
    dispatch({ type: KNOWLEDGE_CENTRE_ACTIONS.IMAGE_UPLOAD_START });
    const _imageUrl = await uploadImageToFirebase(file);
    setImageUrl(_imageUrl);
    dispatch({ type: KNOWLEDGE_CENTRE_ACTIONS.IMAGE_UPLOAD_SUCCESS });
  }, []);

  const uploadContentImage = useCallback(async (file: File) => {
    dispatch({ type: KNOWLEDGE_CENTRE_ACTIONS.IMAGE_UPLOAD_START });
    const _imageUrl = await uploadImageToFirebase(file);
    dispatch({
      type: KNOWLEDGE_CENTRE_ACTIONS.IMAGE_UPLOAD_SUCCESS,
      data: { contentImageUrl: _imageUrl },
    });
  }, []);

  return (
    <Provider
      value={{
        state,
        create,
        update,
        remove,
        upload,
        uploadContentImage,
        getBatch,
      }}
    >
      {children}
    </Provider>
  );
};
