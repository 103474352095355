import { User } from '../utils/types/user';

export interface UsersState {
  data: Array<User> | null;
  selectedUser?: User;
  loading: boolean;
  updating: boolean;
  error: any;
}

export const initialUsersState: UsersState = {
  data: null,
  loading: false,
  updating: false,
  error: null,
};

export const USERS_ACTIONS = {
  FETCH: 'USERS/FETCH',
  FETCH_DATA_SUCCESS: 'USERS/SUCCESS',
  FETCH_DATA_FAIL: 'USERS/FAIL',
  LOAD_MORE_DATA_SUCCESS: 'USERS/LOAD_MORE_DATA_SUCCESS',
  LOAD_MORE_DATA_FAIL: 'USERS/LOAD_MORE_FAIL',
  LOAD_MORE_DATA_START: 'USERS/LOAD_MORE_DATA_START',
  UPDATE_START: 'USERS/UPDATE_START',
  UPDATE_SUCCESS: 'USERS/UPDATE_SUCCESS',
  UPDATE_FAIL: 'USERS/UPDATE_FAIL',
  REMOVE_START: 'USERS/REMOVE_START',
  REMOVE_SUCCESS: 'USERS/REMOVE_SUCCESS',
  FETCH_DETAILS: 'USERS/FETCH_DETAILS',
  FETCH_DETAILS_SUCCESS: 'USERS/FETCH_DETAILS_SUCCESS',
};

export const UsersReducer = (state: UsersState, action: any): UsersState => {
  switch (action.type) {
    case USERS_ACTIONS.FETCH:
      return { data: null, updating: false, loading: true, error: null };
    case USERS_ACTIONS.FETCH_DATA_SUCCESS:
      return {
        ...state,
        data: action.data,
        updating: false,
        loading: false,
        error: null,
      };
    case USERS_ACTIONS.LOAD_MORE_DATA_START:
      return {
        ...state,
        updating: true,
        loading: true,
        error: null,
      };
    case USERS_ACTIONS.LOAD_MORE_DATA_SUCCESS:
      return {
        ...state,
        data: [...(state.data ? state.data : []), ...action.data],
        updating: false,
        loading: false,
        error: null,
      };
    case USERS_ACTIONS.LOAD_MORE_DATA_FAIL:
      return {
        ...state,
        updating: false,
        loading: false,
        error: action.error,
      };
    case USERS_ACTIONS.FETCH_DATA_FAIL:
      return { data: null, updating: false, loading: false, error: null };
    case USERS_ACTIONS.UPDATE_START:
      return { ...state, updating: true };
    case USERS_ACTIONS.UPDATE_SUCCESS:
      return {
        ...state,
        updating: false,
        loading: false,
        error: null,
      };
    case USERS_ACTIONS.UPDATE_FAIL:
      return {
        ...state,
        updating: false,
        loading: false,
        error: action.error,
      };
    case USERS_ACTIONS.REMOVE_START:
      return { ...state, updating: true };
    case USERS_ACTIONS.REMOVE_SUCCESS:
      return { ...state, data: action.data };
    case USERS_ACTIONS.FETCH_DETAILS:
      return { ...state, loading: true };
    case USERS_ACTIONS.FETCH_DETAILS_SUCCESS:
      return { ...state, selectedUser: action.data, loading: false };
    default:
      throw new Error();
  }
};
