import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  Divider,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import WrappedDialog from '../common/WrappedDialog';

interface DirectoryDeleteDialogProps {
  isDialogOpened: boolean;
  onDialogClose: () => void;
  onDialogOpen: () => void;
  onDelete: () => void;
}

const useStyles = makeStyles((theme) => ({
  button: {
    width: 100,
    borderRadius: 10,
    marginLeft: theme.spacing(2),
  },
  content: {
    padding: 0,
  },
  text: {
    fontFamily: 'Hero New Bold',
    fontSize: 12,
  },
}));

const DirectoryDeleteDialog: React.FC<DirectoryDeleteDialogProps> = ({
  isDialogOpened,
  onDialogClose,
  onDelete,
}: DirectoryDeleteDialogProps): JSX.Element => {
  const classes = useStyles();

  return (
    <WrappedDialog
      open={isDialogOpened}
      handleDialogClose={onDialogClose}
      dialogTitle={'Delete profile'}
    >
      <DialogContent classes={{ root: classes.content }}>
        <Divider />
        <Box mr={10} ml={10}>
          <Box mt={6} mb={6}>
            <Box display="flex">
              <Typography classes={{ root: classes.text }}>
                Profile –{' '}
              </Typography>
              <Typography classes={{ root: classes.text }} color="primary">
                Joe Bloggs
              </Typography>
            </Box>
            <Typography variant="body2" color="textSecondary">
              Last updated July 26, 2021 12:01 AM by John Smith
            </Typography>
          </Box>
          <Divider />
          <Box mt={6} mb={6}>
            <Typography variant="body1">
              Are you sure you want to delete the above profile?
            </Typography>
          </Box>
        </Box>
        <Divider />
      </DialogContent>
      <DialogActions>
        <Box mt={3} mb={3} mr={8}>
          <Button
            onClick={onDialogClose}
            color="secondary"
            variant="contained"
            disableElevation
            classes={{ root: classes.button }}
          >
            Cancel
          </Button>
          <Button
            onClick={onDelete}
            color="secondary"
            variant="contained"
            disableElevation
            classes={{ root: classes.button }}
          >
            Delete
          </Button>
        </Box>
      </DialogActions>
    </WrappedDialog>
  );
};

export default DirectoryDeleteDialog;
