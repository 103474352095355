import { Box, Typography } from '@material-ui/core';
import React from 'react';
import StyledPaper from '../common/StyledPaper';
import ProfileRecordRow from './ProfileRecordRow';
interface ProfileRecordProps {
  title: string;
  mapping?: any;
  data?: any;
}

const ProfileRecord: React.FC<ProfileRecordProps> = ({
  title,
  mapping,
  data,
}: ProfileRecordProps): JSX.Element => {
  return (
    <StyledPaper>
      <Box pt={4} pb={4} pl={4} pr={4} mb={4}>
        <Typography variant="subtitle1" color="primary" align="left">
          {title}
        </Typography>
        {mapping
          ?.filter((m: any) => m.order !== -1)
          .sort((m1: any, m2: any) => (m1.order > m2.order ? 1 : -1))
          ?.map((m: any) => {
            const fieldId = Object.keys(m)[0];
            return (
              <ProfileRecordRow
                key={`profile-record-row-${fieldId}`}
                title={m[fieldId]}
                value={data[fieldId]}
              />
            );
          })}
      </Box>
    </StyledPaper>
  );
};

export default ProfileRecord;
