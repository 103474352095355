import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { TooltipsStore } from '../../providers/tooltips';
import { serialize } from '../../utils/helpers/htmlSerializer.helpers';
import { Tooltip } from '../../utils/types/tooltip';
import { CustomElement } from '../common/TextEditor';
import TooltipsAddEditForm from './TooltipsAddEditForm';
import { TooltipsFormFields } from './TooltipsFormSection';

interface TooltipsEditContentProps {}

const TooltipsEditContent: React.FC<
  TooltipsEditContentProps
> = (): JSX.Element => {
  const [defaultValues, setDefaultValues] = useState<TooltipsFormFields>({
    id: 'initial_id',
    tooltipTitle: '',
    tooltipKey: '',
    tooltipPublishDate: new Date(),
    tooltipPublishTime: new Date(),
    tooltipText: [] as CustomElement[],
  });
  const { id } = useParams<any>();
  const history = useHistory();

  const {
    state: { data },
    update,
  } = useContext(TooltipsStore);

  useEffect(() => {
    const selectedTooltip = data?.find((tooltip: Tooltip) => tooltip.id === id);

    if (selectedTooltip) {
      const _defaultValues = {
        id: selectedTooltip.id,
        tooltipTitle: selectedTooltip.title,
        tooltipKey: selectedTooltip.tooltipKey,
        tooltipPublishDate: selectedTooltip.created,
        tooltipPublishTime: selectedTooltip.created,
        tooltipText: selectedTooltip.rawText
          ? (selectedTooltip.rawText as CustomElement[])
          : ([
              {
                type: 'paragraph',
                children: [{ text: '' }],
              },
            ] as CustomElement[]),
      };
      setDefaultValues(_defaultValues);
    }
  }, [id, data]);

  const getTooltipPublishDateTime = (date: Date, time: Date): Date => {
    const dateTime = new Date(date);
    dateTime.setHours(time.getHours());
    dateTime.setMinutes(time.getMinutes());
    return dateTime;
  };

  const handleUpdate = async (formData: TooltipsFormFields) => {
    const selectedTooltip = data?.find((tooltip: Tooltip) => tooltip.id === id);
    const tooltip = {
      id: selectedTooltip?.id,
      title: formData.tooltipTitle,
      tooltipKey: formData.tooltipKey,
      created: getTooltipPublishDateTime(
        formData.tooltipPublishDate,
        formData.tooltipPublishTime
      ),
      updated: new Date(),
      rawText: formData.tooltipText,
      text: serialize({ children: formData.tooltipText }),
    };
    update(tooltip);
    history.goBack();
  };

  return (
    <TooltipsAddEditForm
      defaultValues={defaultValues}
      handleUpdate={handleUpdate}
      title="Edit Tooltip"
      editMode={true}
    />
  );
};

export default TooltipsEditContent;
