import {
  MultiFactorError,
  TotpMultiFactorGenerator,
  UserCredential,
  getMultiFactorResolver,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signOut,
} from 'firebase/auth';
import { auth } from './firebase';

export const loginWithFirebase = async (email: string, password: string) => {
  const result: UserCredential = await signInWithEmailAndPassword(
    auth,
    email,
    password
  );

  return result;
};

export const checkFirebaseAuth = async (): Promise<any> => {
  const promise = new Promise((resolve, reject) => {
    onAuthStateChanged(auth, async (user) => {
      const token = await user?.getIdTokenResult();
      if (
        token?.claims.role !== 'superadmin' &&
        token?.claims.role !== 'editor' &&
        user?.uid
      ) {
        signOut(auth);
        reject({
          message:
            'You do not have permission to access this page, please contact your administrator',
        });
      } else {
        resolve({ ...user, role: token?.claims.role });
      }
    });
  });

  return promise;
};

export const logoutFirebase = async () => {
  return signOut(auth);
};

export const resolveMfaSignin = async (
  otp: string,
  error: MultiFactorError
) => {
  const mfaResolver = getMultiFactorResolver(auth, error);

  const multiFactorAssertion = TotpMultiFactorGenerator.assertionForSignIn(
    mfaResolver.hints[0].uid,
    otp
  );
  try {
    const userCredential = await mfaResolver.resolveSignIn(
      multiFactorAssertion
    );
    // Successfully signed in!
    return userCredential;
  } catch (error) {
    // Invalid or expired OTP.
    console.error('Error signing in user with TTOP: ', error);
  }
};
