import { useCallback, useEffect, useState } from 'react';

const useKeyboardEvents = () => {
  const [currentPosition, setCurrentPosition] = useState({
    x: 0,
    y: 0,
  });
  const [key, setKey] = useState({
    key: '',
  });

  const [mode, setMode] = useState('link');

  var sel = window.getSelection();
  const clientRect = sel?.focusNode?.parentElement?.getBoundingClientRect();

  useEffect(() => {
    const onKeyDown = (kd: KeyboardEvent) => {
      if (kd.key === '@') {
        setMode('internal');
        setKey({
          key: kd.key,
        });
      }
    };
    window.addEventListener('keydown', onKeyDown);
    return () => {
      window.removeEventListener('keydown', onKeyDown);
    };
  }, []);

  useEffect(() => {
    const onKeyUp = (kd: KeyboardEvent) => {
      if (kd.key === '@') {
        return;
      }

      if (kd.key === 'Escape') {
        setMode('link');
        setKey({ key: '' });
      }

      setKey({
        key: kd.key,
      });
    };

    window.addEventListener('keyup', onKeyUp);
    return () => {
      window.removeEventListener('keyup', onKeyUp);
    };
  }, []);

  useEffect(() => {
    if (sel?.focusNode?.nodeName === '#text') {
      setCurrentPosition({
        x: (clientRect?.x || 0) + (clientRect?.width || 0),
        y: clientRect?.y || 0,
      });
    }
  }, [clientRect?.x, clientRect?.y, clientRect?.width]);

  const resetMode = useCallback(() => {
    setMode('link');
  }, [mode]);

  const resetKey = useCallback(() => {
    setKey({ key: '' });
  }, []);

  return {
    ...currentPosition,
    ...key,
    mode,
    resetMode,
    resetKey,
  };
};

export default useKeyboardEvents;
