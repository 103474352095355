import { Box } from '@material-ui/core';
import React from 'react';
import { Route, useLocation } from 'react-router-dom';
import UpdatesContent from '../../components/updates/UpdatesContent';
import { UpdatesProvider } from '../../providers/updates';
import { PROFILE_COMPARISON_ROUTE, UPDATES_ROUTE } from '../../routes/Types';
import ProfilesComparisonPage from './ProfilesComparisonPage';

interface UpdatesPageProps {}

const UpdatesPage: React.FC<UpdatesPageProps> = (): JSX.Element => {
  const currentLocation = useLocation();
  return (
    <UpdatesProvider>
      <Box
        style={
          currentLocation.pathname !== UPDATES_ROUTE ? { display: 'none' } : {}
        }
      >
        <UpdatesContent />
      </Box>
      <Route path={`${PROFILE_COMPARISON_ROUTE}/:id`}>
        <ProfilesComparisonPage />
      </Route>
    </UpdatesProvider>
  );
};

export default UpdatesPage;
