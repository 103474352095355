import React from 'react';

import UploadContent from '../../components/upload/UploadContent';
import { UploadsProvider } from '../../providers/uploads';

interface UploadPageProps {}

const UploadPage: React.FC<UploadPageProps> = (): JSX.Element => {
  return (
    <UploadsProvider>
      <UploadContent />
    </UploadsProvider>
  );
};

export default UploadPage;
