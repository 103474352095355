export interface StorageState {
  data: string;
  loading: boolean;
  updating: boolean;
  error: any;
}

export const initialStorageState: StorageState = {
  data: '',
  loading: false,
  updating: false,
  error: null,
};

export const STORAGE_ACTIONS = {
  UPLOAD_START: 'STORAGE/UPLOAD_START',
  UPLOAD_SUCCESS: 'STORAGE/UPLOAD_SUCCESS',
  UPDATE_START: 'STORAGE/UPDATE_START',
  UPDATE_SUCCESS: 'STORAGE/UPDATE_SUCCESS',
  FETCH_DATA_FAIL: 'STORAGE/FAIL',
  RESET: 'STORAGE/RESET',
};

export const StorageReducer = (
  state: StorageState,
  action: any
): StorageState => {
  switch (action.type) {
    case STORAGE_ACTIONS.UPLOAD_START:
      return { data: '', loading: true, updating: false, error: null };
    case STORAGE_ACTIONS.UPLOAD_SUCCESS:
      return {
        data: action.data,
        loading: false,
        updating: false,
        error: null,
      };
    case STORAGE_ACTIONS.UPDATE_START:
      return { ...state, updating: true, error: null };
    case STORAGE_ACTIONS.UPDATE_SUCCESS:
      return { ...state, updating: false, error: null };
    case STORAGE_ACTIONS.FETCH_DATA_FAIL:
      return { data: '', loading: false, updating: false, error: null };
    case STORAGE_ACTIONS.RESET:
      return { ...state };
    default:
      throw new Error();
  }
};
