import { Grid, Tab, Tabs, makeStyles } from '@material-ui/core';
import { TabContext, TabPanel } from '@material-ui/lab';
import React, { useContext } from 'react';
import { DeleteProfilesStore } from '../../providers/deleteProfiles';
import { DELETE_PROFILES_PAGE } from '../../utils/constants/pageNames.constants';
import { DeleteProfileTab } from '../../utils/types/deleteProfiles';
import ContentLayout from '../common/ContentLayout';
import DeletedCompaniesView from './DeletedCompaniesView';
import DeletedIndividualsView from './DeletedIndividualsView';

interface DeletedProfilesContentProps {}

const useStyles = makeStyles((theme) => {
  return {
    itemOrder: {
      order: 1,
      height: '480px',
      [theme.breakpoints.up('md')]: {
        order: 'initial',
        height: 'auto',
      },
    },
  };
});

const DeletedProfilesContent: React.FC<
  DeletedProfilesContentProps
> = (): JSX.Element => {
  const classes = useStyles();
  const {
    state: { selectedTab },
    onTabChange,
  } = useContext(DeleteProfilesStore);

  return (
    <ContentLayout title={DELETE_PROFILES_PAGE}>
      <Grid item xs={12} sm={12} md={12} lg={12} className={classes.itemOrder}>
        <TabContext value={selectedTab}>
          <Tabs
            value={selectedTab}
            onChange={(e, val) => onTabChange(val)}
            aria-label="Deleted profiles tabs"
          >
            <Tab label="Individuals" value={DeleteProfileTab.individuals} />
            <Tab label="Companies" value={DeleteProfileTab.companies} />
          </Tabs>
          <TabPanel value={DeleteProfileTab.individuals}>
            <DeletedIndividualsView />
          </TabPanel>
          <TabPanel value={DeleteProfileTab.companies}>
            <DeletedCompaniesView />
          </TabPanel>
        </TabContext>
      </Grid>
    </ContentLayout>
  );
};

export default DeletedProfilesContent;
