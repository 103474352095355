import React from 'react';
import KnowledgeCentreEditContent from '../../components/knowledge-centre/KnowledgeCentreEditContent';

interface KnowledgeCentreEditPageProps {}

const KnowledgeCentreEditPage: React.FC<
  KnowledgeCentreEditPageProps
> = (): JSX.Element => {
  return <KnowledgeCentreEditContent />;
};

export default KnowledgeCentreEditPage;
