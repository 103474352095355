import { useQuery } from '@tanstack/react-query';
import { useContext } from 'react';
import { AuthStore } from '../../providers/auth';
import { fetchAllOrganizations } from '../../services/organisations';
import { Organisation } from '../../utils/types/organisation';

export const useOrganisations = () => {
  const [{ user }] = useContext(AuthStore);

  const { data, isLoading, error } = useQuery<Organisation[]>(
    ['organizations', 'lists'],
    () => fetchAllOrganizations(),
    {
      enabled: !!user.role && user.role === 'superadmin',
    }
  );

  return { organisations: data, isLoading, error };
};

export default useOrganisations;
