import { createContext, useCallback, useReducer, useState } from 'react';
import {
  GlossaryReducer,
  GlossaryState,
  GLOSSARY_ACTIONS,
  initialGlossaryState,
} from '../reducers/glossary';
import {
  createFirebase,
  deleteFirebase,
  getBatchFirebase,
  getFullGlossary,
  GlossaryFilterType,
  updateFirebase,
  uploadImageToFirebase,
} from '../services/glossary';
import { GlossarySort } from '../utils/constants/glossarySort.constants';

import { GlossaryTerm } from '../utils/types/glossary-term';
interface GlossaryContext {
  state: GlossaryState;
  create: (glossaryTerm: GlossaryTerm) => void;
  update: (glossaryTerm: GlossaryTerm) => void;
  remove: (glossaryTerm: GlossaryTerm) => void;
  uploadContentImage: (_file: File) => void;
  getGlossaryBatch: (lastDoc: GlossaryTerm | null) => void;
  onKeywordChange: (value: string) => void;
  onSortChange: (value: GlossarySort) => void;
  search: () => void;
  filter: GlossaryFilterType;
  sort: GlossarySort;
  filteredGlossary: GlossaryTerm[] | null;
  fullDataFetched: boolean;
}

export const GlossaryStore = createContext<GlossaryContext>({
  state: initialGlossaryState,
  create: () => {},
  update: () => {},
  remove: () => {},
  uploadContentImage: () => {},
  getGlossaryBatch: async () => {},
  onKeywordChange: () => {},
  onSortChange: () => {},
  search: () => {},
  filter: {},
  filteredGlossary: null,
  sort: 'updated-desc',
  fullDataFetched: false,
});

const { Provider } = GlossaryStore;

export const GlossaryProvider = ({ children }: any) => {
  const [state, dispatch] = useReducer(GlossaryReducer, initialGlossaryState);
  const [filter, setFilter] = useState({ keyword: '' });
  const [sort, setSort] = useState<GlossarySort>('updated-desc');
  const [filteredGlossary, setFilteredGlossary] = useState<
    GlossaryTerm[] | null
  >(null);
  const [fullDataFetched, setFullDataFetched] = useState(false);

  const create = useCallback(
    async (glossaryTerm: GlossaryTerm) => {
      dispatch({ type: GLOSSARY_ACTIONS.UPDATE_START });
      const _createdGlossaryTerm = await createFirebase({
        ...glossaryTerm,
      });
      dispatch({
        type: GLOSSARY_ACTIONS.UPDATE_SUCCESS,
        data: [...(state.data || []), _createdGlossaryTerm],
      });
    },
    [state]
  );

  const update = useCallback(
    async (glossaryTerm: GlossaryTerm) => {
      dispatch({ type: GLOSSARY_ACTIONS.UPDATE_START });
      await updateFirebase({ ...glossaryTerm });
      let editedGlossaryTermIndex = state.data?.findIndex((a: GlossaryTerm) => {
        return a.id === glossaryTerm.id;
      });
      let newData = state.data ? [...state.data] : undefined;
      if (
        newData &&
        editedGlossaryTermIndex !== undefined &&
        editedGlossaryTermIndex > -1
      ) {
        newData[editedGlossaryTermIndex] = glossaryTerm;
      }
      dispatch({ type: GLOSSARY_ACTIONS.UPDATE_SUCCESS, data: newData });
    },
    [state]
  );

  const remove = useCallback(
    async (glossaryTerm: GlossaryTerm) => {
      dispatch({ type: GLOSSARY_ACTIONS.REMOVE_START });
      await deleteFirebase(glossaryTerm);
      let index = state.data?.findIndex((a: GlossaryTerm) => {
        return a.id === glossaryTerm.id;
      });
      if (index !== undefined && index > -1) {
        let data = state.data;
        data?.splice(index, 1);
        dispatch({ type: GLOSSARY_ACTIONS.REMOVE_SUCCESS, data });
      }
    },
    [state]
  );

  const manuallySortGlossary = useCallback(
    (sortValue: string, data: GlossaryTerm[]) => {
      const [field, _sort] = sortValue.split('-');
      let sortedGlossary;
      if (field === 'updated') {
        sortedGlossary = data?.sort((g1: GlossaryTerm, g2: GlossaryTerm) => {
          return g1.updated > g2.updated ? -1 : 1;
        });
        if (_sort === 'asc') {
          sortedGlossary = data?.sort((g1: GlossaryTerm, g2: GlossaryTerm) => {
            return g1.updated < g2.updated ? -1 : 1;
          });
        }
      }
      if (field === 'title') {
        sortedGlossary = data?.sort((g1: GlossaryTerm, g2: GlossaryTerm) => {
          return g1.title > g2.title ? -1 : 1;
        });
        if (_sort === 'asc') {
          sortedGlossary = data?.sort((g1: GlossaryTerm, g2: GlossaryTerm) => {
            return g1.title < g2.title ? -1 : 1;
          });
        }
      }
      if (filteredGlossary) {
        setFilteredGlossary(sortedGlossary || []);
      } else {
        dispatch({
          type: GLOSSARY_ACTIONS.FETCH_INITIAL_DATA_SUCCESS,
          data: sortedGlossary,
        });
      }
    },
    [filteredGlossary]
  );

  const onSortChange = useCallback(
    async (value: GlossarySort) => {
      setSort(value);
      if (fullDataFetched) {
        manuallySortGlossary(value, filteredGlossary || state.data || []);
      } else {
        getInitialGlossaryBatch(value);
      }
    },
    [setSort, fullDataFetched, filteredGlossary, state.data]
  );

  const onKeywordChange = useCallback(
    async (value: string) => {
      setFilter({ keyword: value });
      if (!value) {
        setFilteredGlossary(null);
        manuallySortGlossary(sort, state.data || []);
      }
    },
    [setFilter, sort, filteredGlossary, state.data]
  );

  const search = useCallback(async () => {
    const { keyword } = filter;
    let glossary = state.data || [];

    if (!fullDataFetched) {
      dispatch({ type: GLOSSARY_ACTIONS.FETCH });
      glossary = await getFullGlossary(sort);
      dispatch({
        type: GLOSSARY_ACTIONS.FETCH_INITIAL_DATA_SUCCESS,
        data: glossary,
      });
      setFullDataFetched(true);
    }

    const _filteredGlossary = glossary.filter((term) =>
      term.title.toLocaleLowerCase().includes(keyword.toLocaleLowerCase())
    );
    setFilteredGlossary(_filteredGlossary);
  }, [filter, state, sort]);

  const getInitialGlossaryBatch = useCallback(async (sort: GlossarySort) => {
    dispatch({ type: GLOSSARY_ACTIONS.FETCH });

    const glossary = await getBatchFirebase(null, sort);

    dispatch({
      type: GLOSSARY_ACTIONS.FETCH_INITIAL_DATA_SUCCESS,
      loading: false,
      data: glossary,
    });
  }, []);

  const getGlossaryBatch = useCallback(
    async (lastDoc: GlossaryTerm | null) => {
      if (fullDataFetched) return;
      dispatch({ type: GLOSSARY_ACTIONS.FETCH });
      const glossary = await getBatchFirebase(lastDoc, sort);
      if (glossary.length < 20) {
        setFullDataFetched(true);
      }

      dispatch({
        type: GLOSSARY_ACTIONS.FETCH_DATA_SUCCESS,
        loading: false,
        data: glossary,
      });
    },
    [sort, fullDataFetched]
  );

  const uploadContentImage = useCallback(async (file: File) => {
    dispatch({ type: GLOSSARY_ACTIONS.IMAGE_UPLOAD_START });
    const _imageUrl = await uploadImageToFirebase(file);
    dispatch({
      type: GLOSSARY_ACTIONS.IMAGE_UPLOAD_SUCCESS,
      data: { contentImageUrl: _imageUrl },
    });
  }, []);

  return (
    <Provider
      value={{
        state,
        create,
        update,
        remove,
        uploadContentImage,
        getGlossaryBatch,
        onKeywordChange,
        search,
        filter,
        filteredGlossary,
        onSortChange,
        sort,
        fullDataFetched,
      }}
    >
      {children}
    </Provider>
  );
};
