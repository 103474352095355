import React from 'react';
import { Box, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import { useCallback } from 'react';
import Searchbar from '../common/Searchbar';

interface OrganisationSearchProps {
  keyword: string;
  onSearch: () => void;
  onClear: () => void;
  onChange: (value: string) => void;
}

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 55,
    minHeight: theme.spacing(10),
    marginLeft: theme.spacing(5),
    borderRadius: 10,
    border: 0,
  },
}));

const OrganisationsSearch = ({
  onChange,
  onClear,
  onSearch,
  keyword,
}: OrganisationSearchProps) => {
  const classes = useStyles();

  const handleOnSearch = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      onSearch();
    },
    [onSearch]
  );

  return (
    <Box display="flex">
      <Searchbar keyword={keyword} onChange={onChange} onClear={onClear} />
      <Button
        classes={{
          root: classes.root,
        }}
        variant="contained"
        color="primary"
        onClick={handleOnSearch}
        type="submit"
      >
        <SearchIcon fontSize="medium" />
      </Button>
    </Box>
  );
};

export default OrganisationsSearch;
