import { Box } from '@material-ui/core';
import React from 'react';
import { Route, useLocation } from 'react-router-dom';
import GlossaryContent from '../../components/glossary/GlossaryContent';
import { DirectoryProvider } from '../../providers/directory';
import { GlossaryProvider } from '../../providers/glossary';
import {
  GLOSSARY_ADD_ROUTE,
  GLOSSARY_EDIT_ROUTE,
  GLOSSARY_ROUTE,
} from '../../routes/Types';
import GlossaryAddPage from './GlossaryAddPage';
import GlossaryEditPage from './GlossaryEditPage';

interface GlossaryPageProps {}

const GlossaryPage: React.FC<GlossaryPageProps> = (): JSX.Element => {
  const currentLocation = useLocation();
  return (
    <DirectoryProvider>
      <GlossaryProvider>
        <Box
          style={
            currentLocation.pathname !== GLOSSARY_ROUTE
              ? { display: 'none' }
              : {}
          }
        >
          <GlossaryContent />
        </Box>

        <Route path={GLOSSARY_ADD_ROUTE}>
          <GlossaryAddPage />
        </Route>

        <Route path={GLOSSARY_EDIT_ROUTE}>
          <GlossaryEditPage />
        </Route>
      </GlossaryProvider>
    </DirectoryProvider>
  );
};

export default GlossaryPage;
