import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Divider,
  List,
  ListItem,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useCallback, useContext, useState } from 'react';
import { UpdatesStore } from '../../providers/updates';
import StyledPaper from '../common/StyledPaper';

type PublishHistoryListItem = {
  id: string;
  title: string;
  type: string;
  date: number;
  user: string;
};

interface PublishHistoryListProps {
  list: PublishHistoryListItem[];
}

const useStyles = makeStyles((theme) => ({
  listItem: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  chip: {
    background: 'rgba(76, 217, 100, 1)',
    borderRadius: 4,
    height: 20,
  },
  chipLabel: {
    color: theme.palette.common.white,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
}));

const PublishHistoryList: React.FC<PublishHistoryListProps> = ({
  list,
}: PublishHistoryListProps): JSX.Element => {
  const classes = useStyles();
  const [selectedId, setSelectedId] = useState<string | undefined>(undefined);
  const {
    state: { updating },
    revertUpdate,
  } = useContext(UpdatesStore);

  const onRevertClicked = useCallback(
    (id: string) => {
      setSelectedId(id);
      revertUpdate(id);
    },
    [revertUpdate]
  );

  return (
    <StyledPaper>
      <List>
        <Box pt={0}>
          {list.map((listItem: PublishHistoryListItem, index: number) => (
            <Box key={`publish-history-list-item-${listItem.id}`}>
              {index > 0 && <Divider />}
              <ListItem classes={{ root: classes.listItem }}>
                <Box mb={4}>
                  <Typography variant="subtitle2" align="left">
                    {listItem.title}
                  </Typography>
                  {index === 0 && (
                    <Chip
                      classes={{ root: classes.chip, label: classes.chipLabel }}
                      label="Last update"
                    ></Chip>
                  )}
                </Box>
                <Typography variant="body2" align="left">
                  {listItem.type}
                </Typography>
                <Typography
                  variant="caption"
                  color="textSecondary"
                  align="left"
                >
                  Updated {new Date(listItem?.date).toLocaleDateString()} by{' '}
                  {listItem?.user}
                </Typography>
                <Box
                  pt={2}
                  pb={2}
                  textAlign={'right'}
                  width={'100%'}
                  display={'flex'}
                  justifyContent={'flex-end'}
                >
                  {selectedId === listItem.id && updating && (
                    <CircularProgress />
                  )}
                  <Button
                    disabled={updating}
                    variant={'text'}
                    onClick={() => onRevertClicked(listItem.id)}
                  >
                    <Typography
                      variant="body2"
                      color={updating ? 'secondary' : 'primary'}
                    >
                      Revert
                    </Typography>
                  </Button>
                </Box>
              </ListItem>
            </Box>
          ))}
        </Box>
      </List>
    </StyledPaper>
  );
};

export default PublishHistoryList;
