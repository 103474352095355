import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { TooltipsStore } from '../../providers/tooltips';
import { serialize } from '../../utils/helpers/htmlSerializer.helpers';
import { CustomElement } from '../common/TextEditor';
import TooltipsAddEditForm from './TooltipsAddEditForm';
import { TooltipsFormFields } from './TooltipsFormSection';

interface TooltipsAddContentProps {}

const defaultValues = {
  tooltipKey: '',
  tooltipTitle: '',
  tooltipPublishDate: new Date(),
  tooltipPublishTime: new Date(),
  tooltipText: [
    {
      type: 'paragraph',
      children: [{ text: '' }],
    },
  ] as CustomElement[],
};

const TooltipsAddContent: React.FC<
  TooltipsAddContentProps
> = (): JSX.Element => {
  const history = useHistory();

  const { create } = useContext(TooltipsStore);

  const getTooltipPublishDateTime = (date: Date, time: Date): Date => {
    const dateTime = new Date(date);
    dateTime.setHours(time.getHours());
    dateTime.setMinutes(time.getMinutes());
    return dateTime;
  };

  const handleCreate = async (data: TooltipsFormFields) => {
    const timeStamp = getTooltipPublishDateTime(
      data.tooltipPublishDate,
      data.tooltipPublishTime
    );
    const tooltip = {
      tooltipKey: data.tooltipKey,
      title: data.tooltipTitle,
      created: timeStamp,
      updated: timeStamp,
      rawText: data.tooltipText,
      text: serialize({ children: data.tooltipText }),
    };
    await create(tooltip);
    history.goBack();
  };

  return (
    <TooltipsAddEditForm
      defaultValues={defaultValues}
      handleUpdate={handleCreate}
      title="Add tooltip"
      editMode={false}
    />
  );
};

export default TooltipsAddContent;
