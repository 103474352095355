export type FilterOption = {
  optionName: string;
  id: string;
  type: string;
};

export const OPTIONS_LIST: FilterOption[] = [
  { optionName: 'People', id: 'individual', type: 'types' },
  { optionName: 'Company', id: 'company', type: 'types' },
  { optionName: 'Status - New', id: 'New', type: 'statuses' },
  { optionName: 'Status - Reviewing', id: 'Needs Reviewing', type: 'statuses' },
  { optionName: 'Status - Healthy', id: 'Healthy', type: 'statuses' },
  { optionName: 'Status - Moderate', id: 'Moderate', type: 'statuses' },
  { optionName: 'Live', id: 'live', type: 'additionalTypes' },
  { optionName: 'Not Live', id: 'notLive', type: 'additionalTypes' },
  { optionName: 'Missing Logo', id: 'missingLogo', type: 'additionalTypes' },
];
