import {
  AppBar,
  Box,
  Divider,
  Drawer,
  IconButton,
  Toolbar,
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Menu } from '@material-ui/icons';
import React, { useCallback, useState } from 'react';
import { LAYOUT } from '../../utils/constants/layout.constants';
import Logo from './Logo';
import Navigation from './Navigation';
import UserAvatar from './UserAvatar';

interface DrawerMobileProps {
  onMenuItemClick: React.MouseEventHandler;
  logout: Function;
}

const useStyles = makeStyles((theme) => ({
  appBar: {
    backgroundColor: 'white',
    boxShadow: 'none',
  },
  toolBar: {
    justifyContent: 'space-between',
    padding: 0,
  },
  menuButton: {
    marginRight: theme.spacing(4),
  },
  drawerPaper: {
    width: LAYOUT.DrawerWidth,
    backgroundColor: theme.palette.primary.main,
  },
}));

const DrawerMobile: React.FC<DrawerMobileProps> = ({
  onMenuItemClick,
  logout,
}: DrawerMobileProps): JSX.Element => {
  const classes = useStyles();
  const theme = useTheme();

  const [mobileOpen, setMobileOpen] = useState(false);

  const container =
    window !== undefined ? () => window.document.body : undefined;

  const handleDrawerToggle = useCallback(() => {
    setMobileOpen(!mobileOpen);
  }, [mobileOpen]);

  return (
    <>
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar className={classes.toolBar}>
          <Box ml={4}>
            <Logo variant="primary" width={120} height={34} />
          </Box>

          <IconButton
            color="primary"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <Menu />
          </IconButton>
        </Toolbar>
      </AppBar>
      <nav aria-label="mailbox folders">
        <Drawer
          container={container}
          variant="temporary"
          anchor={theme.direction === 'rtl' ? 'right' : 'left'}
          open={mobileOpen}
          onClose={handleDrawerToggle}
          classes={{
            paper: classes.drawerPaper,
          }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          <Box mt={14} ml={4}>
            <UserAvatar />
            <Divider />
          </Box>

          <Navigation onMenuItemClick={onMenuItemClick} logout={logout} />
        </Drawer>
      </nav>
    </>
  );
};

export default DrawerMobile;
