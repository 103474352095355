import {
  Box,
  Button,
  Divider,
  List,
  ListItem,
  ListItemText,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useCallback, useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { UpdatesStore } from '../../providers/updates';
import { MANAGE_TILES, PROFILE_COMPARISON_ROUTE } from '../../routes/Types';
import { PendingUpdatesListItem } from '../../services/updates';
import {
  CHIPS,
  ChipStatus,
} from '../../utils/constants/chipStatuses.constants';
import { UploadStatusType } from '../../utils/types/upload';
import StatusChip from '../common/StatusChip';
import ApproveDialog from './ApproveDialog';
import RejectDialog from './RejectDialog';

export interface RejectDialogFormFields {
  rejectionReasons: string;
}

const useStyles = makeStyles((theme) => ({
  item: {
    paddingTop: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingBottom: theme.spacing(0),
    paddingLeft: theme.spacing(4),
    boxShadow: '0px 4px 16px 1px rgba(76, 83, 94, 0.2)',
    borderRadius: 12,
  },
  listItem: {
    justifyContent: 'space-between',
    paddingBottom: theme.spacing(0),
    paddingTop: theme.spacing(0),
  },
  title: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  type: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  date: {
    display: 'inline-block',
    width: '100%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  link: {
    textDecoration: 'none',
    '&:visited': {
      color: theme.palette.text.primary,
    },
    '&:hover': {
      color: theme.palette.primary.main,
    },
    '&:active': {
      color: theme.palette.primary.main,
    },
  },
  tag: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingRight: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    paddingLeft: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  divider: {
    paddingRight: theme.spacing(3),
    marginBottom: theme.spacing(2),
    paddingLeft: theme.spacing(3),
  },
}));

const formatDate = (inputDate: Date) => {
  const options: Intl.DateTimeFormatOptions = {
    weekday: 'long',
    day: 'numeric',
    month: 'long',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
    timeZoneName: 'short',
    timeZone: 'GMT',
  };

  const formattedDate = inputDate.toLocaleDateString('en-GB', options);

  return formattedDate.replace(/(\d+)(th|st|nd|rd)/, (_, day, suffix) => {
    return `${day}${suffix}`;
  });
};

const getUpdateStatus = (status: string, scheduled?: number): any => {
  if (scheduled) {
    return {
      label: `Publish: ${formatDate(new Date(scheduled))}`,
      status: 'info',
    };
  }
  if (status === UploadStatusType.new) {
    return CHIPS['validationPending'];
  }
  if (status === UploadStatusType.validation_fail) {
    return CHIPS['validationErrors'];
  }
  if (status === UploadStatusType.pending) {
    return CHIPS['reviewPending'];
  }
  if (status === UploadStatusType.published) {
    return CHIPS['updateSuccess'];
  }
  if (status === UploadStatusType.publish_fail) {
    return CHIPS['publishErrors'];
  }
  if (status === UploadStatusType.import_existing) {
    return CHIPS['importExists'];
  }
  if (status === UploadStatusType.rejected) {
    return CHIPS['rejected'];
  }
  if (status === UploadStatusType.reverted) {
    return CHIPS['reverted'];
  }
  if (status === UploadStatusType.partially_uploaded) {
    return CHIPS['partiallyUploaded'];
  }
  return CHIPS['updatePending'];
};

const PendingUpdatesList: React.FC = (): JSX.Element => {
  const [isApproveDialogOpened, setApproveDialogOpen] = useState(false);
  const [isRejectDialogOpened, setRejectDialogOpen] = useState(false);
  const [publishTypeValue, setPublishTypeValue] = useState('1');
  const [currentId, setCurrentId] = useState('');

  const classes = useStyles();
  const {
    approveUpdate,
    rejectUpdate,
    state: { updatesList, updating },
  } = useContext(UpdatesStore);

  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<RejectDialogFormFields>({
    defaultValues: {
      rejectionReasons: '',
    },
  });

  const onApproveClicked = useCallback(
    (id: string) => {
      setCurrentId(id);
      setApproveDialogOpen(true);
    },
    [setApproveDialogOpen, setCurrentId]
  );

  const onRejectClicked = useCallback(
    (id: string) => {
      setCurrentId(id);
      setRejectDialogOpen(true);
    },
    [setRejectDialogOpen, setCurrentId]
  );

  const handleApproveDialogClose = useCallback(() => {
    setApproveDialogOpen(false);
  }, [setApproveDialogOpen]);

  const handleRejectDialogClose = useCallback(() => {
    setRejectDialogOpen(false);
  }, [setRejectDialogOpen]);

  const handlePublishUpdates = useCallback(() => {
    approveUpdate(currentId, publishTypeValue === '1' ? true : false);
  }, [approveUpdate, publishTypeValue, currentId]);

  const handlePublishTypeChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setPublishTypeValue((e.target as HTMLInputElement).value);
    },
    [setPublishTypeValue]
  );

  const handleRejectContinue = useCallback(
    (data: RejectDialogFormFields) => {
      rejectUpdate(currentId, data.rejectionReasons);
    },
    [rejectUpdate, currentId]
  );

  return (
    <>
      <List>
        {updatesList?.map((listItem: PendingUpdatesListItem) => {
          return (
            <Box
              className={classes.item}
              key={`pending-updates-list-item-${listItem.id}`}
            >
              <ListItem classes={{ root: classes.listItem }}>
                <ListItemText>
                  <Typography variant="subtitle2" className={classes.title}>
                    {listItem.title}
                  </Typography>
                  <Box mt={4}>
                    <Typography
                      variant="caption"
                      color="textSecondary"
                      className={classes.date}
                    >
                      Updated {new Date(listItem.date).toLocaleString()} by $
                      {listItem.user}
                    </Typography>
                  </Box>
                </ListItemText>
                {listItem?.status === UploadStatusType.pending ||
                listItem?.status === UploadStatusType.partially_uploaded ? (
                  <Box
                    display="flex"
                    height={32}
                    alignSelf="flex-start"
                    alignItems="center"
                  >
                    <Box pt={3} pr={4} pb={3} pl={4}>
                      <Button
                        onClick={() => onApproveClicked(listItem.id)}
                        className={classes.link}
                      >
                        <Typography variant="button" color="primary">
                          Approve
                        </Typography>
                      </Button>
                    </Box>
                    <Divider orientation="vertical" />
                    <Box pt={3} pr={4} pb={3} pl={4}>
                      <Button
                        onClick={() => onRejectClicked(listItem.id)}
                        className={classes.link}
                      >
                        <Typography variant="button" color="primary">
                          Reject
                        </Typography>
                      </Button>
                    </Box>
                    <Box pt={3} pr={4} pb={3} pl={4}>
                      <Link
                        to={`${PROFILE_COMPARISON_ROUTE}/${listItem.id}`}
                        className={classes.link}
                      >
                        <Typography variant="button" color="primary">
                          Review
                        </Typography>
                      </Link>
                    </Box>
                  </Box>
                ) : (
                  <Box
                    display="flex"
                    height={32}
                    alignSelf="flex-start"
                    alignItems="center"
                  >
                    {listItem?.status === UploadStatusType.published && (
                      <>
                        <Box pr={4} alignSelf="flex-start">
                          <Link
                            to={`${MANAGE_TILES}/${listItem.id}`}
                            className={classes.link}
                          >
                            <Button className={classes.link}>
                              <Typography variant="button" color="primary">
                                Manage&nbsp;tiles
                              </Typography>
                            </Button>
                          </Link>
                        </Box>
                        <Divider orientation="vertical" />
                      </>
                    )}
                    <Box pt={3} pr={4} pb={3} pl={4}>
                      <Link
                        to={`${PROFILE_COMPARISON_ROUTE}/${listItem.id}`}
                        className={classes.link}
                      >
                        <Typography variant="button" color="primary">
                          Review
                        </Typography>
                      </Link>
                    </Box>
                  </Box>
                )}
              </ListItem>
              <Box className={classes.divider}>
                <Divider orientation="horizontal" />
              </Box>

              <Box className={classes.tag}>
                <Typography variant="body2">Data upload</Typography>
                <Box>
                  {listItem.status && (
                    <StatusChip
                      label={
                        getUpdateStatus(listItem.status, listItem.scheduledDate)
                          .label
                      }
                      variant={
                        getUpdateStatus(listItem.status, listItem.scheduledDate)
                          .status as ChipStatus
                      }
                    />
                  )}
                </Box>
              </Box>
            </Box>
          );
        })}
      </List>
      <ApproveDialog
        loading={updating}
        isDialogOpened={isApproveDialogOpened}
        onDialogClose={handleApproveDialogClose}
        onPublishUpdates={handlePublishUpdates}
        onPublishTypeChange={handlePublishTypeChange}
        publishTypeValue={publishTypeValue}
        title={'-'}
        date={new Date()}
        user={'-'}
      />
      <RejectDialog
        isDialogOpened={isRejectDialogOpened}
        onDialogClose={handleRejectDialogClose}
        onRejectContinue={handleSubmit(handleRejectContinue)}
        control={control}
        loading={updating}
        errors={errors}
        title={'-'}
        date={new Date()}
        user={'-'}
      />
    </>
  );
};

export default PendingUpdatesList;
