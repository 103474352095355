import { createContext, useCallback, useReducer } from 'react';
import {
  initialTooltipsState,
  TooltipsReducer,
  TooltipsState,
  TOOLTIPS_ACTIONS,
} from '../reducers/tooltips';
import { uploadImageToFirebase } from '../services/common';
import {
  createFirebase,
  deleteFirebase,
  getBatchFirebase,
  updateFirebase,
} from '../services/tooltips';
import { Tooltip } from '../utils/types/tooltip';

interface TooltipsContext {
  state: TooltipsState;
  create: (tooltip: Tooltip) => void;
  update: (tooltip: Tooltip) => void;
  remove: (tooltip: Tooltip) => void;
  uploadContentImage: (_file: File) => void;
  getTooltipsBatch: (lastDoc: Tooltip | null) => void;
}

export const TooltipsStore = createContext<TooltipsContext>({
  state: initialTooltipsState,
  create: () => {},
  update: () => {},
  remove: () => {},
  uploadContentImage: () => {},
  getTooltipsBatch: async () => {},
});

const { Provider } = TooltipsStore;
export const TooltipsProvider = ({ children }: any) => {
  const [state, dispatch] = useReducer(TooltipsReducer, initialTooltipsState);

  const create = useCallback(
    async (tooltip: Tooltip) => {
      dispatch({ type: TOOLTIPS_ACTIONS.UPDATE_START });
      const _createdTooltip = await createFirebase({
        ...tooltip,
      });
      dispatch({
        type: TOOLTIPS_ACTIONS.UPDATE_SUCCESS,
        data: [...(state.data || []), _createdTooltip],
      });
    },
    [state]
  );

  const update = useCallback(
    async (tooltip: Tooltip) => {
      dispatch({ type: TOOLTIPS_ACTIONS.UPDATE_START });
      await updateFirebase({ ...tooltip });
      let editedTooltipIndex = state.data?.findIndex((t: Tooltip) => {
        return t.id === tooltip.id;
      });
      let newData = state.data ? [...state.data] : undefined;
      if (
        newData &&
        editedTooltipIndex !== undefined &&
        editedTooltipIndex > -1
      ) {
        newData[editedTooltipIndex] = tooltip;
      }
      dispatch({ type: TOOLTIPS_ACTIONS.UPDATE_SUCCESS, data: newData });
    },
    [state]
  );

  const remove = useCallback(
    async (tooltip: Tooltip) => {
      dispatch({ type: TOOLTIPS_ACTIONS.REMOVE_START });
      await deleteFirebase(tooltip);
      let index = state.data?.findIndex((t: Tooltip) => {
        return t.id === tooltip.id;
      });
      if (index !== undefined && index > -1) {
        let data = state.data;
        data?.splice(index, 1);
        dispatch({ type: TOOLTIPS_ACTIONS.REMOVE_SUCCESS, data });
      }
    },
    [state]
  );

  const getTooltipsBatch = useCallback(async (lastDoc: Tooltip | null) => {
    dispatch({ type: TOOLTIPS_ACTIONS.FETCH });
    const tooltip = await getBatchFirebase(lastDoc);

    dispatch({
      type: TOOLTIPS_ACTIONS.FETCH_DATA_SUCCESS,
      loading: false,
      data: tooltip,
    });
  }, []);

  const uploadContentImage = useCallback(async (file: File) => {
    dispatch({ type: TOOLTIPS_ACTIONS.IMAGE_UPLOAD_START });
    const _imageUrl = await uploadImageToFirebase(file, 'tooltips');
    dispatch({
      type: TOOLTIPS_ACTIONS.IMAGE_UPLOAD_SUCCESS,
      data: { contentImageUrl: _imageUrl },
    });
  }, []);

  return (
    <Provider
      value={{
        state,
        create,
        update,
        remove,
        uploadContentImage,
        getTooltipsBatch,
      }}
    >
      {children}
    </Provider>
  );
};
