import {
  Box,
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
  makeStyles,
} from '@material-ui/core';
import React, { useCallback, useContext, useState } from 'react';
import { UploadsStore } from '../../providers/uploads';
import { EntityTypes } from '../../utils/constants/profileFields.constants';
import { SUPPORTED_FILE_UPLOADER_FORMATS } from '../../utils/constants/uploadFileFormats.constants';
import FileUploader from '../common/FileUploader';
import WrappedDialog from '../common/WrappedDialog';

interface UploadTabProps {
  handleResetTab: () => void;
}

const useStyles = makeStyles((theme) => {
  return {
    button: {
      height: 40,
      borderRadius: 10,
      justifyContent: 'flex-start',
      paddingTop: theme.spacing(3),
      paddingLeft: theme.spacing(4),
      paddingBottom: theme.spacing(3),
      paddingRight: theme.spacing(4),
      marginBottom: theme.spacing(3),
    },
    label: {
      color: theme.palette.text.secondary,
    },
  };
});

const UploadTab: React.FC<UploadTabProps> = ({
  handleResetTab,
}: UploadTabProps) => {
  const classes = useStyles();
  const [publishTypeValue, setPublishTypeValue] = useState('');
  const {
    state: { uploadProgress, url },
    reset,
    send,
    upload,
  } = useContext(UploadsStore);

  const handleUpload = (data: { upload: FileList }) => {
    switch (data.upload[0].name.slice(0, 3)) {
      case 'COM':
        setPublishTypeValue(EntityTypes.Companies);
        break;
      case 'IND':
        setPublishTypeValue(EntityTypes.Individuals);
        break;
      case 'BRA':
        setPublishTypeValue(EntityTypes.Branches);
        break;
      default:
        setPublishTypeValue('');
        break;
    }
    upload(data.upload[0]);
  };

  const onDialogClose = useCallback(() => {
    reset();
  }, [reset]);

  const onDialogConfirm = useCallback(() => {
    send(publishTypeValue);
    handleResetTab();
  }, [send, publishTypeValue]);

  return (
    <Grid container>
      <Box
        ml={{ xs: 4, sm: 4, md: 4, lg: 12 }}
        mr={{ xs: 4, sm: 4, md: 4, lg: 12 }}
        width={{ xs: '100%', sm: '100%', md: '100%', lg: '50%' }}
      >
        <FileUploader
          title={'Upload a data file'}
          onUpload={handleUpload}
          disabled={uploadProgress}
          supportedFileFormats={SUPPORTED_FILE_UPLOADER_FORMATS}
        />
        <Box mt={5}>{uploadProgress && <CircularProgress />}</Box>
      </Box>
      <WrappedDialog
        open={!!url}
        handleDialogClose={onDialogClose}
        dialogTitle={'Upload an update'}
      >
        <DialogContent>
          <Box display="flex" flexDirection={'column'}>
            <Typography>Do you want to upload this file ?</Typography>
            <Box mt={4} mb={10}>
              <RadioGroup
                row
                aria-label="publish type"
                name="publish type-radio-buttons"
                value={publishTypeValue}
              >
                {publishTypeValue === EntityTypes.Companies && (
                  <FormControlLabel
                    value={EntityTypes.Companies}
                    control={<Radio color="primary" />}
                    label="Companies"
                    classes={{ label: classes.label }}
                  />
                )}
                {publishTypeValue === EntityTypes.Individuals && (
                  <FormControlLabel
                    value={EntityTypes.Individuals}
                    control={<Radio color="primary" />}
                    label="Individuals"
                    classes={{ label: classes.label }}
                  />
                )}
                {publishTypeValue === EntityTypes.Branches && (
                  <FormControlLabel
                    value={EntityTypes.Branches}
                    control={<Radio color="primary" />}
                    label="Branches"
                    classes={{ label: classes.label }}
                  />
                )}
                {publishTypeValue === '' && (
                  <>
                    <FormControlLabel
                      value={EntityTypes.Companies}
                      control={<Radio color="primary" />}
                      label="Companies"
                      classes={{ label: classes.label }}
                    />
                    <FormControlLabel
                      value={EntityTypes.Individuals}
                      control={<Radio color="primary" />}
                      label="Individuals"
                      classes={{ label: classes.label }}
                    />
                    <FormControlLabel
                      value={EntityTypes.Branches}
                      control={<Radio color="primary" />}
                      label="Branches"
                      classes={{ label: classes.label }}
                    />
                  </>
                )}
              </RadioGroup>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Box mt={3} mb={3} mr={1}>
            <Button
              onClick={onDialogClose}
              color="secondary"
              variant="contained"
              disableElevation
              classes={{ root: classes.button }}
            >
              Close
            </Button>
          </Box>
          <Box mt={3} mb={3} mr={5}>
            <Button
              onClick={onDialogConfirm}
              color="primary"
              variant="contained"
              disableElevation
              classes={{ root: classes.button }}
            >
              Confirm
            </Button>
          </Box>
        </DialogActions>
      </WrappedDialog>
    </Grid>
  );
};

export default UploadTab;
