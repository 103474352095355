export const LAYOUT = Object.freeze({
  TopPanelHeight: 112,
  TopPanelHeightSmall: 64,
  TopPanelBottomMargin: 24,
  TopPanelBottomMarginSmall: 4,
  DrawerWidth: 260,
  DialogMaxWidth: 677,
  DialogMaxWidthSmall: 500,
  DrawerLogoSize: {
    width: 190,
    height: 54,
  },
  LoginFormWidth: 343,
  LoginLogoSize: {
    width: 390,
    height: 110,
  },
  LoginLogoSizeSmall: {
    width: 278,
    height: 79,
  },
  VirtuilizedListItemHeight: 80,
  VirtuilizedUserListItemHeight: 100,
  VirtuilizedArticlesListItemHeight: 130,
  VirtuilizedGlossaryListItemHeight: 80,
});
