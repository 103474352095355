import { IconButton, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CancelRoundedIcon from '@material-ui/icons/CancelRounded';
import React, { useCallback } from 'react';

interface SearchbarProps {
  keyword: string;
  onChange: (value: string) => void;
  onClear: () => void;
}

const useStyles = makeStyles({
  input: {
    height: 40,
    fontSize: 18,
    padding: 0,
  },
  iconRoot: {
    padding: 4,
  },
});

const Searchbar: React.FC<SearchbarProps> = ({
  keyword,
  onChange,
  onClear,
}: SearchbarProps): JSX.Element => {
  const classes = useStyles();

  const handleSearchChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onChange(e.currentTarget.value);
    },
    [onChange]
  );

  const handleSearchValueRemove = useCallback(() => {
    onClear();
  }, [onClear]);

  return (
    <TextField
      placeholder="Search"
      type="text"
      fullWidth
      size="small"
      onChange={handleSearchChange}
      value={keyword}
      InputProps={{
        classes: {
          input: classes.input,
        },
        endAdornment: (
          <IconButton
            aria-label="delete button"
            onClick={handleSearchValueRemove}
            classes={{
              root: classes.iconRoot,
            }}
          >
            <CancelRoundedIcon />
          </IconButton>
        ),
      }}
    />
  );
};

export default Searchbar;
