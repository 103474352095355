import { Box, CircularProgress } from '@material-ui/core';
import React, { useCallback, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { TooltipsStore } from '../../providers/tooltips';
import * as ROUTES from '../../routes/Types';
import { TOOLTIPS_PAGE } from '../../utils/constants/pageNames.constants';
import SectionContent from '../common/SectionContent';
import TooltipsList from './TooltipsList';

interface TooltipsContentProps {}

export interface TooltipsListItem {
  id: string;
  date: string;
  tooltipTitle: string;
}

const TooltipsContent: React.FC<TooltipsContentProps> = (): JSX.Element => {
  const {
    state: { data, loading },
    getTooltipsBatch,
  } = useContext(TooltipsStore);

  let history = useHistory();

  const handleAddTooltipClick = useCallback(() => {
    history.push(ROUTES.TOOLTIPS_ADD_ROUTE);
  }, [history]);

  useEffect(() => {
    getTooltipsBatch(null);
  }, []);

  return (
    <SectionContent
      handleAddClick={handleAddTooltipClick}
      addButtonText={'Add tooltip'}
      pageName={TOOLTIPS_PAGE}
    >
      <Box height={'100%'}>
        {data && (
          <TooltipsList
            tooltipsList={data}
            loadMoreItemsHandler={getTooltipsBatch}
          />
        )}
        {loading && <CircularProgress />}
      </Box>
    </SectionContent>
  );
};

export default TooltipsContent;
