import React, { useCallback, useContext } from 'react';
import { DirectoryStore } from '../../providers/directory';
import {
  FilterOption,
  OPTIONS_LIST,
} from '../../utils/constants/directoryFilter.constants';
import WrappedAutocomplete from '../common/WrappedAutocomplete';

interface DirectoryFilterProps {}

const DirectoryFilter: React.FC<DirectoryFilterProps> = (): JSX.Element => {
  const { search } = useContext(DirectoryStore);
  const handleAutocompleteChange = useCallback(
    (optionsList) => {
      const filter = optionsList.reduce(
        (acc: any, currentValue: FilterOption) => {
          const option = OPTIONS_LIST.find(
            (o: FilterOption) => o.id === currentValue.id
          );
          if (option) {
            acc[option.type].push(option.id);
          }

          return acc;
        },
        { types: [], statuses: [], additionalTypes: [] }
      );

      search(filter);
    },
    [search]
  );
  return (
    <WrappedAutocomplete
      options={OPTIONS_LIST}
      label={'Options'}
      handleAutocompleteChange={handleAutocompleteChange}
    ></WrappedAutocomplete>
  );
};

export default DirectoryFilter;
