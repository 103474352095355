import { useQuery } from '@tanstack/react-query';
import { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { AuthStore } from '../../providers/auth';
import { fetchOrganization } from '../../services/organisations';
import { Organisation } from '../../utils/types/organisation';

export const useOrganisation = (organisationId?: string) => {
  const { orgId } = useParams<{ orgId: string }>();
  const id = Number(organisationId || orgId);
  const [{ user }] = useContext(AuthStore);

  const { data, isLoading, error } = useQuery<Organisation>(
    ['organizations', 'details', id],
    () => fetchOrganization(id),
    {
      enabled: !!user.role && user.role === 'superadmin',
    }
  );

  return { organisation: data, isLoading, error };
};
