import { Box } from '@material-ui/core';
import { Route, useLocation } from 'react-router-dom';
import KnowledgeCentreContent from '../../components/knowledge-centre/KnowledgeCentreContent';
import { DirectoryProvider } from '../../providers/directory';
import { KnowledgeCentreProvider } from '../../providers/knowledgeCentre';
import {
  KNOWLEDGE_CENTRE_ADD_ROUTE,
  KNOWLEDGE_CENTRE_EDIT_ROUTE,
  KNOWLEDGE_CENTRE_ROUTE,
} from '../../routes/Types';
import KnowledgeCentreAddPage from './KnowledgeCentreAddPage';
import KnowledgeCentreEditPage from './KnowledgeCentreEditPage';

const KnowledgeCentrePage = () => {
  const currentLocation = useLocation();
  return (
    <DirectoryProvider>
      <KnowledgeCentreProvider>
        <Box
          style={
            currentLocation.pathname !== KNOWLEDGE_CENTRE_ROUTE
              ? { display: 'none' }
              : {}
          }
        >
          <KnowledgeCentreContent />
        </Box>

        <Route path={KNOWLEDGE_CENTRE_ADD_ROUTE}>
          <KnowledgeCentreAddPage />
        </Route>

        <Route path={KNOWLEDGE_CENTRE_EDIT_ROUTE}>
          <KnowledgeCentreEditPage />
        </Route>
      </KnowledgeCentreProvider>
    </DirectoryProvider>
  );
};

export default KnowledgeCentrePage;
