import { createContext, useCallback, useReducer, useState } from 'react';
import {
  DIRECTORY_ACTIONS,
  DirectoryReducer,
  DirectoryState,
  initialDirectoryState,
} from '../reducers/directory';
import {
  DirectoryFilterType,
  fetchDirectoryData,
  getBranch,
  getElasticBranches,
  getElasticKeyPeople,
  getIndividual,
  searchDirectoryData,
} from '../services/directory';
export type { DirectoryListItem } from '../services/directory';
interface DirectoryContext {
  directoryState: DirectoryState;
  getDirectoryList: () => void;
  search: (filter: DirectoryFilterType) => void;
  filter: DirectoryFilterType;
  onKeywordChange: (value: string, match?: string) => void;
  onMatchRadioChange: (value: string, keyword?: string) => void;
  getRecordById: (id: string) => any;
  getBranchById: (id: string) => any;
  getCompanyDetails: (id: string) => any;
  exportLogos: () => any;
  reset: () => void;
}

export const DirectoryStore = createContext<DirectoryContext>({
  directoryState: initialDirectoryState,
  getDirectoryList: () => {},
  search: (_filter: DirectoryFilterType) => {},
  filter: {},
  onKeywordChange: (_value: string, _match?: string) => {},
  onMatchRadioChange: (_value: string, _keyword?: string) => {},
  getRecordById: (_id: string) => {},
  getBranchById: (_id: string) => {},
  getCompanyDetails: (_id: string) => {},
  exportLogos: () => {},
  reset: () => {},
});

const { Provider } = DirectoryStore;

export const DirectoryProvider = ({ children }: any) => {
  const [filter, setFilter] = useState<DirectoryFilterType>({});
  const [
    { directoryList, keyPeople, branches, total, loading, error },
    dispatch,
  ] = useReducer(DirectoryReducer, initialDirectoryState);

  const getDirectoryList = useCallback(async () => {
    dispatch({ type: DIRECTORY_ACTIONS.FETCH, loading: true });
    try {
      const directoryList = await fetchDirectoryData();
      dispatch({
        type: DIRECTORY_ACTIONS.FETCH_DATA_SUCCESS,
        data: directoryList.data,
        total: directoryList.total,
        loading: false,
      });
    } catch (error: any) {
      dispatch({
        type: DIRECTORY_ACTIONS.FETCH_DATA_FAIL,
        loading: false,
        error: error?.toString(),
      });
    }
  }, []);

  const reset = useCallback(() => {
    dispatch({ type: DIRECTORY_ACTIONS.RESET, loading: true });
  }, []);

  const search = useCallback(
    async (changedFilter: DirectoryFilterType) => {
      const newFilter = { ...filter, ...changedFilter };

      dispatch({ type: DIRECTORY_ACTIONS.FETCH, loading: true });
      try {
        const directoryList = await searchDirectoryData(newFilter);
        dispatch({
          type: DIRECTORY_ACTIONS.FETCH_DATA_SUCCESS,
          data: directoryList.data,
          total: directoryList.total,
          loading: false,
        });
      } catch (error) {
        dispatch({ type: DIRECTORY_ACTIONS.FETCH_DATA_FAIL, loading: false });
      }

      setFilter(newFilter);
    },
    [filter]
  );

  const onKeywordChange = (value: string, match?: string) => {
    setFilter({
      ...filter,
      search: { keyword: value, match },
      letter: undefined,
    });
  };

  const onMatchRadioChange = (value: string, keyword?: string) => {
    setFilter({ ...filter, search: { match: value, keyword } });
  };

  const getRecordById = useCallback(
    async (id: string) => {
      if (directoryList) {
        let directoryItem = directoryList.find((r: any) => r.id === id);
        if (!directoryItem) {
          const result = await getIndividual(id);
          return result;
        }

        return directoryItem;
      }

      return null;
    },
    [directoryList]
  );

  const getBranchById = useCallback(async (id: string) => {
    const result = await getBranch(id);
    return result._source;
  }, []);

  const getKeyPeople = useCallback(async (id: string) => {
    const keyPeople = await getElasticKeyPeople(id);

    dispatch({
      type: DIRECTORY_ACTIONS.KEY_PEOPLE_SUCCESS,
      data: keyPeople?.results || [],
    });
  }, []);

  const getBranches = useCallback(async (id: string) => {
    const branches = await getElasticBranches(id);
    dispatch({
      type: DIRECTORY_ACTIONS.BRANCHES_SUCCESS,
      data: branches?.results || [],
    });
  }, []);

  const getCompanyDetails = useCallback(
    (id: string) => {
      getBranches(id);
      getKeyPeople(id);
    },
    [getBranches, getKeyPeople]
  );

  const exportLogos = useCallback(async () => {
    const directoryList = await searchDirectoryData({
      types: ['company'],
      statuses: [],
    });
    const exportableData = directoryList.data
      .filter((d: any) => !!d.logo)
      .map((d: any) => ({
        id: d.id,
        logo: d.logo,
      }));

    const filename = `${Date.now()}_company_logos.json`;
    const jsonStr = JSON.stringify(exportableData);

    let element = document.createElement('a');
    element.setAttribute(
      'href',
      'data:text/plain;charset=utf-8,' + encodeURIComponent(jsonStr)
    );
    element.setAttribute('download', filename);

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  }, []);

  return (
    <Provider
      value={{
        directoryState: {
          directoryList,
          keyPeople,
          branches,
          total,
          loading,
          error,
        },
        getDirectoryList,
        search,
        filter,
        onKeywordChange,
        onMatchRadioChange,
        getRecordById,
        getBranchById,
        getCompanyDetails,
        exportLogos,
        reset,
      }}
    >
      {children}
    </Provider>
  );
};
