import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { GlossaryStore } from '../../providers/glossary';
import { serialize } from '../../utils/helpers/htmlSerializer.helpers';
import { CustomElement } from '../common/TextEditor';
import GlossaryAddEditForm from './GlossaryAddEditForm';
import { GlossaryFormFields } from './GlossaryFormSection';

interface GlossaryAddContentProps {}

const defaultValues = {
  glossaryTermTitle: '',
  glossaryTermPublishDate: new Date(),
  glossaryTermPublishTime: new Date(),
  glossaryTermText: [
    {
      type: 'paragraph',
      children: [{ text: '' }],
    },
  ] as CustomElement[],
};

const GlossaryAddContent: React.FC<
  GlossaryAddContentProps
> = (): JSX.Element => {
  const history = useHistory();

  const { create } = useContext(GlossaryStore);

  const getGlossaryTermPublishDateTime = (date: Date, time: Date): Date => {
    const dateTime = new Date(date);
    dateTime.setHours(time.getHours());
    dateTime.setMinutes(time.getMinutes());
    return dateTime;
  };

  const handleCreate = async (data: GlossaryFormFields) => {
    const timeStamp = getGlossaryTermPublishDateTime(
      data.glossaryTermPublishDate,
      data.glossaryTermPublishTime
    );
    const glossaryTerm = {
      title: data.glossaryTermTitle,
      created: timeStamp,
      updated: timeStamp,
      rawText: data.glossaryTermText,
      text: serialize({ children: data.glossaryTermText }),
    };
    await create(glossaryTerm);
    history.goBack();
  };

  return (
    <GlossaryAddEditForm
      defaultValues={defaultValues}
      handleUpdate={handleCreate}
      title="Add Glossary Term"
    />
  );
};

export default GlossaryAddContent;
