import { Box, Button, DialogActions } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useCallback } from 'react';
import { Article } from '../../utils/types/article';
import { GlossaryTerm } from '../../utils/types/glossary-term';
import { KnowledgeItem } from '../../utils/types/knowledgeCentre';
import { Tooltip } from '../../utils/types/tooltip';
import WrappedDialog from '../common/WrappedDialog';

interface RemoveConfirmationDialogProps {
  isDialogOpened: boolean;
  onDialogClose: () => void;
  dataToRemove: Article | KnowledgeItem | Tooltip | GlossaryTerm;
  title: string;
  remove: (item: Article & KnowledgeItem & Tooltip & GlossaryTerm) => void;
}

const useStyles = makeStyles((theme) => ({
  button: {
    width: 100,
    borderRadius: 10,
    marginLeft: theme.spacing(2),
  },
  content: {
    padding: 0,
  },
  text: {
    fontFamily: 'Hero New Bold',
    fontSize: 12,
  },
  boldText: {
    fontFamily: 'Hero New ExtraBold',
    fontSize: 16,
  },
  label: {
    color: theme.palette.text.secondary,
  },
}));

const RemoveConfirmationDialog: React.FC<RemoveConfirmationDialogProps> = ({
  isDialogOpened,
  onDialogClose,
  dataToRemove,
  title,
  remove,
}: RemoveConfirmationDialogProps): JSX.Element => {
  const classes = useStyles();
  const onRemove = useCallback(
    (item) => {
      remove(item);
      onDialogClose();
    },
    [remove]
  );
  return (
    <WrappedDialog
      open={isDialogOpened}
      handleDialogClose={onDialogClose}
      dialogTitle={title}
      withCloseIcon={false}
    >
      <DialogActions>
        <Box
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
          mt={2}
          mb={2}
          mr={8}
          ml={8}
        >
          <Box>
            <Button
              onClick={() => {
                onRemove(dataToRemove);
              }}
              color="secondary"
              variant="contained"
              disableElevation
              classes={{ root: classes.button }}
            >
              Remove
            </Button>
          </Box>
          <Box>
            <Button
              onClick={onDialogClose}
              color="secondary"
              variant="contained"
              disableElevation
              classes={{ root: classes.button }}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </DialogActions>
    </WrappedDialog>
  );
};

export default RemoveConfirmationDialog;
