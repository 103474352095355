import { createContext, useCallback, useReducer } from 'react';
import {
  initialStorageState,
  StorageReducer,
  StorageState,
  STORAGE_ACTIONS,
} from '../reducers/storage';
import { updateCompanyLogo } from '../services/directory';
import { uploadToFirebase } from '../services/uploads';
interface StorageContext {
  state: StorageState;
  upload: (file: File) => void;
  saveCompanyLogo: (
    companyId: string,
    url: string,
    shouldGenerateTile: boolean
  ) => void;
  reset: () => void;
}

export const StorageStore = createContext<StorageContext>({
  state: initialStorageState,
  upload: (_file: File) => {},
  saveCompanyLogo: (
    _companyId: string,
    _url: string,
    _shouldGenerateTile: boolean
  ) => {},
  reset: () => {},
});

const { Provider } = StorageStore;

export const StorageProvider = ({ children }: any) => {
  const [state, dispatch] = useReducer(StorageReducer, initialStorageState);

  const upload = useCallback(async (file: File) => {
    dispatch({ type: STORAGE_ACTIONS.UPLOAD_START });
    const url = await uploadToFirebase(file, true);
    dispatch({
      type: STORAGE_ACTIONS.UPLOAD_SUCCESS,
      loading: false,
      data: url,
    });
  }, []);

  const saveCompanyLogo = useCallback(
    async (companyId: string, url: string, shouldGenerateTile: boolean) => {
      dispatch({ type: STORAGE_ACTIONS.UPDATE_START });
      await updateCompanyLogo(companyId, url, shouldGenerateTile);
      dispatch({ type: STORAGE_ACTIONS.UPDATE_SUCCESS, loading: false });
    },
    []
  );

  const reset = useCallback(async () => {
    dispatch({ type: STORAGE_ACTIONS.RESET });
  }, []);

  return (
    <Provider
      value={{
        state,
        upload,
        saveCompanyLogo,
        reset,
      }}
    >
      {children}
    </Provider>
  );
};
