import Button, { ButtonProps } from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

type StyledButtonProps = ButtonProps;

const useStyles = makeStyles({
  root: {
    width: '100%',
    height: 50,
    borderRadius: 8,
    border: 0,
  },
  label: {
    textTransform: 'capitalize',
    fontSize: 16,
    fontWeight: 600,
  },
});

const StyledButton: React.FC<StyledButtonProps> = (
  props: StyledButtonProps
): JSX.Element => {
  const { children, ...other } = props;
  const classes = useStyles();

  return (
    <Button
      classes={{
        root: classes.root,
        label: classes.label,
      }}
      {...other}
    >
      {children}
    </Button>
  );
};

export default StyledButton;
