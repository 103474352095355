import { Box, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import React, { useCallback, useContext } from 'react';
import { InvitesStore } from '../../providers/invites';
import Searchbar from '../common/Searchbar';

interface UsersSearchProps {}

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 55,
    minHeight: theme.spacing(10),
    marginLeft: theme.spacing(5),
    borderRadius: 10,
    border: 0,
  },
}));

const UsersSearch: React.FC<UsersSearchProps> = (): JSX.Element => {
  const classes = useStyles();

  const { invitesSearch, onInvitesKeywordChange, invitesFilter } =
    useContext(InvitesStore);

  const onSearch = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      invitesSearch();
    },
    [invitesSearch]
  );

  const onClear = useCallback(() => {
    onInvitesKeywordChange('');
  }, [onInvitesKeywordChange]);

  const onChange = useCallback(
    (value: string) => {
      onInvitesKeywordChange(value);
    },
    [onInvitesKeywordChange]
  );

  return (
    <Box display="flex">
      <Searchbar
        keyword={invitesFilter.keywords}
        onChange={onChange}
        onClear={onClear}
      />
      <Button
        classes={{
          root: classes.root,
        }}
        variant="contained"
        color="primary"
        onClick={onSearch}
        type="submit"
      >
        <SearchIcon fontSize="medium" />
      </Button>
    </Box>
  );
};

export default UsersSearch;
