import { Box, Typography } from '@material-ui/core';
import React from 'react';

interface TextEditorToolbarProps {
  title: string;
  children: any;
}

const TextEditorToolbar: React.FC<TextEditorToolbarProps> = ({
  title,
  children,
}: TextEditorToolbarProps): JSX.Element => {
  return (
    <Box display="flex" alignItems="center" justifyContent="space-between">
      <Typography variant="subtitle1">{title}</Typography>
      <Box display="flex">{children}</Box>
    </Box>
  );
};

export default TextEditorToolbar;
