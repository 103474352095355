import {
  Avatar,
  Box,
  ListItemText,
  MenuItem,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useUserRoleRestrictions } from '../../hooks/services/useUserRoleRestrictions';
import { DIRECTORY_PROFILE_ROUTE } from '../../routes/Types';
import VirualizedListMenu from '../common/VirtualizedListMenu';
import DirectoryDeleteDialog from './DirectoryDeleteDialog';
import { UserRole } from '../../utils/types/user';
interface DirectoryListItemContentProps {
  id: string;
  name: string;
  logo?: string;
  role: string;
  children: any;
}

const useStyles = makeStyles((theme) => ({
  listItemText: {
    paddingLeft: theme.spacing(2),
  },
  name: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  role: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));

const DirectoryListItemContent: React.FC<DirectoryListItemContentProps> = ({
  id,
  name,
  logo,
  role,
  children,
}: DirectoryListItemContentProps): JSX.Element => {
  const classes = useStyles();
  const history = useHistory();
  const { isUserRole: isUserAdmin } = useUserRoleRestrictions(UserRole.SuperAdmin);
  const [isDialogOpened, setDialogOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isMenuOpened = Boolean(anchorEl);

  const handleMenuClick = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
    },
    [setAnchorEl]
  );

  const handleMenuClose = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  const handleDialogOpen = useCallback(() => {
    handleMenuClose();
    setDialogOpen(true);
  }, [setDialogOpen, handleMenuClose]);

  const handleDialogClose = useCallback(() => {
    setDialogOpen(false);
  }, [setDialogOpen]);

  const handleDelete = () => {
    console.log('delete-action');
  };
  return (
    <>
      <Avatar alt={name} src={logo}></Avatar>
      <ListItemText className={classes.listItemText}>
        <Typography variant="subtitle2" className={classes.name}>
          {name}
        </Typography>
        <Typography title={role} variant="body2" className={classes.role}>
          {id} | {role}
        </Typography>
      </ListItemText>
      <Box display="flex" alignSelf="flex-start" alignItems="center">
        <Box mr={2}>{children}</Box>
        {isUserAdmin && (
          <VirualizedListMenu
            anchorEl={anchorEl}
            open={isMenuOpened}
            onClose={handleMenuClose}
            onClick={handleMenuClick}
          >
            <MenuItem
              onClick={() => {
                history.push(`${DIRECTORY_PROFILE_ROUTE}/${id}`);
                setAnchorEl(null);
              }}
            >
              View profile
            </MenuItem>
          </VirualizedListMenu>
        )}
      </Box>
      <DirectoryDeleteDialog
        onDialogOpen={handleDialogOpen}
        onDialogClose={handleDialogClose}
        isDialogOpened={isDialogOpened}
        onDelete={handleDelete}
      />
    </>
  );
};

export default DirectoryListItemContent;
