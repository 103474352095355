import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  CircularProgress,
  Divider,
  Grid,
  Typography,
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';
import { ArticlesStore } from '../../providers/articles';
import { ARTICLES_ROUTE } from '../../routes/Types';
import {
  ArticleTarget,
  ArticleTileSize,
} from '../../utils/constants/articles.constants';
import { LAYOUT } from '../../utils/constants/layout.constants';
import { SUPPORTED_IMAGE_UPLOADER_FORMATS } from '../../utils/constants/uploadFileFormats.constants';
import { getImageBrightness } from '../../utils/helpers/colorExtractors.helpers';
import { getImageAspectRatio } from '../../utils/helpers/imageSizeExtractor.helpers';
import EditContentButtons from '../common/EditContentButtons';
import PublishDateTimeSection from '../common/PublishDateTimeSection';
import { CustomElement, CustomText } from '../common/TextEditor';
import Title from '../common/Title';
import TopPannel from '../common/TopPannel';
import ArticlesFormSection, { ArticlesFormFields } from './ArticlesFormSection';
import ArticlesTargetSelect from './ArticlesTargetSelect';
import ArticlesTileSizeSelect from './ArticlesTileSizeSelect';

interface ArticlesAddEditFormProps {
  defaultValues?: ArticlesFormFields;
  handleUpdate: (data: ArticlesFormFields) => void;
  title: string;
}

const useStyles = makeStyles((theme) => ({
  content: {
    marginTop: 64,
    background: theme.palette.background.paper,
    [theme.breakpoints.up('md')]: {
      marginTop: LAYOUT.TopPanelHeight + LAYOUT.TopPanelBottomMargin,
      width: `calc(100% - ${LAYOUT.DrawerWidth}px)`,
      marginLeft: LAYOUT.DrawerWidth,
    },
  },
  title: {
    marginTop: theme.spacing(7),
    marginBottom: theme.spacing(5),
  },
}));

const ArticlesAddEditForm: React.FC<ArticlesAddEditFormProps> = ({
  defaultValues,
  handleUpdate,
  title,
}: ArticlesAddEditFormProps): JSX.Element => {
  const classes = useStyles();
  const theme = useTheme();
  const [tileSize, setTileSize] = useState<ArticleTileSize>(
    ArticleTileSize.Large
  );

  const matchesMediaQuery = useMediaQuery(theme.breakpoints.up('md'), {
    noSsr: true,
  });

  const history = useHistory();

  const handleRedirectLinkClick = () => {
    history.push(`${ARTICLES_ROUTE}`);
  };

  const {
    state: { updating },
    upload,
  } = useContext(ArticlesStore);

  const validationSchema = yup.object().shape({
    articleTitle: yup.string().required('Title is required'),
    articleExtract: yup
      .string()
      .max(245, 'Must be no longer than 245 characters'),
    articlePublishDate: yup.date().required('Date is required'),
    articlePublishTime: yup.date().required('Time is required'),
    articleText: yup
      .array()
      .compact(
        (value) =>
          !value.children.some((child: CustomText) => child.text !== '')
      )
      .min(1, 'Text is required'),
    articleImageInfo: yup.object().when([], {
      is: () => tileSize === ArticleTileSize.Small,
      then: yup.object(),
      otherwise: yup.object().required('Image needs to be uploaded'),
    }),
  });

  const {
    handleSubmit,
    setValue,
    setError,
    formState: { errors },
    control,
    reset,
  } = useForm<ArticlesFormFields>({
    defaultValues: defaultValues
      ? defaultValues
      : {
          articleTitle: '',
          articleExtract: '',
          articlePublishDate: new Date(),
          articlePublishTime: new Date(),
          articleText: [
            {
              type: 'paragraph',
              children: [{ text: '' }],
            },
          ] as CustomElement[],
          articleTileSize: ArticleTileSize.Large,
          articleTarget: ArticleTarget.Go,
        },
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    if (!defaultValues) return;
    setTileSize(defaultValues.articleTileSize);
    reset(defaultValues);
  }, [defaultValues]);

  const handleUpload = async (data: { upload: FileList }) => {
    setValue('articleImageInfo', undefined);
    setError('articleImageInfo', {});

    const imgUrl = window.URL.createObjectURL(data.upload[0]);

    try {
      let brightness, aspectRatio;
      try {
        brightness = await getImageBrightness(imgUrl);
      } catch (err) {
        setError('articleImageInfo', {
          message: 'Invalid image (could not get brightness)',
        });
        throw err;
      }

      try {
        aspectRatio = await getImageAspectRatio(imgUrl);
      } catch (err) {
        setError('articleImageInfo', {
          message: 'Invalid image (could not get aspect ratio)',
        });
        throw err;
      }

      upload(data.upload[0]);

      setValue('articleImageInfo', {
        storageUrl: '',
        brightness,
        aspectRatio,
      });
    } catch (err) {
      console.log(err);
    }

    window.URL.revokeObjectURL(imgUrl);
  };

  return (
    <Box className={classes.content}>
      {matchesMediaQuery ? (
        <TopPannel>
          <Title
            onRedirectLinkClick={handleRedirectLinkClick}
            pageName={'Back to all articles'}
          />
        </TopPannel>
      ) : (
        <Box textAlign="left" mr={4} ml={4}>
          <Title
            onRedirectLinkClick={handleRedirectLinkClick}
            pageName={'Back to all articles'}
          />
        </Box>
      )}
      <Box
        ml={{ xs: 4, sm: 4, md: 4, lg: 10 }}
        mr={{ xs: 4, sm: 4, md: 4, lg: 20 }}
      >
        <Typography
          variant="h4"
          color="textPrimary"
          align="left"
          classes={{ root: classes.title }}
        >
          {title}
        </Typography>
        <Divider />
        <Grid container direction="row">
          <Grid item xs={12} sm={12} md={7}>
            <Box mt={8}>
              <ArticlesFormSection
                control={control}
                errors={errors}
                handleUpload={handleUpload}
                supportedFileFormats={SUPPORTED_IMAGE_UPLOADER_FORMATS}
                tileSize={tileSize}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={5}>
            <Box mt={8} ml={{ xs: 4, sm: 4, md: 4, lg: 15 }}>
              <PublishDateTimeSection
                control={control}
                publishDateName="articlePublishDate"
                publishTimeName="articlePublishTime"
              ></PublishDateTimeSection>
              <ArticlesTargetSelect control={control} />
              <ArticlesTileSizeSelect
                control={control}
                setTileSize={setTileSize}
                setValue={setValue}
              />
              <EditContentButtons
                updating={updating}
                handleRedirectLinkClick={handleRedirectLinkClick}
                handleSave={handleSubmit(handleUpdate)}
              />
              <Box mt={5}>{updating && <CircularProgress />}</Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default ArticlesAddEditForm;
