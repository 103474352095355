export enum UserLicense {
  Go = 'go',
  Pro = 'pro',
  All = 'all',
}

export const USER_LICENSE_OPTIONS = [
  {
    name: 'Go',
    value: UserLicense.Go,
  },
  {
    name: 'Pro',
    value: UserLicense.Pro,
  },
  // {
  //   name: 'All',
  //   value: UserLicense.All,
  // },
];

export enum UserAccessLevel {
  Superadmin = 'superadmin',
  Editor = 'editor',
  Client = 'client',
}

export const USER_ACCESS_LEVEL_OPTIONS = [
  {
    name: 'Superadmin',
    value: UserAccessLevel.Superadmin,
  },
  {
    name: 'Editor',
    value: UserAccessLevel.Editor,
  },
  {
    name: 'Client',
    value: UserAccessLevel.Client,
  },
];

export const USER_DISABLED_OPTIONS = [
  {
    name: 'Enabled',
    value: 'false',
  },
  {
    name: 'Disabled',
    value: 'true',
  },
];
