import { Box, Typography } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import React from 'react';
import TopPannel from './TopPannel';

interface SectionTitleProps {
  title: string;
}

const SectionTitle: React.FC<SectionTitleProps> = ({
  title,
}: SectionTitleProps): JSX.Element => {
  const theme = useTheme();

  const matchesMediaQuery = useMediaQuery(theme.breakpoints.up('md'));

  if (matchesMediaQuery) {
    return (
      <TopPannel>
        <Typography variant="h4" color="textPrimary">
          {title}
        </Typography>
      </TopPannel>
    );
  }

  return (
    <Box ml={4} mb={4}>
      <Typography variant="h4" color="textPrimary" align="left">
        {title}
      </Typography>
    </Box>
  );
};

export default SectionTitle;
