import { Box, CircularProgress, Grid, makeStyles } from '@material-ui/core';
import React, { useContext } from 'react';
import { DeleteProfilesStore } from '../../providers/deleteProfiles';
import { SUPPORTED_FILE_UPLOADER_FORMATS } from '../../utils/constants/uploadFileFormats.constants';
import FileUploader from '../common/FileUploader';

interface DeletedProfilesLayoutProps {
  children: React.ReactNode;
}

const useStyles = makeStyles((theme) => {
  return {
    root: {
      marginTop: theme.spacing(6),
    },
  };
});

const DeletedProfilesLayout: React.FC<DeletedProfilesLayoutProps> = ({
  children,
}: DeletedProfilesLayoutProps): JSX.Element => {
  const handleUpload = (data: { upload: FileList }) => {
    upload(data.upload[0]);
  };

  const classes = useStyles();
  const {
    state: { uploadProgress },
    upload,
  } = useContext(DeleteProfilesStore);

  return (
    <Grid container className={classes.root}>
      <Grid item xs={12} sm={12} md={6}>
        <Box mt={8} ml={{ xs: 0, sm: 0, md: 0, lg: 5 }}>
          {children}
        </Box>
      </Grid>
      <Grid item xs={12} sm={12} md={6}>
        <Box
          ml={{ xs: 4, sm: 4, md: 4, lg: 28 }}
          mr={{ xs: 4, sm: 4, md: 4, lg: 15 }}
        >
          <FileUploader
            title={'Upload a data file'}
            onUpload={handleUpload}
            disabled={uploadProgress}
            supportedFileFormats={SUPPORTED_FILE_UPLOADER_FORMATS}
          />
          <Box mt={5}>{uploadProgress && <CircularProgress />}</Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default DeletedProfilesLayout;
