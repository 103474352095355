import { Route, Switch } from 'react-router-dom';
import LoginPage from '../pages/public/Login';
import VerificationPage from '../pages/public/Verification';

import { LOGIN_ROUTE, VERIFICATION_ROUTE } from './Types';

function PublicRoutes() {
  return (
    <Switch>
      <Route path={LOGIN_ROUTE} exact>
        <LoginPage />
      </Route>
      <Route path={VERIFICATION_ROUTE} exact>
        <VerificationPage />
      </Route>
    </Switch>
  );
}

export default PublicRoutes;
