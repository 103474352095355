import { UserLicense } from '../constants/userLicenses.constats';
import { UserOrganisationRole } from './organisation';
export interface User {
  id: string;
  firstName: string;
  lastName: string;
  company: string;
  email: string;
  expiry?: Date;
  officeAddress: string;
  jobTitle?: string;
  role?: string;
  clientTypes?: string;
  assetClasses?: string;
  regions?: string;
  strategies?: string;
  type: string;
  archived?: boolean;
  permission?: string;
  avatar?: string;
  license?: UserLicense;
  organization_role?: UserOrganisationRole;
  organization_id?: number;
  disabled?: boolean;
  access_level?: string;
  license_expires_at?: number;
}

export enum UserListType {
  Users = 'users',
  Invites = 'invites',
}

export enum UserRole {
  SuperAdmin = 'superadmin',
  User = 'user',
}
