export const clearUndefined = <T>(
  data: { [key: string]: any },
  replace?: { [key: string]: any }
) =>
  Object.keys(data).reduce((result, key) => {
    if (data[key] === undefined && replace?.[key] === undefined) {
      return result;
    }
    return {
      ...result,
      [key]: data[key] === undefined ? replace?.[key] : data[key],
    };
  }, {}) as T;
