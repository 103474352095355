import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { LAYOUT } from '../../../../utils/constants/layout.constants';

interface Props {}
const useStyles = makeStyles((theme) => ({
  content: {
    [theme.breakpoints.up('md')]: {
      marginTop: LAYOUT.TopPanelHeight + LAYOUT.TopPanelBottomMargin,
      width: `calc(100% - ${LAYOUT.DrawerWidth}px)`,
      marginLeft: LAYOUT.DrawerWidth,
    },
  },
}));

const WrapperContent = (props: Props) => {
  const { ...other } = props;
  const classes = useStyles(props);
  return <Box className={classes.content} {...other} />;
};

export default WrapperContent;
