import { Box } from '@material-ui/core';
import React from 'react';
import ManageTilesContent from '../../components/manage-tiles/ManageTilesContent';

const ManageTiles: React.FC = (): JSX.Element => {
  return (
    <Box>
      <ManageTilesContent />
    </Box>
  );
};

export default ManageTiles;
