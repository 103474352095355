import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Grid, LinearProgress, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useContext } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import StyledButton from '../../components/common/StyledButton';
import StyledInput from '../../components/common/StyledInput';
import WelcomeMessage from '../../components/common/WelcomeMessage';
import { AuthStore } from '../../providers/auth';
import { LAYOUT } from '../../utils/constants/layout.constants';

interface LoginFormInputs {
  email: string;
  password: string;
}

const useStyles = makeStyles((theme) => ({
  formWrapper: {
    width: LAYOUT.LoginFormWidth,
  },
  errorMessage: {
    height: theme.spacing(6),
  },
  loader: {
    width: '100%',
    position: 'absolute',
    top: 0,
  },
}));

function Login() {
  const [{ error, loading }, login] = useContext(AuthStore) || [];
  const classes = useStyles();

  let validationSchema = yup.object().shape({
    email: yup.string().required('Email is required').email('Email is invalid'),
    password: yup
      .string()
      .required('Password is required')
      .min(8, 'Password should contain at least 8 characters')
      .max(40, 'Password should not be longer than 40 characters')
      .matches(
        /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[^\w\d\s]).{8,}$/,
        'Password should contain letters, numbers and special symbols'
      ),
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = (data: LoginFormInputs) => {
    login(data.email, data.password);
  };

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      style={{ minHeight: '100vh' }}
    >
      {loading && <LinearProgress className={classes.loader} />}
      <Grid item>
        <WelcomeMessage />
        <form noValidate autoComplete="off">
          <Grid item className={classes.formWrapper}>
            <Box mb={2} mt={8}>
              <StyledInput
                id="login-email"
                name="email"
                label="Enter your email address:"
                type="email"
                control={control}
                error={!!errors.email}
              />
              <Typography
                variant="body1"
                color="error"
                className={classes.errorMessage}
              >
                {errors.email?.message}
              </Typography>
            </Box>
            <Box mb={2}>
              <StyledInput
                id="login-password"
                name="password"
                label="And password:"
                type="password"
                control={control}
                error={!!errors.password}
              />
              <Typography
                variant="body1"
                color="error"
                className={classes.errorMessage}
              >
                {errors.password?.message}
              </Typography>
            </Box>
            <Box mb={2} mt={8}>
              <StyledButton
                type="submit"
                variant="contained"
                color="primary"
                onClick={handleSubmit(onSubmit)}
              >
                Login
              </StyledButton>
            </Box>
            {error && (
              <Typography
                variant="body1"
                color="error"
                className={classes.errorMessage}
              >
                {error.message}
              </Typography>
            )}
          </Grid>
        </form>
      </Grid>
    </Grid>
  );
}

export default Login;
